import { createSlice } from '@reduxjs/toolkit';
import { ApiCallBegan } from '../actions/api';

const JobPackageSlice = createSlice(
    {
        name: "jobPackages",
        initialState: {
            data: [],
            lastFetched: null,
            loading: false
        },
        reducers: {
            jobPackageFetched: (state, action) => {
                state.data = action.payload.data.results
                state.loading = false;
                state.lastFetched = Date.now()
                return state
            },
            jobPackageCallFailed: (state, action) => {
                state.loading = false;
                return state;
            },
            jobPackageCallBegan: (state, action) => {
                state.loading = true;
                return state;
            }
        }
    }
);

const { jobPackageCallBegan, jobPackageCallFailed, jobPackageFetched } = JobPackageSlice.actions;

export default JobPackageSlice.reducer;


export const loadJobPackages = () =>
    ApiCallBegan({
        url: "api/company/packages/",
        onSuccess: jobPackageFetched.type,
        onError: jobPackageCallFailed.type,
        onStart: jobPackageCallBegan.type

    })