import { createSelector, createSlice } from '@reduxjs/toolkit';
import { ApiCallBegan } from "../actions/api"
import checkCache from "../../services/cache"
import { coreCacheLimit } from "../../config.json"
import { toast } from "react-toastify";
const talentSlice = createSlice({
    name: "talent",
    initialState: {
        data: {},
        reload:0,
        lastFetch: null,
        loading: true
    },
    reducers: {
        talentFetched: (state, action) => {
            state.data = action.payload.data
            state.lastFetch = Date.now()
            state.loading = false;
            return state;
        },
        talentUpdated: (state, action) => {
            state.data = action.payload.data
            state.lastFetch = Date.now()
            toast.success("Information Updated")
            state.reload= 1;
            state.loading = false;
            // window.location.reload();
            return state;
        },
        talentRequested: (state, action) => {
            state.loading = true;
            return state
        },
        talentRequestFailed: (state, action) => {
            state.loading = false;
            toast.error("Somethig went Wrong; Please Try Again!")
            return state
        },
    }

})

export const getTalent = createSelector(state => state.entities.talent, talent => talent)
export const { talentFetched, talentRequested, talentRequestFailed, talentUpdated } = talentSlice.actions
export default talentSlice.reducer;


export const loadTalentInformation = () => (dispatch, getState) => {
    // remember to cache
    const { lastFetch } = getState().entities.talent
    if (checkCache(lastFetch, coreCacheLimit)) return;

    dispatch(ApiCallBegan({
        url: "api/talent/jobseeker/me/",
        onSuccess: talentFetched.type,
        onError: talentRequestFailed.type,
        onStart: talentRequested.type,
    }))
}

export const updateTalentInformation = (data) => (dispatch, getState) => {


    dispatch(ApiCallBegan({
        url: "api/talent/jobseeker/me/",
        method: "put",
        headers: { 'Content-Type': 'multipart/form-data; boundary=63c5979328c44e2c869349443a94200e' },
        data: data,
        onSuccess: talentUpdated.type,
        onError: talentRequestFailed.type,
        onStart: talentRequested.type,
    }))
}