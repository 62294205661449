import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import CompanyProfileSideBar from "../Element/companyProfileSideBar";
import { loadJobHistory } from "../../store/reducers/jobHistory";
import { connect } from "react-redux";
import _ from "lodash";
import Loading from "../Element/common/loading";
import { checkPermission } from "../../utils/utilFunctions";
import { loadJobPackages } from "../../store/reducers/jobPackage";
import PricingTableRow from "../Element/common/pricingTableRow";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { apiUrl } from "../../config.json";
const swal = withReactContent(Swal);
const pageUrl = "api/jobs/jobHistory/";
class CompanyBuyPackage extends Component {
  state = {
    sortColumn: { path: "full_name", order: "asc" },
    jobHistory: false,
    data: {
      current_package: null,
    },
    errors: {},
  };

  componentDidMount() {
    const permission = checkPermission("add", "job_package");
    if (!permission) {
      this.props.history.push("/");
    }
    this.props.loadJobPackages();
  }
  handlePackageSelect = (current_package) => {
    // //console.log(this.state.current_package,current_package, "selected")
    let data = { ...this.state.data };
    data.current_package = current_package;
    this.setState({ data });
  };
  renderPage = () => {
    if (this.props.jobPackage.loading == true) {
      return (
        <div className="col-xl-9 col-lg-8 m-b30">
          <Loading />
        </div>
      );
    } else {
      return (
        <div className="col-xl-9 col-lg-8 m-b30">
          <div className="job-bx table-job-bx clearfix">
            <div className="job-bx-title clearfix">
              <h5 className="font-weight-700 pull-left text-uppercase">
                Purchase A Job Package
              </h5>
              <Link
                to={"/company-post-jobs"}
                className="site-button right-arrow button-sm float-right"
              >
                Back
              </Link>
              {/* <Link to="/company-jobHistorys-form/new" className="site-button button-sm float-left m-l16">Add New jobHistory</Link> */}
            </div>

            <div className="p-a30 job-bx max-w2500 radius-sm bg-white m-auto">
              <div className="container">
                <div className="section-head text-black text-center">
                  <h2 className="m-b0">Membership Plans</h2>
                  <p></p>
                </div>
                <div className="section-content box-sort-in button-example m-t80">
                  {this.state.errors.current_package && (
                    <div className="alert alert-danger fs-5">
                      {this.state.errors.current_package}
                    </div>
                  )}
                  <div className="pricingtable-row">
                    <div className="row max-w2500 m-auto">
                      {this.props.jobPackage.data.map((item, index) => {
                        return (
                          <PricingTableRow
                            key={item.id}
                            selected={this.state.data.current_package}
                            item={item}
                            handleSelect={this.handlePackageSelect}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div className="row">
                  <div className="text-left">
                    {
                        this.state.data.current_package && (

                            <button className="site-button button-lg outline outline-2 mr-2" onClick={()=>{
                                swal.fire({
                                    title: "Are you sure?",
                                    text: "You want to purchase this package!",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonText: "Yes, Purchase it!",
                                    cancelButtonText: "No, cancel!",
                                    reverseButtons: true,
                                    }).then(async (result) =>  {
                                        
                                    if (result.isConfirmed) {
                                        let data = new FormData();
                                        data.append("package_id", this.state.data.current_package);
                                        
                                        await axios.post(apiUrl+"api/company/employer/buyPackage/", data)
                                        .then((res)=>{
                                            // console.log(res.status == 202 ,res.status)
                                            if(res.status == 202){
                                                swal.fire({
                                                    title: "Success",
                                                    text: "Your package purchase request has been submitted! Your package will be available after proper payment is done.",
                                                    icon: "success",
                                                });
                                            // reload page
                                            window.location.reload();
                                            }
                                            else{
                                                swal.fire(
                                                    {
                                                        title:"Error",
                                                        text:"Something went wrong",
                                                        icon:"error"
                                                    }

                                                )
                                            }
                                        })
                                        
                                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                                        swal.fire({
                                            title: "Cancelled",
                                            text: "Your package is not purchased.",
                                            icon: "error",
                                        });
                                    }
                                    }

                                );

                                
                            }}>Purchase Package</button>
                        )
                  }
                    {/* {this.renderButton("Submit")} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  render() {
    return (
      <>
        <Header />
        <div className="page-content bg-white">
          <div className="content-block">
            <div className="section-full bg-white p-t50 p-b20">
              <div className="container-fluid">
                <div className="row">
                  <CompanyProfileSideBar currentPage={"Buy Package"} />
                  {this.renderPage()}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  jobHistory: state.entities.jobHistory,
  jobPackage: state.entities.jobPackage,
});

const mapDispatchToProps = (dispatch) => ({
  loadJobHistory: (data) => dispatch(loadJobHistory(data)),
  loadJobPackages: () => dispatch(loadJobPackages()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyBuyPackage);
// export default CompanyPackageManage;
