import React from "react";
import Joi from "joi-browser";
import { Link } from "react-router-dom";

import loginbg from "./../../images/bg6.jpg";
import logo2 from "./../../images/logo-black.png";
import Form from "../Element/common/form";
import authServices from "../../services/authServices";
import { apiUrl } from "../../config.json";
import http from "../../services/httpService";
import { toast } from "react-toastify";
import axios from "axios";

const apiEndpoint = apiUrl + "auth/users/reset_password/";
const confirmPasswordUrl = apiUrl + "auth/users/reset_password_confirm/";
class ResetPasswordPage extends Form {
  state = {
    page: 0,
    success:-1,
    data: {
      new_password: "",
      confirmPassword: "",
    },
    errors: {},
  };

  schema = {
    new_password: Joi.string().min(8).required().label("New Password"),
    confirmPassword: Joi.string().min(8).required().label("Confirm Password"),
  };
  componentDidMount() {
    if (
      this.props.match.params.token !== undefined &&
      this.props.match.params.uid !== undefined
    ) {
      console.log("token", this.props.match.params.token);
      this.setState({ page: 1 });
    } else {
      console.log(">>>>>>>>>>>>>>>>>>token<<<<<<<<<<<<<<", this.props);
    }
  }
  routingFunction = (param) => {
    this.props.history.push({
      pathname: `/`,
      state: param,
    });
  };

  doSubmit = async () => {
    const data = { ...this.state.data };
    let errors = { ...this.state.errors };
    if (data.new_password !== data.confirmPassword) {
      errors.confirmPassword = "Password does not match";
      this.setState({ errors });
      return;
    }
    try {
      const resp = await axios.post(confirmPasswordUrl, {
        new_password: data.new_password,
        uid: this.props.match.params.uid,
        token: this.props.match.params.token,
      });

      if (resp.status === 204) {
        toast.success("Password changed successfully");
        this.props.history.push("/login");
      }
    } catch (err) {
      toast.error("Reset Token has Expired Please Try Again");
      const data = err.response.data;
      let errors = { ...this.state.errors };
      Object.keys(data).forEach((key) => {
        //console.log(key)
        errors[key] = data[key];
      });
      this.setState({ errors });
      //console.log(err.response.data, "<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<")
      // if (err.response && err.response.status === 400) {
      toast.error(err.response);
      // }

      // toast.error()
    }
  };

  resetEmailLink = () => {
    return (
      <div className="col-lg-6 col-md-6">
        <div className="login-2 submit-resume p-a30 seth">
          <div className="nav">
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                try {
                  let response = await axios.post(apiEndpoint, {
                    email: e.target.email.value,
                  });
                  

                  if (response.status === 204) {
                   this.setState({success:1})
                  }
                } catch (err) {
                  this.setState({success:0})

                }
                
              }}
              className="col-12 p-a0 "
            >
              <p className="font-weight-600">Please Enter Your email</p>
              {/* danger alert */}
             {this.state.success===1 && <div
                className="alert alert-success alert-dismissible fade show"
                role="alert"
              >
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <strong>Done!</strong> we've sent you an email with a link to reset your password.
              </div>}

             {this.state.success===0 && <div
                className="alert alert-danger alert-dismissible fade show"
                role="alert"
              >
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <strong>Oh snap!</strong> we Can't find your email, please try
                again.
              </div>}

              <div className="form-group">
                <label className="font-weight-700">Email</label>
                <input
                  name="email"
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  required
                />
              </div>

              <div className="text-center">
                {this.renderButton("Reset Password")}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };
  changePassword = () => {
    return (
      <div className="col-lg-6 col-md-6">
        <div className="login-2 submit-resume p-a30 seth">
          <div className="nav">
            <form onSubmit={this.handleSubmit} className="col-12 p-a0 ">
              <div className="col-lg-12">
                {this.renderInput("new_password", "New Password", "password")}
                {/* <div className="form-group">
															<label>New Password </label>
															<input type="password" className="form-control" />
														</div> */}
              </div>
              <div className="col-lg-12">
                {this.renderInput(
                  "confirmPassword",
                  "Confirm Password",
                  "password"
                )}
                {/* <div className="form-group">
															<label>Confirm New Password</label>
															<input type="password" className="form-control" />
														</div> */}
              </div>
              <div className="col-lg-12 m-b10">
                <button className="site-button">Update Password</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="page-wraper">
        <div
          className="page-content bg-white login-style2"
          style={{
            backgroundImage: "url(" + loginbg + ")",
            backgroundSize: "cover",
          }}
        >
          <div className="section-full">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6 d-flex ">
                  <div
                    className="text-white max-w400 align-self-center rounded"
                    style={{ padding: "21px", backgroundColor: "#0000006e" }}
                  >
                    <div className="logo">
                      <Link to={"/"}>
                        <img src={logo2} alt="" />
                      </Link>
                    </div>
                    <h2 className="m-b10">Welcome Back</h2>
                    <p className="m-b30">
                      ethiotalent is the best online recruitment solution
                      provider in Ethiopia. The website advertises jobs across a
                      wide range of job types by different employers, including
                      private, local, international and multinational companies
                      who are hiring in Ethiopia.
                    </p>
                    <ul className="list-inline m-a0">
                      <li>
                        <Link to={""} className="m-r10 text-white ">
                          <i className="fa fa-facebook"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to={""} className="m-r10 text-white ">
                          <i className="fa fa-google-plus"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to={""} className="m-r10 text-white ">
                          <i className="fa fa-linkedin"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to={""} className="m-r10 text-white ">
                          <i className="fa fa-instagram"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to={""} className="m-r10 text-white">
                          <i className="fa fa-twitter"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {this.state.page == 0
                  ? this.resetEmailLink()
                  : this.changePassword()}
              </div>
            </div>
          </div>
          <footer className="login-footer">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <span className="float-left">
                    © Copyright by{" "}
                    <i className="fa fa-bolt m-lr5 text-yellow heart"></i>
                    <Link to={"#"}>ABH Partners </Link>{" "}
                  </span>
                  <span className="float-right">All rights reserved.</span>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default ResetPasswordPage;
