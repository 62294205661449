import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { Modal } from "react-bootstrap";
import CompanyProfileSideBar from "../Element/companyProfileSideBar";
import Table from "../Element/common/table";
import { getMyJobs } from "../../store/reducers/myjobs";
import { getMyJobsCompanyUrl } from "../../config.json";
import { checkPermission } from "../../utils/utilFunctions";

import { connect } from "react-redux";
import _ from "lodash";
import { clearApplicantsData } from "../../store/reducers/appliedApplicants";

class AdminCompanyManage extends Component {
  state = {
    company: false,
    sortColumn: { path: "title", order: "asc" },
  };
  componentDidMount() {
    const company = this.props.match.params.id;
    if (company === undefined) {
      this.props.history.push("/");
    }
    this.props.getMyJobs(getMyJobsCompanyUrl+`${this.props.match.params.id}/`,true);
    //console.log(this.props.myJobs)
  }

  setCompany = (company) => {
    this.setState({ company: company });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  buildStatus(status) {
    switch (status) {
      case 0:
        return <td className="expired text-warning">Draft</td>;
      case 1:
        return <td className="expired text-success">Active </td>;
      case 2:
        return <td className="expired text-primary">Pending </td>;
      case 3:
        return <td className="expired text-danger">Expired </td>;
      case 4:
        return <td className="expired text-danger">Canceled </td>;
      case 5:
        return <td className="expired text-success">Selecting </td>;
      case 6:
        return <td className="expired text-danger">Closed</td>;
      default:
        return <td className="expired text-danger">Closed</td>;
    }
  }

  handleNext = () => {
    //console.log(this.props.myJobs.next)
    let url = this.props.myJobs.next.split("?")[1];
    this.props.getMyJobs(`${getMyJobsCompanyUrl+`${this.props.match.params.id}/`}?${url}`,true);
  };

  handlePrevious = () => {
    //console.log(this.props.myJobs.previous)
    let url = this.props.myJobs.previous.split("?")[1];
    this.props.getMyJobs(`${getMyJobsCompanyUrl+`${this.props.match.params.id}/`}?${url}`,true);
  };

  handlePageChange = (page) => {
    this.props.getMyJobs(`${getMyJobsCompanyUrl+`${this.props.match.params.id}/`}?page=${page}`,true);
  };

  pagination = (
    handlePrevious,
    handlePageChange,
    handleNext,
    currentPage,
    pageCount
  ) => {
    let pages = [];
    let lastIndex = 0;
    if (pageCount <= 11) {
      pages = _.range(pageCount);
    } else {
      pages.push(0);
      pages.push(1);
      if (currentPage - 3 >= 2) {
        pages.push("...");
        for (let i = currentPage - 3; i <= currentPage + 3; i++) {
          if (i > 1 && i < pageCount - 2) {
            pages.push(i);
          }
        }
      } else {
        for (let i = 2; i <= currentPage + 6 - (currentPage - 2); i++) {
          if (i > 1 && i < pageCount - 2) {
            pages.push(i);
          }
        }
      }
      if (currentPage + 3 < pageCount) {
        pages.push("...");
      }

      pages.push(pageCount - 2);
      pages.push(pageCount - 1);
    }
    return (
      <div className="row">
        <div>
          <div className="pagination-bx m-t30 float-right">
            <ul className="pagination">
              {currentPage != 0 && (
                <li className="previous">
                  <Link to={"#"} onClick={handlePrevious}>
                    <i className="ti-arrow-left"></i> Prev
                  </Link>
                </li>
              )}

              {pages.map((page, index) => {
                if (page == "...") {
                  return (
                    <li className="">
                      <Link to={"#"}>...</Link>
                    </li>
                  );
                } else {
                  return (
                    <li className={page == currentPage ? "active" : ""}>
                      <Link
                        onClick={() => {
                          handlePageChange(page + 1);
                        }}
                        to={"#"}
                      >
                        {page + 1}
                      </Link>
                    </li>
                  );
                }
              })}

              {/* <li className="active"><Link to={"#"}>1</Link></li>
					<li><Link to={"#"}>2</Link></li>
					<li><Link to={"#"}>3</Link></li> */}
              {pageCount - 1 != currentPage && (
                <li className="next">
                  <Link to={"#"} onClick={handleNext}>
                    Next <i className="ti-arrow-right"></i>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    );
  };

  raiseSort = (path) => {
    let sortColumn = this.state.sortColumn;
    if (sortColumn.path === path)
      sortColumn.order = sortColumn.order === "asc" ? "desc" : "asc";
    else {
      sortColumn.path = path;
      sortColumn.order = "asc";
    }
    this.setState({ sortColumn });
    //console.log(sortColumn, "SORT<<")
  };

  renderSortIcon = (column) => {
    const sortColumn = this.state.sortColumn;

    if (column.path !== sortColumn.path) return null;
    if (sortColumn.order === "asc") return <i className="fa fa-sort-asc" />;
    return <i className="fa fa-sort-desc" />;
  };

  generateHeaders = (headers) => {
    return (
      <thead>
        <tr>
          {headers.map((header, index) => {
            return (
              <th
                className="clickable"
                style={{ cursor: "pointer" }}
                onClick={() => this.raiseSort(header.path)}
                key={index}
              >
                {header.label} {this.renderSortIcon(header)}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };
  generateBody = (data) => {
    const { currentPage, pageCount } = this.props.myJobs;
    return (
      <tbody>
        {data.map((item, index) => {
          return (
            <tr key={index}>
              <td>{item.id}</td>
              <td className="job-name">
                <Link to={`/job-detail/${item.id}`}>{item.title}</Link> -{" "}
                {Intl.NumberFormat().format(item.views)} views
              </td>
              <td> {item.date_created.split("T")[0]}</td>
              <td>{item.application_start_date}</td>
              <td>{item.application_end_date}</td>
              <td className="application text-primary">
                {Intl.NumberFormat().format(item.applicants)} Applications
              </td>
              <td>{this.buildStatus(item.is_published)}</td>
              <td className="job-links">
                <Link
                  to={`/company-resume/${item.id}`}
                  className="site-button"
                  onClick={() => {
                    this.props.clearApplicants();
                    
                  }}
                >
                  View Applicants
                </Link>
                
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  renderPage() {
    if (this.props.myJobs.loading == true || !this.props.myJobs.data[0]) {
      return <h1>Loading...</h1>;
    } else {
      const columns = [
        { path: "id", label: "ID" },
        { path: "title", label: "Job Title/views" },
        { path: "date_posted", label: "Date Posted" },
        { path: "application_start_date", label: "Start Date" },
        { path: "application_end_date", label: "Deadline" },
        { path: "applicants", label: "Applicants" },
        { path: "is_published", label: "Status" },
        { path: "", label: "Actions" },
      ];
      // const sortColumn = { path: 'name', order: 'asc' }
      let data = [];
      let companyName = ""

      const myJobs =
        this.props.myJobs.data[parseInt(this.props.myJobs.currentPage)];
        try{
          companyName = myJobs[0].company
        }
        catch(e){

        }
      // //console.log(myJobs[0].id, "MYJOBS")
      myJobs.map((item, index) => {
        data.push({
          id: item.id,
          title: item.title,
          application_start_date: item.application_start_date,
          application_end_date: item.application_end_date,
          is_published: item.is_published == 1 ? "Published" : "Unpublished",
        });
        // //console.log(data)
      });

      const { company, sortColumn } = this.state;
      // //console.log(columns)
      return (
        <div className="col-xl-12 col-lg-12 m-b30">
          <div className="job-bx browse-job clearfix">
            <div className="job-bx-title  clearfix">
              <h5 className="font-weight-700 pull-left text-uppercase">
                Manage jobs for {companyName}
              </h5>
              {/* <div className="float-right">
								<span className="select-title">Sort by freshness</span>
								<select className="custom-btn">
									<option>All</option>
									<option>None</option>
									<option>Read</option>
									<option>Unread</option>
									<option>Starred</option>
									<option>Unstarred</option>
								</select>
							</div> */}
            </div>

            {/* <Table
							columns={columns}
							data={data}
							sortColumn={this.state.sortColumn}
							onSort={this.handleSort}
						/> */}
            <table className="table-job-bx cv-manager company-manage-job">
              {this.generateHeaders(columns)}

              {this.generateBody(
                this.props.myJobs.data[parseInt(this.props.myJobs.currentPage)]
              )}
            </table>
            {this.pagination(
              this.handlePrevious,
              this.handlePageChange,
              this.handleNext,
              this.props.myJobs.currentPage,
              this.props.myJobs.pageCount
            )}

           
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <>
        <Header />
        <div className="page-content bg-white">
          <div className="content-block">
            <div className="section-full bg-white p-t50 p-b20">
              <div className="container">
                <div className="row">
                  {this.renderPage()}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  myJobs: state.entities.myJobs,
});

const mapDispatchToProps = (dispatch) => ({
  getMyJobs: (url,data) => dispatch(getMyJobs(url,data)),
  clearApplicants: () => dispatch(clearApplicantsData()),
});
export default connect(mapStateToProps, mapDispatchToProps)(AdminCompanyManage);
