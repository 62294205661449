import moment from "moment";

export default function checkCache(lastFetched, time) {
    if (lastFetched==null) return false;
    const timeDiffInMinutes = moment().diff(moment(localStorage.getItem(lastFetched)), "minutes");
    if (timeDiffInMinutes >= time) {
        localStorage.removeItem(lastFetched);
        return false;
    }
    return true;

}