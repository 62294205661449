import { createSlice } from '@reduxjs/toolkit';
import { ApiCallBegan } from "../actions/api"
import checkCache from "../../services/cache";
import { coreCacheLimit } from "../../config.json"


const currencySlice = createSlice({
    name: "currency",
    initialState: {
        data: [],
        lastFetch: null,
        loading: false

    },
    reducers: {
        currencyAdded: (currency, action) => {
            currency = action.payload.data.results
            currency.lastFetch = Date.now()
            currency.loading = false;
            return currency
        },
        currencyRequested: (currency, action) => {
            currency.loading = true;
            return currency
        },
        currencyRequestFailed: (currency, action) => {
            currency.loading = false;
            return currency
        }


    }

})

export const { currencyAdded, currencyRequestFailed, currencyRequested } = currencySlice.actions
export default currencySlice.reducer;

export const loadCurrency = () => (dispatch, getState) => {
    const { lastFetch } = getState().entities.currency
    if (checkCache(lastFetch, coreCacheLimit)) return;
    dispatch(ApiCallBegan({
        url: "api/core/currency/",
        onStart: currencyRequested.type,
        onSuccess: currencyAdded.type,
        onError: currencyRequestFailed.type
    }))
}
