import { createSlice } from '@reduxjs/toolkit';
import { ApiCallBegan } from "../actions/api"
import checkCache from "../../services/cache";
import { coreCacheLimit } from "../../config.json"


const companyReportsSlice = createSlice({
    name: "companyReports",
    initialState: {
        data: [],
        lastFetch: null,
        loading: true

    },
    reducers: {
        companyReportAdded: (companyReport, action) => {
            console.log(">>><<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<",action.payload.data)
            companyReport.data = action.payload.data
            companyReport.lastFetch = Date.now()
            companyReport.loading = false;
            return companyReport
        },
        companyReportRequested: (companyReport, action) => {
            companyReport.loading = true;
            return companyReport
        },
        companyReportRequestFailed: (companyReport, action) => {
            companyReport.loading = false;
            return companyReport
        }


    }

})

export const { companyReportAdded, companyReportRequestFailed, companyReportRequested } = companyReportsSlice.actions
export default companyReportsSlice.reducer;

export const loadCompanyReport = (id) => (dispatch, getState) => {
    // const { lastFetch } = getState().entities.companyReports;
    // // alert(1)
    // if (checkCache(lastFetch, coreCacheLimit)) return;
    dispatch(ApiCallBegan({
        url: `/api/core/reports/companyReport/?id=${id}`,
        onStart: companyReportRequested.type,
        onSuccess: companyReportAdded.type,
        onError: companyReportRequestFailed.type
    }))
}
