import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import auth from "../../services/authServices";
import { apiUrl } from "../../config.json";
import { checkPermission } from "../../utils/utilFunctions";

const CompanyProfileSideBar = ({ currentPage }) => {
  const user = auth.getCurrentUser();
  const authData = useSelector((state) => state.auth.auth);
  // alert("1")
  const companyProfile = checkPermission("view", "company")
    ? {
        name: "Company Profile",
        link: "/company-profile",
        icon: "fa fa-user",
      }
    : null;
  const postJob = checkPermission("add", "job")
    ? {
        name: "Post A Job",
        link: "/company-post-jobs",
        icon: "fa fa-briefcase",
      }
    : null;
  const manageUsers = checkPermission("view", "user")
    ? {
        name: "Manage Users",
        link: "/company-users",
        icon: "fa fa-user",
      }
    : null;
  const manageJobs = checkPermission("view", "job")
    ? {
        name: "Manage Jobs",
        link: "/company-manage-job",
        icon: "fa fa-briefcase",
      }
    : null;
  const managePackages = checkPermission("view", "job_package")
    ? {
        name: "Manage Package",
        link: "/company-packages",
        icon: "fa fa-reorder",
      }
    : null;
  const buyPackages = checkPermission("add", "job_package")
    ? {
        name: "Buy Package",
        link: "/purchase-job-package",
        icon: "fa fa-money",
      }
    : null;
  const Pages = [
    companyProfile,
    postJob,
    // {
    //     name: "Transactions",
    //     link: "/company-transactions",
    //     icon: "fa fa-money",
    // },
    manageJobs,
    // {
    //     name: "Company Resume",
    //     link: "/company-resume",
    //     icon: "fa fa-id-card-o",

    // },
    manageUsers,
    managePackages,
    buyPackages,
    {
      name: "Change Password",
      link: "/jobs-change-password",
      icon: "fa fa-key",
    },
  ];

  return (
    <div className="col-xl-3 col-lg-4 m-b30">
      <div className="sticky-top">
        <div className="candidate-info company-info">
          <div className="candidate-detail text-center">
            <div className="canditate-dess">
              <Link to={"#"}>
                <img
                  alt="Company Logo"
                  style={{ width: "150px", height: "150px", objectFit: "fill" }}
                  src={apiUrl + authData.data.logo}
                />
              </Link>
              {/* <div className="upload-link" title="update" data-toggle="tooltip" data-placement="right">
                                <input type="file" className="update-flie" />
                                <i className="fa fa-pencil"></i>
                            </div> */}
            </div>
            <div className="candidate-title">
              <h4 className="m-b5">
                <Link to={"#"}>{user.companyName}</Link>
              </h4>
                <p style={{fontSize:"1.4em"}}>Remaining job Posts: <strong>{authData.data.remaining_jobs}</strong></p>
                {authData.data.remaining_jobs ==0 && (
                  <p style={{fontSize:"1em"}}>
                    <Link className="site-button" to={"/purchase-job-package"}>Buy a package</Link>
                  </p>

                )}
            </div>
          </div>
          <ul>
            {Pages.map((page, index) => {
              if (page == null) return null;
              return (
                <li key={index}>
                  <Link
                    to={page.link}
                    className={currentPage == page.name ? "active" : ""}
                  >
                    <i className={page.icon} aria-hidden="true"></i>
                    <span>{page.name}</span>
                  </Link>
                </li>
              );
            })}
            {/* <li><Link to={"/company-profile"} className={currentPage == "Company Profile" ? "active" : ""}>
                            <i className="fa fa-user-o" aria-hidden="true"></i>
                            <span>Company Profile</span></Link></li>
                        <li><Link to={"/company-post-jobs"}>
                            <i className="fa fa-file-text-o" aria-hidden="true"></i>
                            <span>Post A Job</span></Link></li>
                        <li><Link to={"/company-transactions"}>
                            <i className="fa fa-random" aria-hidden="true"></i>
                            <span>Transactions</span></Link></li>
                        <li><Link to={"/company-manage-job"}>
                            <i className="fa fa-briefcase" aria-hidden="true"></i>
                            <span>Manage jobs</span></Link></li>
                        <li><Link to={"/company-resume"}>
                            <i className="fa fa-id-card-o" aria-hidden="true"></i>
                            <span>Resume</span></Link></li>
                        <li><Link to={"/jobs-change-password"}>
                            <i className="fa fa-key" aria-hidden="true"></i>
                            <span>Change Password</span></Link></li>
                        <li><Link to={"./"}>
                            <i className="fa fa-sign-out" aria-hidden="true"></i>
                            <span>Log Out</span></Link></li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CompanyProfileSideBar;
