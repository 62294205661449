import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Jobsection from "../Element/Jobsection";
import auth from "../../services/authServices";
import { getOpenJobs } from "../../store/reducers/openJobs";
import { connect } from "react-redux";
import { loadsectorAggrigates } from "../../store/reducers/sectorAggrigate";
import Loading from "../Element/common/loading";
import { Nav, Tab } from "react-bootstrap";
import { Controls, Reveal, Tween } from "react-gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import gsap from "gsap";
import BannerAd from "../Element/common/banner";
import { loadBanners } from "../../store/reducers/banners";

gsap.registerPlugin(ScrollTrigger);
var bnr1 = require("./../../images/main-slider/slide2T.png");
var bnr2 = require("./../../images/slide1.jpg");
const TelegramIcon = require("../../images/social_icons/telegram.png");

var bnr3 = require("./../../images/telegram.png");
class Homepage2 extends Component {
  state = {
    width: 0,
    height: 0,
    user: "",
  };
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentDidMount() {
    const user = auth.getCurrentUser();
    this.props.loadsectorAggrigates();
    this.props.loadBanners();
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    this.setState({ user });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  renderJobCategories = () => {
    const { sectorAggrigate } = this.props;
    if (sectorAggrigate.loading) {
      return <Loading />;
    } else {
      let sectorAggrigateData = [];
      try {
        sectorAggrigateData = Object.keys(sectorAggrigate.data.sectors);
      } catch {
        sectorAggrigateData = [];
      }
      let items = [[], [], []];
      // alert(sectorAggrigateData.length)
      for (let i = 0; i < sectorAggrigateData.length; i++) {
        items[i % 2].push({
          item: sectorAggrigateData[i],
          count: sectorAggrigate.data.sectors[sectorAggrigateData[i]],
        });
      }

      return (
        <div className="job-bx bg-white" style={{ overflow: "hidden" }}>
          <div className="job-bx-title clearfix">
            <h6 className="text-uppercase">Browse Jobs by Industry / Sector</h6>
          </div>
          <div
            className="row"
            style={{ maxHeight: "500px", overflowY: "auto" }}
          >
            <div className="col-lg-6 col-sm-12">
              <ul className="category-list">
                {items[0].map((item, index) => {
                  return (
                    <li key={index}>
                      <div
                        className=""
                        style={{
                          // textDecoration: "underline",
                          overflow: "hidden",
                          borderRadius: "4px",
                          backgroundColor: "#e9e9e9",
                          padding: "0px",
                          color: "black",
                          textAlign: "left",

                          cursor: "pointer",
                        }}
                        onClick={() => {
                          this.props.getOpenJobs(null, item.item);
                          this.props.history.push("/browse-job-filter-list");
                          this.props.getbanners();
                        }}
                      >
                        <table style={{ margin: "0px" }}>
                          <tbody>
                            <tr>
                              <td>{item.item}</td>

                              <td
                                style={{
                                  textAlign: "center",
                                  overflow: "hidden",
                                  width: "10px",
                                  backgroundColor: "#282A3C",
                                  padding: "6px",
                                  color: "white",
                                }}
                              >
                                <span>{item.count}</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="col-lg-6 col-sm-12">
              <ul className="category-list">
                {items[1].map((item, index) => {
                  return (
                    <li key={index}>
                      <div
                        className=""
                        style={{
                          // textDecoration: "underline",
                          overflow: "hidden",
                          borderRadius: "4px",
                          backgroundColor: "#e9e9e9",
                          padding: "0px",
                          color: "black",
                          textAlign: "left",

                          cursor: "pointer",
                        }}
                        onClick={() => {
                          this.props.getOpenJobs(null, item.item);
                          this.props.history.push("/browse-job-filter-list");
                        }}
                      >
                        <table style={{ margin: "0px" }}>
                          <tbody>
                            <tr>
                              <td>{item.item}</td>

                              <td
                                style={{
                                  textAlign: "center",
                                  overflow: "hidden",
                                  width: "10px",
                                  backgroundColor: "#282A3C",
                                  padding: "6px",
                                  color: "white",
                                }}
                              >
                                <span>{item.count}</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            {/* <div className="col-lg-4 col-sm-6">
              <ul className="category-list">
                {items[2].map((item, index) => {
                  return (
                    <li key={index}>
                      <div
                        className=""
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          this.props.getOpenJobs(null, item.item);
                          this.props.history.push("/browse-job-filter-list");
                        }}
                      >
                        <table style={{ margin: "0px" }}>
                          <tbody>
                            <tr>
                             <td  style={{overflow:"hidden"}} >{item.item}</td>
                              <td style={{ textAlign: "right" }}>
                                <span className="badge badge-primary text-white badge-lg">
                                  {item.count}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div> */}
          </div>
        </div>
      );
    }
  };

  renderJobLocations = () => {
    const { sectorAggrigate } = this.props;
    if (sectorAggrigate.loading) {
      return <Loading />;
    } else {
      let sectorAggrigateData = Object.keys(sectorAggrigate.data.regions);
      let items = [[], []];
      // alert(sectorAggrigateData.length)
      for (let i = 0; i < sectorAggrigateData.length; i++) {
        items[i % 2].push({
          item: sectorAggrigateData[i],
          count: sectorAggrigate.data.regions[sectorAggrigateData[i]],
        });
      }

      return (
        <div className="job-bx bg-white" style={{ overflow: "hidden" }}>
          <div className="job-bx-title clearfix">
            <h6 className="text-uppercase">Browse Jobs by Location</h6>
          </div>
          <div
            className="row"
            style={{ maxHeight: "500px", overflowY: "auto" }}
          >
            <div className="col-lg-6 col-sm-12">
              <ul className="category-list">
                {items[0].map((item, index) => {
                  return (
                    <li key={index}>
                      <div
                        className=""
                        style={{
                          // textDecoration: "underline",
                          overflow: "hidden",
                          borderRadius: "4px",
                          backgroundColor: "#e9e9e9",
                          padding: "0px",
                          color: "black",
                          textAlign: "left",

                          cursor: "pointer",
                        }}
                        onClick={() => {
                          this.props.getOpenJobs(null, item.item);
                          this.props.history.push("/browse-job-filter-list");
                        }}
                      >
                        <table style={{ margin: "0px" }}>
                          <tbody>
                            <tr>
                              <td>{item.item}</td>

                              <td
                                style={{
                                  textAlign: "center",
                                  overflow: "hidden",
                                  width: "10px",
                                  backgroundColor: "#282A3C",
                                  padding: "6px",
                                  color: "white",
                                }}
                              >
                                <span>{item.count}</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="col-lg-6 col-sm-12">
              <ul className="category-list">
                {items[1].map((item, index) => {
                  return (
                    <li key={index}>
                      <div
                        className=""
                        style={{
                          // textDecoration: "underline",
                          overflow: "hidden",
                          borderRadius: "4px",
                          backgroundColor: "#e9e9e9",
                          padding: "0px",
                          color: "black",
                          textAlign: "left",

                          cursor: "pointer",
                        }}
                        onClick={() => {
                          this.props.getOpenJobs(null, item.item);
                          this.props.history.push("/browse-job-filter-list");
                        }}
                      >
                        <table style={{ margin: "0px" }}>
                          <tbody>
                            <tr>
                              <td>{item.item}</td>

                              <td
                                style={{
                                  textAlign: "center",
                                  overflow: "hidden",
                                  width: "10px",
                                  backgroundColor: "#282A3C",
                                  padding: "6px",
                                  color: "white",
                                }}
                              >
                                <span>{item.count}</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      );
    }
  };

  renderTopBanners = () => {
    if (this.props.banners.loading) {
      return null;
    } else {
      return this.props.banners.data.map((item, index) => {
        if (item.position === "T") {
          console.log(item.position);

          return (
            <BannerAd
              key={index}
              title={item.title}
              description={item.description}
              imageUrl={item.image}
              linkUrl={item.actionUrl}
              linkText={item.actionText}
              isParallax={item.parallax}
            />
          );
        }
      });
    }
  };

  renderBottomBanners = () => {
    if (this.props.banners.loading) {
      return null;
    } else {
      return this.props.banners.data.map((item, index) => {
        if (item.position == "B") {
          return (
            <BannerAd
              key={index}
              title={item.title}
              description={item.description}
              imageUrl={item.image}
              linkUrl={item.actionUrl}
              linkText={item.actionText}
              isParallax={item.parallax}
            />
          );
        }
      });
    }
  };
  render() {
    return (
      <>
        <Header />

        <div className="page-content">
          <Tween
            from={{ x: "200px", backgroundColor: "#ffffff" }}
            to={{ x: "0px", backgroundColor: "#009299" }}
            duration={2}
            ease="expo.out(1.7)"
          >
            <div
              className="dez-bnr-inr dez-bnr-inr-md "
              style={{
                backgroundImage: "url(" + bnr1 + ")",
                maxHeight: "400px",
                backgroundColor: "#009299",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "left",
              }}
            >
              <div className="container">
                <div className="dez-bnr-inr-entry align-m text-white">
                  <div
                    className=" job-search-form"
                    // style={{ width: this.state.width > 1174 ? "70%" : "100%" }}
                  >
                    <Reveal repeat>
                      <Tween
                        from={{
                          x: "600px",
                          opacity: 0,
                        }}
                      >
                        <h2 className="text-center">
                          The Easiest Way to Get Your New Job
                        </h2>
                      </Tween>
                    </Reveal>
                    <Reveal repeat>
                      <Tween
                        from={{
                          x: "-600px",
                          opacity: 0,
                        }}
                      >
                        <h4 className="text-center">
                          Find Jobs, Employment & Career Opportunities
                        </h4>
                      </Tween>
                    </Reveal>
                    {/* <h3>Find Jobs, Employment & Career Opportunities</h3> */}
                    <Reveal repeat>
                      <Tween
                        from={{
                          x: "600px",
                          opacity: 0,
                          
                        }}
                      >
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Job Title, Keywords Or Company Name"
                            id="searchField"
                          />
                          {/* <input type="text" className="form-control" placeholder="City, Province Or Region" /> */}
                          <div className="input-group-prepend">
                            <button
                              className="site-button"
                              onClick={() => {
                                this.props.getOpenJobs(
                                  null,
                                  document.getElementById("searchField").value
                                );
                                this.props.history.push(
                                  "/browse-job-filter-list"
                                );
                                // //console.log(this.props)
                                // this.props.this.props.history.push({
                                // 	pathname: '/jobs',
                                // 	state:{}
                                // });
                              }}
                            >
                              Search
                            </button>
                          </div>
                        </div>
                      </Tween>
                    </Reveal>
                  </div>
                </div>
              </div>
            </div>
          </Tween>
          {/* <div
            className="dez-bnr-inr dez-bnr-inr-md content-inner-2 call-to-action   text-center  m-t100"
            style={{ backgroundImage: "url(" + bnr3 + ")" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <a
                    className="site-button radius-xl"
                    href={"https://t.me/abhpartners"}
                    target="_blank"
                  >
                    Join us on Telegram
                  </a>
                </div>
              </div>
            </div>
          </div> */}
          {this.renderTopBanners()}

          <Jobsection />
          {this.renderBottomBanners()}

          {this.state.user == null && (
            <div
              className="section-full content-inner-2 call-to-action text-white overlay-black-dark text-center bg-img-fix m-t100"
              style={{ backgroundImage: "url(" + bnr2 + ")" }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <h2 className="m-b10">
                      Make a Difference with Your Online Resume!
                    </h2>
                    {/* <p className="m-b0">Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p> */}
                    <Link
                      to={"/register-2"}
                      className="site-button m-t20 outline outline-2 radius-xl"
                    >
                      Create an Account
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="container">
            <Tab.Container defaultActiveKey="category">
              <div className="row">
                {/* <div className="container"> */}

                {/* </div> */}

                <div className="col-lg-9 m-b30">
                  <Tab.Content>
                    <Tab.Pane eventKey="category">
                      <div className=" job-categories content-inner-2 bg-white">
                        {/* <div className="container"> */}
                        <div className="section-head text-center">
                          <h2 className="m-b5">Popular Categories</h2>
                          {/* <h5 className="fw4">
                            20+ Categories waiting for you
                          </h5> */}
                        </div>

                        {/* <Jobcategorie /> */}
                        {this.renderJobCategories()}
                      </div>
                      {/* </div> */}
                    </Tab.Pane>
                    {/* </div> */}
                    <Tab.Pane eventKey="location">
                      {/* <div className="col-lg-6 m-b30"> */}
                      <div className="section-full job-categories content-inner-2 bg-white">
                        {/* <div className="container"> */}
                        <div className="section-head text-center">
                          <h2 className="m-b5">Browse Jobs By Location</h2>
                          {/* <h5 className="fw4">20+ Categories waiting for you</h5> */}
                        </div>

                        {/* <Jobcategories /> */}
                        {this.renderJobLocations()}
                      </div>
                      {/* </div> */}
                      {/* </div> */}
                    </Tab.Pane>
                  </Tab.Content>
                </div>
                <div
                  className="col-lg-3"
                  style={{
                    paddingTop: this.state.width > 986 ? "200px" : "0px",
                  }}
                >
                  <div tabs="true">
                    <div className="site-filters clearfix center  ">
                      <Nav as="ul" className="filters">
                        <Nav.Item as="li" className="">
                          <input type="radio" />
                          <Nav.Link
                            to={"#"}
                            eventKey="category"
                            className="site-button-secondry radius-sm"
                          >
                            <span>Jobs By Category</span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" className="">
                          <input type="radio" />
                          <Nav.Link
                            to={"#"}
                            eventKey="location"
                            className="site-button-secondry radius-sm"
                          >
                            <span>jobs By Location</span>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                  </div>
                </div>
              </div>
            </Tab.Container>
          </div>

          {/* <div className="section-full p-tb70 overlay-black-dark text-white text-center bg-img-fix" style={{ backgroundImage: "url(" + bnr3 + ")" }}>
						<div className="container">
							<div className="section-head text-center text-white">
								<h2 className="m-b5">Testimonials</h2>
								<h5 className="fw4">Few words from candidates</h5>
							</div>
							<Owltestimonial />
						</div>
					</div> */}
          {/* <div className="section-full content-inner-2 overlay-white-middle">
						<div className="container">
							<div className="section-head text-black text-center">
								<h2 className="text-uppercase m-b0">Our Latest Blog</h2>
								<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.</p>
							</div>
							<Latestblogowl /> 
						</div>
					</div> */}
        </div>
        <div className="floating-container floating-container-animation">
          <div className="floating-button">+</div>
          <div className="element-container">
            <span className="float-element">
              <a
                className="site-button radius-xl"
                style={{ backgroundColor: "#ffffff" }}
                href={"https://t.me/abhpartners"}
                target="_blank"
              >
                <img src={TelegramIcon} alt="Telegram Logo" />
              </a>
            </span>
          </div>
        </div>
        <hr />
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  openJobs: state.entities.openJobs,
  sectorAggrigate: state.entities.sectorAggrigate,
  banners: state.entities.banners,
});

const mapDispatchToProps = (dispatch) => ({
  getOpenJobs: (filter, search) => dispatch(getOpenJobs(filter, search)),
  loadsectorAggrigates: () => dispatch(loadsectorAggrigates()),
  loadBanners: () => dispatch(loadBanners()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Homepage2);
