import { createSlice } from "@reduxjs/toolkit";
import { ApiCallBegan } from "../actions/api";
import checkCache from "../../services/cache";
import { coreCacheLimit,getOpenJobsUrl } from "../../config.json"
import { getCurrentPage } from "../../utils/utilFunctions";
// import { toast } from "react-toastify";
import _ from "lodash";
const pagination = 20;
// const s = "api/jobs/openjobs/"
const openJobsSlice = createSlice({
    name: "openJobs",
    initialState: {
        data: [],
        jobDetail: {},
        count: 0,
        history: [],
        filterQuery: "",
        searchQuery: "",

        currentPage: 0,
        pageCount: 1,
        next: null,
        previous: null,
        lastFetch: null,
        loading: true
    },
    reducers: {
        openJobsFetched: (state, action) => {
            let newState = []
            let newHistory = []
            let data = action.payload.data
            let pageCount = Math.ceil(data.count / pagination)
            if (state.data.length == 0) {
                newState = _.fill(Array(pageCount), [])
                newHistory = _.fill(Array(pageCount), { next: null, previous: null, loaded: false })
            }
            else {
                newState = state.data
                newHistory = state.history
            }

            let currentPage = getCurrentPage(action.payload.data.previous)
            ////console.log(currentPage, action.payload.data.previous, "current page and previous is right here")

            newState[currentPage] = data.results
            newHistory[currentPage] = { next: data.next, previous: data.previous, loaded: true }
            state.history = newHistory
            state.data = newState
            state.filteredData = newState
            state.count = data.count
            state.currentPage = currentPage
            state.pageCount = pageCount
            state.next = data.next
            state.previous = data.previous
            state.lastFetch = Date.now()
            state.loading = false;
            return state;
        },

        openJobsLoadedFromCache: (state, action) => {
            state.currentPage = action.payload.currentPage
            state.previous = action.payload.previous
            state.next = action.payload.next
            return state;

        },
        openJobsRequested: (state, action) => {
            state.loading = true;
            return state
        },
        openJobsRequestFailed: (state, action) => {
            state.loading = false;
            return state
        },
        openJobsUpdated: (state, action) => {
            state.data = action.payload.data
            return state;
        },
        openJobCreated: (state, action) => {
            state.data = action.payload.data
            return state;
        },
        setUrl: (state, action) => {
            state.filterQuery = action.payload.filter
            state.searchQuery = action.payload.search
            // state.data=[]
            return state;
        },
        singleJobFetched: (state, action) => {
            state.jobDetail = action.payload.data
            state.loading = false;
            return state;
        }



    }
})  

export const { openJobsFetched, openJobsRequested, openJobsRequestFailed, openJobsLoadedFromCache, openJobCreated, openJobsUpdated, setUrl, singleJobFetched } = openJobsSlice.actions
export default openJobsSlice.reducer;

export const getOpenJobs = (filterParameter = "", searchparameter = "",baseUrl = getOpenJobsUrl) => (dispatch, getState) => {
    const { lastFetch, filterQuery, searchQuery } = getState().entities.openJobs

    let search = searchparameter ? searchparameter : searchQuery
    let filters = filterParameter ? filterParameter : filterQuery
    if (filterParameter == "0|0") {
        filters = ""
    }
    if (searchparameter == "0|0") {
        search = ""
    }
    const pageUrl = baseUrl + "?" + filters + "&search=" + search

    dispatch({ type: setUrl.type, payload: { filter: filters, search: search } })

    return dispatch(ApiCallBegan({
        url: pageUrl,
        onSuccess: openJobsFetched.type,
        onError: openJobsRequestFailed.type,
        onStart: openJobsRequested.type
    }))


}

export const getJobDetail = (id) => (dispatch, getState) => {
    ////console.log("get job detail called",id,"<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<")
   

        dispatch(ApiCallBegan({
            url: getOpenJobsUrl + id + "/",
            onSuccess: singleJobFetched.type,
            onError: openJobsRequestFailed.type,
            onStart: openJobsRequested.type
        }))
    
}
