import { createSlice } from '@reduxjs/toolkit';
import { ApiCallBegan } from "../actions/api"
import checkCache from "../../services/cache"
import { coreCacheLimit } from "../../config.json"
const employmentTypeSlice = createSlice({
    name: "employmentType",
    initialState: {
        data: [],
        lastFetch: null,
        loading: false
    },
    reducers: {
        employmentTypeAdded: (employmentType, action) => {
            employmentType.data = action.payload.data.results
            employmentType.lastFetch = Date.now()
            employmentType.loading = false;

            return employmentType
        },
        employmentTypeRequested: (employmentType, action) => {
            employmentType.loading = true;
            return employmentType
        },
        employmentTypeRequestFailed: (employmentType, action) => {
            employmentType.loading = false;
            return employmentType
        },



    }

})

export const { employmentTypeAdded, employmentTypeRequestFailed, employmentTypeRequested } = employmentTypeSlice.actions
export default employmentTypeSlice.reducer;

export const loadEmploymentTypes = () => (dispatch, getState) => {
    const { lastFetch } = getState().entities.employmentType
    if (checkCache(lastFetch, coreCacheLimit)) return;

    dispatch(ApiCallBegan({
        url: "api/core/employmenttype/",
        onStart: employmentTypeRequested.type,
        onSuccess: employmentTypeAdded.type,
        onError: employmentTypeRequestFailed.type,

    }))
}
