import { createSlice } from '@reduxjs/toolkit';
import { ApiCallBegan } from "../actions/api"
import checkCache from "../../services/cache"
import { coreCacheLimit } from "../../config.json"
const regionSlice = createSlice({
    name: "region",
    initialState: {
        data: [],
        lastFetch: null,
        loading: false,
    },
    reducers: {
        regionAdded: (region, action) => {
            region.data = action.payload.data.results
            region.lastFetch = Date.now()
            region.loading = false;
            return region
        },
        regionRequested: (region, action) => {
            region.loading = true;
            return region
        },
        regionRequestFailed: (region, action) => {
            region.loading = false;
            return region
        }



    }

})

export const { regionAdded, regionRequested, regionRequestFailed } = regionSlice.actions
export default regionSlice.reducer;
export const getRegionbyName = (id) => (dispatch, getState) => {
    const reg = getState().entities.region.data
    const region = reg.find(region => region.id === id)
    // //console.log(language?language.name: "asd")
    return region ? region.name : ""
    // return "sdsd"
}

export const loadRegions = () => (dispatch, getState) => {
    const { lastFetch } = getState().entities.region
    if (checkCache(lastFetch, coreCacheLimit)) return;

    dispatch(ApiCallBegan({
        url: "api/core/region/",
        onSuccess: regionAdded.type,
        onStart:regionRequested.type,
        onError: regionRequestFailed.type
    }))
}
