import { createSlice } from '@reduxjs/toolkit';
import { ApiCallBegan } from "../actions/api"
import checkCache from "../../services/cache";
import { coreCacheLimit } from "../../config.json"


const bannersSlice = createSlice({
    name: "banners",
    initialState: {
        data: [],
        lastFetch: null,
        loading: true

    },
    reducers: {
        bannersAdded: (banners, action) => {
            console.log(">>><<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<",action.payload.data.results)
            banners.data = action.payload.data.results
            banners.lastFetch = Date.now()
            banners.loading = false;
            return banners
        },
        bannersRequested: (banners, action) => {
            banners.loading = true;
            return banners
        },
        bannersRequestFailed: (banners, action) => {
            banners.loading = false;
            return banners
        }


    }

})

export const { bannersAdded, bannersRequestFailed, bannersRequested } = bannersSlice.actions
export default bannersSlice.reducer;

export const loadBanners = () => (dispatch, getState) => {
    const { lastFetch } = getState().entities.banners;
    // alert(1)
    if (checkCache(lastFetch, coreCacheLimit)) return;
    dispatch(ApiCallBegan({
        url: "api/core/banners/",
        onStart: bannersRequested.type,
        onSuccess: bannersAdded.type,
        onError: bannersRequestFailed.type
    }))
}
