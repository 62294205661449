import React from "react";
import {Form}  from 'react-bootstrap';  
const Select = ({ items, name, label, error, ...rest }) => {
    return (
        <>

            <div className="form-group">
                <label htmlFor={name}>{label} </label>
                <Form.Control name={name} id={name} {...rest} as="select" custom className="custom-select">
                    <option value="" />
                    {items.map((item) => {
                        return (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        );
                    })}
                </Form.Control>
                {error && <div className="alert alert-danger fs-12">{error}</div>}

            </div>
        </>
    );
};

export default Select;
