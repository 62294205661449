import React from 'react';
import Joi from 'joi-browser';
import { Link } from 'react-router-dom'


import loginbg from "./../../images/bg6.jpg";
import logo2 from './../../images/logo-black.png';
import Form from "../Element/common/form";
import authServices from '../../services/authServices';
import { apiUrl } from "../../config.json";
import httpService from '../../services/httpService';



const apiEndpoint = apiUrl + "api/core/auth/";
class LoginPage extends Form {
    state = {
        data: {
            email: "",
            password: "",
        },
        errors: {},

    }

    schema = {
        email: Joi.string().required().email().label("Email"),
        password: Joi.string().required().min(8).label("Password"),

    };
    componentDidMount() {
        // if(auth.getCurrentUser()){
        //     this.props.history.replace("/buildcv");
        // }
    }
    routingFunction = (param) => {
        this.props.history.push({
            pathname: `/`,
            state: param
        });
    }

    doSubmit = async () => {
        try {
            const { data } = this.state;
            // //console.log(data.email, data.password);

            await httpService.post(apiEndpoint, { password: data.password, email: data.email }).then((res) => {
                // //console.log(res.data,"ole ole");
                authServices.loginWithJwt(res.data);
                window.location = "/";
            })


            // auth.login(data.email, data.password).then((res) => {
            //     const user = authServices.getCurrentUser();
            //     //console.log(user, "User")
            //     //console.log(res, "res")

            // });
            // this.routingFunction()
            // const { state } = this.props.location;
            // window.location = state ? state.from.pathname : "/buildcv";
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {

            }
            const errors = { ...this.state.errors };
            errors.email = ex.response.data["detail"];
            this.setState({ errors });
            //console.log(ex.response.data["detail"]);
        }
    }


    render() {
        return (
            <div className="page-wraper">
                <div className="page-content bg-white login-style2" style={{ backgroundImage: "url(" + loginbg + ")", backgroundSize: "cover" }}>
                    <div className="section-full">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 d-flex " >
                                    <div className="text-white max-w400 align-self-center rounded" style= {{padding:"21px", backgroundColor:"#0000006e"}}>
                                        <div className="logo">
                                            <Link to={"/"}><img src={logo2} alt="" /></Link>
                                        </div>
                                        <h2 className="m-b10">Welcome Back</h2>
                                        <p className="m-b30">Ethiotalent is the best online recruitment solution provider
                                            in Ethiopia. The website advertises jobs across a wide
                                            range of job types by different employers, including private, local,
                                            international and  multinational companies who are hiring in Ethiopia.</p>
                                        <ul className="list-inline m-a0">
                                            <li><Link to={''} className="m-r10 text-white "><i className="fa fa-facebook"></i></Link></li>
                                            <li><Link to={''} className="m-r10 text-white "><i className="fa fa-google-plus"></i></Link></li>
                                            <li><Link to={''} className="m-r10 text-white "><i className="fa fa-linkedin"></i></Link></li>
                                            <li><Link to={''} className="m-r10 text-white "><i className="fa fa-instagram"></i></Link></li>
                                            <li><Link to={''} className="m-r10 text-white"><i className="fa fa-twitter"></i></Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="login-2 submit-resume p-a30 seth">
                                        <div className="nav">
                                            <form onSubmit={this.handleSubmit} className="col-12 p-a0 ">
                                                <p className="font-weight-600">If you have an account with us, please log in.</p>
                                                {/* {props.errorMessage && (
                                                    <div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2'>
                                                        {props.errorMessage}
                                                    </div>
                                                )}
                                                {props.successMessage && (
                                                    <div className='bg-green-300 text-green-900 border border-green-900 p-1 my-2'>
                                                        {props.successMessage}
                                                    </div>
                                                )} */}
                                                {this.renderInput("email", "E-Mail Address*", "email")}
                                                {this.renderInput("password", "Password*", "password")}

                                                <div className="text-center">
                                                    {this.renderButton("login")}
                                                    <Link to="register-2" className="site-button-link forget-pass m-t15 float-right"><i className="fa fa-unlock-alt"></i> Sign up</Link>
                                                </div>
                                            </form>
                                            <Link data-toggle="tab" to="/reset-password" className="forget-pass m-l5"><i className="fa fa-unlock-alt"></i> Forgot Password</Link>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <footer className="login-footer">
                        <div className="container">
                            <div className="row" style={{backgroundColor:"#00000009"}}>
                                <div className="col-lg-12 text-center rounded" style= {{padding:"21px", backgroundColor:"#0000006e"}}>
                                    <span className="float-left">© Copyright by {" "}
                                        <Link to={"#"}>ABH Partners </Link> </span>
                                    <span className="float-right">
                                        All rights reserved.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        );
    }
}

export default LoginPage;