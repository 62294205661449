import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import CompanyProfileSideBar from "../Element/companyProfileSideBar";
import { loadUsers } from "../../store/reducers/users";
import { connect } from "react-redux";
import _ from "lodash";
import Loading from "../Element/common/loading";
import { Modal } from "react-bootstrap";
import { loadGroups } from "../../store/reducers/groups";
import { checkPermission } from "../../utils/utilFunctions";

const pageUrl = "api/company/employerUser/";

class CompanyUsers extends Component {
  state = {
    company: false,
    sortColumn: { path: "full_name", order: "asc" },
    user: false,
  };
  setUser(user) {
    this.setState({ user: user });
  }
  componentDidMount() {
    const permission = checkPermission("view", "user");
    if (!permission) {
      this.props.history.push("/");
    }
    this.props.loadUsers(pageUrl);
    this.props.loadGroups();
  }
  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  buildStatus(status) {
    switch (status) {
      case 0:
        return <td className="expired text-warning">Draft</td>;
      case 1:
        return <td className="expired text-success">Active </td>;
      case 2:
        return <td className="expired text-primary">Pending </td>;
      case 3:
        return <td className="expired text-danger">Expired </td>;
      case 4:
        return <td className="expired text-danger">Canceled </td>;
      case 5:
        return <td className="expired text-success">Selecting </td>;
      case 6:
        return <td className="expired text-danger">Closed</td>;
      default:
        return <td className="expired text-danger">Closed</td>;
    }
  }

  handleNext = () => {
    //console.log(this.props.users.next)
    let url = this.props.users.next.split("?")[1];
    this.props.loadUsers(`${pageUrl}?${url}`);
  };

  handlePrevious = () => {
    //console.log(this.props.users.previous)
    let url = this.props.users.previous.split("?")[1];
    this.props.loadUsers(`${pageUrl}?${url}`);
  };

  handlePageChange = (page) => {
    this.props.loadUsers(`${pageUrl}?page=${page}`);
  };

  pagination = (
    handlePrevious,
    handlePageChange,
    handleNext,
    currentPage,
    pageCount
  ) => {
    return (
      <div className="pagination-bx m-t30 float-right">
        <ul className="pagination">
          {currentPage != 0 && (
            <li className="previous">
              <Link to={"#"} onClick={handlePrevious}>
                <i className="ti-arrow-left"></i> Prev
              </Link>
            </li>
          )}
          {_.range(pageCount).map((page) => {
            return (
              <li className={page == currentPage ? "active" : ""}>
                <Link
                  onClick={() => {
                    handlePageChange(page + 1);
                  }}
                  to={"#"}
                >
                  {page + 1}
                </Link>
              </li>
            );
          })}

          {/* <li className="active"><Link to={"#"}>1</Link></li>
					<li><Link to={"#"}>2</Link></li>
					<li><Link to={"#"}>3</Link></li> */}
          {pageCount - 1 != currentPage && (
            <li className="next">
              <Link to={"#"} onClick={handleNext}>
                Next <i className="ti-arrow-right"></i>
              </Link>
            </li>
          )}
        </ul>
      </div>
    );
  };

  raiseSort = (path) => {
    let sortColumn = this.state.sortColumn;
    if (sortColumn.path === path)
      sortColumn.order = sortColumn.order === "asc" ? "desc" : "asc";
    else {
      sortColumn.path = path;
      sortColumn.order = "asc";
    }
    this.setState({ sortColumn });
    //console.log(sortColumn, "SORT<<")
  };

  renderSortIcon = (column) => {
    const sortColumn = this.state.sortColumn;

    if (column.path !== sortColumn.path) return null;
    if (sortColumn.order === "asc") return <i className="fa fa-sort-asc" />;
    return <i className="fa fa-sort-desc" />;
  };

  generateHeaders = (headers) => {
    return (
      <thead>
        <tr>
          {headers.map((header, index) => {
            return (
              <th
                className="clickable"
                style={{ cursor: "pointer" }}
                onClick={() => this.raiseSort(header.path)}
                key={index}
              >
                {header.label} {this.renderSortIcon(header)}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };
  generateBody = (data) => {
    const { currentPage, pageCount } = this.props.users;
    //console.log(data, "USERS")
    return (
      <tbody>
        {data.map((item, index) => {
          return (
            <tr key={index}>
              <td>{item.id}</td>
              <td className="job-name">
                {/* <Link to={`/job-detail/${item.id}`}>
										{item.title}
									</Link> */}
                {/* {" "}- {Intl.NumberFormat().format(item.views)} views */}
                {item.user.first_name} {item.user.last_name}
              </td>
              <td> {item.user.email}</td>
              <td>{item.user.is_active ? "Active" : "In Active"}</td>
              <td>{item.role.name ? item.role.name : "None"}</td>
              <td> {item.user.date_joined}</td>
              {/* <td> {item.user.last_login ? item.user.last_login : "Never"}</td> */}

              {/* <td>{item.application_end_date}</td> */}
              {/* <td className="application text-primary">{Intl.NumberFormat().format(item.applicants)} Applications</td>
								<td>{this.buildStatus(item.is_published)}</td> */}
              <td className="job-links">
                <Link
                  to={`#`}
                  className="button-sm"
                  onClick={() => {
                    this.props.history.push(`/company-users-form/${item.id}`);
                  }}
                >
                  <i className="fa fa-pencil"></i>
                </Link>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  renderPage = () => {
    const user = this.state.user;
    if (this.props.users.loading == true || this.props.users.data === []) {
      return (
        <div className="col-xl-9 col-lg-8 m-b30">
          <Loading />
        </div>
      );
    } else {
      const columns = [
        { path: "id", label: "No" },
        { path: "full_name", label: "Full Name" },
        { path: "email", label: "Email" },
        { path: "status", label: "Status" },
        { path: "role", label: "Role" },
        { path: "date_created", label: "Date Created" },
        // { path: "last_login", label: "Last Login" },
        // { path: 'is_published', label: 'Status' },
        { path: "", label: "Actions" },
      ];
      let data = [];
      //console.log(this.props.users.data,this.props.users.currentPage, "<DATA>")
      const users =
        this.props.users.data[parseInt(this.props.users.currentPage)];
      users.map((item, index) => {
        data.push({
          id: index,
          full_name: item.user.first_name + " " + item.user.last_name,
          email: item.user.email,
          status: item.is_active ? "Active" : "Inactive",
        });
        // //console.log(data)
      });
      const { company, sortColumn } = this.state;
      return (
        <div className="col-xl-9 col-lg-8 m-b30">
          <div className="job-bx table-job-bx clearfix">
            <div className="job-bx-title clearfix">
              <h5 className="font-weight-700 pull-left text-uppercase">
                Manage Users
              </h5>
              <Link
                to={"/company-post-jobs"}
                className="site-button right-arrow button-sm float-right"
              >
                Back
              </Link>
              {(checkPermission("add", "user") ||
                checkPermission("change", "user")) && (
                <Link
                  to="/company-users-form/new"
                  className="site-button button-sm float-left m-l16"
                >
                  Add New User
                </Link>
              )}
            </div>
            <table>
              {this.generateHeaders(columns)}

              {this.generateBody(
                this.props.users.data[parseInt(this.props.users.currentPage)]
              )}
            </table>

            {this.pagination(
              this.handlePrevious,
              this.handlePageChange,
              this.handleNext,
              this.props.users.currentPage,
              this.props.users.pageCount
            )}
          </div>
          <Modal
            show={user}
            onHide={this.setUser}
            className="modal fade modal-bx-info"
          >
            <div className="modal-dialog my-0" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <div className="logo-img">
                    <img
                      alt=""
                      src={require("./../../images/logo/icon2.png")}
                    />
                  </div>
                  <h5 className="modal-title">User Information</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => this.setUser(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">add user Info here</div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => this.setUser(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      );
    }
  };

  render() {
    return (
      <>
        <Header />
        <div className="page-content bg-white">
          <div className="content-block">
            <div className="section-full bg-white p-t50 p-b20">
              <div className="container-fluid">
                <div className="row">
                  <CompanyProfileSideBar currentPage="Manage Users" />
                  {this.renderPage()}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.entities.users,
  groups: state.entities.groups,
});

const mapDispatchToProps = (dispatch) => ({
  loadUsers: (url) => dispatch(loadUsers(url)),
  loadGroups: () => dispatch(loadGroups()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyUsers);

// export default CompanyUsers;
