import { createSlice } from "@reduxjs/toolkit";
import { ApiCallBegan } from "../actions/api";
import checkCache from "../../services/cache";
import { coreCacheLimit } from "../../config.json";
import { getCurrentPage } from "../../utils/utilFunctions";
import { toast } from "react-toastify";
import _ from "lodash";

const pagination = 20;
const pageUrl = "api/company/employerUser/";


const slice = createSlice({
    name: "jobHistory",
    initialState: {
        data: [],
        count: 0,
        history: [],
        currentPage: 0,
        pageCount: 1,
        next: null,
        previous: null,
        lastFetch: null,
        loading: true,
    },
    reducers: {
        jobHistoryFetched: (state, action) => {
            let newState = [];
            let newHistory = [];
            let data = action.payload.data;
            let pageCount = Math.ceil(data.count / pagination);
            if (state.data.length == 0) {
                newState = _.fill(Array(pageCount), []);
                newHistory = _.fill(Array(pageCount), {
                    next: null,
                    previous: null,
                    loaded: false,
                });
            } else {
                newState = state.data;
                newHistory = state.history;
            }

            let currentPage = getCurrentPage(action.payload.data.previous);

            newState[currentPage] = data.results;
            newHistory[currentPage] = {
                next: data.next,
                previous: data.previous,
                loaded: true,
            };
            state.history = newHistory;
            state.data = newState;
            state.count = data.count;
            state.currentPage = currentPage;
            state.pageCount = pageCount;
            state.next = data.next;
            state.previous = data.previous;
            state.lastFetch = Date.now();
            state.loading = false;
        },
       jobHistoryLoadedFromCache: (state, action) => {
            state.currentPage = action.payload.currentPage
            state.previous = action.payload.previous
            state.next = action.payload.next
            return state;

        },
       jobHistoryRequested: (state, action) => {
            state.loading = true;
            return state
        },
       jobHistoryRequestFailed: (state, action) => {
            state.loading = false;
            return state
        },
    }
});

export const { jobHistoryFetched, jobHistoryRequested,jobHistoryLoadedFromCache,jobHistoryRequestFailed } = slice.actions;
export default slice.reducer;

export const loadJobHistory = (pageUrl) => (dispatch, getState) => {
    const { lastFetch, history } = getState().entities.jobHistory;
    const thisPage = getCurrentPage(pageUrl) - 1
    const pageHistory = history[thisPage]
    if (checkCache(lastFetch, coreCacheLimit) && pageHistory.loaded) {
        dispatch(jobHistoryLoadedFromCache({ currentPage: thisPage, next: pageHistory.next, previous: pageHistory.previous }))
        return;
    }

    
    dispatch(
        ApiCallBegan({
            url: pageUrl,
            onStart: jobHistoryRequested.type,
            onError: jobHistoryRequestFailed.type,
            onSuccess: jobHistoryFetched.type,
        })
    );
}

