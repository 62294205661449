import { configureStore } from '@reduxjs/toolkit';
import { getDefaultMiddleware } from '@reduxjs/toolkit';
import api from "./middlewares/apiMiddleware"
// import reducer
import reducer from "./reducer"

export default function(){
    return configureStore({
    reducer,
    middleware:[
        ...getDefaultMiddleware(),
        api

    ]
    });
}
