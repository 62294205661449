import React from "react";
import { Link } from "react-router-dom";
import Header from "./../Layout/Header";
import Footer from "./../Layout/Footer";
import { useEffect } from "react";
import auth from "../../services/authServices";
// import Latestblogowl from './../Element/Owlblog2';

var bnr1 = require("./../../images/main-slider/slide2.png");
var bnr2 = require("./../../images/background/bg6.jpg");

function Aboutus() {
    let user = null;
    useEffect(() => {
        user = auth.getCurrentUser();
        

    }, []);
  return (
    <div className="page-wraper">
      <Header />
      <div className="page-content bg-white">
        <div
          className="dez-bnr-inr overlay-black-middle"
          style={{ backgroundImage: "url(" + bnr1 + ")" }}
        >
          <div className="container">
            <div className="dez-bnr-inr-entry">
              <h1 className="text-white">About Us</h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                    <Link to={"#"}>Home</Link>
                  </li>
                  <li>About Us</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="content-block">
          <div className="section-full content-inner overlay-white-middle">
            <div className="container">
              <div className="row align-items-center m-b50">
                <div className="col-md-12 col-lg-6 m-b20">
                  <h2 className="m-b5">About Us</h2>
                  <h3 className="fw4">
                    We provide local knowledge at global standards to enable
                    impactful societal change
                  </h3>
                  <p className="m-b15">
                    Founded in 2007, ABH Partners PLC (ABH) is a development
                    consulting firm providing expert technical assistance and
                    solutions for equitable development and sustainable growth
                    in developing countries. Headquartered in Ethiopia, with
                    branches in U.S.A. and U.A.E., ABH has an operational
                    presence in U.S.A., U.A.E., Libya, and Syria. Its workforce
                    comprises over 60 national and international full-time
                    staff. ABH focuses on knowledge generation, evaluation, and
                    dissemination as the enabler and cross-cutting theme across
                    its five core Business Divisions in diverse practices of
                    Consultancy, Human Resources Management (HRM), Pharma and
                    Scientific Supply, and Academy.
                  </p>
                  {/* <p className="m-b15">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less.</p> */}
                  <a  className="site-button" href="https://abhpartners.com/" target="_blank">
                    Read More
                  </a>
                </div>
                <div className="col-md-12 col-lg-6">
                  <img src={require("../../images/aboutus/5.png")} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-12 m-b30">
                  <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                    <div className=" text-primary m-b20">
                      {" "}
                      {/* <Link to={"#"} className="icon-cell text-primary"> */}
                      <img
                        src={require("../../images/aboutus/4.jpg")}
                        style={{ height: "150px" }}
                        alt=""
                      />{" "}
                      {/* </Link>{" "} */}
                    </div>
                    <div className="icon-content">
                      <h5 className="dlab-tilte text-uppercase">Consulting </h5>
                      <p>
                        Consultancy services has been a core service offering of
                        ABH since its foundation.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12 m-b30">
                  <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                    <div className=" text-primary m-b20">
                      {" "}
                      {/* <Link to={"#"} className="icon-cell text-primary"> */}
                      <img
                        src={require("../../images/aboutus/3.jpg")}
                        style={{ height: "150px" }}
                        alt=""
                      />{" "}
                      {/* </Link>{" "} */}
                    </div>
                    <div className="icon-content">
                      <h5 className="dlab-tilte text-uppercase">Acadamy</h5>
                      <p>
                        ABH organizes short and long-term training at different
                        levels including in-service, pre-service, basic, and
                        graduate levels.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12 m-b30">
                  <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                    <div className=" text-primary m-b20">
                      {" "}
                      {/* <Link to={"#"} className="icon-cell text-primary"> */}
                      <img
                        src={require("../../images/aboutus/2.jpg")}
                        style={{ height: "150px" }}
                        alt=""
                      />{" "}
                      {/* </Link>{" "} */}
                    </div>
                    <div className="icon-content">
                      <h5 className="dlab-tilte text-uppercase">
                        Human Resource Management{" "}
                      </h5>
                      <p>
                        ABH is one of the leading Human Resource Management
                        (HRM) practitioners in Ethiopia.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12 m-b30">
                  <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                    <div className=" text-primary m-b20">
                      {" "}
                      {/* <Link to={"#"} className="icon-cell text-primary"> */}
                      <img
                        src={require("../../images/aboutus/1.jpg")}
                        style={{ height: "150px" }}
                        alt=""
                      />{" "}
                      {/* </Link>{" "} */}
                    </div>
                    <div className="icon-content">
                      <h5 className="dlab-tilte text-uppercase">
                        Pharmaceuticals and Scientific Supplies
                      </h5>
                      <p>
                        Pharma and Scientific Division is one of the divisions
                        at ABH Partners plc born out of the 12 years proven
                        experience of ABH.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="section-full content-inner-2 call-to-action overlay-black-dark text-white text-center bg-img-fix"
            style={{ backgroundImage: "url(" + bnr2 + ")" }}
          >
            {user == null && (<div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h2 className="m-b10">
                    Make a Difference with Your Online Resume!
                  </h2>
                  
                  <Link
                    to={"/register-2"}
                    className="site-button m-t20 outline outline-2 radius-xl"
                  >
                    Create an Account
                  </Link>
                </div>
              </div>
            </div>)}
          </div>
          <div className="section-full content-inner-2 overlay-white-middle">
            <div className="container">
              <div className="section-head text-black text-center">
                {/* <h2 className="text-uppercase m-b0">Our Latest Blog</h2> */}
                <p></p>
              </div>
              {/* <Latestblogowl /> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Aboutus;
