import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import CompanyProfileSideBar from "../Element/companyProfileSideBar";
import { loadJobHistory } from "../../store/reducers/jobHistory";
import { connect } from "react-redux";
import _ from "lodash";
import Loading from "../Element/common/loading";
import { checkPermission } from "../../utils/utilFunctions";

const pageUrl = "api/jobs/jobHistory/";
class CompanyPackageManage extends Component {
  state = {
    sortColumn: { path: "full_name", order: "asc" },
    jobHistory: false,
  };
  componentDidMount() {
    const permission = checkPermission("view", "job_package");
    if (!permission) {
      this.props.history.push("/");
    }
    this.props.loadJobHistory(pageUrl);
  }
  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handleNext = () => {
    //console.log(this.props.jobHistorys.next)
    let url = this.props.jobHistory.next.split("?")[1];
    alert(`${pageUrl}?${url!=undefined?url:""}`)
    this.props.loadJobHistory(`${pageUrl}?${url!=undefined?url:""}`);
  };

  handlePrevious = () => {
    //console.log(this.props.jobHistorys.previous)
    let url = this.props.jobHistory.previous.split("?")[1];
    alert(`${pageUrl}?${url!=undefined?url:""}`)
    this.props.loadJobHistory(`${pageUrl}?${url!=undefined?url:""}`);
  
  };

  handlePageChange = (page) => {
    // alert(`${pageUrl}?page=${page}`);
    this.props.loadJobHistory(`${pageUrl}?page=${page}`);
    
    // this.props.loadjobHistory(`${pageUrl}?page=${page}`);
  };

  pagination = (
    handlePrevious,
    handlePageChange,
    handleNext,
    currentPage,
    pageCount
  ) => {
    let pages = [];
    let lastIndex = 0;
    if (pageCount <= 11) {
      pages = _.range(pageCount);
    } else {
      pages.push(0);
      pages.push(1);
      if (currentPage - 3 > 2) {
        pages.push("...");
        for (let i = currentPage - 3; i <= currentPage + 3; i++) {
          if (i > 1 && i < pageCount - 2) {
            pages.push(i);
          }
         }
      } else {
        for (let i = 2; i <= currentPage + 6 - (currentPage - 2); i++) {
          if (i > 1 && i < pageCount - 2) {
            pages.push(i);
          }
        }
      }
      if (currentPage + 3 < pageCount) {
        pages.push("...");
      }

      pages.push(pageCount - 2);
      pages.push(pageCount - 1);
    }
    return (
      <div className="row">
        <div>
          <div className="pagination-bx m-t30 float-right">
            <ul className="pagination">
              {currentPage != 0 && (
                <li className="previous">
                  <Link to={"#"} onClick={handlePrevious}>
                    <i className="ti-arrow-left"></i> Prev
                  </Link>
                </li>
              )}

              {pages.map((page, index) => {
                if (page == "...") {
                  return (
                    <li className="">
                      <Link to={"#"}>...</Link>
                    </li>
                  );
                } else {
                  return (
                    <li className={page == currentPage ? "active" : ""}>
                      <Link
                        onClick={() => {
                          handlePageChange(page + 1);
                        }}
                        to={"#"}
                      >
                        {page + 1}
                      </Link>
                    </li>
                  );
                }
              })}

              {/* <li className="active"><Link to={"#"}>1</Link></li>
						<li><Link to={"#"}>2</Link></li>
						<li><Link to={"#"}>3</Link></li> */}
              {pageCount - 1 != currentPage && (
                <li className="next">
                  <Link to={"#"} onClick={handleNext}>
                    Next <i className="ti-arrow-right"></i>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    );
  };

  raiseSort = (path) => {
    let sortColumn = this.state.sortColumn;
    if (sortColumn.path === path)
      sortColumn.order = sortColumn.order === "asc" ? "desc" : "asc";
    else {
      sortColumn.path = path;
      sortColumn.order = "asc";
    }
    this.setState({ sortColumn });
    //console.log(sortColumn, "SORT<<")
  };

  renderSortIcon = (column) => {
    const sortColumn = this.state.sortColumn;

    if (column.path !== sortColumn.path) return null;
    if (sortColumn.order === "asc") return <i className="fa fa-sort-asc" />;
    return <i className="fa fa-sort-desc" />;
  };

  generateHeaders = (headers) => {
    return (
      <thead>
        <tr>
          {headers.map((header, index) => {
            return (
              <th
                className="clickable"
                style={{ cursor: "pointer" }}
                onClick={() => this.raiseSort(header.path)}
                key={index}
              >
                {header.label} {this.renderSortIcon(header)}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };
  generateBody = (data) => {
    const { currentPage, pageCount } = this.props.jobHistory;
    return (
      <tbody>
        {data.map((item, index) => {
          return (
            <tr key={index}>
              <td>{item.id}</td>
              <td className="job-name">
                {item.job_post === null ? "Purchased" : item.job_post}
              </td>
              <td>{Intl.NumberFormat().format(item.remaining_jobs)}</td>
              {/* <td>{Intl.NumberFormat().format(item.remaining_featured_jobs)}</td> */}
              <td> {item.date_created.split("T")[0]}</td>
            </tr>
          );
        })}
      </tbody>
    );
  };
  renderPage = () => {
    if (this.props.jobHistory.loading == true) {
      return (
        <div className="col-xl-9 col-lg-8 m-b30">
          <Loading />
        </div>
      );
    } else {
      const columns = [
        { path: "id", label: "No" },
        { path: "job_post", label: "Job" },
        { path: "remaining_jobs", label: "Remaining Jobs" },
        // { path: 'remaining_featured_jobs', label: 'Remaining Featured Jobs' },
        { path: "date_created", label: "Date Created" },
      ];
      let data = [];
      const jobHistory =
        this.props.jobHistory.data[parseInt(this.props.jobHistory.currentPage)];
      jobHistory.map((item, index) => {
        data.push({
          id: index,
          job_post: item.job_post,
          remaining_jobs: item.remaining_jobs,
          remaining_featured_jobs: item.remaining_featured_jobs,
          date_created: item.date_created,
        });
      });
      const { company, sortColumn } = this.state;
      return (
        <div className="col-xl-9 col-lg-8 m-b30">
          <div className="job-bx table-job-bx clearfix">
            <div className="job-bx-title clearfix">
              <h5 className="font-weight-700 pull-left text-uppercase">
                Job Post History
              </h5>
              <Link
                to={"/company-post-jobs"}
                className="site-button right-arrow button-sm float-right"
              >
                Back
              </Link>
              {/* <Link to="/company-jobHistorys-form/new" className="site-button button-sm float-left m-l16">Add New jobHistory</Link> */}
            </div>
            <table>
              {this.generateHeaders(columns)}

              {this.generateBody(
                this.props.jobHistory.data[
                  parseInt(this.props.jobHistory.currentPage)
                ]
              )}
            </table>

            {this.pagination(
              this.handlePrevious,
              this.handlePageChange,
              this.handleNext,
              this.props.jobHistory.currentPage,
              this.props.jobHistory.pageCount
            )}
          </div>
        </div>
      );
    }
  };

  render() {
    return (
      <>
        <Header />
        <div className="page-content bg-white">
          <div className="content-block">
            <div className="section-full bg-white p-t50 p-b20">
              <div className="container-fluid">
                <div className="row">
                  <CompanyProfileSideBar currentPage={"Manage Package"} />
                  {this.renderPage()}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  jobHistory: state.entities.jobHistory,
});

const mapDispatchToProps = (dispatch) => ({
  loadJobHistory: (data) => dispatch(loadJobHistory(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyPackageManage);
// export default CompanyPackageManage;
