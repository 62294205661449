import { createSlice } from '@reduxjs/toolkit';
import { ApiCallBegan } from "../actions/api"
import checkCache from "../../services/cache"
import { coreCacheLimit } from "../../config.json"

const salaryTypeSlice = createSlice({
    name: "salaryType",
    initialState: {
        data: [],
        lastFetch: null,
        loading: false

    },
    reducers: {
        salaryTypeAdded: (salaryType, action) => {
            salaryType.data = action.payload.data.results
            salaryType.lastFetch = Date.now()
            salaryType.loading = false;

            return salaryType
        },
        salaryTypeRequested: (salaryType, action) => {
            salaryType.loading = true;
            return salaryType
        },
        salaryTypeRequestFailed: (salaryType, action) => {
            salaryType.loading = false;
            return salaryType
        }


    }

})

export const { salaryTypeAdded,salaryTypeRequestFailed,salaryTypeRequested } = salaryTypeSlice.actions
export default salaryTypeSlice.reducer;

export const loadSalaryType = () => (dispatch, getState) => {
    const { lastFetch } = getState().entities.employmentType
    if (checkCache(lastFetch, coreCacheLimit)) return;
   dispatch( ApiCallBegan({
        url: "api/core/salarytype/",
        onStart: salaryTypeRequested.type,
        onSuccess: salaryTypeAdded.type,
        onError: salaryTypeRequestFailed.type,
    }))
}