import React from "react";
const Input = ({ name, label, error, ...rest }) => {
  return (
    // <div className="form-group">
    //   <label htmlFor={name}>{label}</label>
    //   <input {...rest} id={name} name={name} className="form-control" />
    //   {error && <div className="alert alert-danger">{error}</div>}
    // </div>
    <div className="form-group" >
    <label  htmlFor={name}>{label}</label>
    <input className="form-control" {...rest} id={name} name={name} min="0" />
    {error && <div className="alert alert-danger fs-5">{error}</div>}
    
</div>
  );
};

export default Input;
