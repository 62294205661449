import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getOpenJobs } from "../../../store/reducers/openJobs";
import { Reveal, Tween } from "react-gsap";
import moment from "moment";
import { getSalary } from "../../../utils/utilFunctions";
const primaryColor = "#009299";
const secondaryColor = "#009299";

const JobTile = ({ job, regions }) => {
  const history = useHistory();
  const primaryColor = "#009299";
  const secondaryColor = "#5E7BB9";
  const dispatch = useDispatch();

  return (
    <li key={job.id}>
      <div className="post-bx">
        <div className="d-flex m-b30">
          <div className="job-post-company">
            <span>
              <img alt="" src={job.company.logo} />
            </span>
          </div>
          <div className="job-post-info">
            <Reveal>
              <Tween
                from={{
                  opacity: 0,
                  transform: "translate3d(100px, 0, 0)",
                }}
                ease="back.out(1.4)"
                duration={2}
              >
                <h4>
                  <Link
                    to={`/job-detail/${job.id}`}
                    style={{
                      color: primaryColor,
                      textDecoration: "underline",
                      fontSize: "20px",
                    }}
                  >
                    {job.title}
                  </Link>
                </h4>
              </Tween>
            </Reveal>
            {job.is_published != 1 && (
              <div className="d-flex">
                <Reveal>
                  <Tween
                    from={{
                      opacity: 0,
                    }}
                    ease="back.out(1.4)"
                    duration={2}
                  >
                    <label className="">
                      {1 == 1 ? <p style={{ color: "red" }}>Closed</p> : <></>}
                      {/* <input type="checkbox" />
                                          <span className="checkmark"></span> */}
                    </label>
                  </Tween>
                </Reveal>
              </div>
            )}
            <Reveal>
              <Tween
                from={{
                  opacity: 0,
                  transform: "translate3d(-100px, 0, 0)",
                }}
                ease="back.out(1.4)"
                duration={2}
              >
                <ul>
                  <li style={{ color: secondaryColor }}>
                    <i className="fa fa-map-marker"></i> {regions},
                  </li>
                  <li style={{ color: secondaryColor }}>
                    <i className="fa fa-bookmark-o"></i> {job.employment_type}
                  </li>
                  <li style={{ color: secondaryColor }}>
                    <i className="fa fa-clock-o"></i> Published{" "}
                    {moment(job.date_created).fromNow()}
                  </li>
                </ul>
              </Tween>
            </Reveal>
          </div>
        </div>
        <div className="d-flex">
          <p className="text">{job.description}</p>
        </div>
        <div className="d-flex">
          <div className="job-time mr-auto">
            
            <Link
              to={"#"}
              onClick={() => {
                dispatch(getOpenJobs(null, job.company.company_name));
                history.push("/browse-job-filter-list");
              }}
            >
              <span
                style={{
                  color: primaryColor,
                }}
              >
                {job.company.company_name}
              </span>
            </Link>
          </div>
          <div className="salary-bx">
            <span>
              {" "}
              {getSalary(
                Intl.NumberFormat().format(job.salary),
                job.salary_currency,
                job.salary_type,
                job.salary_mode
              )}
            </span>
          </div>
        </div>
        <label className="like-btn">
          {job.applied != undefined && job.applied ? (
            <p style={{ color: "green" }}>
              <i class="fa fa-check-circle" aria-hidden="true"></i> Applied
            </p>
          ) : (
            <></>
          )}
          {/* <input type="checkbox" />
                                          <span className="checkmark"></span> */}
        </label>
      </div>
    </li>
  );
};

export default JobTile;
