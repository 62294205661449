import React from "react";
import ReactDOM from "react-dom";
import { PDFViewer } from "@react-pdf/renderer";
import CVView from "./cvView";

const RenderPdf = ({props}) => (
    <PDFViewer style={{width:"100%",height:"100vh"}}>
      <CVView props={props}/>
    </PDFViewer>
);
// ReactDOM.render(<RenderPdf />, document.getElementById("app"))
export default RenderPdf;
