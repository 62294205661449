import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Profilesidebar from "../Element/Profilesidebar";
import { getAppliedJobsUrl } from "../../config.json";
import { connect, useDispatch, useSelector } from "react-redux";
import { getAppliedJobs } from "../../store/reducers/appliedJobs";
import _, { mapKeys } from "lodash";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { getSalary } from "../../utils/utilFunctions";
import auth from "../../services/authServices";
import authServices from "../../services/authServices";
import { apiUrl } from "../../config.json";
import Swal from "sweetalert2";

import axios from "axios";
import withReactContent from "sweetalert2-react-content";
import { data } from "jquery";

const postBlog = [
  { title: "PHP Web Developer" },
  { title: "Software Developer" },
  { title: "Branch Credit Manager" },
];
const swal = withReactContent(Swal);

class Jobsappliedjob extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.getAppliedJobs(getAppliedJobsUrl);
  }

  state = {
    coverLetter: false,
    modal: {
      companyName: "",
      coverLetter: "",
    },
  };

  setCoverLetter = (
    coverLetter,
    modal = { companyName: "", coverLetter: "" }
  ) => {
    this.setState({ coverLetter, modal });
  };
  // 	setCoverLetter = (coverLetter, modal = {
  // 		companyName: '',
  // 		coverLetter: '',
  // 	}) => {
  // 		this.setState({ coverLetter, modal })
  // 	}
  // }

  handleNext = () => {
    //console.log(this.props.appliedJobs.next)
    let url = this.props.appliedJobs.next.split("?")[1];
    getAppliedJobs(`${getAppliedJobsUrl}?${url}`);
  };

  handlePrevious = () => {
    //console.log(this.props.appliedJobs.previous)
    let url = this.props.appliedJobs.previous.split("?")[1];
    getAppliedJobs(`${getAppliedJobsUrl}?${url}`);
  };

  handlePageChange = (page) => {
    getAppliedJobs(`${getAppliedJobsUrl}?page=${page}`);
  };

  handleDeleteJob = async (job_id) => {
    
    const user = auth.getCurrentUser();
    const token = user.token;

    const headers = {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    };
    const response = await axios.delete(
      apiUrl + `api/appliedjobs/jobs/${job_id}/`,
      headers
    );
  };

  promptDeleteJob = (job_id) => {
    swal
      .fire({
        title: "Are you sure you want to continue? ",
        text: "If you withdraw your application, you will no longer be considered for this position.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Withdraw!",
        cancelButtonText: "No, Cancel!",
        
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.handleDeleteJob(job_id);
          swal
            .fire("Deleted!", "Your file has been deleted.", "success")
            .then((result) => {
              if (result.isConfirmed) {
                this.props.history.push("/");
              }
            });
        }
      });
  };

  pagination = (
    handlePrevious,
    handlePageChange,
    handleNext,
    currentPage,
    pageCount
  ) => {
    return (
      <div className="pagination-bx m-t30 float-right">
        <ul className="pagination">
          {currentPage != 0 && (
            <li className="previous">
              <Link to={"#"} onClick={handlePrevious}>
                <i className="ti-arrow-left"></i> Prev
              </Link>
            </li>
          )}
          {_.range(pageCount).map((page) => {
            return (
              <li className={page == currentPage ? "active" : ""}>
                <Link
                  onClick={() => {
                    this.handlePageChange(page + 1);
                  }}
                  to={"#"}
                >
                  {page + 1}
                </Link>
              </li>
            );
          })}

          {/* <li className="active"><Link to={"#"}>1</Link></li>
					<li><Link to={"#"}>2</Link></li>
					<li><Link to={"#"}>3</Link></li> */}
          {pageCount - 1 != currentPage && (
            <li className="next">
              <Link to={"#"} onClick={handleNext}>
                Next <i className="ti-arrow-right"></i>
              </Link>
            </li>
          )}
        </ul>
      </div>
    );
  };

  renderContent = () => {
    const { coverLetter } = this.state;
    if (
      this.props.appliedJobs.loading == true ||
      !this.props.appliedJobs.data[0]
    ) {
      return <h1>Loading...</h1>;
    } else {
      return (
        <React.Fragment>
          <div className="col-xl-9 col-lg-8 m-b30 browse-job">
            <div className="job-bx-title  clearfix">
              <h5 className="font-weight-700 pull-left text-uppercase">
                {this.props.appliedJobs.count} Jobs Found
              </h5>
              {/* <div className="float-right">
								<span className="select-title">Sort by freshness</span>
								<select className="custom-btn">
									<option>Last 2 Months</option>
									<option>Last Months</option>
									<option>Last Weeks</option>
									<option>Last 3 Days</option>
								</select>
							</div> */}
            </div>
            <ul className="post-job-bx browse-job">
              {this.props.appliedJobs.data[
                parseInt(this.props.appliedJobs.currentPage)
              ].map((item, index) => {
                return (
                  <li key={index}>
                    <div className="post-bx">
                      <div className="job-post-info m-a0">
                        <h4>
                          <Link to={`/job-detail/${item.job.id}`}>
                            {item.job.title}
                          </Link>
                        </h4>
                        <ul>
                          <li>{item.job.company.company_name}</li>
                          <li>
                            <i className="fa fa-map-marker"></i>{" "}
                            {item.job.region}, {item.job.city},{" "}
                            {item.job.address}
                          </li>
                          <li>
                          {/* {item.job.salary} {item.job.salary_currency} {item.job.salary_type} */}
                            <i className="fa fa-money"></i>{" "}
                            {getSalary(
                              Intl.NumberFormat().format(item.job.salary),
                              item.job.salary_currency,
                              item.job.salary_type,
                              item.job.salary_mode
                            )}
                          </li>
                        </ul>
                        <div className="job-time m-t15 m-b10">
                          {item.job.sector.map((item, index) => {
                            return (
                              <Link to={"#"} key={index} className="mr-1">
                                <span>{item}</span>
                              </Link>
                            );
                          })}

                          {/* <Link to={'#'} className="mr-1"><span>Angular</span></Link>
													<Link to={'#'} className="mr-1"><span>Bootstrap</span></Link>
													<Link to={'#'} className="mr-1"><span>Wordpress</span></Link> */}
                        </div>
                        <div className="posted-info clearfix">
                          <p className="m-tb0 text-primary float-left">
                            <span className="text-black m-r10">Posted:</span>{" "}
                            {moment(item.job.date_created).fromNow()}
                          </p>
                          <Link
                            to={"#"}
                            className="site-button button-sm float-right"
                            onClick={() => {
                              this.setCoverLetter(true, {
                                companyName: item.job.company.company_name,
                                coverLetter: item.cover_letter,
                              });
                            }}
                          >
                            View Cover Letter
                          </Link>
                        </div>
                        <button className="btn-danger btn m-r20 m-t10"
                          onClick={() => {
                            this.promptDeleteJob(item.id);
                          }}
                        >
                          Withdraw Application

                        </button>
                        
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
            {this.pagination(
              this.handlePrevious,
              this.handlePageChange,
              this.handleNext,
              this.props.appliedJobs.currentPage,
              this.props.appliedJobs.pageCount
            )}
          </div>
        </React.Fragment>
      );
    }
  };

  render() {
    return (
      <>
        <Header />
        <div className="page-content bg-white">
          <div className="content-block">
            <div className="section-full bg-white p-t50 p-b20">
              <div className="container">
                <div className="row">
                  <Profilesidebar currentPage="Applied Jobs" />
                  {this.renderContent()}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.coverLetter}
          onHide={this.setCoverLetter}
          className=" fade modal-bx-info"
        >
          <div role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div className="logo-img">
                  {/* <img alt="" src={require("./../../images/logo/icon2.png")} /> */}
                </div>
                <h5 className="modal-title">{this.state.modal.companyName}</h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => this.setCoverLetter(false)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>{this.state.modal.coverLetter}</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => this.setCoverLetter(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appliedJobs: state.entities.appliedJobs,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAppliedJobs: (url) => dispatch(getAppliedJobs(url)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Jobsappliedjob);
// export default Jobsappliedjob;
