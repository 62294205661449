import React, { Component, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import Header from "../Layout/Header";

import Footer from "../Layout/Footer";
import CompanyProfileSideBar from "../Element/companyProfileSideBar";
import { connect, useDispatch, useSelector } from "react-redux";
import { loadAppliedApplicants } from "../../store/reducers/appliedApplicants";
import "../../css/text.css";
import { apiUrl, languagesConstant } from "../../config.json";
import moment from "moment";
import {
  loadlanguages,
  getLanguagebyName,
} from "../../store/reducers/language";
import CandidateFilterBar from "../Element/candidateFilterBar";
import { checkPermission } from "../../utils/utilFunctions";
import ApplicantFindBox from "../Element/applicantFindBox";

class AdminCompanyResume extends Component {
  state = {
    company: false,
    modal: {
      jobTitle: "",
      employeeName: "",
      employeePhoto: "",
      phoneNumber: "",
      age: "",
      email: "",
      languages: [],
      fieldOfStudy: "",
      educationLevel: "",
      experience: "",
      address: "",
      cover_letter: "",
    },
  };

  setCompany = (
    company,
    modal = {
      jobTitle: "",
      employeeName: "",
      employeePhoto: "",
      phoneNumber: "",
      age: "",
      email: "",
      languages: [],
      fieldOfStudy: "",
      educationLevel: "",
      experience: "",
      address: "",
      cover_letter: "",
    }
  ) => {
    this.setState({ company, modal });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.match.params.id === undefined) {
      this.props.history.replace("/company-manage-job/");
    } else {
      this.props.loadAppliedApplicants(
        `api/appliedjobs/jobs/getapplicants/${this.props.match.params.id}/?`
      );
      this.props.loadlanguages();
    }
  }
  handleNext = () => {
    //console.log(this.props.appliedApplicants.next)
    let url = this.props.appliedApplicants.next.split("?")[1];
    this.props.loadAppliedApplicants(
      `api/appliedjobs/jobs/getapplicants/${this.props.match.params.id}/?${url}&`
    );
  };

  handlePrevious = () => {
    //console.log(this.props.appliedApplicants.previous)
    let url = this.props.appliedApplicants.previous.split("?")[1];
    this.props.loadAppliedApplicants(
      `api/appliedjobs/jobs/getapplicants/${this.props.match.params.id}/?${url}&`
    );
  };

  handlePageChange = (page) => {
    this.props.loadAppliedApplicants(
      `api/appliedjobs/jobs/getapplicants/${this.props.match.params.id}/?page=${page}&`
    );
  };

  pagination = (
    handlePrevious,
    handlePageChange,
    handleNext,
    currentPage,
    pageCount
  ) => {
    let pages = [];
    let lastIndex = 0;
    if (pageCount <= 11) {
      pages = _.range(pageCount);
    } else {
      pages.push(0);
      pages.push(1);
      if (currentPage - 3 > 2) {
        pages.push("...");
        for (let i = currentPage - 3; i <= currentPage + 3; i++) {
          if (i > 1 && i < pageCount - 2) {
            pages.push(i);
          }
        }
      } else {
        for (let i = 2; i <= currentPage + 6 - (currentPage - 2); i++) {
          if (i > 1 && i < pageCount - 2) {
            pages.push(i);
          }
        }
      }
      if (currentPage + 3 < pageCount) {
        pages.push("...");
      }

      pages.push(pageCount - 2);
      pages.push(pageCount - 1);
    }
    return (
      <div className="row">
        <div>
          <div className="pagination-bx m-t30 float-right">
            <ul className="pagination">
              {currentPage != 0 && (
                <li className="previous">
                  <Link to={"#"} onClick={handlePrevious}>
                    <i className="ti-arrow-left"></i> Prev
                  </Link>
                </li>
              )}

              {pages.map((page, index) => {
                if (page == "...") {
                  return (
                    <li className="">
                      <Link to={"#"}>...</Link>
                    </li>
                  );
                } else {
                  return (
                    <li className={page == currentPage ? "active" : ""}>
                      <Link
                        onClick={() => {
                          handlePageChange(page + 1);
                        }}
                        to={"#"}
                      >
                        {page + 1}
                      </Link>
                    </li>
                  );
                }
              })}

              {/* <li className="active"><Link to={"#"}>1</Link></li>
					<li><Link to={"#"}>2</Link></li>
					<li><Link to={"#"}>3</Link></li> */}
              {pageCount - 1 != currentPage && (
                <li className="next">
                  <Link to={"#"} onClick={handleNext}>
                    Next <i className="ti-arrow-right"></i>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    );
  };

  renderContent = () => {
    //console.log(this.props.language,"<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<")
    if (this.props.appliedApplicants.loading) {
      return <div className="loader"></div>;
    } else {
      return (
        <div className="col-xl-9 col-lg-8 m-b30">
          <ApplicantFindBox />

          <div className="job-bx clearfix">
            <div className="job-bx-title clearfix">
              <h5 className="font-weight-700 pull-left text-uppercase">
                {this.props.appliedApplicants.count} Applicants
              </h5>
              <Link
                to={"/"}
                className="site-button right-arrow button-sm float-right"
              >
                Back
              </Link>
            </div>
            <ul className="post-job-bx browse-job-grid post-resume row">
              {this.props.appliedApplicants.data[
                parseInt(this.props.appliedApplicants.currentPage)
              ].map((item, index) => {
                return (
                  <li className="col-lg-6 col-md-6" key={index}>
                    <div className="post-bx">
                      <div className="d-flex m-b20">
                        <div className="job-post-info">
                          <h5 className="m-b0">
                            {item.employee.title}{" "}
                            {item.employee.user.first_name}{" "}
                            {item.employee.user.last_name} (
                            {moment().diff(item.employee.dob, "years")} years)
                          </h5>
                          <p className="m-0">
                            {item.employee.user.email},{" "}
                            {item.employee.phone_number}
                          </p>
                          <p className="m-0 font-13">
                            {item.employee.current_company != ""
                              ? `${item.employee.current_company}`
                              : ""}
                          </p>
                          <ul>
                            <li>
                              <i className="fa fa-map-marker"></i>
                              {item.employee.region} {item.employee.city}{" "}
                              {item.employee.address}
                            </li>
                            {/* <li><i className="fa fa-money"></i> $ 2500</li> */}
                          </ul>
                        </div>
                      </div>
                      <div className="job-time m-t15 m-b10">
                        <p className="m-0">
                          <b>Applied Region: </b>
                          {item.region}
                        </p>
                        <p className="m-b10">
                          <b>Applied Town: </b>
                          {item.town}
                        </p>
                        <p className="m-0">
                          <b>Languages :</b>
                          {item.employee.talent_language.map(
                            (language, index) => {
                              return (
                                <React.Fragment>
                                  <table>
                                    <thead>
                                      <h4 style={{ fontSize: "16px" }}>
                                        <b>
                                          {this.props.getLanguagebyName(
                                            language.language
                                          )}
                                        </b>
                                      </h4>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td style={{ padding: "0px" }}>
                                          Speaking:{" "}
                                          {languagesConstant[language.speaking]}
                                        </td>
                                        <td style={{ padding: "0px" }}>
                                          Listening:{" "}
                                          {
                                            languagesConstant[
                                              language.listening
                                            ]
                                          }
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style={{ padding: "0px" }}>
                                          Reading:{" "}
                                          {languagesConstant[language.reading]}
                                        </td>
                                        <td style={{ padding: "0px" }}>
                                          Writing:{" "}
                                          {languagesConstant[language.writing]}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </React.Fragment>
                              );
                            }
                          )}
                        </p>
                        <p className="m-0">
                          <b>Education Level:</b>{" "}
                          {` ${item.employee.education_level}`}{" "}
                        </p>
                        <p className="m-0">
                          <b>Work Experience:</b>{" "}
                          {` ${item.employee.experience_in_years} years and ${item.employee.experience_in_months} months`}{" "}
                        </p>
                        <p className="m-0">
                          <b>Field of Study:</b>{" "}
                          {` ${item.employee.field_of_study.join(", ")}`}{" "}
                        </p>
                      </div>
                      <div className="job-time m-t15 m-b10">
                        <b>Cover Letter:</b>
                        <p className="text"> {item.cover_letter}</p>
                      </div>
                      <div className="job-time m-t15 m-b10">
                        {item.employee.sector.map((item, index) => {
                          return (
                            <Link to={"#"} key={index} className="mr-1">
                              <span>{item}</span>
                            </Link>
                          );
                        })}
                      </div>
                      <div className="job-time m-t15 m-b10">
                        Applied {moment(item.date_applied).fromNow()}
                      </div>
                      <button
                        className="site-button btn-primary rounded"
                        onClick={() => {
                          this.setCompany(true, {
                            jobTitle: item.job,
                            employeeName:
                              item.employee.user.first_name +
                              " " +
                              item.employee.user.last_name,
                            employeePhoto: item.employee.image,
                            phoneNumber: item.employee.phone_number,
                            age: moment().diff(item.employee.dob, "years"),
                            email: item.employee.user.email,
                            languages: item.employee.talent_language,
                            fieldOfStudy:
                              item.employee.field_of_study.join(", "),
                            educationLevel: item.employee.education_level,
                            experience:
                              item.employee.experience_in_years +
                              " years and " +
                              item.employee.experience_in_months +
                              " months",
                            address:
                              item.employee.region +
                              " " +
                              item.employee.city +
                              " " +
                              item.employee.address,
                            cover_letter: item.cover_letter,
                          });
                          //console.log(item.employee.talent_language, this.state.modal,"olaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaA<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<");
                        }}
                      >
                        View Applicant Details
                      </button>

                      <a
                        href={apiUrl + item.employee.uploaded_cv}
                        target="_blank"
                        download
                      >
                        <button className="site-button btn-primary rounded m-3">
                          <i className="fa fa-download"></i>
                        </button>
                      </a>
                    </div>
                  </li>
                );
              })}
            </ul>
            {this.pagination(
              this.handlePrevious,
              this.handlePageChange,
              this.handleNext,
              this.props.appliedApplicants.currentPage,
              this.props.appliedApplicants.pageCount
            )}
          </div>
        </div>
      );
    }
  };
  render() {
    const { company } = this.state;

    return (
      <>
        <Header />
        <div className="page-content bg-white">
          <div className="content-block">
            <div className="section-full bg-white p-t50 p-b20">
              <div className="container-fluid">
                <div className="row">
                  {/* <CompanyProfileSideBar currentPage={"Manage Jobs"} />
                   */}
                  <CandidateFilterBar jobId={this.props.match.params.id} />

                  {this.renderContent()}
                  <Modal
                    show={company}
                    onHide={this.setCompany}
                    className="modal fade modal-bx-info"
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="example-custom-modal-styling-title">
                        {/* Custom Modal Styling */}
                        <h5 className="modal-title">Detail Information </h5>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <ul>
                        <li>
                          <strong>Job Title :</strong>
                          <p> {this.state.modal.jobTitle} </p>
                        </li>
                        <li>
                          <strong>Cover Letter :</strong>
                          <p>{this.state.modal.cover_letter}</p>
                        </li>
                        <hr />
                        <li>
                          <h5>Job Seeker Information: </h5>{" "}
                        </li>
                        <li>
                          {" "}
                          <img
                            style={{
                              width: "100px",
                              width: "100px",
                              margin: "auto",
                              objectFit: "contain",
                            }}
                            src={apiUrl + this.state.modal.employeePhoto}
                            alt={`${this.state.modal.employeeName}'s photo`}
                          />{" "}
                        </li>

                        <li>
                          <strong>Name :</strong>
                          <p> {this.state.modal.employeeName} </p>
                        </li>
                        <li>
                          <strong>Email :</strong>
                          <p> {this.state.modal.email} </p>
                        </li>
                        <li>
                          <strong>Phone Number :</strong>
                          <p> {this.state.modal.phoneNumber} </p>
                        </li>
                        <li>
                          <strong>Age :</strong>
                          <p> {this.state.modal.age} </p>
                        </li>

                        <li>
                          <hr />

                          <b>Languages:</b>
                          {this.state.modal.languages.map((language, index) => {
                            return (
                              <React.Fragment>
                                <table>
                                  <thead>
                                    <h4 style={{ fontSize: "16px" }}>
                                      <b>
                                        {this.props.getLanguagebyName(
                                          language.language
                                        )}
                                      </b>
                                    </h4>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td style={{ padding: "0px" }}>
                                        Speaking:{" "}
                                        {languagesConstant[language.speaking]}
                                      </td>
                                      <td style={{ padding: "0px" }}>
                                        Listening:{" "}
                                        {languagesConstant[language.listening]}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ padding: "0px" }}>
                                        Reading:{" "}
                                        {languagesConstant[language.reading]}
                                      </td>
                                      <td style={{ padding: "0px" }}>
                                        Writing:{" "}
                                        {languagesConstant[language.writing]}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </React.Fragment>
                            );
                          })}
                          <hr />
                        </li>
                        <li>
                          <strong>Field Of Study :</strong>
                          <p> {this.state.modal.fieldOfStudy} </p>
                        </li>
                        <li>
                          <strong>Education Level :</strong>
                          <p> {this.state.modal.educationLevel} </p>
                        </li>
                        <li>
                          <strong>Experience :</strong>
                          <p> {this.state.modal.experience} </p>
                        </li>
                        <li>
                          <strong>Address :</strong>
                          <p> {this.state.modal.address} </p>
                        </li>
                      </ul>
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => this.setCompany(false)}
                      >
                        Close
                      </button>
                    </Modal.Footer>
                    {/* <div className="modal-dialog my-0 lg" role="document">
											<div className="modal-content lg" >
												<div className="modal-header" id="example-modal-sizes-title-lg">

													<button type="button" className="close" onClick={() => this.setCompany(false)}>
														<span aria-hidden="true">&times;</span>
													</button>
												</div>
												<div className="modal-body">
													<ul>
														<li><strong>Job Title :</strong><p> {this.state.modal.jobTitle} </p></li>
														<li><strong>Cover Letter :</strong>
															<p>{this.state.modal.cover_letter}</p>
														</li>
														<hr />
														<li><strong>Name :</strong><p> {this.state.modal.employeeName} </p></li>
														<li><strong>Email :</strong><p> {this.state.modal.email} </p></li>
														<li><strong>Phone Number :</strong><p> {this.state.modal.phoneNumber} </p></li>
														<li><strong>Age :</strong><p> {this.state.modal.age} </p></li>
														<li><strong>Languages :</strong><p> {this.state.modal.languages} </p></li>
														<li><strong>Field Of Study :</strong><p> {this.state.modal.fieldOfStudy} </p></li>
														<li><strong>Education Level :</strong><p> {this.state.modal.educationLevel} </p></li>
														<li><strong>Experience :</strong><p> {this.state.modal.experience} </p></li>
														<li><strong>Address :</strong><p> {this.state.modal.address} </p></li>

													</ul>
												</div>
												<div className="modal-footer">
													<button type="button" className="btn btn-secondary" onClick={() => this.setCompany(false)}>Close</button>
												</div>
											</div>
										</div> */}
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    appliedApplicants: state.entities.appliedApplicants,
    languages: state.entities.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadAppliedApplicants: (data) => dispatch(loadAppliedApplicants(data)),
    getLanguagebyName: (data) => dispatch(getLanguagebyName(data)),
    loadlanguages: () => dispatch(loadlanguages()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminCompanyResume);

// export default CompanyResume;
