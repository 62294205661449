import React from 'react';
const PricingTableRow = ({ item, selected, handleSelect }) => {

    const getClassName = () => {
        //console.log(item.id, selected)
        if (item.id == selected) {
            return "pricingtable-wrapper style2 bg-primary text-white active";
        }
        else {
            return "pricingtable-wrapper style2 bg-white"

        }
    }
    const duration = { "D": "Days", "W": "weeks", "M": "Months", "Y": "Years" }
    const getDuration = () => {
        if (item.duration == 0) {
            return "Unlimited"
        }
        else {
            return (`${item.duration} ${duration[item.duration_unit]}`)
        }
    }
    const getApplicationTracking = () => {
        if (item.application_tracking == 0) {
            return <span><i className="fa fa-circle text-red"></i></span>

        }
        else {
            return <span><i className="fa fa-circle text-green"></i></span>
        }
    }
    const getJobPosts = () => {
        if (item.job_post_count == 0) {
            return "Unlimited";
        }
        else {
            return item.job_post_count + " Job Posts";
        }

    }
    const getFeaturedJob = () => {
        if (item.featured_job == 0) {
            return <span><i className="fa fa-circle text-red"></i></span>

        }
        else {
            return item.featured_job + " Featured Job Posts"
        }
    }
    const getFeaturedEmployer = () => {
        if (item.featured_employer == 0) {
            return <span><i className="fa fa-circle text-red"></i></span>

        }
        else {
            return <span><i className="fa fa-circle text-green"></i></span>
        }

    }

    /**
         * id(pin):1
        jobs_package_name(pin):"Basic(Time)"
        price(pin):250
        duration(pin):30
        duration_unit(pin):"D"
        application_tracking(pin):1
        featured_job(pin):0
        featured_job_count(pin):0
        job_post_count(pin):0
        featured_employer(pin):1
     * 
     * 
     */
    return (
        <div className="col-sm-12 col-md-4 col-lg-4 p-2" style={{ cursor: "pointer" }} onClick={() => { handleSelect(item.id) }}>
            <div className={getClassName()}>

                {/* <div className="pricingtable-wrapper style2 bg-white"> */}
                <div className="pricingtable-inner">
                    <div className="pricingtable-price">
                        <h4 className="font-weight-300 m-t10 m-b0">{item.jobs_package_name}</h4>
                        <div className="pricingtable-bx"><span>{item.price} ETB</span></div>
                    </div>
                    {/* <p>{description}</p> */}
                    <table style={{width:"auto"}}>
                        <tbody>
                            <tr>
                                <td>
                                    <strong>Duration:</strong>
                                </td>
                                <td> {getDuration()}</td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Application Tracking:</strong>
                                </td>
                                <td> {getApplicationTracking()}</td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Job post:</strong>
                                </td>
                                <td> {getJobPosts()}</td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Featured Job:</strong>
                                </td>
                                <td> {getFeaturedJob()}</td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Featured Employer:</strong>
                                </td>
                                <td> <span><i className="fa fa-circle text-red"></i></span></td>
                            </tr>
                        </tbody>
                    </table>

                    {/* <div className="m-t20">
                        <Link to={"/register"} className="site-button radius-xl"><span className="p-lr30">Sign Up</span></Link>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default PricingTableRow;