import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Logout from "./Logout";
import auth, { getCurrentUser } from "../../services/authServices";
import { apiUrl } from "../../config.json";

import { checkPermission } from "../../utils/utilFunctions";
import logo2 from "./../../images/logo.png";
import { connect } from "react-redux";
import { getauthDataInformation } from "../../store/reducers/auth";
import _ from "lodash";
var bnr3 = require("./../../images/background/bg6.jpg");
class Header extends Component {
  state = {
    // initial state
    show: true,
    name: "",
    profile: "",
    userType: 0,
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  checkUserType = () => {
    // check if company or candidate
    const user = auth.getCurrentUser();
    if (user === null) return;
    let userType = user.userProfile;
    let name = "";
    let profile = "";
    const authData = this.props.auth;
    switch (userType) {
      case "company":
        name =
          authData.data.company_name +
          " (" +
          authData.data.representative.first_name +
          " " +
          authData.data.representative.last_name +
          ")";
        profile = apiUrl + authData.data.logo;
        this.setState({ userType: 2, name, profile });
        break;
      case "jobSeeker":
        name =
          authData.data.user.first_name + " " + authData.data.user.last_name;
        profile = apiUrl + authData.data.image;
        this.setState({ userType: 1, name, profile });
        break;
      case "admin":
        name = authData.data.first_name + " " + authData.data.last_name;
        profile = apiUrl + "media/default.jpg";
        this.setState({ userType: 3, name, profile });
        break;

      default:
        this.setState({ userType: 0 });
    }
    // //console.log(auth.getCurrentUser(), "curret user<<<<<<<<<<<<<<<<<<<<")
  };

  componentDidMount() {
    // sidebar open/close
    this.checkUserType();
    // //console.log(auth.getCurrentUserPermissions())
    // //console.log(this.checkPermission("view","applied__jobs"),"checkPermission")
    const user = auth.getCurrentUser();
    if (user != null) {
      if (this.props.auth.data === {} || this.props.auth.lastFetch === null) {
        this.props.getAuthDataInformation(user.userProfile);
      }
    }
    var Navicon = document.querySelector(".navicon");
    var sidebarmenu = document.querySelector(".myNavbar ");

    function toggleFunc() {
      sidebarmenu.classList.toggle("show");
      //   Navicon.classList.toggle('open');
    }
    Navicon.addEventListener("click", toggleFunc);

    // Sidenav li open close
    var navUl = [].slice.call(
      document.querySelectorAll(".navbar-nav > li > a, .sub-menu > li > a")
    );
    for (var y = 0; y < navUl.length; y++) {
      navUl[y].addEventListener("click", function () {
        checkLi(this);
      });
    }

    function checkLi(current) {
      current.parentElement.parentElement
        .querySelectorAll("li")
        .forEach((el) =>
          current.parentElement !== el ? el.classList.remove("open") : ""
        );
      setTimeout(() => {
        current.parentElement.classList.toggle("open");
      }, 100);
    }
  }
  render() {
    const { auth } = this.state;
    return (
      <>
        <header className="site-header mo-left header border-bottom fullwidth">
          <div className="sticky-header main-bar-wraper navbar-expand-lg">
            <div className="main-bar clearfix">
              <div className="container-fluid clearfix">
                <div className="logo-header mostion">
                  <Link to={"/"}>
                    <img src={logo2} className="logo" alt="abh-logo" />
                  </Link>
                </div>

                <button
                  className="navbar-toggler collapsed navicon  justify-content-end"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNavDropdown"
                  aria-controls="navbarNavDropdown"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
                <div className="extra-nav" style={{ padding: "12px" }}>
                  <div className="extra-cell">
                    {this.state.userType != 0 ? (
                      <ul className="navbar-nav flex-row">
                        <li className="nav-item me-3 me-lg-1">
                          <a
                            className="nav-link d-sm-flex align-items-sm-center"
                            href="#"
                          >
                            <img
                              src={this.state.profile}
                              className="rounded-circle shadow-sm border bg-white "
                              style={{
                                height: "50px",
                                width: "50px",
                                objectFit: "cover",
                                marginRight: "23px",
                              }}
                              alt="profile picture"
                              loading="lazy"
                            />
                            <strong className="d-none d-sm-block ms-1">
                              {this.state.name}
                            </strong>
                          </a>
                        </li>
                      </ul>
                    ) : (
                      <></>
                    )}
                    {/* {this.state.userType === 0 && (
                     <div className="extra-nav">
                     <div className="extra-cell">
                       <Link to={"/about-us"} className="site-button">About Us{" "} </Link>
                       
                     </div>
                   </div>
                    )} */}
                  </div>
                </div>

                <div
                  className="header-nav navbar-collapse collapse myNavbar justify-content-start"
                  id="navbarNavDropdown"
                >
                  <div className="logo-header mostion d-md-block d-lg-none">
                    <Link to={"/"} className="dez-page">
                      <img src={logo2} alt="logo" />
                    </Link>
                  </div>
                  <ul className="nav navbar-nav">
                    {/* <li className=""> 
										
											<Link to={'#'} >Home <i className="fa fa-chevron-down"></i></Link>
											<ul className="sub-menu">
												<li><Link to={"./"} className="dez-page">Home 1</Link></li>
												<li><Link to={"/index-2"} className="dez-page">Home 2</Link></li>
											</ul>
										</li> */}
                    {this.state.userType === 0 ? (
                      <React.Fragment>
                        <li>
                          <Link to={"/"} className="dez-page">
                            Home
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={"/browse-job-filter-list"}
                            className="dez-page"
                          >
                            Jobs
                          </Link>
                        </li>
                        <li>
                          <Link to={"/login"} className="dez-page">
                            Log in
                          </Link>
                        </li>
                        <li>
                          <Link to={"/register-2"} className="dez-page">
                            {" "}
                            join Us
                          </Link>
                        </li>
                        <li>
                          <Link to={"#"}>
                            Employer? <i className="fa fa-chevron-down"></i>
                          </Link>
                          <ul className="sub-menu">
                            {/* <li>
                              <Link to={"/register"} className="dez-page">
                                Register{" "}
                              </Link>
                            </li> */}
                            <li>
                              <Link to={"/login"} className="dez-page">
                                Sign in
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link to={"/contact"} className="dez-page">
                            {" "}
                            Contact Us
                          </Link>
                        </li>
                        <li>
                          <Link to={"/about-us"} className="dez-page">
                            {" "}
                            About Us
                          </Link>
                        </li>
                        {/* <li><Link to={"/jobs-profile"} className="dez-page">My Profile</Link></li> */}
                      </React.Fragment>
                    ) : (
                      <></>
                    )}
                    {this.state.userType === 1 ? (
                      <React.Fragment>
                        {/* <li><Link to={"/jobs-profile"} className="dez-page">My Profile</Link></li> */}
                        <li>
                          <Link to={"/"} className="dez-page">
                            Home
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={"/browse-job-filter-list"}
                            className="dez-page"
                          >
                            Jobs
                          </Link>
                        </li>
                        <li>
                          <Link to={"/jobs-profile"} className="dez-page">
                            My Profile
                          </Link>
                        </li>
                        {/* <li><Link to={"/jobs-cv-manager"} className="dez-page">CV Manager </Link></li> */}
                        <li>
                          <Link to={"/jobs-my-resume"} className="dez-page">
                            My Resume{" "}
                          </Link>
                        </li>
                        <li>
                          <Link to={"/jobs-applied-job"} className="dez-page">
                            Applied Jobs{" "}
                          </Link>
                        </li>
                        <li>
                          <Link to={"/contact"} className="dez-page">
                            {" "}
                            Contact Us
                          </Link>
                        </li>
                        <li>
                          <Link to={"/about-us"} className="dez-page">
                            {" "}
                            About Us
                          </Link>
                        </li>
                        {/* <li > */}
                        {/* <Link to={'#'} >Jobs <i className="fa fa-chevron-down"></i></Link> */}
                        {/* <ul className="sub-menu"> */}
                        {/* <li><Link to={"/jobs-alerts"} className="dez-page">Jobs Alerts </Link></li> */}
                        {/* <li><Link to={"/jobs-saved-jobs"} className="dez-page">Saved Job </Link></li> */}

                        {/* </ul> */}
                        {/* </li> */}

                        {/* <li>
                          <Link to={"#"}>
                            Profile <i className="fa fa-chevron-down"></i>
                          </Link>
                          <ul className="sub-menu">
                            <li>
                              <Link
                                to={"/jobs-change-password"}
                                className="dez-page"
                              >
                                Change Password{" "}
                              </Link>
                            </li>
                            <li>
                              <Logout />
                            </li>
                          </ul>
                        </li> */}
                        <li>
                          <Logout />
                        </li>
                      </React.Fragment>
                    ) : (
                      <></>
                    )}
                    {this.state.userType === 2 ? (
                      <React.Fragment>
                        {/* <li><Link to={"/company-dashboard"} className="dez-page">Dashboard </Link></li> */}
                        {checkPermission("view", "company") && (
                          <li>
                            <Link to={"#"}>
                              My Company <i className="fa fa-chevron-down"></i>
                            </Link>
                            <ul className="sub-menu">
                              <li>
                                <Link
                                  to={"/company-profile"}
                                  className="dez-page"
                                >
                                  Company Profile{" "}
                                </Link>
                              </li>
                            </ul>
                          </li>
                        )}

                        {(checkPermission("add", "job") ||
                          checkPermission("change", "job") ||
                          checkPermission("view", "job")) && (
                          <li>
                            <Link to={"#"}>
                              Manage Jobs <i className="fa fa-chevron-down"></i>
                            </Link>
                            <ul className="sub-menu">
                              {/* <li><Link to={"/company-profile"} className="dez-page">Company Profile </Link></li> */}
                              {/* <li><Link to={"/company-resume"} className="dez-page">Employer Resume </Link></li> */}
                              {(checkPermission("add", "job") ||
                                checkPermission("change", "job")) && (
                                <li>
                                  <Link
                                    to={"/company-post-jobs"}
                                    className="dez-page"
                                  >
                                    Post A Job{" "}
                                  </Link>
                                </li>
                              )}
                              {checkPermission("view", "job") && (
                                <li>
                                  <Link
                                    to={"/company-manage-job"}
                                    className="dez-page"
                                  >
                                    Manage jobs{" "}
                                  </Link>
                                </li>
                              )}
                              {/* <li><Link to={"/browse-candidates"} className="dez-page">Browse Candidates</Link></li> */}
                            </ul>
                          </li>
                        )}
                        {checkPermission("view", "user") && (
                          <li>
                            <Link to={"/company-users"} className="dez-page">
                              Manage Users{" "}
                            </Link>
                          </li>
                        )}
                        <li>
                          <Link to={"/about-us"} className="dez-page">
                            {" "}
                            About Us
                          </Link>
                        </li>

                        <li>
                          <Logout />
                        </li>
                      </React.Fragment>
                    ) : (
                      <></>
                    )}

                    {this.state.userType === 3 ? (
                      <React.Fragment>
                        <li>
                          <Link to={"/"} className="dez-page">
                            Dashboard{" "}
                          </Link>
                        </li>
                        <li>
                          <Link to={"/companies"} className="dez-page">
                            Companies{" "}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={"/jobs-change-password"}
                            className="dez-page"
                          >
                            Change Password
                          </Link>
                        </li>
                        <li>
                          <Link to={"/contact"} className="dez-page">
                            {" "}
                            Contact Us
                          </Link>
                        </li>

                        <li>
                          <Link to={"/about-us"} className="dez-page">
                            {" "}
                            About Us
                          </Link>
                        </li>
                        <li>
                          <Logout />
                        </li>
                      </React.Fragment>
                    ) : (
                      <></>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});
const mapDispatchToProps = (dispatch) => ({
  getAuthDataInformation: (data) => dispatch(getauthDataInformation(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
// export default Header;
