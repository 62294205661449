import React, { Component } from "react";
import { getOpenJobs } from "../../store/reducers/openJobs";
import { connect } from "react-redux";
import appliedApplicants, { loadAppliedApplicants } from "../../store/reducers/appliedApplicants";
import { getAppliedJobsUrl } from "../../config.json";
class ApplicantFindBox extends Component {
  state = {
    data: "",
  };
  handleChange = (e) => {
    // const data = { ...this.state.data }
    const data = e.currentTarget.value;
    this.setState({ data });
  };

  componentDidMount() {
    var inputSelector = document.querySelectorAll("input, textarea");
    this.setState({ data: this.props.openJobs.searchQuery });
    if (this.props.openJobs.searchQuery.trim().length > 0) {
      inputSelector[0].parentElement.parentElement.classList.add("focused");
    }
    var i = 0;

    // Placeholder Animation Start

    for (i = 0; i < inputSelector.length; i++) {
      inputSelector[i].addEventListener("focus", function (event) {
        return this.parentElement.parentElement.classList.add("focused");
      });
    }

    for (i = 0; i < inputSelector.length; i++) {
      inputSelector[i].addEventListener("blur", function (event) {
        var inputValue = this.value;
        if (inputValue === "") {
          this.parentElement.parentElement.classList.remove("filled");
          this.parentElement.parentElement.classList.remove("focused");
        } else {
          this.parentElement.parentElement.classList.add("filled");
        }
      });
    }

    // Placeholder Animation End
  }
  render() {
    return (
      <div className="section-full browse-job-find m-t30">
        <div className="container">
          <div className="find-job-bx">
            <form
              className="dezPlaceAni"
              onSubmit={(e) => {
                e.preventDefault();
                // this.props.getOpenJobs(null, this.state.data);
				this.props.loadAppliedApplicants(this.props.appliedApplicants.currentUrl,"", "&search="+this.state.data);
                // routingFunction = (param) => {
                // }
              }}
            >
              <div className="row">
                <div className="col-lg-10 col-md-6">
                  <div className="form-group">
                    <label>Job Seeker Name, or Keywords</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        id="searchField"
                        onChange={this.handleChange}
                        value={this.state.data}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <i className="fa fa-search"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="col-lg-3 col-md-6">
									<div className="form-group">
										<Form.Control as="select" custom className="select-btn">
											<option>Select Sector</option>
											<option>Construction</option>
											<option>Corodinator</option>
											<option>Employer</option>
											<option>Financial Career</option>
											<option>Information Technology</option>
											<option>Marketing</option>
											<option>Quality check</option>
											<option>Real Estate</option>
											<option>Sales</option>
											<option>Supporting</option>
											<option>Teaching</option> 
										</Form.Control>
									</div>
								</div> */}
                <div className="col-lg-2 col-md-6">
                  <button type="submit" className="site-button btn-block">
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  openJobs: state.entities.openJobs,
  appliedApplicants: state.entities.appliedApplicants,
});
const mapDispatchToProps = (dispatch) => ({
  getOpenJobs: (filter, search) => dispatch(getOpenJobs(filter, search)),
  loadAppliedApplicants: (url, filter, search) =>
    dispatch(loadAppliedApplicants(url, filter, search)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicantFindBox);
// export default Jobfindbox;
