import { createSlice } from "@reduxjs/toolkit";
import { ApiCallBegan } from "../actions/api";
import checkCache from "../../services/cache";
import { coreCacheLimit } from "../../config.json";
import { getCurrentPage } from "../../utils/utilFunctions";
import { toast } from "react-toastify";
import _ from "lodash";

const pagination = 20;
const pageUrl = "api/company/employerUser/";

const slice = createSlice({
  name: "users",
  initialState: {
    data: [],
    user: {},
    count: 0,
    history: [],
    currentPage: 0,
    pageCount: 1,
    next: null,
    previous: null,
    lastFetch: null,
    loading: true,
  },
  reducers: {
    usersFetched: (state, action) => {
      let newState = [];
      let newHistory = [];
      let data = action.payload.data;
      let pageCount = Math.ceil(data.count / pagination);
      if (state.data.length == 0) {
        newState = _.fill(Array(pageCount), []);
        newHistory = _.fill(Array(pageCount), {
          next: null,
          previous: null,
          loaded: false,
        });
      } else {
        newState = state.data;
        newHistory = state.history;
      }

      let currentPage = getCurrentPage(action.payload.data.previous);

      newState[currentPage] = data.results;
      newHistory[currentPage] = {
        next: data.next,
        previous: data.previous,
        loaded: true,
      };
      state.history = newHistory;
      state.data = newState;
      state.count = data.count;
      state.currentPage = currentPage;
      state.pageCount = pageCount;
      state.next = data.next;
      state.previous = data.previous;
      state.lastFetch = Date.now();
      state.loading = false;
      return state;
    },
    usersLoadedFromCache: (state, action) => {
      state.currentPage = action.payload.currentPage;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
      return state;
    },
    usersRequested: (state, action) => {
      state.loading = true;
      return state;
    },
    usersRequestFailed: (state, action) => {
      state.loading = false;
      return state;
    },
    userUpdated: (state, action) => {
      // state.user = action.payload.data;
      state.lastFetch = Date.now();
      toast.success("User Updated");
      state.loaded = true;
      state.action = 1;
      state.loading = false;
      return state;
    },
    userCreated: (state, action) => {
      state.user = action.payload.data;
      state.lastFetch = Date.now();
      toast.success("User Created");
      state.action = 2;
      state.loaded = true;
      state.loading = false;
      return state;
    },
    userFetched: (state, action) => {
      state.user = action.payload.data;
      state.lastFetch = Date.now();
      state.loading = false;
      return state;
    },
  },
});

export const {
  usersFetched,
  usersLoadedFromCache,
  usersRequested,
  usersRequestFailed,
  userUpdated,
  userCreated,
  userFetched,
} = slice.actions;
export default slice.reducer;

export const createUser = (data) => (dispatch, getState) => {
  return dispatch(
    ApiCallBegan({
      url: pageUrl,
      onSuccess: userCreated.type,
      onError: usersRequestFailed.type,
      onStart: usersRequested.type,
      data: data,
      method: "POST",
    })
  );
};

export const updateUser = (data) => {
  //console.log(data,"Update user");
  return ApiCallBegan({
    url: `${pageUrl}${data.id}/`,
    onSuccess: userUpdated.type,
    onError: usersRequestFailed.type,
    onStart: usersRequested.type,
    data: data,
    method: "PUT",
  });
};

// export const loadUser = (id) => (dispatch, getState) => {
//   return ApiCallBegan({
//     url: `${pageUrl}${id}/`,
//     onSuccess: userFetched.type,
//     onError: usersRequestFailed.type,
//     onStart: usersRequested.type,
//     data: data,
//     method: "PUT",
//   });
// };

export const loadUsers = (page) => (dispatch, getState) => {
  const { lastFetch, history, data } = getState().entities.users;
  const thisPage = getCurrentPage(pageUrl) - 1;
  const pageHistory = history[thisPage];

  // if (checkCache(lastFetch, coreCacheLimit) && pageHistory.loaded && data!=[]) {
    
  //   dispatch(
  //     usersLoadedFromCache({
  //       currentPage: thisPage,
  //       next: pageHistory.next,
  //       previous: pageHistory.previous,
  //     })
  //   );
  //   return;
  // }

  dispatch(
    ApiCallBegan({
      url: page,
      onStart: usersRequested.type,
      onSuccess: usersFetched.type,
      onError: usersRequestFailed.type,
    })
  );
};

export const loadUser = (id) => (dispatch, getState) => {
  const { lastFetch } = getState().entities.users;
  const currentId = getState().entities.users.user.id;

  const diffInMinutes = (Date.now() - lastFetch) / 1000 / 60;
  if (diffInMinutes < coreCacheLimit && currentId == id) return;

  dispatch(
    ApiCallBegan({
      url: `${pageUrl}${id}/`,
      onSuccess: userFetched.type,
      onStart: usersRequested.type,
      onError: usersRequestFailed.type,
    })
  );
};
