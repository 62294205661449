import React from 'react';
import { Link } from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import Profilesidebar from '../Element/Profilesidebar';
import authServices from '../../services/authServices';
import CompanyProfileSideBar from '../Element/companyProfileSideBar';
import Joi from 'joi-browser';
import { toast } from 'react-toastify';


import Form from '../Element/common/form';
import httpService from '../../services/httpService';
import { apiUrl, changePasswordUrl } from '../../config.json';
class ChangePasswordPage extends Form {
	state = {
		data: {
			current_password: "",
			new_password: "",
			confirmPassword: ""
		},
		errors: {}
	}
	getProfileBar = () => {
		const user = authServices.getCurrentUser()
		if (user.userProfile === "jobSeeker") {
			return <Profilesidebar currentPage={"Change Password"} />
		}
		else {
			return <CompanyProfileSideBar currentPage={"Change Password"} />

		}
	}
	doSubmit = async () => {
		const data = { ...this.state.data }
		let errors = { ...this.state.errors }
		if (data.new_password !== data.confirmPassword) {
			errors.confirmPassword = "Password does not match"
			this.setState({ errors })
			return
		}
		try {

			const resp = await httpService.post(apiUrl + changePasswordUrl, data)

			if (resp.status === 204) {
				toast.success("Password changed successfully")
				this.props.history.push("/")
			}
		}
		catch (err) {
			toast.success("Password changed successfully")
			const data = err.response.data
			let errors = { ...this.state.errors }
			Object.keys(data).forEach(key => {
				//console.log(key)
				errors[key] = data[key]
			})
			this.setState({ errors })
			//console.log(err.response.data, "<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<")
			// if (err.response && err.response.status === 400) {
			toast.error(err.response)
			// }

			// toast.error()
		}
	}

	schema = {
		current_password: Joi.string().min(8).required().label("Old Password"),
		new_password: Joi.string().min(8).required().label("New Password"),
		confirmPassword: Joi.string().min(8).required().label("Confirm Password")
	}

	render() {

		return (
			<>
				<Header />
				<div className="page-content bg-white">
					<div className="content-block">
						<div className="section-full bg-white browse-job p-t50 p-b20">
							<div className="container">
								<div className="row">


									{this.getProfileBar()}
									<div className="col-xl-9 col-lg-8 m-b30">
										<div className="job-bx job-profile">
											<div className="job-bx-title clearfix">
												<h5 className="font-weight-700 pull-left text-uppercase">Change Password</h5>
												<Link to={"/jobs-cv-manager"} className="site-button right-arrow button-sm float-right">Back</Link>
											</div>
											<form onSubmit={this.handleSubmit}>
												<div className="row">
													<div className="col-lg-12">
														{this.renderInput("current_password", "Old Password", "password")}
														{/* <div className"formgroup">
															<label>OldPassword/label>
															<input type="password" className="form-control" />
														</div> */}
													</div>
													<div className="col-lg-6">
														{this.renderInput("new_password", "New Password", "password")}
														{/* <div className="form-group">
															<label>New Password </label>
															<input type="password" className="form-control" />
														</div> */}
													</div>
													<div className="col-lg-6">
														{this.renderInput("confirmPassword", "Confirm Password", "password")}
														{/* <div className="form-group">
															<label>Confirm New Password</label>
															<input type="password" className="form-control" />
														</div> */}
													</div>
													<div className="col-lg-12 m-b10">
														<button className="site-button">Update Password</button>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</>
		)
	}
}
export default ChangePasswordPage;