import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, Card } from 'react-bootstrap';
import auth from '../../services/authServices';
import { getOpenJobs } from '../../store/reducers/openJobs';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { loadRegions } from '../../store/reducers/region';
import { loadExperienceLevels } from '../../store/reducers/experienceLevel';
import { loadEmploymentTypes } from '../../store/reducers/employmentType';
import sector from '../../store/reducers/sector';
import { loadSalaryType } from '../../store/reducers/salaryType';
import { indexOf } from 'lodash';

function Accordsidebar() {
	const dispatch = useDispatch();
	const jobs = useSelector(state => state.entities.openJobs);
	const region = useSelector(state => state.entities.region);
	const experience_level = useSelector(state => state.entities.experienceLevel);
	const employment_type = useSelector(state => state.entities.employmentType);
	const salary_type = useSelector(state => state.entities.salaryType);


	useEffect(() => {
		window.scrollTo(0, 0)
		// dispatch(getOpenJobs());
		dispatch(loadExperienceLevels());
		dispatch(loadEmploymentTypes());
		dispatch(loadRegions());
		dispatch(loadSalaryType());



	}, [])

	return (
		<div className="col-xl-3 col-lg-4 col-md-5 m-b30">
			{(region.loading || experience_level.loading || employment_type.loading || salary_type.loading) ? (
				<div className="d-flex justify-content-center" >
					<div className="spinner-border" role="status">
						<i className="fa fa-spinner fa-spin m-r5" style={{ fontSize: "75px" }}></i>
					</div>
				</div>) :
				(
					<aside id="accordion1" className="sticky-top sidebar-filter bg-white">
						<Accordion defaultActiveKey="0">
							<h6 className="title"><i className="fa fa-sliders m-r5"></i> Refined By <Link to={"#"} className="font-12 float-right" onClick={() => {

								const regionCount = region.data.length;
								for (let i = 0; i < regionCount; i++) {
									document.getElementById(`region${i}`).checked = false;
								}
								const experienceLevelCount = experience_level.data.length;
								for (let i = 0; i < experienceLevelCount; i++) {
									document.getElementById(`experience${i}`).checked = false;
								}
								const employmentTypeCount = employment_type.data.length;
								for (let i = 0; i < employmentTypeCount; i++) {
									document.getElementById(`Employment${i}`).checked = false;
								}
								const salaryTypeCount = salary_type.data.length;
								for (let i = 0; i < salaryTypeCount; i++) {
									document.getElementById(`salaryType${i}`).checked = false;
								}
								document.getElementById('salaryGreaterThan').value = '';
								document.getElementById('salaryLessThan').value = '';



							}}>Reset All</Link></h6>

							<Accordion.Toggle as={Card} eventKey="0">
								<div className="acod-head">
									<h6 className="acod-title">
										<Link data-toggle="collapse" to="#regions">Region</Link>
									</h6>
								</div>
							</Accordion.Toggle>

							<Accordion.Collapse eventKey="0">
								<div id="regions" className="acod-body collapse show">
									<div className="acod-content">
										{region.data.map((item, index) => {
											return (
												<div className="custom-control custom-checkbox">
													<input type="checkbox" className="custom-control-input" id={"region" + index} />
													<label className="custom-control-label" htmlFor={"region" + index}>{item.name}</label>
												</div>
											)
										})}

									</div>
								</div>
							</Accordion.Collapse>

							<Accordion.Toggle as={Card} eventKey="1">
								<div className="acod-head">
									<h6 className="acod-title">
										<a data-toggle="collapse" href="#experience" className="collapsed">Experience Level</a>
									</h6>
								</div>
							</Accordion.Toggle>
							<Accordion.Collapse eventKey="1">
								<div id="experience" className="acod-body collapse show">
									<div className="acod-content">
										{experience_level.data.map((item, index) => {
											return (
												<div className="custom-control custom-checkbox">
													<input type="checkbox" className="custom-control-input" id={"experience" + index} />
													<label className="custom-control-label" htmlFor={"experience" + index}>{item.name}</label>
												</div>
											)
										})}

									</div>
								</div>
							</Accordion.Collapse>

							<Accordion.Toggle as={Card} eventKey="2">
								<div className="acod-head">
									<h6 className="acod-title">
										<a data-toggle="collapse" href="#Employment" className="collapsed">Employment Type</a>
									</h6>
								</div>
							</Accordion.Toggle>
							<Accordion.Collapse eventKey="2">
								<div id="Employment" className="acod-body collapse show">
									<div className="acod-content">
										{employment_type.data.map((item, index) => {
											return (
												<div className="custom-control custom-checkbox">
													<input type="checkbox" className="custom-control-input" id={"Employment" + index} />
													<label className="custom-control-label" htmlFor={"Employment" + index}>{item.name}</label>
												</div>
											)
										})}

									</div>
								</div>
							</Accordion.Collapse>

							<Accordion.Toggle as={Card} eventKey="3">
								<div className="acod-head">
									<h6 className="acod-title">
										<a data-toggle="collapse" href="#salaryType" className="collapsed" >
											salary Type
										</a>
									</h6>
								</div>
							</Accordion.Toggle>
							<Accordion.Collapse eventKey="3">
								<div id="salaryType" className="acod-body collapse show">
									<div className="acod-content">
										{salary_type.data.map((item, index) => {
											//console.log(item,index)
											return (
												<div className="custom-control custom-checkbox">
													<input type="checkbox" className="custom-control-input" id={"salaryType" + index} />
													<label className="custom-control-label" htmlFor={"salaryType" + index}>{item.name}</label>
												</div>
											)
										})}
									</div>
								</div>
							</Accordion.Collapse>
							<Accordion.Toggle as={Card} eventKey="4">
								<div className="acod-head">
									<h6 className="acod-title">
										<a data-toggle="collapse" href="#salary" className="collapsed" >
											Salary
										</a>
									</h6>
								</div>
							</Accordion.Toggle>
							<Accordion.Collapse eventKey="4">
								<div id="salary" className="acod-body collapse show">
									<div className="">
										<div className="custom-control p-3">
											<label className="" htmlFor="function-services-1">Greater Than $</label>
											<input type="number" min="0" className="form-control" placeholder="Salary Greater Than" id='salaryGreaterThan' />
										</div>
										<div className="custom-control p-3">
											<label className="" htmlFor="function-services-1">Less Than $</label>
											<input type="number" min="0" className="form-control" placeholder="Salary Less Than" id='salaryLessThan' />
											<label className="" htmlFor="function-services-1"></label>

										</div>
									</div>
								</div>
							</Accordion.Collapse>
							{/* <Accordion.Toggle as={Card} eventKey="3">
								<div className="acod-head">
									<h6 className="acod-title">
										<a data-toggle="collapse" href="#salary" className="collapsed" >
											Job Function
										</a>
									</h6>
								</div>
							</Accordion.Toggle>
							<Accordion.Collapse eventKey="3">
								<div id="salary" className="acod-body collapse show">
									<div className="acod-content">
										<div className="custom-control custom-radio">
											<input className="custom-control-input" id="function-services-1" type="radio" name="radio-function" />
											<label className="custom-control-label" htmlFor="function-services-1">Production Management <span>(120)</span> </label>
										</div>
										<div className="custom-control custom-radio">
											<input className="custom-control-input" id="function-services-2" type="radio" name="radio-function" />
											<label className="custom-control-label" htmlFor="function-services-2">Design Engineering <span>(300)</span> </label>
										</div>
										<div className="custom-control custom-radio">
											<input className="custom-control-input" id="function-services-3" type="radio" name="radio-function" />
											<label className="custom-control-label" htmlFor="function-services-3">Safety/ Health <span>(235)</span> </label>
										</div>
										<div className="custom-control custom-radio">
											<input className="custom-control-input" id="function-services-4" type="radio" name="radio-function" />
											<label className="custom-control-label" htmlFor="function-services-4">Engineering <span>(568)</span></label>
										</div>
										<div className="custom-control custom-radio">
											<input className="custom-control-input" id="function-services-5" type="radio" name="radio-function" />
											<label className="custom-control-label" htmlFor="function-services-5">Product Development <span>(798)</span></label>
										</div>
									</div>
								</div>
							</Accordion.Collapse> */}
							{/* <Accordion.Toggle as={Card} eventKey="4">
								<div className="acod-head">
									<h6 className="acod-title">
										<a data-toggle="collapse" href="#industry" className="collapsed" >
											Industry
										</a>
									</h6>
								</div>
							</Accordion.Toggle>
							<Accordion.Collapse eventKey="4">
								<div id="industry" className="acod-body collapse show">
									<div className="acod-content">
										<div className="custom-control custom-radio">
											<input className="custom-control-input" id="industry1" type="radio" name="radio-industry" />
											<label className="custom-control-label" htmlFor="industry1">Telecom <span>(5)</span> </label>
										</div>
										<div className="custom-control custom-radio">
											<input className="custom-control-input" id="industry2" type="radio" name="radio-industry" />
											<label className="custom-control-label" htmlFor="industry2">Consulting Services <span>(10)</span> </label>
										</div>
										<div className="custom-control custom-radio">
											<input className="custom-control-input" id="industry3" type="radio" name="radio-industry" />
											<label className="custom-control-label" htmlFor="industry3">Engineering/Projects <span>(15)</span> </label>
										</div>
										<div className="custom-control custom-radio">
											<input className="custom-control-input" id="industry4" type="radio" name="radio-industry" />
											<label className="custom-control-label" htmlFor="industry4">Manufacturing/Industrial <span>(12)</span></label>
										</div>
										<div className="custom-control custom-radio">
											<input className="custom-control-input" id="industry5" type="radio" name="radio-industry" />
											<label className="custom-control-label" htmlFor="industry5">Architecture/Interior Design <span>(8)</span></label>
										</div>
									</div>
								</div>
							</Accordion.Collapse> */}

						</Accordion>
						<button className="btn btn-block btn-primary"
							onClick={() => {
								let regionFilterBase = "regions=";
								let regionFilter ="";
								const regionCount = region.data.length;
								for (let i = 0; i < regionCount; i++) {

									if (document.getElementById("region" + i).checked) {
										regionFilter += regionFilterBase+region.data[i].id + "&";
									}
								}
								regionFilter = regionFilter.slice(0, -1);

								let experience_levelFilter = "experience_level__in=";
								const experience_levelCount = experience_level.data.length;
								for (let i = 0; i < experience_levelCount; i++) {

									if (document.getElementById("experience" + i).checked) {
										experience_levelFilter += experience_level.data[i].id + ",";
									}
								}
								experience_levelFilter = experience_levelFilter.slice(0, -1);

								let salaryTypeFilter = "salary_type__in=";
								const salaryTypeCount = salary_type.data.length;
								for (let i = 0; i < salaryTypeCount; i++) {

									if (document.getElementById("salaryType" + i).checked) {
										salaryTypeFilter += salary_type.data[i].id + ",";
									}
								}
								salaryTypeFilter = salaryTypeFilter.slice(0, -1);

								let employment_typeFilter = "employment_type__in=";
								const employment_typeCount = employment_type.data.length;
								for (let i = 0; i < employment_typeCount; i++) {

									if (document.getElementById("Employment" + i).checked) {
										employment_typeFilter += employment_type.data[i].id + ",";
									}
								}
								employment_typeFilter = employment_typeFilter.slice(0, -1);

								let salaryFilter = "salary__gte=" + document.getElementById("salaryGreaterThan").value + "&salary__lte=" + document.getElementById("salaryLessThan").value;
								const requestArray = [regionFilter, experience_levelFilter, salaryTypeFilter, employment_typeFilter, salaryFilter];
								const requestString = requestArray.join("&");
								dispatch(getOpenJobs(requestString,null));

							}
							}>Filter</button>

					</aside>
				)
			}
		</div>
	)
}
export default Accordsidebar; 