import { createSlice } from '@reduxjs/toolkit';
import { ApiCallBegan } from "../actions/api"
import checkCache from "../../services/cache";
import { coreCacheLimit } from "../../config.json"

const countrySlice = createSlice({
    name: "country",
    initialState: {
        data: [],
        lastFetch: null,
        loading: false
    },
    reducers: {
        countryAdded: (country, action) => {
            country.data = action.payload.data.results
            country.lastFetch = Date.now()
            country.loading = false;
            return country;
        },
        countryRequested: (country, action) => {
            country.loading = true;
            return country;
        },
        countryRequestFailed: (country, action) => {
            country.loading = false;
            return country;
        },




    }

})

export const { countryAdded, countryRequestFailed, countryRequested } = countrySlice.actions
export default countrySlice.reducer;
export const getCountrybyName = (id) => (dispatch, getState) => {
    const reg = getState().entities.country.data
    const country = reg.find(country => country.id === id)
    // //console.log(language?language.name: "asd")
    return country ? country.name : ""
    // return "sdsd"
}

export const loadCountries = () => (dispatch, getState) => {
    const { lastFetch } = getState().entities.country
    if (checkCache(lastFetch, coreCacheLimit)) return;

    dispatch(ApiCallBegan({
        url: "api/core/country/",
        onStart: countryRequested.type,
        onSuccess: countryAdded.type,
        onError: countryRequestFailed.type
    }))
}
