import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "./input";
import Select from './select';
import CustomMultiSelect from "./multiSelect";
import TextArea from "./textArea";
class Form extends Component {
  state = {
    data: {},
    errors: {},
  };
  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);

    if (!error) return null;
    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
    }
    //console.log(errors);
    return errors;

  };
  validateProperty = ({ name, value }) => {
    // //console.log("validating")
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    // console.log(errors);
    //console.log(errors);
    if (errors) return;
    this.doSubmit();
  };
  handleChange = ({ currentTarget: input }) => {
    // //console.log("handling change", input);
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });
  };
  handleChangeForMultiSelect = (selectedOption, name) => {
    // this.setState({ selectedOption });
    //console.log(`Option selected:`, selectedOption);
    const value = [];
    selectedOption.map((item) => {
      value.push(parseInt(item.id));
    })
    //console.log(value)
    const errors = { ...this.state.errors };
    const error = selectedOption.length===0?"Please select at least one option":null;
    const errorMessage = error;
    if (errorMessage) errors[name] = errorMessage;
    else delete errors[name];

    const data = { ...this.state.data };
    data[name] = value;

    this.setState({ data, errors });
  }
  renderButton(label, icon = null) {
    return (
      // 
      <button className="site-button " >
        {icon}{label}
      </button>
    );
  }
  renderInput(name, label, type = "text") {
    const { data, errors } = this.state;
    return (
      <Input
        type={type}
        name={name}
        label={label}
        value={data[name]}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }
  renderTextArea(name, label, type = "text") {
    const { data, errors } = this.state;
    return (
      <TextArea
        type={type}
        name={name}
        label={label}
        value={data[name]}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }
  renderSelect(items, name, label) {
    const { data, errors } = this.state;
    return (
      <Select
        items={items}

        name={name}
        label={label}
        value={data[name]}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }
  renderMultiSelect(items, name, label,limit=3,selectedValue=[]) {
    const { data, errors } = this.state;
    return (
      <CustomMultiSelect
        items={items}
        name={name}
        label={label}
        value={data[name]}
        onRemove={(e) => { this.handleChangeForMultiSelect(e, name) }}
        onSelect={(e) => { this.handleChangeForMultiSelect(e, name) }}
        error={errors[name]}
        limit={limit}
        selectedValue={selectedValue}
      />
    );
  }
}

export default Form;
