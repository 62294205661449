import React, { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import {
  Chart as ChartJS,
  ArcElement,
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Doughnut, Line } from "react-chartjs-2";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Container } from "@mui/material";
import { Widget } from "../Element/common/Widget";
import {
  Person,
  AddHomeWork,
  GroupAdd,
  Check,
  Signpost,
  Error,
} from "@mui/icons-material";
import CountUp from "react-countup";
import { useDispatch, useSelector } from "react-redux";
import { loadreport } from "../../store/reducers/reports";
import { loadCompanyReport } from "../../store/reducers/companyReports";
import Loading from "../Element/common/loading";
import { loadCompanies } from "../../store/reducers/companies";
// import {CategoryScale} from 'chart.js';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);



function AdminDashboard() {
  const dispatch = useDispatch();
  const report = useSelector((state) => state.entities.reports);
  const companies = useSelector((state) => state.entities.companies);
  const companyReport = useSelector((state) => state.entities.companyReports);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(loadreport());
    dispatch(loadCompanies());
  }, []);

  const [companyID, setCompanyID] = useState(0);

  const renderSelect = () => {
    if (companies.loading) return <Loading />;
    // console.log(companies.data);
    return (
      <select className="form-control" id="selectCompany">
        <option value="0">Select Company</option>
        {companies.data[0].map((company, index) => {
          return (
            <option key={index} value={company.id}>
              {company.company_name}
            </option>
          );
        })}
      </select>
    );
  };
  const renderGeneralReport = () => {
    return (
      <Container maxWidth="xl">
        {report.loading && <Loading />}
        {!report.loading && (
          <Box sx={{ flexGrow: 1 }}>

            <Grid container spacing={2}>
            <Grid item xs={6} md={8} sm={12}>
                <Widget title="Applied Applicants">
                  <Line
                    options={{
                      responsive: true,
                      plugins: {
                        legend: {
                          position: "top",
                        },
                        title: {
                          display: true,
                          text: "Applied Applicants in the past year by month",
                        },
                      },
                    }}
                    data={{
                      labels: report.data.appliedJobs.labels,
                      datasets: [
                        {
                          label: "Applied Applicants",
                          data: report.data.appliedJobs.data, 
                          fill: true,
                          backgroundColor: "rgba(75,192,192,0.2)",
                          borderColor: "rgba(75,192,192,1)",
                        },
                        
                      ],
                    }}
                  />
                </Widget>
              </Grid>
              <Grid item xs={12} md={4} sm={12}>
                <Widget title="Male To Female Ratio">
                  <Doughnut
                    data={{
                      labels: ["Male", "Female"],
                      datasets: [
                        {
                          label: "Ratio of Male and Female",
                          data: [
                            report.data.maleTalents,
                            report.data.femaleTalents,
                          ],
                          backgroundColor: [
                            "rgba(54, 162, 235, 0.2)",
                            "rgba(255, 99, 132, 0.2)",
                          ],
                          borderColor: [
                            "rgba(54, 162, 235, 1)",
                            "rgba(255, 99, 132, 1)",
                          ],
                          borderWidth: 1,
                        },
                      ],
                    }}
                  />
                  {/* <DoughnutChart /> */}
                </Widget>
              </Grid>
              <Grid item xs={12} md={4} sm={12}>
                <Widget title="Total Job seekers">
                  <Person scale={20} style={{ fontSize: "4rem" }} />
                  <CountUp
                    style={{ fontSize: "3rem" }}
                    end={report.data.totalTalents}
                    separator=","
                  />
                </Widget>
              </Grid>
              <Grid item xs={12} md={4} sm={12}>
                <Widget title="Total Companies">
                  <AddHomeWork scale={20} style={{ fontSize: "4rem" }} />
                  <CountUp
                    style={{ fontSize: "3rem" }}
                    end={report.data.totalCompanies}
                    separator=","
                  />
                </Widget>
              </Grid>
              <Grid item xs={12} md={4} sm={12}>
                <Widget title="Total Admin Users">
                  <GroupAdd scale={40} style={{ fontSize: "4rem" }} />
                  <CountUp
                    style={{ fontSize: "3rem" }}
                    end={report.data.totalCompanyUsers}
                    separator=","
                  />
                </Widget>
              </Grid>
              <Grid item xs={12} md={4} sm={12}>
                <Widget title="Total Jobs Posted">
                  <Signpost scale={40} style={{ fontSize: "4rem" }} />
                  <CountUp
                    style={{ fontSize: "3rem" }}
                    end={report.data.totalJobs}
                    separator=","
                  />
                </Widget>
              </Grid>
              <Grid item xs={12} md={4} sm={12}>
                <Widget title="Total Active Jobs">
                  <Check scale={1} style={{ fontSize: "4rem" }} />
                  <CountUp
                    style={{ fontSize: "3rem" }}
                    end={report.data.activeJobs}
                    separator=","
                  />
                </Widget>
              </Grid>
              <Grid item xs={12} md={4} sm={12}>
                <Widget title="Jobs Closing Today">
                  <Error scale={8} style={{ fontSize: "4rem" }} />
                  <CountUp
                    style={{ fontSize: "3rem" }}
                    end={report.data.jobsClosingToday}
                    separator=","
                  />
                </Widget>
              </Grid>
              
              
              {/* <Grid item xs={6} md={8} sm={12}>
                  <Widget title="Crypto market"><DataTable /></Widget>
                </Grid> */}
            </Grid>
          </Box>
        )}
      </Container>
    );
  };

  const renderCompanyReport = () => {
    if (companyReport.loading) return <Loading />;

    const company = companies.data[0].find(
      (c) => parseInt(`${c.id}`) === parseInt(`${companyID}`)
    );
    const remaining_jobs = company.remaining_jobs;
    console.log(remaining_jobs);
    // console.log(companyID,company);
    return (
      <Container maxWidth="xl">
        {report.loading && <Loading />}
        {!report.loading && (
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} sm={12}>
                <Widget title="Total Job seekers">
                  <Person scale={20} style={{ fontSize: "4rem" }} />
                  <CountUp
                    style={{ fontSize: "3rem" }}
                    end={report.data.totalTalents}
                    separator=","
                  />
                </Widget>
              </Grid>
              <Grid item xs={12} md={4} sm={12}>
                <Widget title="Total Applied Applicants">
                  <Person scale={20} style={{ fontSize: "4rem" }} />
                  <CountUp
                    style={{ fontSize: "3rem" }}
                    end={companyReport.data.totalApplicants}
                    separator=","
                  />
                </Widget>
              </Grid>
              <Grid item xs={12} md={4} sm={12}>
                <Widget title="Total Users">
                  <GroupAdd scale={40} style={{ fontSize: "4rem" }} />
                  <CountUp
                    style={{ fontSize: "3rem" }}
                    end={companyReport.data.totalUsers + 1}
                    separator=","
                  />
                </Widget>
              </Grid>
              <Grid item xs={12} md={4} sm={12}>
                <Widget title="Total Jobs Posted">
                  <Signpost scale={40} style={{ fontSize: "4rem" }} />
                  <CountUp
                    style={{ fontSize: "3rem" }}
                    end={companyReport.data.totalJobsPosted}
                    separator=","
                  />
                </Widget>
              </Grid>
              <Grid item xs={12} md={4} sm={12}>
                <Widget title="Total Active Jobs">
                  <Check scale={1} style={{ fontSize: "4rem" }} />
                  <CountUp
                    style={{ fontSize: "3rem" }}
                    end={companyReport.data.activeJobs}
                    separator=","
                  />
                </Widget>
              </Grid>
              <Grid item xs={12} md={4} sm={12}>
                <Widget title="Jobs Closing Today">
                  <Error scale={8} style={{ fontSize: "4rem" }} />
                  <CountUp
                    style={{ fontSize: "3rem" }}
                    end={companyReport.data.jobsClosingToday}
                    separator=","
                  />
                </Widget>
              </Grid>
              <Grid item xs={12} md={4} sm={12}>
                <Widget title="Job Posts Remaining">
                  <Error scale={8} style={{ fontSize: "4rem" }} />
                  <CountUp
                    style={{ fontSize: "3rem" }}
                    end={remaining_jobs}
                    separator=","
                  />
                </Widget>
              </Grid>

              {/* <Grid item xs={6} md={4} sm={12}>
                  <Widget title="Gainers"><DoughnutChart /></Widget>
                </Grid> */}
              {/* <Grid item xs={6} md={8} sm={12}>
                  <Widget title="Crypto market"><DataTable /></Widget>
                </Grid> */}
            </Grid>
          </Box>
        )}
      </Container>
    );
  };

  const renderPage = () => {
    // alert(companyID)
    if (parseInt(companyID) === 0) {
      return renderGeneralReport();
    } else {
      return renderCompanyReport();
    }
  };
  return (
    <div>
      <Header />
      {/* <h1>Company Dashboard</h1> */}

      <section className="dashboard">
        <Container maxWidth="xl">
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} sm={12}>
                <h1>Admin Dashboard</h1>
              </Grid>
              <Grid item xs={12} md={4} sm={12}>
                {renderSelect()}
              </Grid>
              <Grid item xs={12} md={4} sm={12}>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    const newId =
                      document.getElementById("selectCompany").value;
                    setCompanyID(newId);
                    if (parseInt(newId) != 0) {
                      dispatch(loadCompanyReport(newId));
                    }
                  }}
                >
                  Go
                </button>
              </Grid>
            </Grid>
          </Box>
        </Container>
        {renderPage()}
      </section>
      <Footer />
    </div>
  );
}

export default AdminDashboard;
