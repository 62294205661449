import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Form as BootStrapForm } from "react-bootstrap";
import { connect } from "react-redux";
import Joi from "joi-browser";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import ReactPDF, { PDFDownloadLink } from "@react-pdf/renderer";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File


import {
  loadEducationLevels,
  getEducationName,
} from "../../store/reducers/educationLevel";
import {
  loadFieldOfStudies,
  getFieldOfStudyName,
} from "../../store/reducers/fieldOfStudy";
import { getCountrybyName, loadCountries } from "../../store/reducers/country";

import "../../css/textEditor.css";
import DatePicker from "../Element/common/datePicker";
import {
  loadTalentCvInformation,
  skillsChanged,
  summaryChanged,
  languageRemoved,
  languageEdited,
  languageAdded,
  loadLanguageById,
  educationAdded,
  educationEdited,
  educationRemoved,
  loadEducationById,
  experienceAdded,
  experienceEdited,
  experienceRemoved,
  loadExperienceById,
  projectAdded,
  projectEdited,
  projectRemoved,
  loadProjectById,
  certificationAdded,
  certificationEdited,
  certificationRemoved,
  loadCertificationById,
  referenceAdded,
  referenceEdited,
  referenceRemoved,
  loadReferenceById,
  sendTalentCvInformation,
} from "../../store/reducers/cv";
import {
  loadlanguages,
  getLanguagebyName,
} from "../../store/reducers/language";
import Header from "./../Layout/Header";
import Footer from "./../Layout/Footer";
import { stateToHTML } from "draft-js-export-html";
import { loadTalentInformation } from "../../store/reducers/talent";
import Listingsidebar from "./../Element/Listingsidebar";
import Form from "../Element/common/form";
import CVPage from "./cv";
import Profilesidebar from "../Element/Profilesidebar";
import { languagesConstant } from "../../config.json";
import CVView from "./cvView";
import { PDFViewer } from "@react-pdf/renderer";
import RenderPdf from "./cvViewWeb";

var bnr = require("./../../images/banner/bnr1.jpg");

class Jobmyresume extends Form {
  state = {
    data: {
      language: "",
      school: "",
      degree: "",
      field: "",
      education: "",
      educationStartYear: "",
      educationStartMonth: "",
      educationEndYear: "",
      educationEndMonth: "",
      experienceJobTitle: "",
      experienceCompany: "",
      experienceCompanyType: "",
      experiencePosition: "",
      experienceStartYear: "",
      experienceStartMonth: "",
      experienceEndYear: "",
      experienceEndMonth: "",
      projectTitle: "",
      projectLink: "",
      projectDuration: "",
      certificationTitle: "",
      certificationIssuedBy: "",
      certificationYear: "",
      certificationMonth: "",
      referenceFullName: "",
      referenceCompany: "",
      referencePosition: "",
      referenceEmail: "",
      referencePhone: "",
    },
    renderPage: 0,
    basicdetails: false,
    languageId: 0,
    educationId: 0,
    experienceId: 0,
    projectId: 0,

    certificationId: 0,
    referenceId: 0,
    reference: false,
    keyskill: false,
    employment: false,
    education: false,
    languages: false,
    projects: false,
    profilesummary: false,
    certification: false,
    worksample: false,
    whitepaper: false,
    presentation: false,
    patent: false,
    certification: false,
    careerprofile: false,
    personaldetails: false,
    summaryState: EditorState.createEmpty(),
    skillSummaryState: EditorState.createEmpty(),
    workProjectCompleted: EditorState.createEmpty(),
    workDutiesAndResponsibilities: EditorState.createEmpty(),
    certificateDescription: EditorState.createEmpty(),
    projectDescription: EditorState.createEmpty(),
    errors: {},
  };

  schema = {
    language: Joi.string().allow("").optional().label("Current Position"),
    education: Joi.string().required().label("Education Level"),
    field: Joi.string().required().label("Field of study"),
    degree: Joi.string().required().label("Degree"),
    school: Joi.string().required().label("School"),
    educationStartYear: Joi.string().required().label("Start Year"),
    educationStartMonth: Joi.string().required().label("Start Month"),
    educationEndYear: Joi.string().required().label("End Year"),
    educationEndMonth: Joi.string().required().label("End Month"),
    experienceJobTitle: Joi.string().required().label("Job Title"),
    experienceCompany: Joi.string().required().label("Company"),
    experiencePosition: Joi.string().required().label("Position"),
    experienceStartYear: Joi.string().required().label("Start Year"),
    experienceStartMonth: Joi.string().required().label("Start Month"),
    experienceEndYear: Joi.string().required().label("End Year"),
    experienceEndMonth: Joi.string().required().label("End Month"),
    experienceCompanyType: Joi.string().required().label("Company Type"),
    projectTitle: Joi.string().required().label("Project Title"),
    projectLink: Joi.string().required().label("Project Link"),
    certificationTitle: Joi.string().required().label("Certification Title"),
    certificationIssuedBy: Joi.string().required().label("Issued By"),
    referenceFullName: Joi.string().required().label("Full Name"),
    referenceCompany: Joi.string().required().label("Company"),
    referencePosition: Joi.string().required().label("Position"),
    referenceEmail: Joi.string().required().label("Email"),
    referencePhone: Joi.string().required().label("Phone"),
  };

  componentDidMount() {
    this.props.loadCv();
    this.props.loadTalent();
    this.props.loadEducationLevels();
    this.props.loadFieldOfStudies();
    this.props.loadLanguages();
    this.props.loadCountries();
  }
  getPercentage = () => {
    if (this.props.talent.loading || this.props.cv.loading) {
      return 0;
    } else {
      const talent = this.props.talent;
      console.log(talent);
      const cv = this.props.cv;
      const name =
        talent.data.user.first_name + " " + talent.data.user.last_name;
      const title = talent.data.title;
      const country = talent.data.country;
      const region = talent.data.region;
      const city = talent.data.city;
      const address = talent.data.address;
      const phone = talent.data.phone_number;
      const email = talent.data.user.email;
      const summary = cv.data.summary;
      const skills = cv.data.skills;
      const experiences = cv.data.cv_experience;
      const educations = cv.data.cv_education;
      const languages = cv.data.cv_language;
      const projects = cv.data.cv_project;
      const references = cv.data.cv_referance;
      const certifications = cv.data.cv_certification;

      let percentage = 0;
      if (name !== "") {
        percentage += 1;
      }
      if (title !== "") {
        percentage += 1;
      }
      if (country !== "") {
        percentage += 1;
      }
      if (region !== "") {
        percentage += 1;
      }
      if (city !== "") {
        percentage += 1;
      }
      if (address !== "") {
        percentage += 1;
      }
      if (phone !== "") {
        percentage += 2;
      }
      if (email !== "") {
        percentage += 2;
      }
      if (summary !== "") {
        percentage += 20;
      }
      if (skills !== "") {
        percentage += 10;
      }
      if (experiences.length > 0) {
        percentage += 10;
      }
      if (educations.length > 0) {
        percentage += 10;
      }
      if (languages.length > 0) {
        percentage += 10;
      }
      if (projects.length > 0) {
        percentage += 10;
      }
      if (references.length > 0) {
        percentage += 10;
      }
      if (certifications.length > 0) {
        percentage += 10;
      }

      return percentage;
    }
  };

  togglePreview = () => {
    const renderPage = this.state.renderPage === 0 ? 1 : 0;
    this.setState({ renderPage });
  };
  togglePreviewDemo = () => {
    const renderPage = this.state.renderPage === 0 ? 2 : 0;
    this.setState({ renderPage });
  };
  setBasicDetails = (state) => {
    this.setState({ basicdetails: state });
  };
  setReference = (state, id = 0) => {
    let data = { ...this.state.data };
    if (id !== 0) {
      const ref = this.props.getReferences(id);
      data.referenceFullName = ref.name;
      data.referenceCompany = ref.company;
      data.referencePosition = ref.position;
      data.referenceEmail = ref.email;
      data.referencePhone = ref.phone;

      // data.referenceFullName =
    } else {
      data.referenceFullName = "";
      data.referenceCompany = "";
      data.referencePosition = "";
      data.referenceEmail = "";
      data.referencePhone = "";
    }
    this.setState({ reference: state, data, referenceId: id });
  };
  setKeyskill = (state, id = 0) => {
    this.setState({ keyskill: state });
  };
  setEmployment = (state, id = 0) => {
    let data = { ...this.state.data };
    let workDutiesAndResponsibilities = EditorState.createEmpty();
    let workProjectCompleted = EditorState.createEmpty();
    if (id !== 0) {
      const exp = this.props.getExperiences(id);
      data.experienceCompany = exp.company;
      data.experienceJobTitle = exp.job_title;
      data.experiencePosition = exp.position;
      data.experienceCompanyType = exp.type_of_organization;
      const experienceStart = exp.start_date.split("-");
      const experienceEnd = exp.end_date.split("-");
      data.experienceStartYear = experienceStart[0];
      data.experienceStartMonth = experienceStart[1];
      data.experienceEndYear = experienceEnd[0];
      data.experienceEndMonth = experienceEnd[1];

      workDutiesAndResponsibilities = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(exp.duties_and_responsibilities)
        )
      );
      workProjectCompleted = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(exp.projects_completed)
        )
      );
    } else {
      data.experienceCompany = "";
      data.experienceJobTitle = "";
      data.experiencePosition = "";
      data.experienceCompanyType = "";
      data.experienceStartYear = "";
      data.experienceStartMonth = "";
      data.experienceEndYear = "";
      data.experienceEndMonth = "";
    }
    this.setState({
      employment: state,
      data,
      experienceId: id,
      workDutiesAndResponsibilities: workDutiesAndResponsibilities,
      workProjectCompleted: workProjectCompleted,
    });
    // //console.log(this.state.data)
  };
  setEducation = (state, id = 0) => {
    let data = { ...this.state.data };
    if (id !== 0) {
      data.field = this.props.getEducations(id).field_of_study;
      data.degree = this.props.getEducations(id).degree;
      data.school = this.props.getEducations(id).school;
      const educationStart = this.props.getEducations(id).start_date.split("-");
      const educationEnd = this.props.getEducations(id).end_date.split("-");
      data.educationStartYear = educationStart[0];
      data.educationStartMonth = educationStart[1];
      data.educationEndYear = educationEnd[0];
      data.educationEndMonth = educationEnd[1];
    } else {
      data.field = "";
      data.degree = "";
      data.school = "";
      data.educationStartYear = "";
      data.educationStartMonth = "";
      data.educationEndYear = "";
      data.educationEndMonth = "";
    }
    this.setState({ education: state, data, educationId: id });
  };
  setLanguages = (state, id = 0) => {
    let data = { ...this.state.data };
    if (id !== 0) {
      data.language = this.props.getLanguages(id).language;
    } else {
      data.language = "";
    }
    this.setState({ languages: state, languageId: id, data: data });
  };
  setProjects = (state, id = 0) => {
    let data = { ...this.state.data };
    let projectDescription = EditorState.createEmpty();
    if (id !== 0) {
      const project = this.props.getProjects(id);
      data.projectTitle = project.project;
      data.projectLink = project.project_link;
      projectDescription = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(project.project_description)
        )
      );
    } else {
      data.projectTitle = "";
      data.projectLink = "";
    }

    this.setState({
      projects: state,
      projectId: id,
      data: data,
      projectDescription: projectDescription,
    });
  };
  setProfileSummary = (state) => {
    this.setState({ profilesummary: state });
  };
  setCertification = (state, id = 0) => {
    // //console.log(this.state.data)

    let data = { ...this.state.data };
    let certificationDescription = EditorState.createEmpty();

    if (id !== 0) {
      const cert = this.props.getCertificates(id);
      data.certificationTitle = cert.certification;
      data.certificationIssuedBy = cert.issued_by;
      const date = cert.start_date.split("-");
      data.certificationYear = date[0];
      data.certificationMonth = date[1];
      certificationDescription = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(cert.additional_information)
        )
      );

      // data.certificationTitle = this
    } else {
      data.certificationTitle = "";
      data.certificationIssuedBy = "";
      data.certificationYear = "";
      data.certificationMonth = "";
    }

    this.setState({
      certification: state,
      certificationId: id,
      data: data,
      certificationDescription,
    });
    //console.log(this.state.data)
  };
  setWorkSample = (state) => {
    this.setState({ worksample: state });
  };
  setWhitePaper = (state) => {
    this.setState({ whitepaper: state });
  };
  setPresentation = (state) => {
    this.setState({ presentation: state });
  };
  setPatent = (state) => {
    this.setState({ patent: state });
  };
  // setCertification = state => {
  // 	this.setState({ certification: state })
  // }
  setCareerProfile = (state) => {
    this.setState({ careerprofile: state });
  };
  setPersonalDetails = (state) => {
    this.setState({ personaldetails: state });
  };

  renderLoadingScreen = (title) => {
    return (
      <div
        id={title}
        className="job-bx bg-white m-b30 d-flex justify-content-center"
      >
        <div className="spinner-border" role="status">
          <i
            className="fa fa-spinner fa-spin m-r5"
            style={{ fontSize: "75px" }}
          ></i>
        </div>
      </div>
    );
  };
  renderOptions = (item, Id) => {
    let id = "";
    if (item) {
      switch (Id) {
        case 1:
          id = item.speaking;
          break;
        case 2:
          id = item.listening;
          break;
        case 3:
          id = item.reading;
          break;
        case 4:
          id = item.writing;
        default:
          break;
      }
    }

    {
      return [0, 1, 2, 3, 4].map((item, index) => {
        if (id && id == item) {
          // //console.log("id", id)
          return (
            <option key={index} value={item} selected>
              {languagesConstant[item]}
            </option>
          );
        } else {
          return (
            <option key={index} value={item}>
              {languagesConstant[item]}
            </option>
          );
        }
      });
    }
  };

  renderProfileSummary = (id, name) => {
    const { profilesummary } = this.state;
    if (this.props.cv.loading === true || !this.props.cv.data.title) {
      return this.renderLoadingScreen("profile_summary_bx");
    } else {
      return (
        <div id="profile_summary_bx" className="job-bx bg-white m-b30">
          <div className="d-flex">
            <h5 className="m-b15">Profile Summary</h5>
            <Link
              to={"#"}
              onClick={() => {
                this.setProfileSummary(true);
                let editor = EditorState.createWithContent(
                  ContentState.createFromBlockArray(
                    convertFromHTML(this.props.cv.data.summary)
                  )
                );
                this.setState({ summaryState: editor });
              }}
              className="site-button add-btn button-sm"
            >
              <i className="fa fa-pencil m-r5"></i> Edit
            </Link>
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: this.props.cv.data.summary }}
          ></div>

          <Modal
            className="modal fade modal-bx-info editor"
            show={profilesummary}
            onHide={this.setProfileSummary}
          >
            <div className="modal-dialog my-0" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Profile Summary</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => this.setProfileSummary(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>
                    Your Profile Summary should mention the highlights of your
                    career and education, what your professional interests are,
                    and what kind of a career you are looking for. Write a
                    meaningful summary of more than 50 characters.
                  </p>
                  <form>
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <label>Details of Project</label>
                          <div className="shadow-sm border  mb-5 bg-white rounded">
                            <Editor
                              editorState={this.state.summaryState}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              onEditorStateChange={(state) => {
                                this.setState({ summaryState: state });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="site-button"
                    onClick={() => this.setProfileSummary(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="site-button"
                    onClick={() => {
                      this.props.updateSummary(
                        stateToHTML(this.state.summaryState.getCurrentContent())
                      );
                      this.setProfileSummary(false);
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      );
    }
  };

  renderLanguage = () => {
    const scale = [1, 2, 3, 4];
    const { languages } = this.state;
    if (this.props.cv.loading === true || !this.props.cv.data.title) {
      return this.renderLoadingScreen("language_bx");
    } else {
      return (
        <div id="language_bx" className="job-bx table-job-bx bg-white m-b30">
          <div className="d-flex">
            <h5 className="m-b15">Languages</h5>
            <Link
              to={"#"}
              onClick={() => this.setLanguages(true)}
              className="site-button add-btn button-sm"
            >
              <i className="fa fa-plus m-r5"></i> Add Language
            </Link>
          </div>
          <p>Mention the languages</p>

          <table>
            <thead>
              <tr>
                <th>Language</th>
                <th>Speaking</th>
                <th>Listening</th>
                <th>Reading</th>
                <th>Writing</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.props.cv.data.cv_language.map((item, index) => {
                return (
                  <tr key={item.id}>
                    <td>
                      {this.props.getLanguagebyName(parseInt(item.language))}
                    </td>
                    <td>{languagesConstant[item.speaking]}</td>
                    <td>{languagesConstant[item.listening]}</td>
                    <td>{languagesConstant[item.reading]}</td>
                    <td>{languagesConstant[item.writing]}</td>

                    <td>
                      <Link
                        to={"#"}
                        className="m-l15 font-14"
                        data-toggle="modal"
                        data-target="#languages"
                        onClick={() => {
                          // //console.log(())
                          this.setLanguages(true, item.id);
                        }}
                      >
                        <i className="fa fa-pencil"></i>
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={"#"}
                        className="m-l15 text-danger font-14"
                        data-toggle="modal"
                        data-target="#languages"
                        onClick={() => {
                          this.props.removeLanguage(item.id);
                        }}
                      >
                        <i className="fa fa-trash"></i>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <Modal
            className="modal fade modal-bx-info editor"
            show={languages}
            onHide={this.setLanguages}
          >
            <div className="modal-dialog my-0" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Language</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => this.setLanguages(false)}
                  >
                    <span>&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        {/* {this.renderInput("language", "Language", "text")} */}
                        {this.renderSelect(
                          this.props.languages.data,
                          "language",
                          "Speaking"
                        )}
                      </div>

                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>Speaking</label>
                          <BootStrapForm.Control name="speaking" as="select">
                            {this.renderOptions(
                              this.props.getLanguages(this.state.languageId),
                              1
                            )}
                          </BootStrapForm.Control>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>Listening</label>
                          <BootStrapForm.Control name="listening" as="select">
                            {this.renderOptions(
                              this.props.getLanguages(this.state.languageId),
                              2
                            )}
                          </BootStrapForm.Control>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>Reading</label>
                          <BootStrapForm.Control name="reading" as="select">
                            {this.renderOptions(
                              this.props.getLanguages(this.state.languageId),
                              3
                            )}
                          </BootStrapForm.Control>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>Writing</label>
                          <BootStrapForm.Control name="writing" as="select">
                            {this.renderOptions(
                              this.props.getLanguages(this.state.languageId),
                              4
                            )}
                          </BootStrapForm.Control>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="site-button"
                    onClick={() => this.setLanguages(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="site-button"
                    onClick={() => {
                      let language = {};
                      language.language =
                        document.getElementById("language").value;
                      language.speaking =
                        document.getElementsByName("speaking")[0].value;
                      language.listening =
                        document.getElementsByName("listening")[0].value;
                      language.reading =
                        document.getElementsByName("reading")[0].value;
                      language.writing =
                        document.getElementsByName("writing")[0].value;

                      if (this.state.languageId != 0) {
                        language.id = this.state.languageId;
                        this.props.updateLanguage(language);
                      } else {
                        this.props.addLanguage(language);
                      }
                      this.setLanguages(false);
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      );
    }
  };

  renderEducation = () => {
    const { education } = this.state;
    if (this.props.cv.loading === true || !this.props.cv.data.title) {
      return this.renderLoadingScreen("education_bx");
    } else {
      return (
        <div id="education_bx" className="job-bx bg-white m-b30">
          <div className="d-flex">
            <h5 className="m-b15">Education</h5>
            <Link
              to={"#"}
              onClick={() => this.setEducation(true)}
              className="site-button add-btn button-sm"
            >
              <i className="fa fa-plus m-r5"></i> Add
            </Link>
          </div>
          <p>Mention your education background. (This field is required to apply for a job.) </p>

          <Modal
            className="modal fade modal-bx-info editor"
            show={education}
            onHide={this.setEducation}
          >
            <div className="modal-dialog my-0" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="EducationModalLongTitle">
                    Education
                  </h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => this.setEducation(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        {this.renderSelect(
                          this.props.educationLevels.data,
                          "degree",
                          "Education"
                        )}
                      </div>
                      <div className="col-lg-12 col-md-12">
                        {this.renderInput("school", "University/Institute")}
                      </div>
                      <div className="col-lg-12 col-md-12">
                        {this.renderSelect(
                          this.props.fieldOfStudies.data,
                          "field",
                          "Field"
                        )}
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <DatePicker
                          name={"education-start"}
                          label={"Start Year"}
                          year={this.state.data.educationStartYear}
                          month={this.state.data.educationStartMonth}
                        />
                        <DatePicker
                          name={"education-end"}
                          label={"Graduation Year"}
                          year={this.state.data.educationEndYear}
                          month={this.state.data.educationEndMonth}
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="site-button"
                    onClick={() => this.setEducation(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="site-button"
                    onClick={() => {
                      let education = {};
                      education.degree = parseInt(
                        this.state.data.degree.toString()
                      );
                      education.school = this.state.data.school;
                      education.field_of_study = parseInt(
                        this.state.data.field.toString()
                      );
                      education.start_date =
                        document.getElementById("education-start-year").value +
                        "-" +
                        document.getElementById("education-start-month").value +
                        "-01";
                      education.end_date =
                        document.getElementById("education-end-year").value +
                        "-" +
                        document.getElementById("education-end-month").value +
                        "-01";
                      // education.endMonth = document.getElementById("education-end-month").value;
                      //console.log(education)
                      if (this.state.educationId != 0) {
                        education.id = this.state.educationId;
                        this.props.updateEducation(education);
                      } else {
                        this.props.addEducation(education);
                      }
                      this.setEducation(false);
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {this.props.cv.data.cv_education.map((item, index) => {
            return (
              <div className="row  border-top">
                <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                  <div className="d-flex ">
                    <div className="d-flex justify-content-end mb-4">
                      <button
                        onClick={() => {
                          // //console.log(item)
                          this.setEducation(true, item.id);
                        }}
                        className="site-button bg-primary add-btn button-sm danger justify-content-left m-r5"
                      >
                        <i className="fa fa-pencil m-r5"></i> Edit
                      </button>
                      <button
                        onClick={() => {
                          this.props.removeEducation(item.id);
                        }}
                        className="site-button bg-danger add-btn button-sm  justify-content-left danger"
                      >
                        <i className="fa fa-trash m-r5"></i> Remove
                      </button>
                    </div>
                  </div>
                  <div className="clearfix m-b20">
                    <label className="m-b0">{item.school}</label>
                    <span className="clearfix font-18">
                      {this.props.getFieldOfStudyName(item.field_of_study)} (
                      {this.props.getEducationName(item.degree)})
                    </span>
                    <span className="clearfix font-7">
                      {item.start_date.split("-")[0]}-
                      {item.end_date.split("-")[0]}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  };

  renderEmployment = () => {
    const { employment } = this.state;
    if (this.props.cv.loading === true || !this.props.cv.data.title) {
      return this.renderLoadingScreen("employment_bx");
    } else {
      return (
        <div id="employment_bx" className="job-bx bg-white m-b30 ">
          <div className="d-flex">
            <h5 className="m-b15">Employment History </h5>
            
            <Link
              to={"#"}
              onClick={() => this.setEmployment(true)}
              className="site-button add-btn button-sm"
            >
              <i className="fa fa-plus m-r5"></i> Add
            </Link>
          </div>
            <p>Add Your Past Work History. (This field is required to apply for a job.)</p>
          {this.props.cv.data.cv_experience.map((item, index) => {
            return (
              <div className="row  border-top">
                <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                  <div className="d-flex">
                    <div className="d-flex justify-content-end mb-4">
                      <button
                        onClick={() => {
                          // //console.log(item)
                          this.setEmployment(true, item.id);
                        }}
                        className="site-button bg-primary add-btn button-sm danger justify-content-left m-r5"
                      >
                        <i className="fa fa-pencil m-r5"></i> Edit
                      </button>
                      <button
                        onClick={() => {
                          this.props.removeExperience(item.id);
                        }}
                        className="site-button bg-danger add-btn button-sm  justify-content-left danger"
                      >
                        <i className="fa fa-trash m-r5"></i> Remove
                      </button>
                    </div>
                    {/* set Employment */}
                  </div>
                  <h6 className="font-15 m-b0">{item.position}</h6>
                  <p className="font-14 m-b0">
                    {item.company} ({item.type_of_organization})
                  </p>
                  {/* <p className="font-13 m-b0">Software Developer</p> */}
                  <p className="m-b1">
                    from {item.start_date} to {item.end_date}
                  </p>
                  <h6 className="font-14 m-b1">Duties and Responsiblities</h6>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.duties_and_responsibilities,
                    }}
                  ></div>
                  <h6 className="font-14 m-b1">Projects Completed</h6>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.projects_completed,
                    }}
                  ></div>
                </div>
              </div>
            );
          })}

          <Modal
            show={employment}
            onHide={this.setEmployment}
            className="modal fade modal-bx-info editor"
          >
            <div className="modal-dialog my-0" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="EmploymentModalLongTitle">
                    Add Employment
                  </h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => this.setEmployment(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        {this.renderInput("experienceCompany", "Company Name")}
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <label>Company Type</label>
                          <div className="row">
                            <BootStrapForm.Control id="type" as="select">
                              {[
                                "Private",
                                "NGO",
                                "Public Service",
                                "Government",
                              ].map((item, index) => {
                                if (
                                  item === this.state.data.experienceCompanyType
                                ) {
                                  // //console.log(this.state.data.experienceCompanyType, "><><><>")
                                  return (
                                    <option
                                      key={index}
                                      value={item}
                                      selected={true}
                                    >
                                      {item}
                                    </option>
                                  );
                                } else {
                                  return (
                                    <option key={index} value={item}>
                                      {item}
                                    </option>
                                  );
                                }
                              })}
                            </BootStrapForm.Control>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        {this.renderInput(
                          "experiencePosition",
                          "Your Designation"
                        )}
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <label>Is this your current company?</label>
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                              <div className="custom-control custom-radio">
                                <input
                                  type="radio"
                                  className="custom-control-input"
                                  id="employ_yes"
                                  name="example1"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="employ_yes"
                                >
                                  Yes
                                </label>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                              <div className="custom-control custom-radio">
                                <input
                                  type="radio"
                                  className="custom-control-input"
                                  id="employ_no"
                                  name="example1"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="employ_no"
                                >
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <DatePicker
                          name={"work-start"}
                          label={"Start Year"}
                          year={this.state.data.experienceStartYear}
                          month={this.state.data.experienceStartMonth}
                        />
                        <DatePicker
                          name={"work-end"}
                          label={"End Year"}
                          year={this.state.data.experienceEndYear}
                          month={this.state.data.experienceEndMonth}
                        />
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <label>Describe your Completed Projects</label>
                          <div className="shadow-sm border p-3 mb-5 bg-white rounded">
                            <Editor
                              editorState={this.state.workProjectCompleted}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              onEditorStateChange={(state) => {
                                this.setState({ workProjectCompleted: state });
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <label>
                            Describe your Duties and Responsiblities
                          </label>
                          <div className="shadow-sm border p-3 mb-5 bg-white rounded">
                            <Editor
                              editorState={
                                this.state.workDutiesAndResponsibilities
                              }
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              onEditorStateChange={(state) => {
                                this.setState({
                                  workDutiesAndResponsibilities: state,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="site-button"
                    onClick={() => this.setEmployment(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="site-button"
                    onClick={() => {
                      let employment = {};
                      employment.company = this.state.data.experienceCompany;
                      employment.position = this.state.data.experiencePosition;
                      employment.type_of_organization =
                        document.getElementById("type").value;
                      employment.is_current_company =
                        document.getElementById("employ_yes").checked;
                      let start =
                        document.getElementById("work-start-year").value +
                        "-" +
                        document.getElementById("work-start-month").value +
                        "-01";
                      let end =
                        document.getElementById("work-end-year").value +
                        "-" +
                        document.getElementById("work-end-month").value +
                        "-01";
                      employment.start_date = start;
                      employment.end_date = end;

                      employment.projects_completed = stateToHTML(
                        this.state.workProjectCompleted.getCurrentContent()
                      );
                      employment.duties_and_responsibilities = stateToHTML(
                        this.state.workDutiesAndResponsibilities.getCurrentContent()
                      );

                      if (this.state.experienceId !== 0) {
                        employment.id = this.state.experienceId;
                        this.props.updateExperience(employment);
                      } else {
                        //console.log("addExperience")
                        this.props.addExperience(employment);
                      }

                      this.setEmployment(false);
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      );
    }
  };

  renderSkills = () => {
    const { keyskill } = this.state;
    if (this.props.cv.loading === true || !this.props.cv.data.title) {
      return this.renderLoadingScreen("skills_bx");
    } else {
      return (
        <div id="skills_bx" className="job-bx bg-white m-b30">
          <div className="d-flex">
            <h5 className="m-b15">Skills</h5>
            <Link
              to={"#"}
              onClick={() => {
                let editor = EditorState.createWithContent(
                  ContentState.createFromBlockArray(
                    convertFromHTML(this.props.cv.data.skills)
                  )
                );
                this.setState({ skillSummaryState: editor });
                this.setKeyskill(true);
              }}
              className="site-button add-btn button-sm"
            >
              <i className="fa fa-pencil m-r5"></i> Edit
            </Link>
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: this.props.cv.data.skills }}
          ></div>

          <Modal
            className="modal fade modal-bx-info editor"
            show={keyskill}
            onHide={this.setKeyskill}
          >
            <div className="modal-dialog my-0" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Skills</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => this.setKeyskill(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>
                    Your Skill Summary should mention list of your Skills and
                    Ablities, that makes you stand out.
                  </p>
                  <form>
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <label>Details of Project</label>
                          <div className="shadow-sm border mb-5 bg-white rounded">
                            <Editor
                              editorState={this.state.skillSummaryState}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              onEditorStateChange={(state) => {
                                this.setState({ skillSummaryState: state });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="site-button"
                    onClick={() => this.setKeyskill(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="site-button"
                    onClick={() => {
                      this.props.updateSkill(
                        stateToHTML(
                          this.state.skillSummaryState.getCurrentContent()
                        )
                      );
                      this.setKeyskill(false);
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      );
    }
  };

  renderProjects = () => {
    const { projects } = this.state;
    if (this.props.cv.loading === true || !this.props.cv.data.title) {
      return this.renderLoadingScreen("projects_bx");
    } else {
      return (
        <div id="projects_bx" className="job-bx bg-white m-b30">
          <div className="d-flex">
            <h5 className="m-b15">Projects</h5>
            <Link
              to={"#"}
              onClick={() => this.setProjects(true)}
              className="site-button add-btn button-sm"
            >
              <i className="fa fa-plus m-r5"></i> Add Project
            </Link>
          </div>

          <p>Mention projects you have done before.</p>

          {this.props.cv.data.cv_project.map((item, index) => {
            return (
              <div className="row  border-top">
                <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                  <div className="d-flex">
                    <div className="d-flex justify-content-end mb-4 ">
                      <button
                        onClick={() => {
                          // //console.log(item)
                          this.setProjects(true, item.id);
                        }}
                        className="site-button bg-primary add-btn button-sm danger justify-content-left m-r5"
                      >
                        <i className="fa fa-pencil m-r5"></i> Edit
                      </button>
                      <button
                        onClick={() => {
                          this.props.removeProject(item.id);
                        }}
                        className="site-button bg-danger add-btn button-sm  justify-content-left danger"
                      >
                        <i className="fa fa-trash m-r5"></i> Remove
                      </button>
                    </div>
                  </div>
                  <div className="clearfix m-b20">
                    <h6 className="font-14 m-b0">
                      {item.project}
                      {/* ({item.duration}) */}
                    </h6>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.project_description,
                      }}
                    />
                    {/* <p className="m-b0">Very big  text exists here really a very long text exists here</p> */}
                  </div>
                </div>
              </div>
            );
          })}

          <Modal
            className="modal fade modal-bx-info editor"
            show={projects}
            onHide={this.setProjects}
          >
            <div className="modal-dialog my-0" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="ProjectsModalLongTitle">
                    Add Projects
                  </h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => this.setProjects(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        {/* <div className="form-group">
													<label>Project Title</label>
													<input type="email" className="form-control" placeholder="Enter Project Title" />
												</div> */}
                        {this.renderInput("projectTitle", "Project Title")}
                      </div>
                      {/* <div className="col-lg-12 col-md-12">
												<div className="form-group">
													<label>Duration</label>
													<input type="number" className="form-control" placeholder="Enter Project Title" />
												</div>
											</div> */}

                      <div className="col-lg-12 col-md-12">
                        {/* <div className="form-group">
													<label>Project Link</label>
													<input type="Project" className="form-control" placeholder="Enter Client Name" />
												</div> */}
                        {this.renderInput("projectLink", "Project Link")}
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <label>Details of Project</label>
                          <div className="shadow-sm border p-3 mb-5 bg-white rounded">
                            <Editor
                              editorState={this.state.projectDescription}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              onEditorStateChange={(state) => {
                                this.setState({ projectDescription: state });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="site-button"
                    onClick={() => this.setProjects(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="site-button"
                    onClick={() => {
                      let project = {};
                      project.project = this.state.data.projectTitle;
                      project.project_link = this.state.data.projectLink;
                      project.project_description = stateToHTML(
                        this.state.projectDescription.getCurrentContent()
                      );

                      if (this.state.projectId !== 0) {
                        project.id = this.state.projectId;
                        this.props.updateProject(project);
                      } else {
                        this.props.addProject(project);
                      }
                      this.setProjects(false);
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      );
    }
  };

  renderCertifications = () => {
    const { certification: certification } = this.state;
    if (this.props.cv.loading === true || !this.props.cv.data.title) {
      return this.renderLoadingScreen("certifications_bx");
    } else {
      return (
        <div id="certifications_bx" className="job-bx bg-white m-b30">
          <div className="d-flex">
            <h5 className="m-b15">Certificates</h5>
            <Link
              to={"#"}
              data-toggle="modal"
              data-target="#keyskills"
              onClick={() => this.setCertification(true)}
              className="site-button add-btn button-sm"
            >
              <i className="fa fa-plus m-r5"></i> Add Certificate
            </Link>
          </div>
          <p className="m-b0">Add your certificationss.</p>

          <div className="list-row">
            <div className="list-line">
              {this.props.cv.data.cv_certification.map((item, index) => {
                return (
                  <div className="row  border-top">
                    <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                      <div className="d-flex">
                        <div className="d-flex justify-content-end mb-4 ">
                          <button
                            onClick={() => {
                              //console.log("clicked")
                              this.setCertification(true, item.id);
                            }}
                            className="site-button bg-primary add-btn button-sm danger justify-content-left m-r5"
                          >
                            <i className="fa fa-pencil m-r5"></i> Edit
                          </button>
                          <button
                            onClick={() => {
                              this.props.removeCertificate(item.id);
                            }}
                            className="site-button bg-danger add-btn button-sm  justify-content-left danger"
                          >
                            <i className="fa fa-trash m-r5"></i> Remove
                          </button>
                        </div>
                      </div>
                      <div className="clearfix m-b20">
                        <label className="m-b0">
                          {item.certification} ({item.start_date})
                        </label>
                        <span className="clearfix font-15">
                          {item.issued_by}
                        </span>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.additional_information,
                          }}
                        ></div>
                        {/* <p className="">super long text adskjfladkfj akld;fjldska;fj l;dsjflksadfj dsklfh dkjsgh sifhjabygfdjaskfmry7qidhvncskmvhqc0hjdfnvbc iodykfrh dkpfc vhkjds lfhcjndskjghfiaeruhnkvnfjidah kdljasfh aijdfh kjadlsfh iad fhbkdsdasferakjb pjssubke wabfry fuei ahfjdf ihowebkn cdjfk dskjflv ckjn dxjdkfh soa;kdjfn dsakpj njdkncv kmc,v sekjdfnv dskmbvk jdfv ,cxmv kjf hbfdkmvn kfejdnv kmdbg </p> */}
                      </div>
                    </div>
                  </div>
                );
              })}

              <Modal
                className="modal fade modal-bx-info editor"
                show={certification}
                onHide={this.certification}
              >
                <div className="modal-dialog my-0" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Certificate</h5>
                      <button
                        type="button"
                        className="close"
                        onClick={() => this.setCertification(false)}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <form>
                        <div className="row">
                          <div className="col-lg-12 col-md-12">
                            {/* <div className="form-group">
															<label>Certificate Title</label>
															<input type="email" className="form-control" />
														</div> */}
                            {this.renderInput(
                              "certificationTitle",
                              "Certificate Title"
                            )}
                          </div>
                          <div className="col-lg-12 col-md-12">
                            {this.renderInput(
                              "certificationIssuedBy",
                              "Issued by"
                            )}
                          </div>
                          <div className="col-lg-12 col-md-12">
                            {/* make date time till to day */}
                            <DatePicker
                              name="certifaced-issued"
                              label={"Issued On:"}
                              year={this.state.data.certificationYear}
                              month={this.state.data.certificationMonth}
                            />
                          </div>
                          <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                              <label>Description</label>
                              <div className="shadow-sm border p-3 mb-5 bg-white rounded">
                                <Editor
                                  editorState={
                                    this.state.certificateDescription
                                  }
                                  toolbarClassName="toolbarClassName"
                                  wrapperClassName="wrapperClassName"
                                  editorClassName="editorClassName"
                                  onEditorStateChange={(state) => {
                                    this.setState({
                                      certificateDescription: state,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="site-button"
                        onClick={() => this.setCertification(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="site-button"
                        onClick={() => {
                          let certificate = {};
                          certificate.certification =
                            this.state.data.certificationTitle;
                          certificate.issued_by =
                            this.state.data.certificationIssuedBy;
                          certificate.start_date =
                            document.getElementById("certifaced-issued-year")
                              .value +
                            "-" +
                            document.getElementById("certifaced-issued-month")
                              .value +
                            "-01";
                          certificate.additional_information = stateToHTML(
                            this.state.certificateDescription.getCurrentContent()
                          );

                          if (this.state.certificationId !== 0) {
                            certificate.id = this.state.certificationId;
                            this.props.updateCertificate(certificate);
                          } else {
                            this.props.addCertificate(certificate);
                          }
                          this.setCertification(false);
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      );
    }
  };

  renderReferences = () => {
    const { reference } = this.state;
    if (this.props.cv.loading === true || !this.props.cv.data.title) {
      return this.renderLoadingScreen("reference_bx");
    } else {
      return (
        <div id="reference_bx" className="job-bx bg-white m-b30">
          <div className="d-flex">
            <h5 className="m-b15">Reference</h5>
            <Link
              to={"#"}
              className="site-button add-btn button-sm"
              onClick={() => this.setReference(true)}
            >
              <i className="fa fa-plus m-r5"></i> Add Reference
            </Link>
          </div>

          <div className="list-row">
            <div className="list-line">
              {this.props.cv.data.cv_referance.map((item, index) => {
                return (
                  <div key={index} className="row  border-top">
                    <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                      <div className="d-flex">
                        <div className="d-flex justify-content-end mb-4 ">
                          <button
                            onClick={() => {
                              //console.log("clicked")
                              this.setReference(true, item.id);
                            }}
                            className="site-button bg-primary add-btn button-sm danger justify-content-left m-r5"
                          >
                            <i className="fa fa-pencil m-r5"></i> Edit
                          </button>
                          <button
                            onClick={() => {
                              this.props.removeReference(item.id);
                            }}
                            className="site-button bg-danger add-btn button-sm  justify-content-left danger"
                          >
                            <i className="fa fa-trash m-r5"></i> Remove
                          </button>
                        </div>
                      </div>
                      <div className="clearfix m-b20">
                        <label className="m-b0">{item.company}</label>
                        <span className="clearfix font-15">{item.name}</span>
                        <span className="clearfix font-15">
                          {item.position}
                        </span>
                        {/* <span className="clearfix font-15">Mr Bemnet Nikodimos</span> */}
                        <span className="clearfix font-15">{item.email}</span>
                        <span className="clearfix font-15">{item.phone}</span>
                      </div>
                    </div>
                  </div>
                );
              })}

              <Modal
                show={reference}
                onHide={this.setReference}
                className="modal fade modal-bx-info editor"
              >
                <div className="modal-dialog my-0" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title"
                        id="ResumeheadlineModalLongTitle"
                      ></h5>
                      <button
                        type="button"
                        className="close"
                        onClick={() => this.setReference(false)}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      {/* <p>It is the first thing recruiters notice in your profile. Write concisely what makes you unique and right person for the job you are looking for.</p> */}
                      <form>
                        <div className="row">
                          <div className="col-lg-12 col-md-12">
                            {this.renderInput("referenceFullName", "Full Name")}
                          </div>

                          <div className="col-lg-12 col-md-12">
                            {this.renderInput("referencePosition", "Position")}
                          </div>
                          <div className="col-lg-12 col-md-12">
                            {this.renderInput("referenceCompany", "Company")}
                          </div>
                          <div className="col-lg-12 col-md-12">
                            {this.renderInput("referenceEmail", "Email")}
                          </div>
                          <div className="col-lg-12 col-md-12">
                            {this.renderInput("referencePhone", "Phone")}
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="site-button"
                        onClick={() => this.setReference(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="site-button"
                        onClick={() => {
                          let reference = {};
                          reference.name = this.state.data.referenceFullName;
                          reference.position =
                            this.state.data.referencePosition;
                          reference.company = this.state.data.referenceCompany;
                          reference.email = this.state.data.referenceEmail;
                          reference.phone = this.state.data.referencePhone;
                          if (this.state.referenceId !== 0) {
                            reference.id = this.state.referenceId;

                            this.props.updateReference(reference);
                          } else {
                            this.props.addReference(reference);
                          }
                          this.setReference(false);
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      );
    }
  };

  renderBuildCvPage = () => {
    return (
      <div className="row">
        <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12 m-b30">
          <Listingsidebar />
        </div>
        <div className="col-xl-9 col-lg-8 col-md-8 col-sm-12">
          {this.renderProfileSummary()}

          {this.renderLanguage()}

          {this.renderEducation()}

          {this.renderEmployment()}

          {this.renderSkills()}

          {this.renderProjects()}

          {this.renderCertifications()}

          {this.renderReferences()}
        </div>
      </div>
    );
  };

  renderCvPreview = () => {
    if (this.props.cv.loading == true || this.props.talent.loading == true) {
      return this.renderLoadingScreen("loading_bx");
    }
    return <CVPage />;
  };

  renderResumePage = () => {
    if (this.state.renderPage == 1) {
      return this.renderCvPreview();
    } else if (this.state.renderPage == 2) {
      return this.renderCvPreviewTest();
    } else {
      return this.renderBuildCvPage();
    }
  };
  renderCvPreviewTest = () => {
    if (this.props.cv.loading == true || this.props.talent.loading == true) {
      return this.renderLoadingScreen("loading_bx");
    }
    return (
      <div>
        <div id="app"></div>
        <RenderPdf
          props={{
            talent: this.props.talent,
            cv: this.props.cv,
            educationLevels: this.props.educationLevels,
            fieldOfStudies: this.props.fieldOfStudies,
            languages: this.props.languages,
            authData: this.props.authData,
          }}
        />
      </div>
    );
  };

  renderJobResumePage = () => {
    const { renderPage } = this.state;

    if (this.props.cv.loading == true || this.props.talent.loading == true) {
      return this.renderLoadingScreen();
    } else {
      return (
        <>
          <div className="page-content">
            <div
              className="overlay-black-dark profile-edit p-t50 p-b20"
              style={{ backgroundImage: "url(" + bnr + ")" }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 col-md-7 candidate-info">
                    <div className="candidate-detail">
                      <div className="canditate-des text-center">
                        {/* <Link to={"#"}>
                          <img alt="" src={this.props.authData.data.image} />
                        </Link> */}
                        {/* <div className="upload-link" title="update" data-toggle="tooltip" data-placement="right">
													<input type="file" className="update-flie" />
													<i className="fa fa-camera"></i>
												</div> */}
                      </div>
                      <div className="text-white browse-job text-left">
                        <h4 className="m-b0">
                          {this.props.talent.data.user.first_name +
                            " " +
                            this.props.talent.data.user.last_name}
                          {/* <Link to={"#"} onClick={() => this.setBasicDetails(true)} className="m-l15 font-16 text-white"><i className="fa fa-pencil"></i></Link> */}
                        </h4>
                        <p className="m-b15">{this.props.title}</p>
                        <ul className="clearfix">
                          <li>
                            <i className="ti-location-pin"></i>{" "}
                            {this.props.talent.data.city},{" "}
                            {this.props.talent.data.address}
                          </li>
                          <li>
                            <i className="ti-mobile"></i>{" "}
                            {this.props.talent.data.phone_number}
                          </li>
                          <li>
                            <i className="ti-world"></i>{" "}
                            {this.props.getCountrybyName(
                              this.props.talent.data.country
                            )}
                          </li>
                          <li>
                            <i className="ti-email"></i>{" "}
                            {this.props.talent.data.user.email}
                          </li>
                        </ul>
                        {/* <div className="progress-box m-t10">
                          <div className="progress-info">
                            CV Strength <span>{this.getPercentage()}%</span>
                          </div>
                          <div className="progress">
                            <div
                              className="progress-bar bg-primary"
                              style={{ width: `${this.getPercentage()}%` }}
                              role="progressbar"
                            ></div>
                          </div>
                        </div> */}

                        {/* <button
                          type="button"
                          className="site-button ml-9 m-l9 m-t30"
                          onClick={() => {
                            this.props.sendData(this.props.cv);
                          }}
                        >
                          Save
                        </button>
                     
                        <button
                          type="button"
                          className="site-button"
                          onClick={() => {
                            this.togglePreview();
                          }}
                        >
                          {renderPage == 1 ? "Edit" : "Preview"}
                        </button> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-5">
                    <button
                      type="button"
                      className="site-button m-r30 m-t30"
                      onClick={() => {
                        console.log(this.props.cv);
                        let data = {...this.props.cv};
                        data.data = {...data.data,cv:this.props.cv.data.id};

                        this.props.sendData(data);
                      }}
                    >
                      Save
                    </button>
                    {/* <br />
                        <br /> */}
                    {/* <button
                      type="button"
                      className="site-button  m-t30"
                      onClick={() => {
                        this.togglePreview();
                      }}
                    >
                      {renderPage == 1 ? "Edit" : "Preview"}
                    </button> */}
                    {/* <button
                      type="button"
                      className="site-button btn-danger  m-t30"
                      onClick={() => {
                        this.togglePreviewDemo();
                      }}
                    >
                      {renderPage == 2 ? "Edit" : "Preview"}
                    </button> */
                    /* <PDFDownloadLink
                      className="site-button m-t30"
                      document={
                        <CVView
                          props={{
                            talent: this.props.talent,
                            cv: this.props.cv,
                            educationLevels: this.props.educationLevels,
                            fieldOfStudies: this.props.fieldOfStudies,
                            languages: this.props.languages,
                            authData: this.props.authData,
                          }}
                        /> */
                      }
                      {/* fileName="fee_acceptance.pdf"
                    >
                      {({ blob, url, loading, error }) => {
                        console.log(
                          blob,
                          url,
                          loading,
                          error,
                          "<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<"
                        );
                        if (loading) {
                          return "Loading Document";
                        } else {
                          return "Download now";
                        }
                      }}
                    </PDFDownloadLink> */}
                    
                    {/*
                    <Link to={"#"}>
						
					   <div className="pending-info text-white p-a25">
                        <h5>Pending Action</h5>
                        <ul className="list-check secondry">
                          <li>Verify Mobile Number</li>
                          <li>Add Preferred Location</li>
                          <li>Add Resume</li>
                        </ul>
                      </div> 
                    </Link>
					  */}
                  </div>
                </div>
              </div>
            </div>
            <div className="content-block">
              <div className="section-full browse-job content-inner-2">
                <div className="container">{this.renderResumePage()}</div>
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        <Header />

        {this.renderJobResumePage()}
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  cv: state.entities.cv,
  talent: state.entities.talent,
  educationLevels: state.entities.educationLevel,
  fieldOfStudies: state.entities.fieldOfStudy,
  languages: state.entities.language,
  authData: state.auth.auth,
});

const mapDispatchToProps = (dispatch) => ({
  loadCv: () => dispatch(loadTalentCvInformation()),
  loadTalent: () => dispatch(loadTalentInformation()),
  updateSummary: (payload) => dispatch(summaryChanged(payload)),
  updateSkill: (id) => dispatch(skillsChanged(id)),
  removeLanguage: (id) => dispatch(languageRemoved(id)),
  addLanguage: (id) => dispatch(languageAdded(id)),
  updateLanguage: (id) => dispatch(languageEdited(id)),
  getLanguages: (id) => dispatch(loadLanguageById(id)),
  addEducation: (id) => dispatch(educationAdded(id)),
  updateEducation: (id) => dispatch(educationEdited(id)),
  removeEducation: (id) => dispatch(educationRemoved(id)),
  getEducations: (id) => dispatch(loadEducationById(id)),
  loadFieldOfStudies: () => dispatch(loadFieldOfStudies()),
  loadEducationLevels: () => dispatch(loadEducationLevels()),
  getEducationName: (id) => dispatch(getEducationName(id)),
  getFieldOfStudyName: (id) => dispatch(getFieldOfStudyName(id)),
  addExperience: (id) => dispatch(experienceAdded(id)),
  updateExperience: (id) => dispatch(experienceEdited(id)),
  removeExperience: (id) => dispatch(experienceRemoved(id)),
  getExperiences: (id) => dispatch(loadExperienceById(id)),
  addProject: (id) => dispatch(projectAdded(id)),
  updateProject: (id) => dispatch(projectEdited(id)),
  removeProject: (id) => dispatch(projectRemoved(id)),
  getProjects: (id) => dispatch(loadProjectById(id)),
  addCertificate: (id) => dispatch(certificationAdded(id)),
  updateCertificate: (id) => dispatch(certificationEdited(id)),
  removeCertificate: (id) => dispatch(certificationRemoved(id)),
  getCertificates: (id) => dispatch(loadCertificationById(id)),
  addReference: (id) => dispatch(referenceAdded(id)),
  updateReference: (id) => dispatch(referenceEdited(id)),
  removeReference: (id) => dispatch(referenceRemoved(id)),
  getReferences: (id) => dispatch(loadReferenceById(id)),
  sendData: (data) => dispatch(sendTalentCvInformation(data)),
  loadLanguages: () => dispatch(loadlanguages()),
  loadCountries: () => dispatch(loadCountries()),
  getLanguagebyName: (id) => dispatch(getLanguagebyName(id)),
  getCountrybyName: (id) => dispatch(getCountrybyName(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Jobmyresume);

// export default Jobmyresume;
