import React from 'react';
import Multiselect from 'multiselect-react-dropdown';

const CustomMultiSelect = ({ items, name, label, error,limit=3, ...rest }) => {
    return (
        <div className="form-group" >
            
            <label htmlFor={name}>{label}</label>
            <br />
            <Multiselect
                {...rest} id={name} name={name}
                // disablePreSelectedValues
                isObject={true}
                displayValue="name"
                onKeyPressFn={function noRefCheck() { }}
                onSearch={function noRefCheck() { }}
                options={items}
                selectionLimit={limit}
                // showCheckbox={true}
                showArrow={true}
                hidePlaceholder={true}
                disablePreSelectedValues={false}
                selectedValues={rest.selectedValue}

            />
            {error && <div className="alert alert-danger fs-12" >{error}</div>}
        </div>
    );
}

export default CustomMultiSelect;