import { createSelector, createSlice } from '@reduxjs/toolkit';
import { ApiCallBegan } from "../actions/api"

const LanguageSlice = createSlice({
    name: "language",
    initialState: {
        data: [],
        lastFetch: null,
        loading: true
    },
    reducers: {
        languageFetched: (state, action) => {
            state.data = action.payload.data.results
            state.lastFetch = Date.now()
            state.loading = false;
            return state;
        },
        languageRequested: (state, action) => {
            state.loading = true;
            return state
        },
        languageRequestFailed: (state, action) => {
            state.loading = false;
            return state
        },
    }

})

export const getTalent = createSelector(state => state.entities.talent, talent => talent)
export const { languageFetched, languageRequested, languageRequestFailed } = LanguageSlice.actions
export default LanguageSlice.reducer;

export const getLanguagebyName = (id) => (dispatch, getState) => {
    const lang = getState().entities.language.data
    //console.log(lang)
    const language = lang.find(language => language.id === id)
    //console.log(language?language.name: "asd")
    return language?language.name: "X"
    // return "sdsd"
}
export const loadlanguages = () =>
    // remember to cache
    ApiCallBegan({
        url: "api/core/language/",
        onSuccess: languageFetched.type,
        onError: languageRequestFailed.type,
        onStart: languageRequested.type,
    })
