import { createSlice } from '@reduxjs/toolkit';
import { ApiCallBegan } from "../actions/api"
import checkCache from "../../services/cache";
import { coreCacheLimit } from "../../config.json"


const adsSlice = createSlice({
    name: "ads",
    initialState: {
        data: [],
        lastFetch: null,
        loading: true

    },
    reducers: {
        adsAdded: (ads, action) => {
            console.log(">>><<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<",action.payload.data.results)
            ads.data = action.payload.data.results
            ads.lastFetch = Date.now()
            ads.loading = false;
            return ads
        },
        adsRequested: (ads, action) => {
            ads.loading = true;
            return ads
        },
        adsRequestFailed: (ads, action) => {
            ads.loading = false;
            return ads
        }


    }

})

export const { adsAdded, adsRequestFailed, adsRequested } = adsSlice.actions
export default adsSlice.reducer;

export const loadads = () => (dispatch, getState) => {
    const { lastFetch } = getState().entities.ads;
    // alert(1)
    if (checkCache(lastFetch, coreCacheLimit)) return;
    dispatch(ApiCallBegan({
        url: "api/core/ads/",
        onStart: adsRequested.type,
        onSuccess: adsAdded.type,
        onError: adsRequestFailed.type
    }))
}
