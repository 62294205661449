import { combineReducers } from "@reduxjs/toolkit";

import sector from "./reducers/sector"
import country from "./reducers/country"
import currency from "./reducers/currency"
import educationLevel from "./reducers/educationLevel"
import experienceLevel from "./reducers/experienceLevel"
import employmentType from "./reducers/employmentType"
import fieldOfStudy from "./reducers/fieldOfStudy"
import region from "./reducers/region"
import salaryType from "./reducers/salaryType"
import cv from "./reducers/cv";
import talent from "./reducers/talent";
import language from "./reducers/language";
import jobPackage from "./reducers/jobPackage";
import companyProfile from "./reducers/companyProfile";
import jobPost from "./reducers/jobPost";
import myJobs from "./reducers/myjobs";
import openJobs from "./reducers/openJobs";
import appliedJobs from "./reducers/appliedJobs";
import appliedApplicants from "./reducers/appliedApplicants";
import users from "./reducers/users";
import groups from "./reducers/groups";
import jobHistory from "./reducers/jobHistory";
import town from "./reducers/town";
import companies from "./reducers/companies";
import sectorAggrigate from "./reducers/sectorAggrigate";
import ads from "./reducers/ads";
import reports from "./reducers/reports";
import companyReports from "./reducers/companyReports";
import banners from "./reducers/banners";

export default combineReducers({
    sector,
    country,
    currency,
    educationLevel,
    experienceLevel,
    employmentType,
    fieldOfStudy,
    region,
    salaryType,
    cv,
    talent,
    language,
    jobPackage,
    companyProfile,
    jobPost,
    myJobs,
    openJobs,
    appliedJobs,
    appliedApplicants,
    users,
    groups,
    jobHistory,
    town,
    companies,
    sectorAggrigate,
    ads,
    reports,
    companyReports,
    banners
    
});