import { createSlice } from '@reduxjs/toolkit';
import { ApiCallBegan } from "../actions/api"
import checkCache from "../../services/cache"
import { coreCacheLimit } from "../../config.json"
const experienceLevelSlice = createSlice({
    name: "experienceLevel",
    initialState: {
        data: [],
        lastFetch: null,
        loading: false

    },
    reducers: {
        experienceLevelAdded: (experienceLevel, action) => {
            experienceLevel.data = action.payload.data.results
            experienceLevel.lastFetch = Date.now()
            experienceLevel.loading = false;

            return experienceLevel
        },
        experienceLevelRequested: (experienceLevel, action) => {
            experienceLevel.loading = true;
            return experienceLevel
        },
        experienceLevelRequestFailed: (experienceLevel, action) => {
            experienceLevel.loading = false;
            return experienceLevel
        },



    }

})

export const { experienceLevelAdded, experienceLevelRequestFailed, experienceLevelRequested } = experienceLevelSlice.actions
export default experienceLevelSlice.reducer;

export const loadExperienceLevels = () => (dispatch, getState) => {
    const { lastFetch } = getState().entities.experienceLevel
    if (checkCache(lastFetch, coreCacheLimit)) return;

    dispatch(ApiCallBegan({
        url: "api/core/experiencelevel/",
        onStart: experienceLevelRequested.type,
        onSuccess: experienceLevelAdded.type,
        onError: experienceLevelRequestFailed.type,
    }))
}