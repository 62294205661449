import { createSlice } from '@reduxjs/toolkit';
import { ApiCallBegan } from "../actions/api"
import checkCache from "../../services/cache";
import { coreCacheLimit } from "../../config.json"


const educationLevelSlice = createSlice({
    name: "educationLevel",
    initialState: {
        data: [],
        lastFetch: null,
        loading: false
    },
    reducers: {
        educationLevelAdded: (educationLevel, action) => { 
            educationLevel.data = action.payload.data.results
            educationLevel.lastFetch = Date.now()
            educationLevel.loading = false;


            return educationLevel;
        },
        educationLevelRequested: (educationLevel, action) => {
            educationLevel.loading = true;
            return educationLevel;
        },
        educationLevelRequestFailed: (educationLevel, action) => {
            educationLevel.loading = false;
            return educationLevel;
        }


    }

})

export const { educationLevelAdded,educationLevelRequestFailed,educationLevelRequested } = educationLevelSlice.actions
export default educationLevelSlice.reducer;

export const getEducationName = (id) => (dispatch, getState) => {
    const educationLevel = getState().entities.educationLevel.data
    const education = educationLevel.find(educationItem => educationItem.id === id)
    // //console.log(fieldOfStudy?fieldOfStudy.name: "asd")
    return education ? education.name : ""
    // return "sdsd"
}

export const loadEducationLevels = () => (dispatch, getState) => {
    const { lastFetch } = getState().entities.educationLevel
    if (checkCache(lastFetch, coreCacheLimit)) return;
    dispatch(ApiCallBegan({
        url: "api/core/educationlevel/",
        onStart: educationLevelRequested.type,
        onSuccess: educationLevelAdded.type,
        onError: educationLevelRequestFailed.type
    }))
}