import { createSlice } from "@reduxjs/toolkit";
import { ApiCallBegan } from "../actions/api";
import checkCache from "../../services/cache";
import { coreCacheLimit, getOpenJobsUrl } from "../../config.json";
import { getCurrentPage } from "../../utils/utilFunctions";
// import { toast } from "react-toastify";
import _ from "lodash";
const pagination = 20;
// const s = "api/jobs/openjobs/"

const appliedApplicantsSlice = createSlice({
  name: "appliedApplicants",
  initialState: {
    id: "",
    data: [],
    exportData: [],
    count: 0,
    history: [],
    error: {},
    filterLoading: true,
    currentUrl: "",
    currentPage: 0,
    filter: "",
    search: "",

    pageCount: 1,
    next: null,
    previous: null,
    lastFetch: null,
    loading: true,
  },
  reducers: {
    appliedApplicantsFetched: (state, action) => {
      let newState = [];
      let newHistory = [];
      let data = action.payload.data;
      let pageCount = Math.ceil(data.count / pagination);
      if (state.data.length == 0) {
        newState = _.fill(Array(pageCount), []);
        newHistory = _.fill(Array(pageCount), {
          next: null,
          previous: null,
          loaded: false,
        });
      } else {
        newState = state.data;
        newHistory = state.history;
      }

      let currentPage = getCurrentPage(action.payload.data.previous);
      //console.log(currentPage, action.payload.data.previous, "current page and previous is right here")

      newState[currentPage] = data.results;
      newHistory[currentPage] = {
        next: data.next,
        previous: data.previous,
        loaded: true,
      };
      state.history = newHistory;
      state.data = newState;
      state.count = data.count;
      state.currentPage = currentPage;
      state.pageCount = pageCount;
      state.next = data.next;
      state.previous = data.previous;
      state.lastFetch = Date.now();
      state.loading = false;
      return state;
    },
    applicationsLoadedFromCache: (state, action) => {
      state.currentPage = action.payload.currentPage;
      state.previous = action.payload.previous;
      state.next = action.payload.next;

      return state;
    },
    applicantDataRequested: (state, action) => {
      state.loading = true;
      return state;
    },
    applicantDataRequestFailed: (state, action) => {
      state.loading = false;
      return state;
    },
    setJobId: (state, action) => {
      state.id = action.payload.id;
      return state;
    },
    setFilter: (state, action) => {
      state.filter = action.payload.filter;
      return state;
    },
    filterDataFetched: (state, action) => {
      state.exportData = action.payload.data;
      state.filterLoading = false;
    },
    filterDataRequested: (state, action) => {
      state.filterLoading = true;
    },
    filterDataRequestFailed: (state, action) => {
      state.filterLoading = false;
    },
    clearApplicants: (state, action) => {
      state.id = "";
      state.data = [];
      state.exportData = [];
      state.count = 0;
      state.history = [];
      state.error = {};
      state.filterLoading = true;
      state.currentPage = 0;
      state.filter = "";
      state.search = "";
      state.pageCount = 1;
      state.next = null;
      state.previous = null;
      state.lastFetch = null;
      state.loading = true;
    },
    urlAssigned: (state, action) => {
      state.currentUrl = action.payload.url;
    },
    setSearchQuery: (state, action) => {
      state.search = action.payload.search;
    }

  },
});
export const {
  appliedApplicantsFetched,
  applicantDataRequested,
  applicationsLoadedFromCache,
  applicantDataRequestFailed,
  setJobId,
  setFilter,
  filterDataFetched,
  filterDataRequested,
  clearApplicants,
  filterDataRequestFailed,
  urlAssigned,
  setSearchQuery
} = appliedApplicantsSlice.actions;
export default appliedApplicantsSlice.reducer;



export const loadAppliedApplicants =
  (pageUrl, filterQuery = "", searchQuery = "") =>
  (dispatch, getState) => {

    const { lastFetch, history, id, filter,search } =
      getState().entities.appliedApplicants;

    const thisPage = getCurrentPage(pageUrl) - 1;

    const pageHistory = history[thisPage];
    
    let jobId = pageUrl.split("/")[4];
    
    let thisFilter = "";
    
    if (
      checkCache(lastFetch, 5) &&
      pageHistory.loaded &&
      jobId == id &&
      filterQuery == filter &&
      searchQuery == search
    ) {
      dispatch(
        applicationsLoadedFromCache({
          currentPage: thisPage,
          next: pageHistory.next,
          previous: pageHistory.previous,
        })
      );
      return;
    }

    
    dispatch({ type: setJobId.type, payload: { id: jobId } });
    
    if (filterQuery != "" && filterQuery != filter) {
      dispatch({ type: setFilter.type, payload: { filter: filterQuery } });
      thisFilter = filterQuery;
    }
    else {
      thisFilter = filter;
    }

    
    if(searchQuery == "" && search !=searchQuery){
      dispatch({ type: setFilter.type, payload: { filter: filterQuery } });
      thisFilter = thisFilter +  searchQuery;


    }
    
    if (searchQuery != "") {
      thisFilter = thisFilter + searchQuery;
      dispatch({ type: setSearchQuery.type, payload: { search: searchQuery } });
    }
    if(!thisFilter.includes("?") && !pageUrl.includes("?")){
      thisFilter = "?" + thisFilter;
    }
    dispatch({ type: urlAssigned.type, payload: { url: pageUrl } });  

    dispatch(
      ApiCallBegan({
        url: `${pageUrl}${thisFilter}`,
        onStart: applicantDataRequested.type,
        onSuccess: appliedApplicantsFetched.type,
        onError: applicantDataRequestFailed.type,
      })
    );
  };



export const loadFilterdData = (jobId) => (dispatch, getState) => {
  const { filter, search } = getState().entities.appliedApplicants;
  dispatch(
    ApiCallBegan({
      url: `api/appliedjobs/jobs/exportapplicants/${jobId}/?${filter}&${search}`,
      onStart: filterDataRequested.type,
      onSuccess: filterDataFetched.type,
      onError: filterDataRequestFailed.type,
    })
  );
};

export const clearApplicantsData = () => (dispatch, getState) => {
  dispatch({ type: clearApplicants.type, payload: { id: 0 } });
};
