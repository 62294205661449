import React, { useEffect } from "react";
import Pdf from "react-to-pdf";
import "../../css/cv.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faMailBulk,
  faPhone,
  faLocation,
  faCircle,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import Template1LeftPanel from "../Element/common/cvComponents/template1LeftPanel";
import { useDispatch, useSelector } from "react-redux";
import {
  loadEducationLevels,
  getEducationName,
} from "../../store/reducers/educationLevel";
import {
  loadFieldOfStudies,
  getFieldOfStudyName,
} from "../../store/reducers/fieldOfStudy";
import {
  loadlanguages,
  getLanguagebyName,
} from "../../store/reducers/language";
import { getRegionbyName } from "../../store/reducers/region";
import { getCountrybyName } from "../../store/reducers/country";

library.add(faMailBulk, faPhone, faLocation, faCircle);
const printSection = React.createRef();
const CVPage = () => {
  // const { name, title, region, city,country, address, phone, email, summary, skills, extraLeftPane, experiences, educations } = location.state;
  const dispatch = useDispatch();
  const cv = useSelector((state) => state.entities.cv);
  const talent = useSelector((state) => state.entities.talent);
  const countries = useSelector((state) => state.entities.country);
  const regions = useSelector((state) => state.entities.region);
  const languages_ = useSelector((state) => state.entities.language);
  useEffect(() => {
    // const cv = useSelector(state => state.cv);
    // //console.log(cv);
    // const experiences = this.props
  }, []);

  // //console.log(talent, "talent");
  // //console.log(cv, "cv");

  //   const experienceItem = {
  //     company: "Bemmy Tech",
  //     position: "Software Engineer",
  //     start_date: "January 2019",
  //     end_date: "December 2020",
  //     country: "Ethiopia",
  //     city: "Addis Abeba",
  //     projects_completed:
  //       "<ul><li>please work</li><li>please work</li><li>please work</li></ul>",
  //     duties_and_responsibilities:
  //       "<ul><li>please work</li><li>please work</li><li>please work</li></ul>",
  //   };
  //   const educationItem = {
  //     schoolName: "Addis Ababa University",
  //     degree: "BSc",
  //     feild: "Computer Science",
  //     graduationDate: "June 2019",
  //     country: "Ethiopia",
  //     city: "Addis Abeba",
  //     description:
  //       "<ul><li>please work</li><li>please work</li><li>please work</li></ul>",
  //   };

  //   const { extraLeftPane } = {
  //     extraLeftPane: [],
  //     experiences: [experienceItem, experienceItem],
  //     educations: [educationItem],
  //   };
  // const { name, title, region, city, address, phone, email, summary, skills, extraLeftPane, experiences, educations } = { name: "John Doe", title: "Software Engineer", region: "Oromia", city: "Addis Abeba", address: "Addis ketema", phone: "0911-121212", email: "john@bemmytech.com", summary: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam numquam possimus velit accusantium suscipit aut hic facilis quam praesentium deserunt, facere nobis tenetur? Ipsa illum similique ab, assumenda dolore veritatis.", skills: "Skill LIst", extraLeftPane: [], experiences: [experienceItem, experienceItem], educations: [educationItem] }
  const name = talent.data.user.first_name + " " + talent.data.user.last_name;
  const title = talent.data.title;
  const country = dispatch(getCountrybyName(talent.data.country));
  const region = dispatch(getRegionbyName(talent.data.region));
  const city = talent.data.city;
  const address = talent.data.address;
  const phone = talent.data.phone_number;
  const email = talent.data.user.email;
  const summary = cv.data.summary;
  const skills = cv.data.skills;
  const experiences = cv.data.cv_experience;
  const educations = cv.data.cv_education;
  const languages = cv.data.cv_language;
  const projects = cv.data.cv_project;
  const references = cv.data.cv_referance;
  const certifications = cv.data.cv_certification;
  const languagesConstant = [
    "Distinguished",
    "Superior",
    "Advanced",
    "Intermediate",
    "Novice",
  ];

  // const country = "Ethiopia"
  // const country = "Ethiopia"
  return (
    // center the page
    <React.Fragment>
      <div className="container">
        <Pdf
          targetRef={printSection}
          filename={
            talent.data.user.first_name +
            "_" +
            talent.data.user.last_name +
            "'s_CV.pdf"
          }
        >
          {({ toPdf }) => (
            <button className="btn btn-success" onClick={toPdf}>
              Download CV
            </button>
          )}
        </Pdf>
      </div>
      <div
        ref={printSection}
        className="content"
        style={{ minHeight: "1116px", width: "840px", margin: "0px auto" }}
      >
        <div
          className="header"
          style={{
            height: "90px",
            width: "840px",
            padding: "40px 25px",
            backgroundColor: "#4A4A4A",
          }}
        >
          <h1 style={{ fontFamily: "serif", color: "white", fontSize: "45px" }}>
            {title} {name}
          </h1>
          {/* <h3 style={{ fontFamily: 'serif', color: "white", fontSize: "13px" }}></h3> */}
        </div>
        <div>
          <div
            className="left"
            style={{
              minHeight: "966px",
              width: "280px",
              padding: "25px",
              float: "left",
              backgroundColor: "#576D7B",
            }}
          >
            <div
              className="left-top"
              style={{ minHeight: "200px", width: "280px", padding: "0px" }}
            >
              <Template1LeftPanel
                title={"Contact Information"}
                is_list={true}
                list={[
                  {
                    // icon: <FontAwesomeIcon icon={faLocation} />,
                    text:
                      country + ", " + region + ", " + city + ", " + address,
                  },
                  {
                    // icon: <FontAwesomeIcon icon={faPhone} />,
                    text: phone,
                  },
                  {
                    // icon: <FontAwesomeIcon icon={faMailBulk} />,
                    text: email,
                  },
                ]}
              />
              {skills.length > 0 && (
                <>
                  <Template1LeftPanel
                    title={"Skills"}
                    is_list={false}
                    content={skills}
                  />
                </>
              )}
              {/* {extraLeftPane.map((item, index) => {
                return (
                  <Template1LeftPanel
                    key={index}
                    title={item.title}
                    is_list={true}
                    list={item.list}
                  />
                );
              })} */}
              {languages.length > 0 && (
                <>
                  <h4
                    style={{
                      color: "white",
                      fontFamily: "serif",
                      fontSize: "16px",
                    }}
                  >
                    LANGUAGES
                  </h4>

                  {languages.map((item, index) => {
                    return (
                      <React.Fragment>
                        <p style={{ color: "white", fontSize: "12px" }}>
                          {dispatch(getLanguagebyName(item.language))}
                          <table>
                            <tr>
                              <td style={{ padding: "0px" }}>
                                Speaking:
                                {
                                  languagesConstant[parseInt(item.speaking)]
                                }{" "}
                              </td>
                              <td style={{ padding: "0px" }}>
                                Listening:{" "}
                                {languagesConstant[parseInt(item.listening)]}
                              </td>
                            </tr>
                            <tr>
                              <td style={{ padding: "0px" }}>
                                Reading:{" "}
                                {languagesConstant[parseInt(item.reading)]}
                              </td>
                              <td style={{ padding: "0px" }}>
                                Writing:{" "}
                                {languagesConstant[parseInt(item.reading)]}
                              </td>
                            </tr>
                          </table>
                        </p>

                        <hr />
                      </React.Fragment>
                    );
                  })}
                </>
              )}
              {projects.length > 0 && (
                <>
                  <h4
                    style={{
                      color: "white",
                      fontFamily: "serif",
                      fontSize: "16px",
                    }}
                  >
                    Projects
                  </h4>
                  {projects.map((item, index) => {
                    return (
                      <React.Fragment>
                        <p style={{ color: "white", fontSize: "12px" }}>
                          Title: &nbsp; {item.project}
                        </p>
                        <p
                          style={{
                            marginTop: "0px",
                            color: "white",
                            fontSize: "11px",
                          }}
                        >
                          {item.project_link}
                        </p>
                        <div
                          style={{ color: "white" }}
                          dangerouslySetInnerHTML={{
                            __html: item.project_description,
                          }}
                        ></div>

                        <hr />
                      </React.Fragment>
                    );
                  })}
                </>
              )}

              {references.length > 0 && (
                <>
                  <h4
                    style={{
                      color: "white",
                      fontFamily: "serif",
                      fontSize: "16px",
                    }}
                  >
                    References
                  </h4>
                  {references.map((item, index) => {
                    return (
                      <React.Fragment>
                        <p style={{ color: "white", fontSize: "12px" }}>
                          <table>
                            <tr>
                              <td style={{ padding: "0px" }}>
                                Company: {item.company}{" "}
                              </td>
                              {/* <td style={{ padding: "0px" }}>Listening: {item.listening}</td> */}
                            </tr>
                            <tr>
                              {/* <td style={{ padding: "0px" }}>Reading: {item.reading}</td> */}
                              <td style={{ padding: "0px" }}>
                                Name: {item.name}
                              </td>
                            </tr>
                            <tr>
                              {/* <td style={{ padding: "0px" }}>Reading: {item.reading}</td> */}
                              <td style={{ padding: "0px" }}>
                                Position: {item.position}
                              </td>
                            </tr>
                            <tr>
                              {/* <td style={{ padding: "0px" }}>Reading: {item.reading}</td> */}
                              <td style={{ padding: "0px" }}>
                                Phone: {item.phone}
                              </td>
                            </tr>
                            <tr>
                              {/* <td style={{ padding: "0px" }}>Reading: {item.reading}</td> */}
                              <td style={{ padding: "0px" }}>
                                email: {item.email}
                              </td>
                            </tr>
                          </table>
                        </p>
                        {/* <p style={{ marginTop: "0px", color: "white", fontSize: "11px" }} >
                                            {item.project_link}
                                        </p> */}
                        {/* <div style={{ color: "white" }} dangerouslySetInnerHTML={{ __html: item.project_description }}></div> */}

                        <hr />
                      </React.Fragment>
                    );
                  })}
                </>
              )}
            </div>
          </div>
          <div
            className="right"
            style={{
              minHeight: "966px",
              width: "560px",
              padding: "25px",
              float: "right",
              backgroundColor: "#ffffff",
            }}
          >
            {summary.trim().length > 0 && (
              <>
                <div dangerouslySetInnerHTML={{ __html: summary }}></div>

                <hr style={{ backgroundColor: "#6f6f6f" }} />
              </>
            )}
            {experiences.length > 0 && (
              <>
                <div className="experience">
                  <h4>
                    <b>Expierience</b>
                  </h4>

                  {experiences.map((item, index) => {
                    console.log(item);
                    return (
                      <div className="rightPaneSection">
                        <p>
                          {item.start_date} to {item.end_date}
                        </p>
                        <p>
                          <b>{item.title}</b> <b>{item.company}</b>{" "}
                        </p>

                        {item.duties_and_responsibilities != "<p><br></p>" && (
                          <div className="htmlContent">
                            <strong>
                              <b style={{ color: "black" }}>
                                Duties and Responsiblities
                              </b>
                            </strong>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item.duties_and_responsibilities,
                              }}
                            ></p>
                          </div>
                        )}
                        {item.projects_completed != "<p><br></p>" ? (
                          <div className="htmlContent">
                            <strong>
                              <b style={{ color: "black" }}>
                                projects_completed
                              </b>
                            </strong>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item.projects_completed,
                              }}
                            ></p>
                          </div>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
                <hr style={{ backgroundColor: "#6f6f6f" }} />
              </>
            )}

            {educations.length > 0 && (
              <>
                <div className="Education">
                  <h4>
                    <b>Education</b>
                  </h4>

                  {educations.map((item, index) => {
                    return (
                      <div className="rightPaneSection">
                        <p>
                          <b>{item.school}</b>
                        </p>
                        <p>
                          from {item.start_date} to {item.end_date}
                        </p>
                        <p>
                          <b>
                            {dispatch(getFieldOfStudyName(item.field_of_study))}
                            , {dispatch(getEducationName(item.degree))}
                          </b>
                        </p>
                        <div className="htmlContent">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: item.additional_information,
                            }}
                          ></p>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <hr style={{ backgroundColor: "#6f6f6f" }} />
              </>
            )}

            {certifications.length > 0 && (
              <div className="Certifications">
                <h4>
                  <b>Certifications</b>
                </h4>

                {certifications.map((item, index) => {
                  return (
                    <div className="rightPaneSection">
                      <p>{item.start_date}</p>
                      <p>
                        <b>{item.certification}</b> (<b>{item.issued_by}</b>){" "}
                      </p>
                      {item.additional_information !== "<p><br></p>" ? (
                        <div className="htmlContent">
                          <strong>
                            <b style={{ color: "black" }}>
                              Additional Information
                            </b>
                          </strong>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: item.additional_information,
                            }}
                          ></p>
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CVPage;
