import { createSlice } from "@reduxjs/toolkit";
import { ApiCallBegan } from "../actions/api";
import checkCache from "../../services/cache";
import { coreCacheLimit } from "../../config.json"
import { getCurrentPage } from "../../utils/utilFunctions";
// import { toast } from "react-toastify";
import _ from "lodash";
const pagination = 20;

const myJobsSlice = createSlice({
    name: "myJobs",
    initialState: {
        data: [],
        count: 0,
        history: [],
        companyId: 0,
        
        currentPage: 0,
        pageCount: 1,
        next: null,
        previous: null,
        lastFetch: null,
        loading: false
    },
    reducers: {
        myJobsFetched: (state, action) => {
            let newState = []
            let newHistory = []
            let data = action.payload.data
            let pageCount = Math.ceil(data.count / pagination)
            if (state.data.length == 0) {
                newState = _.fill(Array(pageCount), [])
                newHistory = _.fill(Array(pageCount), { next: null, previous: null, loaded: false })
            }
            else {
                newState = state.data
                newHistory = state.history
            }

            let currentPage = getCurrentPage(action.payload.data.previous)
            //console.log(currentPage, action.payload.data.previous, "current page and previous is right here")

            newState[currentPage] = data.results
            newHistory[currentPage] = { next: data.next, previous: data.previous, loaded: true }
            state.history = newHistory
            state.data = newState
            state.count = data.count
            state.currentPage = currentPage
            state.pageCount = pageCount
            state.next = data.next
            state.previous = data.previous
            state.lastFetch = Date.now()
            state.loading = false;
            return state;
        },
        myJobsLoadedFromCache: (state, action) => {
            //console.log("loaded from cache!!!!!!!!!!!!!!!!!!!!!!!!!!")
            state.currentPage = action.payload.currentPage
            state.previous = action.payload.previous
            state.next = action.payload.next
            return state;

        },
        myJobsRequested: (state, action) => {
            state.loading = true;
            return state
        },
        myJobsRequestFailed: (state, action) => {
            state.loading = false;
            return state
        },
        myJobsUpdated: (state, action) => {
            state.data = action.payload.data
            return state;
        },
        myJobCreated: (state, action) => {
            state.data = action.payload.data
            return state;
        },
        setCompanyId: (state, action) => {
            state.companyId = action.payload.companyId
            return state;
        }



    }
})

export const getmyJobs = state => state.entities.myJobs
export const { myJobsFetched, myJobsRequested, myJobsRequestFailed, myJobsLoadedFromCache,myJobCreated,myJobsUpdated, setCompanyId } = myJobsSlice.actions
export default myJobsSlice.reducer;

export const updateJob = (jobPost) => (dispatch, getState) => {
    let data = getState().entities.myJobs.data

    //console.log(data, "data is right here")

    let jobPostId = jobPost.id
    const newData = data.map(page => {
        return page.map(job => {
            if (job.id == jobPostId) {
                return jobPost
            }
            else {
                return job
            }
        })
    })
    dispatch({ type: myJobsUpdated.type, payload: { data: newData } })

}

export const addJob = (jobPost) => (dispatch, getState) => {
    let data = getState().entities.myJobs.data
    try{
        data[0].unshift(jobPost);
        dispatch({ type: myJobCreated.type, payload: { data: data } })

    }
    catch(error){
        // //console.log(error)
    }
}


export const getMyJobs = (pageUrl,admin = false) => (dispatch, getState) => {
    const { lastFetch, history } = getState().entities.myJobs
    const thisPage = getCurrentPage(pageUrl) - 1
    const pageHistory = history[thisPage]
    let companyId = 0;
    if(admin){
        if(pageUrl.split("/").length==6){
            companyId = parseInt(pageUrl.split("/")[3])
        }
    }
    if (checkCache(lastFetch, coreCacheLimit) && pageHistory.loaded) {
        if(admin==false){
            dispatch(myJobsLoadedFromCache({ currentPage: thisPage, next: pageHistory.next, previous: pageHistory.previous }))
            return;
        }
        else{
            if(companyId== getState().entities.myJobs.companyId){
                dispatch(myJobsLoadedFromCache({ currentPage: thisPage, next: pageHistory.next, previous: pageHistory.previous }))
                return;
            }
            
            
        }
    }
    if(companyId !== getState().entities.myJobs.companyId){
        dispatch({ type: setCompanyId.type, payload: { companyId: companyId } })
    }


    return dispatch(ApiCallBegan({
        url: pageUrl,
        onSuccess: myJobsFetched.type,
        onError: myJobsRequestFailed.type,
        onStart: myJobsRequested.type
    }))
}
// "api/jobs/myjobs/"

/*import { createSlice } from "@reduxjs/toolkit";
import { ApiCallBegan } from "../actions/api";
import checkCache from "../../services/cache";
import { coreCacheLimit } from "../../config.json"
import { getCurrentPage } from "../../utils/utilFunctions";
// import { toast } from "react-toastify";
import _ from "lodash";
const pagination = 20;

const myJobsSlice = createSlice({
    name: "myJobs",
    initialState: {
        data: [],
        history: [],
        count: 0,
        currentPage: 0,
        pageCount: 1,
        next: null,
        previous: null,
        lastFetch: null,
        loading: false
    },
    reducers: {
        myJobsFetched: (state, action) => {
            //console.log(action.payload,"Jobs Fetched@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@")
            let newState = []
            // let historyState = []
            let data = action.payload.data
            let pageCount = Math.ceil(data.count / pagination)
            if (state.data.length == 0) {
                newState = _.fill(Array(pageCount), [])
                // historyState = _.fill(Array(pageCount), [])

            }
            else {
                state.currentPage = getCurrentPage(state.previous)
            }

            let currentPage = getCurrentPage(action.payload.data.previous)
            //console.log(currentPage, action.payload.data.previous, "current page and previous is right here")

            newState[currentPage] = data.results
            // historyState[currentPage] = { previous: action.payload.data.previous, next: action.payload.data.next }
            state.data = newState
            // state.history = historyState
            state.count = data.count
            state.currentPage = currentPage
            state.pageCount = pageCount
            state.next = data.next
            state.previous = data.previous
            state.lastFetch = Date.now()
            state.loading = false;
            return state;
        },
        myJobsLoadedFromCache: (state, action) => {
            state.currentPage = action.payload.currentPage
            state.previous = action.payload.previous
            state.next = action.payload.next
            return state;

        },
        myJobsRequested: (state, action) => {
            state.loading = true;
            return state
        },
        myJobsRequestFailed: (state, action) => {
            state.loading = false;
            return state
        },


    }
})

// export const getmyJobs = state => state.entities.myJobs
export const { myJobsFetched, myJobsRequested, myJobsRequestFailed, myJobsLoadedFromCache } = myJobsSlice.actions
export default myJobsSlice.reducer;



export const getMyJobs = (pageUrl) => (dispatch, getState) => {
    const { lastFetch, history } = getState().entities.myJobs
    const thisPage = getCurrentPage(pageUrl) - 1
    const historyPage = history[thisPage]
    if (checkCache(lastFetch) && getState().entities.myJobs.data[thisPage].length > 0) {
        // dispatch({ type: myJobsLoadedFromCache.type, payload: { next: historyPage.next, previous: historyPage.previous, currentPage: thisPage } })
        //console.log("NotDispatched")
        return
    }
    //console.log("Dispatched")

        return dispatch(ApiCallBegan({
            url: pageUrl,
            onSuccess: myJobsFetched.type,
            onError: myJobsRequestFailed.type,
            onStart: myJobsRequested.type
        }))

    
}
// "api/jobs/myjobs/"

*/