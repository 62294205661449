import React from "react";
import { Link } from "react-router-dom";
import { Reveal, Tween } from "react-gsap";


const BannerAd = ({
  title,
  imageUrl,
  description,
  linkText,
  linkUrl,
  isParallax = false,
}) => {
  var bnr1 = require("./../../../images/banner/bnr1.jpg");
  if (isParallax) {
    var class_ =
      "section-full content-inner-2 call-to-action text-white overlay-black-dark text-center bg-img-fix m-t100";
  } else {
    var class_ =
      "section-full content-inner-2 call-to-action text-white overlay-black-dark text-center m-t100";
  }
  return (
    <div
      className={class_}
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "center",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <Reveal>
              <Tween
                from={{ opacity: 0, transform: "translate3d(-100px, 0, 0)" }}
                ease="back.out(1.4)"
                duration={2}
              >
                <h2 className="m-b10">{title}</h2>
              </Tween>
            </Reveal>
            <Reveal>
              <Tween
                from={{ opacity: 0, transform: "translate3d(100px, 0, 0)" }}
                ease="back.out(1.4)"
                duration={2}
              >
                <p className="m-b0">{description} </p>
              </Tween>
            </Reveal>

            {linkText !== "" && linkUrl !== "" && (
              // <Reveal>
              //   <Tween
              //     from={{ opacity: 0, transform: "translate3d(100px, 0, 0)" }}
              //     ease="back.out(1.4)"
              //     duration={2}
              //   >
                  <a
                    href={`//${linkUrl}`}
                    target="_blank"
                    className="site-button m-t20 outline outline-2 radius-xl"
                  >
                    {linkText}
                  </a>
            //     </Tween>
            //   </Reveal>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerAd;
