import React, { Component } from "react";
import { Link } from "react-router-dom";
import Joi from "joi-browser";
import { connect, useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Profilesidebar from "../Element/Profilesidebar";
import {
  loadTalentInformation,
  updateTalentInformation,
} from "../../store/reducers/talent";
import { Form as BootStrapForm } from "react-bootstrap";
import { Modal } from "react-bootstrap";

import { loadSectors,getSectorByName } from "../../store/reducers/sector";
import { loadCurrency } from "../../store/reducers/currency";
import { loadCountries } from "../../store/reducers/country";
import { loadEmploymentTypes } from "../../store/reducers/employmentType";
import { loadEducationLevels } from "../../store/reducers/educationLevel";
import { loadRegions } from "../../store/reducers/region";
import { loadSalaryType } from "../../store/reducers/salaryType";
import { loadExperienceLevels } from "../../store/reducers/experienceLevel";
import { loadFieldOfStudies,getFieldOfStudyName } from "../../store/reducers/fieldOfStudy";
import Form from "../Element/common/form";
import { toast } from "react-toastify";
import { apiUrl, languagesConstant } from "../../config.json";
import { size } from "lodash";
import {
  loadlanguages,
  getLanguagebyName,
} from "../../store/reducers/language";
import { loadLanguageById } from "../../store/reducers/cv";
import Loading from "../Element/common/loading";

class JobProfile extends Form {
  state = {
    loaded: 0,
    cv: "",
    languageId: 0,
    edit: 0,
    editIndex: 0,

    languageState: { reading: 0, writing: 0, speaking: 0, listening: 0 },
    selectedSector: [],
    selectedFieldOfStudy: [],
    data: {
      first_name: "",
      last_name: "",
      title: "",
      gender: "",
      region: "",
      dob: "",
      phone_number: "",
      city: "",
      address: "",
      sector: "",
      current_company: "",
      current_position: "",
      current_salary: 0,
      // profilePicture: "",
      // cv: "",
      education_level: "",
      experience_in_months: "",
      experience_level: "",
      experience_in_years: "",
      country: "",
      field_of_study: [],
      tempLanguage: 0,
      talent_language: [],
    },
    errors: { email: "email is required", password: "password is required" },
  };

  async componentDidMount() {
    this.props.loadCountries();
    this.props.loadCurrency();
    this.props.loadEducationLevels();
    this.props.loadEmploymentTypes();
    this.props.loadExperienceLevels();
    this.props.loadFieldOfStudies();
    this.props.loadRegions();
    this.props.loadSalaryType();
    this.props.loadSectors();
    this.props.loadLanguages();
  }

  doSubmit = () => {
    // //console.log("Submitted")
    const dataX = { ...this.state.data };
    let data = new FormData();
    Object.keys(dataX).forEach((key) => {
      if (key === "sector") {
        //console.log(
        //   JSON.stringify(dataX[key]),
        //   "<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<"
        // );
        data.append(key, JSON.stringify(dataX[key]));
      } else if (key === "talent_language") {
        data.append(key, JSON.stringify(dataX[key]));
      } else if (key == "field_of_study") {
        data.append(key, JSON.stringify(dataX[key]));
      } else {
        data.append(key, dataX[key]);
      }
    });
    let image = document.getElementById("profilePicture").files[0];
    if (image != undefined) {
      // //console.log(image,"<<<<<<<<<<<<<<<<<<<<<<<<<<<")
      // //console.log(image, "<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<")
      data.append("image", image);
    }
    let cv = document.getElementById("cv").files[0];
    if (cv) {
      // //console.log(cv, "<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<")
      data.append("uploaded_cv", cv);
    }
    // //console.log(data, "<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<")

    this.props.updateTalentInformation(data);

    // this.props.updateTalentInformation(this.state.data);
  };

  getDate = (year) => {
    let date = new Date();
    let currentYear = date.getFullYear();
    let diff = currentYear - year;
    let month = date.getMonth();
    let day = date.getDate();
    let newDate = new Date(diff, month, day);
    return newDate;
  };

  schema = {
    first_name: Joi.string().required().label("First Name"),
    last_name: Joi.string().required().label("Last Name"),
    title: Joi.string().required().label("Title"),
    gender: Joi.string().required().label("Gender"),
    region: Joi.number().required().label("Region"),
    talent_language: Joi.array().items().min(1).required().label("Language"),
    tempLanguage: Joi.number().optional().label("temp"),
    dob: Joi.date().required().max(this.getDate(16)).label("Date of Birth"),
    phone_number: Joi.string().required().label("Phone"),
    city: Joi.string().required().label("City"),
    address: Joi.string().required().label("Address"),
    sector: Joi.array().required().label("Sectors"),
    current_company: Joi.string().allow("").optional().label("Current Company"),
    current_position: Joi.string()
      .allow("")
      .optional()
      .label("Current Position"),
    current_salary: Joi.number()
      .allow("")
      .min(0)
      .optional()
      .label("Current Salary"),
    // profilePicture: Joi.string().required().label("Profile Picture"),
    // cv: Joi.uniqueFilename().required().label("CV"),
    education_level: Joi.number().required().label("Education Level"),
    experience_level: Joi.number().required().label("Experience Level"),
    experience_in_years: Joi.number().required().label("Experience in Years"),
    experience_in_months: Joi.number()
      .max(12)
      .min(0)
      .required()
      .label("Experience in Months"),

    country: Joi.number().required().label("Country"),
    field_of_study: Joi.array()
      .items()
      .min(1)
      .required()
      .label("Field of studies"),
  };

  setLanguages = (state, id = 0) => {
    // let data = { ...this.state.data }
    if (id === 0) {
      let languageState = { ...this.state.languageState };

      languageState = { reading: 0, writing: 0, speaking: 0, listening: 0 };
      this.setState({
        languageState,
        edit: 0,
      });
    } else {
      this.setState({ edit: 1, editIndex: id - 1 });
    }

    this.setState({ languages: state, languageId: id });
  };

  renderOptions = (item, Id) => {
    let id = "";
    if (item) {
      switch (Id) {
        case 1:
          id = item.speaking;
          break;
        case 2:
          id = item.listening;
          break;
        case 3:
          id = item.reading;
          break;
        case 4:
          id = item.writing;
        default:
          break;
      }
    }

    {
      return [0, 1, 2, 3, 4].map((item, index) => {
        if (id && id == item) {
          // //console.log("id", id)
          return (
            <option key={index} value={item} selected>
              {languagesConstant[item]}
            </option>
          );
        } else {
          return (
            <option key={index} value={item}>
              {languagesConstant[item]}
            </option>
          );
        }
      });
    }
  };

  renderLanguage = () => {
    const scale = [1, 2, 3, 4];
    const { languages } = this.state;
    //console.log(this.state.data);
    return (
      <div id="language_bx" className="table col-md-12">
        <hr />

        <div className="d-flex">
          <h5 className="m-b15">Languages</h5>
          <Link
            to={"#"}
            onClick={() => this.setLanguages(true)}
            className="site-button add-btn button-sm"
          >
            <i className="fa fa-plus m-r5"></i> Add Language
          </Link>
        </div>
        <p>Mention the Languages You Speak</p>

        <table>
          <thead>
            <tr>
              <th>Language</th>
              <th>Speaking</th>
              <th>Listening</th>
              <th>Reading</th>
              <th>Writing</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.talent_language.map((item, index) => {
              return (
                <tr key={index}>
                  <td>
                    {this.props.getLanguagebyName(parseInt(item.language))}
                  </td>
                  <td>{languagesConstant[item.speaking]}</td>
                  <td>{languagesConstant[item.listening]}</td>
                  <td>{languagesConstant[item.reading]}</td>
                  <td>{languagesConstant[item.writing]}</td>

                  <td>
                    <Link
                      to={"#"}
                      className="m-l15 text-primary font-14"
                      data-toggle="modal"
                      data-target="#languages"
                      onClick={() => {
                        let language = {};
                        this.state.data.talent_language.filter(
                          (item_, index) => {
                            if (item_.language == item.language) {
                              language = item_;
                            }
                          }
                        );

                        // language = this.state.data.talent_language.find((element) => element.language == item.language)                                    // this.setState({ language: language, index: index })

                        const languageState = {
                          speaking: language.speaking,
                          reading: language.reading,
                          writing: language.writing,
                          listening: language.listening,
                        };

                        let data = { ...this.state.data };

                        data.tempLanguage = item.language;

                        this.setState({
                          languageState,
                          data: {
                            ...this.state.data,
                            tempLanguage: item.language,
                          },
                        });

                        this.setLanguages(true, index + 1);
                      }}
                    >
                      <i className="fa fa-pencil"></i>
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={"#"}
                      className="m-l15 text-danger font-14"
                      data-toggle="modal"
                      data-target="#languages"
                      onClick={() => {
                        let language = this.state.data.talent_language;

                        // remove item at index from array
                        let languages = language.splice(index, 1);
                        this.setState({
                          data: {
                            ...this.state.data,
                            talent_language: language,
                          },
                        });
                      }}
                    >
                      <i className="fa fa-trash"></i>
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {this.state.errors.talent_language && (
          <div className="alert alert-danger fs-5">
            {this.state.errors.talent_language}
          </div>
        )}

        <Modal
          className="modal fade modal-bx-info editor"
          show={languages}
          onHide={this.setLanguages}
        >
          <div className="modal-dialog my-0" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Language</h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => this.setLanguages(false)}
                >
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      {/* {this.renderInput("language", "Language", "text")} */}
                      {this.renderSelect(
                        this.props.languages.data,
                        "tempLanguage",
                        "Language"
                      )}
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>Speaking</label>
                        <BootStrapForm.Control name="speaking" as="select">
                          {this.renderOptions(this.state.languageState, 1)}
                        </BootStrapForm.Control>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>Listening</label>
                        <BootStrapForm.Control name="listening" as="select">
                          {this.renderOptions(this.state.languageState, 2)}
                        </BootStrapForm.Control>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>Reading</label>
                        <BootStrapForm.Control name="reading" as="select">
                          {this.renderOptions(this.state.languageState, 3)}
                        </BootStrapForm.Control>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>Writing</label>
                        <BootStrapForm.Control name="writing" as="select">
                          {this.renderOptions(this.state.languageState, 4)}
                        </BootStrapForm.Control>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="site-button"
                  onClick={() => this.setLanguages(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="site-button"
                  onClick={() => {
                    let languages = [...this.state.data.talent_language];
                    let flag = false;
                    languages.forEach((item, index) => {
                      if (
                        document
                          .getElementById("tempLanguage")
                          .value.toString() === item.language.toString()
                      ) {
                        flag = true;
                      }
                    });
                    if (flag && this.state.edit == 0) {
                      toast.error("Selected Language Already Exists,", {
                        position: toast.POSITION.TOP_RIGHT,
                        draggable: true,
                        hideProgressBar: true,
                      });
                    } else if (this.state.data.tempLanguage == 0) {
                      toast.error("Please Select Language,", {
                        position: toast.POSITION.TOP_RIGHT,
                        draggable: true,
                        hideProgressBar: true,
                      });
                    } else {
                      let language = {};
                      language.language =
                        document.getElementById("tempLanguage").value;
                      language.speaking =
                        document.getElementsByName("speaking")[0].value;
                      language.listening =
                        document.getElementsByName("listening")[0].value;
                      language.reading =
                        document.getElementsByName("reading")[0].value;
                      language.writing =
                        document.getElementsByName("writing")[0].value;
                      // alert(1)
                      if (this.state.edit == 1) {
                        languages[this.state.editIndex] = language;
                      } else {
                        languages.push(language);
                      }
                      this.setState({
                        data: {
                          ...this.state.data,
                          talent_language: languages,
                        },
                      });

                      this.setLanguages(false);
                    }
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <hr />
      </div>
    );
  };

  handleProfileChange = (e) => {
    let image = e.target.files[0];
    if (image.size / (1024 * 1024) > 10) {
      let errors = { ...this.state.errors };
      errors.profilePicture = "Image size should be less than 10MB";
      this.setState({ errors });
    } else if (image.type !== "image/jpeg" && image.type !== "image/png") {
      let errors = { ...this.state.errors };
      errors.profilePicture = "Image type should be jpeg or png";
      this.setState({ errors });
    } else {
      let errors = { ...this.state.errors };
      errors.profilePicture = "";
      this.setState({ errors });

      var reader = new FileReader();
      reader.onload = function () {
        var output = document.getElementById("output");
        output.src = reader.result;
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  handleFileChange = (e) => {
    let file = e.target.files[0];
    const size = file.size;
    if (size / (1024 * 1024) > 10) {
      let errors = this.state.errors;
      errors["cv"] = "File Size Must Be Less Than 10MB";
      this.setState({ errors });
      e.target.value = null;
    } else if (file.type !== "application/pdf") {
      let errors = this.state.errors;
      errors["cv"] = "File Type Must Be PDF";
      this.setState({ errors });
      e.target.value = null;
    } else {
      let errors = this.state.errors;
      errors["cv"] = "";
      this.setState({ errors, cv: file });
    }

    //console.log(e.target.files[0]);
    // this.setState({})
  };

  renderPage = () => {
    const talent = this.props.talent;
    if (talent.loading || !talent.data.user || this.props.languages.loading) {
      return <Loading />;
    } else {
      if (this.state.loaded == 0) {
        const talent = this.props.talent;
        if (talent.reload === 1) {
          window.location.reload();
        }
        let propData = talent.data;
        let newData = { ...propData, ...propData.user };
        let data = { ...this.state.data };
        Object.keys(this.state.data).forEach((key) => {
          data[key] = newData[key];
        });
        data["tempLanguage"] = 0;
        data["talent_language"] = [...propData.talent_language];
        let selectedSectors = [];
        let selectedFieldOfStudy = [];

        this.props.talent.data["sector"].forEach((element) => {
          selectedSectors.push({
            id: element,
            name: this.props.getSectorByName(element),
          });
        });

        this.props.talent.data["field_of_study"].forEach((element) => {
          selectedFieldOfStudy.push({
            id: element,
            name: this.props.getFieldOfStudyName(element),
          });
        });


        this.setState({ data: { ...data }, loaded: 1,selectedSectors,selectedFieldOfStudy });
      }

      return (
        <>
          <div className="col-xl-9 col-lg-8 m-b30">
            <div className="job-bx job-profile">
              <div className="job-bx-title clearfix">
                <h5 className="font-weight-700 pull-left text-uppercase">
                  Basic Information
                </h5>
                {/* <button
                  className="site-button button-sm float-right"
                  onClick={() => {
                    const talent = this.props.talent;
                    const propData = talent.data;
                    let data = { ...propData, ...propData.user };
                    // //console.log(talent, "Zdata")

                    this.setState({ data });
                  }}
                >
                  Can't See your information?
                </button> */}
              </div>
              <form onSubmit={this.handleSubmit}>
                <div className="row m-b30">
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <label>
                        <i className="fa fa-image"></i> Upload Profile Picture
                      </label>
                      <input
                        type="file"
                        className="site-button form-control"
                        id="profilePicture"
                        onChange={this.handleProfileChange}
                      />
                      {this.state.errors["profilePicture"] && (
                        <div className="text-danger">
                          {this.state.errors["profilePicture"]}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <img
                      style={{
                        objectFit: "contain",
                        height: "200px",
                        width: "200px",
                        padding: "0px",
                        borderRadius: "10px",
                        boxShadow: "#18181847 -7px 13px 20px 3px",
                        float: "right",
                      }}
                      id="output"
                      src={apiUrl + this.props.authData.data.image}
                    />
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <label>
                        <i className="fa fa-upload"></i>
                        Upload Resume File size {"<"} 10 MB
                      </label>
                      {this.props.talent.data.uploaded_cv && (
                        <React.Fragment>
                          <br />
                          <a
                            href={`${
                              apiUrl + this.props.talent.data.uploaded_cv
                            }`}
                            target="_blank"
                          >
                            {
                              this.props.talent.data.uploaded_cv.split(
                                "job_seeker_cv/"
                              )[1]
                            }
                          </a>
                          <br />
                        </React.Fragment>
                      )}

                      <input
                        type="file"
                        className="form-control"
                        id="cv"
                        onChange={this.handleFileChange}
                      />

                      {this.state.errors["cv"] && (
                        <div className="text-danger">
                          {this.state.errors["cv"]}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    {this.renderSelect(
                      [
                        { id: "Mr", name: "Mr" },
                        { id: "Mrs", name: "Mrs" },
                        { id: "Ms", name: "Ms" },
                        { id: "Dr", name: "Dr" },
                        { id: "Prof", name: "Prof" },
                      ],
                      "title",
                      "Title*"
                    )}
                  </div>
                  <div className="col-lg-6 col-md-6">
                    {this.renderInput("first_name", "Your First Name:")}
                  </div>
                  <div className="col-lg-6 col-md-6">
                    {this.renderInput("last_name", "Your Last Name:")}
                  </div>
                  <div className="col-lg-6 col-md-6">
                    {this.renderSelect(
                      [
                        { id: "M", name: "Male" },
                        { id: "F", name: "Female" },
                      ],
                      "gender",
                      "Gender"
                    )}
                  </div>
                  <div className="col-lg-6 col-md-6">
                    {this.renderInput("dob", "Date of Birth", "date")}
                  </div>
                  <div className="col-lg-6 col-md-6">
                    {this.renderInput(
                      "current_salary",
                      "Current Salary($):",
                      "number"
                    )}
                  </div>
                  <div className="col-lg-6 col-md-6">
                    {this.renderInput("current_company", "Current Company")}
                  </div>
                  <div className="col-lg-12 col-md-12">
                    {this.renderLanguage()}
                  </div>

                  <div className="col-lg-6 col-md-6">
                    {this.renderMultiSelect(
                      this.props.fieldOfStudies.data,
                      "field_of_study",
                      "Field of studies*",
                      4,
                      this.state.selectedFieldOfStudy
                    )}
                  </div>
                  <div className="col-lg-6 col-md-6">
                    {this.renderMultiSelect(
                      this.props.sectors.data,
                      "sector",
                      "Sector",
                      4,
                      this.state.selectedSectors
                    )}
                  </div>
                  <div className="col-lg-6 col-md-6">
                    {this.renderInput("current_position", "Current Position")}
                  </div>

                  <div className="col-lg-6 col-md-6">
                    {this.renderSelect(
                      this.props.educationLevels.data,
                      "education_level",
                      "Education Level"
                    )}
                  </div>
                  <div className="col-lg-6 col-md-6">
                    {this.renderSelect(
                      this.props.experienceLevels.data,
                      "experience_level",
                      "Experience Level"
                    )}
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        {this.renderInput(
                          "experience_in_years",
                          "Experience in Years",
                          "number"
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6">
                        {this.renderInput(
                          "experience_in_months",
                          "Experience in Months",
                          "number"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="job-bx-title clearfix">
                  <h5 className="font-weight-700 pull-left text-uppercase">
                    Contact Information
                  </h5>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    {this.renderInput("phone_number", "Phone")}
                  </div>
                  <div className="col-lg-6 col-md-6">
                    {this.renderSelect(
                      this.props.countries.data,
                      "country",
                      "Country of residence"
                    )}
                  </div>
                  <div className="col-lg-6 col-md-6">
                    {this.renderSelect(
                      this.props.regions.data,
                      "region",
                      "Region"
                    )}
                  </div>
                  <div className="col-lg-6 col-md-6">
                    {this.renderInput("city", "City")}
                  </div>
                  <div className="col-lg-12 col-md-12">
                    {this.renderInput("address", "Address")}
                  </div>
                </div>

                <button className="site-button m-b30">Save</button>
              </form>
            </div>
          </div>
        </>
      );
    }
  };
  render() {
    return (
      <>
        <Header />
        <div className="page-content bg-white">
          <div className="content-block">
            <div className="section-full bg-white browse-job p-t50 p-b20">
              <div className="container">
                <div className="row">
                  <Profilesidebar currentPage={"Profile"} />
                  {this.renderPage()}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  talent: state.entities.talent,
  sectors: state.entities.sector,
  countries: state.entities.country,
  experienceLevels: state.entities.experienceLevel,
  educationLevels: state.entities.educationLevel,
  fieldOfStudies: state.entities.fieldOfStudy,
  regions: state.entities.region,
  authData: state.auth.auth,
  languages: state.entities.language,
});
const mapDispatchToProps = (dispatch) => ({
  loadTalentInformation: () => dispatch(loadTalentInformation()),
  updateTalentInformation: (data) => dispatch(updateTalentInformation(data)),
  loadSectors: () => dispatch(loadSectors()),
  loadCountries: () => dispatch(loadCountries()),
  loadCurrency: () => dispatch(loadCurrency()),
  loadEducationLevels: () => dispatch(loadEducationLevels()),
  loadEmploymentTypes: () => dispatch(loadEmploymentTypes()),
  loadExperienceLevels: () => dispatch(loadExperienceLevels()),
  loadFieldOfStudies: () => dispatch(loadFieldOfStudies()),
  loadRegions: () => dispatch(loadRegions()),
  loadSalaryType: () => dispatch(loadSalaryType()),
  loadLanguages: () => dispatch(loadlanguages()),
  getLanguagebyName: (id) => dispatch(getLanguagebyName(id)),
  getLanguages: (id) => dispatch(loadLanguageById(id)),
  getSectorByName: (id) => dispatch(getSectorByName(id)),
  getFieldOfStudyName: (id) => dispatch(getFieldOfStudyName(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobProfile);
// export default JobProfile;
