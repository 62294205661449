import React, { Suspense, useEffect } from "react";
import { Route, Switch } from "react-router-dom";

import Homepage2 from "./Pages/Homepage2";

import JobProfile from "./Pages/Jobprofile";
import Jobmyresume from "./Pages/Jobmyresume";
import Jobsappliedjob from "./Pages/Jobsappliedjob";

import Changepasswordpage from "./Pages/Changepasswordpage";

import Companyprofile from "./Pages/Companyprofile";
import CompanyResume from "./Pages/Companyresume";
import Companypostjobs from "./Pages/Companypostjobs";
import Companymanage from "./Pages/Companymanage";

import Aboutus from "./Pages/aboutUs";
import Contact from "./Pages/Contact";
import Jobdetail from "./Pages/Jobdetail";

import Error404 from "./Pages/Error404";

import ScrollToTop from "./Element/ScrollToTop";
import CompanyDashboard from "./Pages/companyDashboard";

import CVPage from "./Pages/cv";
import CompanyUsers from "./Pages/companyUsers";
import CompanyUsersForm from "./Pages/companyUsersForm";
import CompanyPackageManage from "./Pages/CompanyPackageManage";
import auth from "../services/authServices";
import ReactGA from "react-ga";
import Browsejobfilterlist from "./Pages/Browsejobfilterlist";
// import Companies from "./Pages/companies";
import BrowseCompanyFilterList from "./Pages/companyFilter";
import AdminCompanyManage from "./Pages/AdminCompanyManage";
import AdminCompanyResume from "./Pages/AdminCompanyResume ";
import AdminDashboard from "./Pages/AdminDashboard";
import companyBuyPackage from "./Pages/companyBuyPackage";

const Markup = () => {
  const TRACKING_ID = "UA-205453516-1"; // OUR_TRACKING_ID

  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const user = auth.getCurrentUser();

  const getLists = () => {
    if (user.userProfile == "jobSeeker") {
      return (
        <Switch>
          <Route path="/" exact component={Homepage2} />
          {/* <Route path='/home' exact component={Homepage} /> */}
          {/* <Route path="/index-2" exact component={Homepage2} /> */}

          <Route path="/jobs-profile" exact component={JobProfile} />
          <Route path="/jobs-my-resume" exact component={Jobmyresume} />
          <Route path="/cv" exact component={CVPage} />
          <Route path="/jobs-applied-job" exact component={Jobsappliedjob} />
          {/* <Route path="/jobs-alerts" exact component={Jobsalert} /> */}
          {/* <Route path="/jobs-saved-jobs" exact component={Jobsavedjobs} /> */}
          {/* <Route path="/jobs-cv-manager" exact component={Jobcvmanager} /> */}
          <Route
            path="/jobs-change-password"
            exact
            component={Changepasswordpage}
          />
          {/* <Route path="/about-us" exact component={Aboutus} /> */}
          <Route path="/job-detail/:id" exact component={Jobdetail} />
          <Route path="/job-detail/" exact component={Jobdetail} />
          {/* <Route path='/companies' exact component={Companies} /> */}
          {/* <Route path='/free-job-alerts' exact component={Freejobalerts} /> */}
          {/* <Route path="/browse-job-list" exact component={Browsejoblist} /> */}
          {/* <Route path='/browse-job-grid' exact component={Browsejobgrid} /> */}
          <Route
            path="/browse-job-filter-list"
            exact
            component={Browsejobfilterlist}
          />
          <Route path="/about-us" exact component={Aboutus} />
          <Route path="/contact" exact component={Contact} />

          {/* <Route path='/browse-job-filter-grid' exact component={Browsejobfiltergrid} /> */}
          <Route path="*" exact component={Error404} />
        </Switch>
      );
    } else if (user.userProfile == "company") {
      return (
        <Switch>
          <Route path="/" exact component={Companymanage} />

          <Route path="/company-dashboard" exact component={CompanyDashboard} />
          <Route path="/company-profile" exact component={Companyprofile} />
          <Route path="/company-resume" exact component={CompanyResume} />
          <Route path="/company-resume/:id" exact component={CompanyResume} />
          <Route path="/company-post-jobs" exact component={Companypostjobs} />
          <Route
            path="/company-post-jobs/:id"
            exact
            component={Companypostjobs}
          />
          <Route path="/company-manage-job" exact component={Companymanage} />
          {/* <Route path='/company-transactions' exact component={Companytransactions} /> */}
          <Route
            path="/company-packages"
            exact
            component={CompanyPackageManage}
          />
          <Route path="/company-users" exact component={CompanyUsers} />
          <Route
            path="/company-users-form/:id"
            exact
            component={CompanyUsersForm}
          />
          {/* <Route path="/browse-candidates" exact component={Browsecandidates} /> */}

          {/* <Route path="/about-us" exact component={Aboutus} /> */}
          <Route path="/job-detail/:id" exact component={Jobdetail} />
          <Route path="/job-detail/" exact component={Jobdetail} />
          <Route path="/purchase-job-package/" exact component={companyBuyPackage} />
          
          {/* <Route path='/companies' exact component={Companies} /> */}
          {/* <Route path='/free-job-alerts' exact component={Freejobalerts} /> */}
          {/* <Route path="/browse-job-list" exact component={Browsejoblist} /> */}
          {/* <Route path='/browse-job-grid' exact component={Browsejobgrid} /> */}
          <Route
            path="/browse-job-filter-list"
            exact
            component={Browsejobfilterlist}
          />

          <Route
            path="/jobs-change-password"
            exact
            component={Changepasswordpage}
          />
          <Route path="/about-us" exact component={Aboutus} />
          <Route path="/contact" exact component={Contact} />

          <Route path="*" exact component={Error404} />
        </Switch>
      );
    } else if (user.userProfile == "admin") {
      // alert(1)
      return (
        <Switch>
          <Route path="/" exact component={AdminDashboard} />
          <Route path="/companies" exact component={BrowseCompanyFilterList} />
          {/* <Route path="/company-dashboard" exact component={CompanyDashboard} /> */}
          <Route path="/company-resume" exact component={AdminCompanyResume} />
          <Route
            path="/company-resume/:id"
            exact
            component={AdminCompanyResume}
          />
          <Route
            path="/company-manage-job/:id"
            exact
            component={AdminCompanyManage}
          />
          <Route path="/job-detail/:id" exact component={Jobdetail} />
          <Route path="/job-detail/" exact component={Jobdetail} />
          <Route
            path="/jobs-change-password"
            exact
            component={Changepasswordpage}
          />
          <Route path="/about-us" exact component={Aboutus} />
          <Route path="/contact" exact component={Contact} />

          <Route path="*" exact component={Error404} />
        </Switch>
      );
    }
  };

  return (
    <>
      <Suspense>
        <div className="page-wraper">{getLists()}</div>
      </Suspense>
      <ScrollToTop />
    </>
  );
};

export default Markup;
