import {createSlice} from '@reduxjs/toolkit';
import {ApiCallBegan} from '../actions/api';
import checkCache from '../../services/cache';
import {coreCacheLimit,getCompaniesUrl} from '../../config.json';
import {getCurrentPage} from '../../utils/utilFunctions';
import {toast} from 'react-toastify';
import _ from 'lodash';

const pagination = 20;

const slice = createSlice({
    name: 'companies',
    initialState: {
        data: [],
        count: 0,
        history: [],
        currentPage: 0,
        searchQuery:"",
        filterQuery:"",
        pageCount: 1,
        next: null,
        previous: null,
        lastFetch: null,
        loading: true,
    },
    reducers: {
        companiesFetched: (state, action) => {
            let newState = [];
            let newHistory = [];
            let data = action.payload.data;
            let pageCount = Math.ceil(data.count / pagination);
            if (state.data.length == 0) {
                newState = _.fill(Array(pageCount), []);
                newHistory = _.fill(Array(pageCount), {
                    next: null,
                    previous: null,
                    loaded: false,
                });
            } else {
                newState = state.data;
                newHistory = state.history;
            }

            let currentPage = getCurrentPage(action.payload.data.previous);

            newState[currentPage] = data.results;
            newHistory[currentPage] = {
                next: data.next,
                previous: data.previous,
                loaded: true,
            };
            state.history = newHistory;
            state.data = newState;
            state.count = data.count;
            state.currentPage = currentPage;
            state.pageCount = pageCount;
            state.next = data.next;
            state.previous = data.previous;
            state.lastFetch = Date.now();
            state.loading = false;
        },
        companyFetched: (state, action) => {
            state.user = action.payload.data;
        },
        companyRequested: (state, action) => {
            state.loading = true;
        },
        companyRequestFailed: (state, action) => {
            state.loading = false;
        },
        setUrl: (state, action) => {
            // state.filterQuery = action.payload.filter
            state.searchQuery = action.payload.search
            // state.data=[]
            return state;
        },





        
    }
});

export const {
    companiesFetched,
    companyFetched,
    companyRequested,
    companyRequestFailed,
    setUrl
} = slice.actions;
export default slice.reducer;

export const loadCompanies = (baseUrl=getCompaniesUrl, searchparameter="") => (dispatch, getState) => {
    const {lastFetch, searchQuery} = getState().entities.companies;
    let search = searchparameter!=="" ? searchparameter : searchQuery
   
    if (searchparameter == "0|0") {
        search = ""
    }
    let dilimiter = "?"
    if (baseUrl.includes("?")) {
        dilimiter = "&"
    }
    const pageUrl = baseUrl.split("&")[0] + dilimiter+"search=" + search+"&"

    dispatch({ type: setUrl.type, payload: {search: search } })


    
    return dispatch(
        ApiCallBegan({
            url: pageUrl,
            onStart: companyRequested.type,
            onSuccess: companiesFetched.type,
            onError: companyRequestFailed.type,
        })
    );
}
