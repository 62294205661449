import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="site-footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-4 col-md-12 col-sm-12">
              <div className="widget">
                <img
                  src={require("./../../images/logo-black.png")}
                  width="160"
                  className="m-b15"
                  alt=""
                />
                <p className=" m-b20">
                  Ethiotalent is the best online recruitment solution provider
                  in Ethiopia. The website advertises jobs across a wide range
                  of job types by different employers, including private, local,
                  international, multinational, who are hiring in Ethiopia.
                </p>
                <div className="subscribe-form m-b20">
                  <form
                    className="dzSubscribe"
                    action="script/mailchamp.php"
                    method="post"
                  >
                    {/* <div className="dzSubscribeMsg"></div> */}
                    {/* <div className="input-group">
                      <input
                        name="dzEmail"
                        required="required"
                        className="form-control"
                        placeholder="Your Email Address"
                        type="email"
                      />
                      <span className="input-group-btn">
                        <button
                          name="submit"
                          value="Submit"
                          type="submit"
                          className="site-button radius-xl"
                        >
                          Subscribe
                        </button>
                      </span>
                    </div> */}
                  </form>
                </div>
                <ul className="list-inline m-a0">
                  <li>
                    <Link
                      to={""}
                      className="site-button white google-plus circle "
                    >
                      <i className="fa fa-google-plus"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={""}
                      className="site-button white facebook circle "
                    >
                      <i className="fa fa-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={""}
                      className="site-button white linkedin circle "
                    >
                      <i className="fa fa-linkedin"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={""}
                      className="site-button white instagram circle "
                    >
                      <i className="fa fa-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to={""} className="site-button white twitter circle ">
                      <i className="fa fa-twitter"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-8 col-sm-8 col-12">
              <div className="widget border-0">
                {/* <h5 className="m-b30 text-white">Frequently Asked Questions</h5> */}
                <ul className="list-1 list-line">
                  <li>
                    <Link to={""}>ABH Partners PLC.</Link>
                  </li>
                  <li>
                    <Link to={""}>Ethiopia, Addis Ababa, Bole Road</Link>
                  </li>
                  <li>
                    <Link to={""}>Behind DH Geda Tower</Link>
                  </li>
                  <li>
                    <Link to={""}>Telephone: +251-11-6-186520</Link>
                  </li>
                  <li>
                    <Link to={""}>FAX: +251-11-6-186528</Link>
                  </li>
                  <li>
                    <Link to={""}>E-mail: abh@abhpartners.com</Link>
                  </li>
                  
                </ul>
              </div>
            </div>
            {/* <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="widget border-0">
								<h5 className="m-b30 text-white">Find Jobs</h5>
								<ul className="list-2 w10 list-line">
									<li><Link to={''}>US Jobs</Link></li>
									<li><Link to={''}>Canada Jobs</Link></li>
									<li><Link to={''}>UK Jobs</Link></li>
									<li><Link to={''}>Emplois en Fnce</Link></li>
									<li><Link to={''}>Jobs in Deuts</Link></li>
									<li><Link to={''}>Vacatures China</Link></li>
								</ul>
							</div>
						</div> */}
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <span>
                {" "}
                © Copyright by{" "}
                <i className="fa fa-circle m-lr5 text-green circle"></i>
                <Link to={""}>ABH Partners</Link> All rights
                reserved.
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
