import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import PageTitle from "../Layout/PageTitle";
import Jobfindbox from "../Element/Jobfindbox";
import Accordsidebar from "../Element/Accordsidebar";
import auth from "../../services/authServices";
import { getOpenJobs } from "../../store/reducers/openJobs";
import { getCompaniesUrl } from "../../config.json";
import "../../css/text.css";
import { getSalary } from "../../utils/utilFunctions";
import { loadCompanies } from "../../store/reducers/companies"; 
import Companyfindbox from "../Element/Companyfindbox ";

//Images
// import logo from "./../../images/logo/icon1.png";
var bnr = require("./../../images/main-slider/slide2.png");

function BrowseCompanyFilterList() {
  const user = auth.getCurrentUser();
  const dispatch = useDispatch();
  const jobs = useSelector((state) => state.entities.openJobs);
  const companies = useSelector((state) => state.entities.companies);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(loadCompanies());
  }, []);
  return (
    <>
      <Header />
      <div className="page-content bg-white">
        <div
          className="dez-bnr-inr overlay-black-middle"
          style={{ backgroundImage: "url(" + bnr + ")" }}
        >
          <PageTitle motherName="Home" activeName="Companies" />
        </div>
        <Companyfindbox />
        <div className="content-block">
          <div className="section-full browse-job p-b50">
            <div className="container">
              <div className="row">
                {/* <Accordsidebar /> */}
                <div className="col-xl-9 col-lg-8 col-md-7 site-filters clearfix center  m-b40">
                  {/* <div className=""> */}
                  <div className="job-bx-title clearfix">
                    <h5 className="font-weight-700 pull-left text-uppercase">
                      {companies.count} Companies Found
                    </h5>
                  </div>
                  <ul
                    id="masonry"
                    className="dez-gallery-listing gallery-grid-4 gallery mfp-gallery col-xl-12 col-lg-12 col-md-12"
                  >
                    {companies.data.map((section) => {
                      return section.map((company, index) => {
                        return (
                          <li
                            className="web card-container col-lg-4 col-md-4 col-sm-4 p-1"
                            key={index}
                          >
                          <Link to={`company-manage-job/${company.id}/`}>
                              <div className="dez-gallery-box">
                                <div className="dez-media overlay-black-light">
                                  <img
                                    style={{

                                      objectFit: "contain",
                                    }}
                                    src={company.logo}
                                    alt=""
                                  />
                                  <div className="overlay-icon overlay-logo"></div>
                                </div>
                              </div>
                              <p
                                style={{
                                  color: "black",
                                  fontFamily: "Montserrat",
                                  fontSize: "15px",
                                }}
                              >
                                {company.company_name}
                              </p>
                              <p
                                style={{
                                  color: "black",
                                  fontFamily: "Montserrat",
                                  fontSize: "15px",
                                }}
                              >
                                Remaining Job Posts: ({company.remaining_jobs})
                              </p>
                              
                            </Link>
                          </li>
                        );
                      });
                    })}
                  </ul>
                  <div className="m-t30">
                    <div className="d-flex">
                      {companies.next && (
                        <Link
                          className="site-button button-sm mr-auto"
                          to={"#"}
                          onClick={() => {
                            //console.log(jobs.next);
                            let url = companies.next.split("?")[1];
                            dispatch(loadCompanies(`${getCompaniesUrl}?${url}`));
                          }}
                        >
                          <i className="ti-dot"></i> Load More
                        </Link>
                      )}

                      {/* <Link className="site-button button-sm" to={""}>Next <i className="ti-arrow-right"></i></Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default BrowseCompanyFilterList;
