import React from "react";
import { Link } from "react-router-dom";
// import GoogleMaps from "simple-react-google-maps";
import Joi from "joi-browser";

import Header from "./../Layout/Header";
import Footer from "./../Layout/Footer";
import CompanyProfileSideBar from "../Element/companyProfileSideBar";
import Form from "../Element/common/form";
import {
  loadCompanyProfile,
  sendCompanyProfile,
} from "../../store/reducers/companyProfile";
import { connect } from "react-redux";
import { loadSectors, getSectorByName } from "../../store/reducers/sector";
import { loadRegions } from "../../store/reducers/region";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { checkPermission } from "../../utils/utilFunctions";

import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

import "../../css/textEditor.css";
import { apiUrl } from "../../config.json";

class CompanyProfile extends Form {
  state = {
    loaded: 0,
    selectedSectors: [],
    data: {
      company_name: "",
      tin_number: "",
      vat_number: "",
      region: "",
      sector: "",
      city: "",
      description:"",
      address: "",
      phone_number: "",
      phone_number1: "",
      phone_number2: "",
      type: "",
      // logo:"",
      website: "",
      facebook: "",
      twitter: "",
      linkedin: "",
      google: "",
      // currentPackage:"",
    },
    description: EditorState.createEmpty(),
    errors: {},
  };
  componentDidMount() {
    const permission = checkPermission("view", "company");
    if (!permission) {
      this.props.history.push("/");
    }
    this.props.loadCompanyProfile();
    this.props.loadSectors();
    this.props.loadRegions();
  }
  schema = {
    company_name: Joi.string().required().label("Company Name"),
    tin_number: Joi.string().required().label("Tin Number"),
    vat_number: Joi.string().required().label("Vat Number"),
    region: Joi.number().required().label("Region"),
    sector: Joi.array().required().label("Sector"),
    city: Joi.string().required().label("City"),
    address: Joi.string().required().label("Address"),
    phone_number: Joi.string().required().label("Phone Number"),
    phone_number1: Joi.string().allow("").optional().label("Phone Number 1"),
    phone_number2: Joi.string().allow("").optional().label("Phone Number 2"),
    type: Joi.string().required().label("Type"),
    description: Joi.string().allow("").optional().label("Company Description"),
    // logo: Joi.string().required().label("Logo"),
    website: Joi.string().allow("").optional().label("Website"),
    facebook: Joi.string().allow("").optional().label("Facebook"),
    twitter: Joi.string().allow("").optional().label("Twitter"),
    linkedin: Joi.string().allow("").optional().label("Linked In"),
    google: Joi.string().allow("").optional().label("Google"),
    // currentPackage: Joi.string().required().label("Current Package"),
  };
  handleProfileChange = (e) => {
    let image = e.target.files[0];
    if (image.size / (1024 * 1024) > 10) {
      let errors = { ...this.state.errors };
      errors.profilePicture = "Image size should be less than 10MB";
      this.setState({ errors });
    } else if (image.type !== "image/jpeg" && image.type !== "image/png") {
      let errors = { ...this.state.errors };
      errors.profilePicture = "Image type should be jpeg or png";
      this.setState({ errors });
    } else {
      let errors = { ...this.state.errors };
      errors.profilePicture = "";
      this.setState({ errors });

      var reader = new FileReader();
      reader.onload = function () {
        var output = document.getElementById("output");
        output.src = reader.result;
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  doSubmit = () => {
    // let x = document.getElementById("description").innerHTML;
    // const { description } = this.state;
    // const description = stateToHTML(this.state.description.getCurrentContent())
    // let data = { ...this.state.data }
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
    const dataX = { ...this.state.data };
    let data = new FormData();
    Object.keys(dataX).forEach((key) => {
      if (key === "sector") {
        data.append(key, JSON.stringify(dataX[key]));
      } else {
        data.append(key, dataX[key]);
      }
    });
    let image = document.getElementById("profilePicture").files[0];
    if (image) {
      data.append("logo", image);
    }
    // data.description = description;
    // // data.id = this.props.companyProfile.id;
    // data.current_package = this.props.companyProfile.data.current_package;
    this.props.sendCompanyProfile(data);
  };

  renderPage = () => {
    if (
      this.props.sector.loading == true ||
      this.props.companyProfile.loading == true ||
      this.props.region.loading == true
    ) {
      return <div className="loading">Loading&#8230;</div>;
    } else {
      if (this.state.loaded == 0) {
        //////console.log(this.props.sector, "sector")
        let data = { ...this.state.data };
        Object.entries(data).forEach(([key, value]) => {
          data[key] = this.props.companyProfile.data[key];
        });

        let description = EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(this.props.companyProfile.data.description)
          )
        );

        let selectedSectors = [];

        this.props.companyProfile.data["sector"].forEach((element) => {
          selectedSectors.push({
            id: element,
            name: this.props.getSectorByName(element),
          });
        });

        this.setState({ loaded: 1, data, description, selectedSectors });
      } else {
        return (
          <div className="page-content bg-white">
            <div className="content-block">
              <div className="section-full bg-white p-t50 p-b20">
                <div className="container">
                  <div className="row">
                    <CompanyProfileSideBar currentPage={"Company Profile"} />

                    <div className="col-xl-9 col-lg-8 m-b30">
                      <div className="job-bx submit-resume">
                        <div className="job-bx-title clearfix">
                          <h5 className="font-weight-700 pull-left text-uppercase">
                            Company Profile
                          </h5>
                          {/* <Link to={"/company-profile"} className="site-button right-arrow button-sm float-right">Back</Link> */}
                        </div>
                        <form onSubmit={this.handleSubmit}>
                          <div className="row m-b30">
                            <div className="col-lg-6 col-md-6">
                              <div className="form-group">
                                <label>
                                  <i className="fa fa-image"></i> Upload Profile
                                  Picture
                                </label>
                                <input
                                  type="file"
                                  className="site-button form-control"
                                  id="profilePicture"
                                  onChange={this.handleProfileChange}
                                />
                                {this.state.errors["profilePicture"] && (
                                  <div className="text-danger">
                                    {this.state.errors["profilePicture"]}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <img
                                style={{
                                  objectFit: "contain",
                                  height: "200px",
                                  width: "200px",
                                  padding: "0px",
                                  borderRadius: "10px",
                                  boxShadow: "#18181847 -7px 13px 20px 3px",
                                  float: "right",
                                }}
                                id="output"
                                src={apiUrl + this.props.authData.data.logo}
                              />
                            </div>
                            <div className="col-lg-12 col-md-12">
                              {this.renderInput(
                                "company_name",
                                "Company Name",
                                "text"
                              )}
                            </div>
                            <div className="col-lg-6 col-md-6">
                              {this.renderInput(
                                "tin_number",
                                "Tin Number",
                                "text"
                              )}
                            </div>
                            <div className="col-lg-6 col-md-6">
                              {this.renderInput(
                                "vat_number",
                                "Vat Number",
                                "text"
                              )}
                            </div>
                            <div className="col-lg-12 col-md-12">
                              {this.renderMultiSelect(
                                this.props.sector.data,
                                "sector",
                                "Sector",
                                4,
                                this.state.selectedSectors
                              )}
                            </div>
                            <div className="col-lg-12 col-md-12">
                              <label className="col-form-label p-1">
                                Company Description
                              </label>
                              <SunEditor
                                setOptions={{
                                  height: 200,
                                  buttonList: buttonList.complex, // Or Array of button list, eg. [['font', 'align'], ['image']]
                                  // plugins: [font] set plugins, all plugins are set by default
                                  // Other option
                                }}
                                onChange={(content) => {
                                  let data = { ...this.state.data };
                                  data.description = content;
                                  this.setState({ data });
                                }}
                                setContents={this.props.companyProfile.data.description}
                              />
                              

                            </div>
                          </div>

                          <div className="job-bx-title clearfix">
                            <h5 className="font-weight-700 pull-left text-uppercase">
                              Contact Information
                            </h5>
                          </div>
                          <div className="row m-b30">
                            <div className="col-lg-6 col-md-6">
                              {this.renderInput(
                                "phone_number",
                                "Phone Number",
                                "text"
                              )}
                            </div>
                            <div className="col-lg-6 col-md-6">
                              {this.renderInput(
                                "phone_number1",
                                "Secondary Phone Number",
                                "text"
                              )}
                            </div>
                            <div className="col-lg-6 col-md-6">
                              {this.renderSelect(
                                this.props.region.data,
                                "region",
                                "Region"
                              )}
                            </div>
                            <div className="col-lg-6 col-md-6">
                              {this.renderInput("city", "City", "text")}
                            </div>
                            <div className="col-lg-6 col-md-6">
                              {this.renderInput("address", "Address", "text")}
                            </div>
                            <div className="col-lg-6 col-md-6">
                              {this.renderInput("website", "Website", "text")}
                            </div>

                                
                          </div>

                          <div className="job-bx-title clearfix">
                            <h5 className="font-weight-700 pull-left text-uppercase">
                              Social link
                            </h5>
                          </div>
                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              {this.renderInput("facebook", "Facebook", "text")}
                            </div>
                            <div className="col-lg-6 col-md-6">
                              {this.renderInput("twitter", "Twitter", "text")}
                            </div>
                            <div className="col-lg-6 col-md-6">
                              {this.renderInput("google", "Google", "text")}
                            </div>
                            <div className="col-lg-6 col-md-6">
                              {this.renderInput("linkedin", "Linkedin", "text")}
                            </div>
                          </div>
                          <button type="submit" className="site-button m-b30">
                            Update Setting
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  };

  render() {
    return (
      <>
        <Header />
        {this.renderPage()}

        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  companyProfile: state.entities.companyProfile,
  sector: state.entities.sector,
  region: state.entities.region,
  authData: state.auth.auth,
});
const mapDispatchToProps = (dispatch) => ({
  loadCompanyProfile: () => dispatch(loadCompanyProfile()),
  loadSectors: () => dispatch(loadSectors()),
  loadRegions: () => dispatch(loadRegions()),
  sendCompanyProfile: (data) => dispatch(sendCompanyProfile(data)),
  getSectorByName: (name) => dispatch(getSectorByName(name)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfile);

// export default Companyprofile;
