import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import talent, { loadTalentInformation } from '../../store/reducers/talent';
import Spinner from 'react-bootstrap/Spinner';
import { apiUrl } from '../../config';
import Loading from './common/loading';

const Profilesidebar = ({ currentPage }) => {
	const dispatch = useDispatch();
	const talent = useSelector(state => state.entities.talent)
	useEffect(() => {
		dispatch(loadTalentInformation());
		// //console.log(/)
		// //console.log(talent, "Atreyu")

	}, [])

	const Pages = [
		{
			name: "Home",
			link: "/",
			icon: "fa fa-home",
		},
		{
			name: "Profile",
			link: "/jobs-profile",
			icon: "fa fa-user",
		},
		{
			name: "My Resume",
			link: "/jobs-my-resume",
			icon: "fa fa-file-text",
		},
		// {
		// 	name: "Saved Jobs",
		// 	link: "/jobs-saved-jobs"
		// },
		{
			name: "Applied Jobs",
			link: "/jobs-applied-job",
			icon: "fa fa-briefcase",
		},
		// {
		// 	name: "Job Alerts",
		// 	link: "/jobs-alerts",
		// 	icon: "fa fa-bell",

		// },
		{
			name: "Change Password",
			link: "/jobs-change-password",
			icon: "fa fa-key",
		},
		{
			name: "Logout",
			link: "./",
			icon: "fa fa-sign-out",
		}

	];

	const renderElement = () => {
		if (talent.loading==true || !talent.data.user) {
			return (
				<div className="candidate-detail text-center">

					<div id="preloader">
						<div className="sk-three-bounce">
							<Loading />
							{/* <div className="sk-child sk-bounce1">Loading...</div> */}
							{/* <div className="sk-child sk-bounce2"></div> */}
							{/* <div className="sk-child sk-bounce3"></div> */}
						</div>
					</div>
				</div>)
		}
		else {
			// //console.log(talent.data.user,"<<<")
			return (<div className="candidate-info">


				<div className="candidate-detail text-center">
					<div className="canditates-des">
						<Link to={''}>
							<img style={{width:"150px",height:"150px", objectFit:"cover"}} alt="" src={apiUrl + talent.data.image} />
						</Link>
						{/* <div className="upload-link" title="update" data-toggle="tooltip" data-placement="right">
							<input type="file" className="update-flie" />
							<i className="fa fa-camera"></i>
						</div> */}
					</div>
					<div className="candidate-title">
						<div className="">
							<h4 className="m-b5"><Link to={"#"}>{(talent.data.user.first_name) + " " + (talent.data.user.last_name)}</Link></h4>
							{/* <p  className="m-b0"><Link to={"#"}>Web developer</Link></p> */}
						</div>
					</div>
				</div>
				<ul>
					{Pages.map((page, index) => {
						return (
							<li key={index}><Link to={page.link} className={currentPage == page.name ? "active" : ""}>
								<i className={page.icon} aria-hidden="true"></i>
								<span>{page.name}</span></Link></li>
						)

					})}
					{/* <li><Link to={"/jobs-profile"} className="active">
						<i className="fa fa-user-o" aria-hidden="true"></i>
						<span>Profile</span></Link></li>
					<li><Link to={"/jobs-my-resume"}>
						<i className="fa fa-file-text-o" aria-hidden="true"></i>
						<span>My Resume</span></Link></li>
					<li><Link to={"/jobs-saved-jobs"}>
						<i className="fa fa-heart-o" aria-hidden="true"></i>
						<span>Saved Jobs</span></Link></li>
					<li><Link to={"/jobs-applied-job"}>
						<i className="fa fa-briefcase" aria-hidden="true"></i>
						<span>Applied Jobs</span></Link></li>
					<li><Link to={"/jobs-alerts"}>
						<i className="fa fa-bell-o" aria-hidden="true"></i>
						<span>Job Alerts</span></Link></li>
					<li><Link to={"/jobs-cv-manager"}>
						<i className="fa fa-id-card-o" aria-hidden="true"></i>
						<span>CV Manager</span></Link></li>
					<li><Link to={"/jobs-change-password"}>
						<i className="fa fa-key" aria-hidden="true"></i>
						<span>Change Password</span></Link></li>
					<li><Link to={"./"}>
						<i className="fa fa-sign-out" aria-hidden="true"></i>
						<span>Log Out</span></Link>
					</li> */}
				</ul>
			</div>)
		}
	}

	return (
		<div className="col-xl-3 col-lg-4 m-b30">
			<div className="sticky-top">
				{renderElement()}
			</div>
		</div>
	)
}



export default Profilesidebar;