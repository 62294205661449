import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, withRouter } from 'react-router-dom';
import auth from '../../services/authServices';
import { authDataRemoved, loggedOut } from '../../store/reducers/auth';
// import { logout } from '../../store/actions/AuthActions';
// import { isAuthenticated } from '../../store/selectors/AuthSelectors';
import { toast } from 'react-toastify';

const LogoutPage = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const onLogout = () => {
        auth.logout();
        dispatch({ type: authDataRemoved.type, payload: {} })
        history.push("/")
        toast.success("Logged out successfully")
    }
    return (
        <>

            <Link to={'#'} title="READ MORE" className="dez-page" onClick={onLogout}>
                <i className="fa fa-lock"></i> Logout
            </Link>
        </>
    )
}
export default LogoutPage;


