import React from "react";
import Joi from "joi-browser";
import axios from "axios";

import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Form as BootStrapForm } from "react-bootstrap";
import _ from "lodash";

import Form from "../Element/common/form";

import { apiUrl, languagesConstant } from "../../config.json";
import auth from "../../services/authServices";
import { loadSectors } from "../../store/reducers/sector";
import { loadCurrency } from "../../store/reducers/currency";
import { loadCountries } from "../../store/reducers/country";
import { loadEmploymentTypes } from "../../store/reducers/employmentType";
import { loadEducationLevels } from "../../store/reducers/educationLevel";
import { loadFieldOfStudies } from "../../store/reducers/fieldOfStudy";
import { loadRegions } from "../../store/reducers/region";
import { loadSalaryType } from "../../store/reducers/salaryType";
import { loadExperienceLevels } from "../../store/reducers/experienceLevel";
import authServices from "../../services/authServices";
import {
  getLanguagebyName,
  loadlanguages,
} from "../../store/reducers/language";
import { Modal } from "react-bootstrap";
import { loadLanguageById } from "../../store/reducers/cv";

const apiEndpoint = apiUrl + "api/talent/jobseeker/";
const loginEndpoint = apiUrl + "api/core/auth/";
var bnr = require("./../../images/background/bg6.jpg");

class RegisterPage extends Form {
  state = {
    renderIndex: 0,
    // languageList: [
    //   { reading: 0, writing: 0, speaking: 0, listening: 0, language: 1 },
    // ],
    languageId: 0,

    languageState: { reading: 0, writing: 0, speaking: 0, listening: 0 },
    loading: false,
    data: {
      title: "",
      first_name: "",

      last_name: "",
      email: "",
      password: "",
      gender: "",
      region: "",
      dob: "",
      phone_number: "",

      city: "",
      address: "",
      sector: [],
      current_company: "",
      current_position: "",
      current_salary: 0,
      // profilePicture: "",
      // cv: "",
      education_level: "",
      experience_level: "",
      experience_in_years: "",
      experience_in_months: "",
      country: "",
      field_of_study: [],
      tempLanguage: 0,
      //   languages: [],
      tempLanguage: 0,
      talent_language: [],
    },
    errors: {},
    regions: [],
    sector: [],
    experienceLevels: [],
    educationLevels: [],
    countries: [],
    field_of_study: [],
  };
  routingFunction = (param) => {
    this.props.history.push({
      pathname: `/`,
      state: param,
    });
  };

  async componentDidMount() {
    this.props.loadCountries();
    this.props.loadCurrency();
    this.props.loadEducationLevels();
    this.props.loadFieldOfStudies();
    this.props.loadEmploymentTypes();
    this.props.loadExperienceLevels();
    this.props.loadRegions();
    this.props.loadSectors();
    this.props.loadLanguages();
    // //console.log(this.props)
    this.setState({ sector: this.props.sectors.data });
  }
  getDate = (year) => {
    // calculate diffrence of year by subtracting current year from given number
    // date = today

    let date = new Date();

    let currentYear = date.getFullYear();
    let diff = currentYear - year;
    let month = date.getMonth();
    let day = date.getDate();
    let newDate = new Date(diff, month, day);
    return newDate;
  };

  schema = {
    title: Joi.string().required().label("Title"),
    first_name: Joi.string().required().label("First Name"),
    last_name: Joi.string().required().label("Last Name"),
    email: Joi.string().required().email().label("Email"),
    password: Joi.string().required().min(8).label("Password"),
    gender: Joi.string().required().label("Gender"),
    region: Joi.string().required().label("Region"),
    talent_language: Joi.array().items().min(1).required().label("Language"),
    tempLanguage: Joi.number().optional().label("temp"),
    // dob: Joi.date().required().label("Date of Birth")messages,
    // dob must atleast be 18 years ago
    dob: Joi.date().required().max(this.getDate(16)).label("Date of Birth"),

    // languages   : Joi.array().items(Joi.object()).label("Languages"),
    phone_number: Joi.string().required().label("Phone"),
    city: Joi.string().required().label("City"),
    address: Joi.string().required().label("Address"),
    // sector: Joi.string().required().label("Sector"),
    sector: Joi.array().items().min(1).required().label("Sectors"),
    current_company: Joi.string().allow("").optional().label("Current Company"),
    current_position: Joi.string()
      .allow("")
      .optional()
      .label("Current Position"),
    current_salary: Joi.number()
      .allow("")
      .min(0)
      .optional()
      .label("Current Salary"),
    // profilePicture: Joi.string().required().label("Profile Picture"),
    // cv: Joi.uniqueFilename().required().label("CV"),
    experience_level: Joi.string().required().label("Experience Level"),
    experience_in_years: Joi.number().required().label("Experience in Years"),
    experience_in_months: Joi.number()
      .max(12)
      .min(0)
      .required()
      .label("Experience in Months"),
    country: Joi.string().required().label("Country"),
    education_level: Joi.string().required().label("Education Level"),
    field_of_study: Joi.array()
      .items()
      .min(1)
      .required()
      .label("Field of studies"),
  };
  setLanguages = (state, id = 0) => {
    // let data = { ...this.state.data }
    if (id === 0) {
      let languageState = { ...this.state.languageState };

      languageState = { reading: 0, writing: 0, speaking: 0, listening: 0 };
      this.setState({
        languageState,
        edit: 0,
      });
    } else {
      this.setState({ edit: 1, editIndex: id - 1 });
    }

    this.setState({ languages: state, languageId: id });
  };

  renderOptions = (item, Id) => {
    let id = "";
    if (item) {
      switch (Id) {
        case 1:
          id = item.speaking;
          break;
        case 2:
          id = item.listening;
          break;
        case 3:
          id = item.reading;
          break;
        case 4:
          id = item.writing;
        default:
          break;
      }
    }

    {
      return [0, 1, 2, 3, 4].map((item, index) => {
        if (id && id == item) {
          // //console.log("id", id)
          return (
            <option key={index} value={item} selected>
              {languagesConstant[item]}
            </option>
          );
        } else {
          return (
            <option key={index} value={item}>
              {languagesConstant[item]}
            </option>
          );
        }
      });
    }
  };

  renderLanguage = () => {
    const scale = [1, 2, 3, 4];
    const { languages } = this.state;
    //console.log(this.state.data);
    return (
      <div id="language_bx" className="table col-md-12">
        <hr />

        <div className="d-flex">
          <h5 className="m-b15">Languages</h5>
          <Link
            to={"#"}
            onClick={() => this.setLanguages(true)}
            className="site-button add-btn button-sm"
          >
            <i className="fa fa-plus m-r5"></i> Add Language
          </Link>
        </div>
        <p>Mention the Languages You Speak</p>

        <table>
          <thead>
            <tr>
              <th>Language</th>
              <th>Speaking</th>
              <th>Listening</th>
              <th>Reading</th>
              <th>Writing</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.talent_language.map((item, index) => {
              return (
                <tr key={index}>
                  <td>
                    {this.props.getLanguagebyName(parseInt(item.language))}
                  </td>
                  <td>{languagesConstant[item.speaking]}</td>
                  <td>{languagesConstant[item.listening]}</td>
                  <td>{languagesConstant[item.reading]}</td>
                  <td>{languagesConstant[item.writing]}</td>

                  <td>
                    <Link
                      to={"#"}
                      className="m-l15 text-primary font-14"
                      data-toggle="modal"
                      data-target="#languages"
                      onClick={() => {
                        let language = {};
                        this.state.data.talent_language.filter(
                          (item_, index) => {
                            if (item_.language == item.language) {
                              language = item_;
                            }
                          }
                        );

                        // language = this.state.data.talent_language.find((element) => element.language == item.language)                                    // this.setState({ language: language, index: index })

                        const languageState = {
                          speaking: language.speaking,
                          reading: language.reading,
                          writing: language.writing,
                          listening: language.listening,
                        };

                        let data = { ...this.state.data };

                        data.tempLanguage = item.language;

                        this.setState({
                          languageState,
                          data: {
                            ...this.state.data,
                            tempLanguage: item.language,
                          },
                        });

                        this.setLanguages(true, index + 1);
                      }}
                    >
                      <i className="fa fa-pencil"></i>
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={"#"}
                      className="m-l15 text-danger font-14"
                      data-toggle="modal"
                      data-target="#languages"
                      onClick={() => {
                        let language = this.state.data.talent_language;

                        // remove item at index from array
                        let languages = language.splice(index, 1);
                        this.setState({
                          data: {
                            ...this.state.data,
                            talent_language: language,
                          },
                        });
                      }}
                    >
                      <i className="fa fa-trash"></i>
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {this.state.errors.talent_language && (
          <div className="alert alert-danger fs-5">
            {this.state.errors.talent_language}
          </div>
        )}

        <Modal
          className="modal fade modal-bx-info editor"
          show={languages}
          onHide={this.setLanguages}
        >
          <div className="modal-dialog my-0" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Language</h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => this.setLanguages(false)}
                >
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      {/* {this.renderInput("language", "Language", "text")} */}
                      {this.renderSelect(
                        this.props.languages.data,
                        "tempLanguage",
                        "Language"
                      )}
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>Speaking</label>
                        <BootStrapForm.Control name="speaking" as="select">
                          {this.renderOptions(this.state.languageState, 1)}
                        </BootStrapForm.Control>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>Listening</label>
                        <BootStrapForm.Control name="listening" as="select">
                          {this.renderOptions(this.state.languageState, 2)}
                        </BootStrapForm.Control>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>Reading</label>
                        <BootStrapForm.Control name="reading" as="select">
                          {this.renderOptions(this.state.languageState, 3)}
                        </BootStrapForm.Control>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>Writing</label>
                        <BootStrapForm.Control name="writing" as="select">
                          {this.renderOptions(this.state.languageState, 4)}
                        </BootStrapForm.Control>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="site-button"
                  onClick={() => this.setLanguages(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="site-button"
                  onClick={() => {
                    let languages = [...this.state.data.talent_language];
                    let flag = false;
                    languages.forEach((item, index) => {
                      if (
                        document
                          .getElementById("tempLanguage")
                          .value.toString() === item.language.toString()
                      ) {
                        flag = true;
                      }
                    });
                    if (flag && this.state.edit == 0) {
                      toast.error("Selected Language Already Exists,", {
                        position: toast.POSITION.TOP_RIGHT,
                        draggable: true,
                        hideProgressBar: true,
                      });
                    } else if (this.state.data.tempLanguage == 0) {
                      toast.error("Please Select Language,", {
                        position: toast.POSITION.TOP_RIGHT,
                        draggable: true,
                        hideProgressBar: true,
                      });
                    } else {
                      let language = {};
                      language.language =
                        document.getElementById("tempLanguage").value;
                      language.speaking =
                        document.getElementsByName("speaking")[0].value;
                      language.listening =
                        document.getElementsByName("listening")[0].value;
                      language.reading =
                        document.getElementsByName("reading")[0].value;
                      language.writing =
                        document.getElementsByName("writing")[0].value;
                      // alert(1)
                      if (this.state.edit == 1) {
                        languages[this.state.editIndex] = language;
                      } else {
                        languages.push(language);
                      }
                      this.setState({
                        data: {
                          ...this.state.data,
                          talent_language: languages,
                        },
                      });

                      this.setLanguages(false);
                    }
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <hr />
      </div>
    );
  };

  doSubmit = async () => {
    if (this.state.data.talent_language.length == 0) {
      let errors = { ...this.state.errors };
      errors.languages = "Please Add Atleast One Language";
      this.setState({ errors });
      return;
    }

    // let history = useHistory();
    try {
      //console.log("submitted");
      const { data } = this.state;
      this.setState({ loading: true });
      const resp = await axios.post(apiEndpoint, data);
      //console.log(resp);
      toast.success("Successfuly Registerd,");
      await axios
        .post(loginEndpoint, { password: data.password, email: data.email })
        .then((res) => {
          // //console.log(res.data,"ole ole");
          authServices.loginWithJwt(res.data);
          window.location = "/";
        });
      // auth.login(data.email, data.password);
      this.setState({ loading: false });

      this.routingFunction();
    } catch (ex) {
      this.setState({ loading: false });

      //console.log(ex);
      if (ex.response && ex.response.status === 400) {
        //console.log(this.state);
        const errors = { ...this.state.errors };

        for (const [key, value] of Object.entries(ex.response.data)) {
          // //console.log(key, value);
          // toast.error(key)
          // swal('error', key, "error");
          if (key === "email" || key === "password") {
            this.pageChange(0);
            toast.error(value);
          }
          let details = "";

          toast.error(ex.response.data);
          // //console.log(ex.response.data);
          value.forEach((element) => {
            toast.error(element);
            // swal('error', element, "error");

            details += element + ", ";
          });
          errors[key] = details;
        }
        this.setState({ errors });

        // ex.response.data.forEach(element => {
        //     // toast.error(element.message);
        //     //console.log(element);

        // });
      }
    }
  };
  pageChange = (page) => {
    // //console.log(page, this.state);
    this.setState({ renderIndex: page });
  };
  renderPage() {
    const pages = [
      <div className="tab-content nav p-b30 tab">
        <div id="login" className="tab-pane active ">
          {/* {props.errorMessage && (
                                                <div className=''>
                                                    {props.errorMessage}
                                                </div>
                                            )}
                                            {props.successMessage && (
                                                <div className=''>
                                                    {props.successMessage}
                                                </div>
                                            )} */}
          <form className=" dez-form p-b30" onSubmit={this.handleSubmit}>
            <h3 className="form-title m-t0">Personal Information</h3>
            <div className="dez-separator-outer m-b5">
              <div className="dez-separator bg-primary style-liner"></div>
            </div>
            <p>Enter your e-mail address and your password. </p>
            {/* <div className="form-group">
                                                    <input value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="hello@example.com" />
                                                    <div className="text-danger">{errors.email && <div>{errors.email}</div>}</div>
                                                </div> */}
            {this.renderInput("email", "Email", "email")}
            {this.renderInput("password", "Password", "password")}
            {/* <div className="form-group">
                                                    <input value={password} className="form-control" defaultValue="Password"
                                                        onChange={(e) =>
                                                            setPassword(e.target.value)
                                                        }
                                                    />
                                                    <div className="text-danger">{errors.password && <div>{errors.password}</div>}</div>
                                                </div> */}
            <div className="form-group text-left">
              <button
                type="button"
                className="site-button dz-xs-flex m-r5"
                onClick={() => {
                  // const er = {"email":["user with this email already exists.","user with this email already exists2."]};
                  // // //console.log(er);
                  // // display all errors in er
                  // for (const [key, value] of Object.entries(er)) {
                  //     //console.log(key, value);
                  //     toast.error(key)
                  //     value.forEach(element => {
                  //         toast.error(element)
                  //     })

                  // }

                  this.validate();
                  //console.log(this.state.errors.email == null);
                  if (
                    this.state.errors.email == undefined &&
                    this.state.errors.password == undefined
                  ) {
                    this.pageChange(1);
                  } else {
                    toast.error(
                      "please fill in email and password to continue"
                    );
                  }
                }}
              >
                Sign me up
              </button>
              {/* <span className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="check1" name="example1" />
                                                        <label className="custom-control-label" htmlFor="check1">Remember me</label>
                                                    </span> */}
            </div>
            <div className="dz-social clearfix">
              <h5 className="form-title m-t5 pull-left">Sign In With</h5>
              <ul className="dez-social-icon dez-border pull-right dez-social-icon-lg text-white">
                <li>
                  <Link
                    to={""}
                    className="fa fa-facebook  fb-btn mr-1"
                    target="bank"
                  ></Link>
                </li>
                <li>
                  <Link
                    to={""}
                    className="fa fa-twitter  tw-btn mr-1"
                    target="bank"
                  ></Link>
                </li>
                <li>
                  <Link
                    to={""}
                    className="fa fa-linkedin link-btn mr-1"
                    target="bank"
                  ></Link>
                </li>
                <li>
                  <Link
                    to={""}
                    className="fa fa-google-plus  gplus-btn"
                    target="bank"
                  ></Link>
                </li>
              </ul>
            </div>
          </form>
          <div className="text-center bottom">
            <Link
              to="/login"
              className="site-button button-md btn-block text-white"
            >
              Sign In
            </Link>
          </div>
        </div>
      </div>,
      <div className="tab-content nav p-b30 tab">
        <div id="register" className="tab-pane active ">
          {/* {props.errorMessage && (
                                                <div className=''>
                                                    {props.errorMessage}
                                                </div>
                                            )}
                                            {props.successMessage && (
                                                <div className=''>
                                                    {props.successMessage}
                                                </div>
                                            )} */}
          <form className=" dez-form p-b30" onSubmit={this.handleSubmit}>
            <h3 className="form-title m-t0">Personal Information</h3>
            <div className="dez-separator-outer m-b5">
              <div className="dez-separator bg-primary style-liner"></div>
            </div>
            <p>Make it easier for employers to contact you</p>
            {/* <p>Enter your e-mail address and your password. </p> */}
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    {this.renderSelect(
                      [
                        { id: "Mr", name: "Mr" },
                        { id: "Mrs", name: "Mrs" },
                        { id: "Ms", name: "Ms" },
                        { id: "Dr", name: "Dr" },
                        { id: "Prof", name: "Prof" },
                      ],
                      "title",
                      "Title*"
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    {this.renderInput("first_name", "First Name*")}
                  </div>
                  <div className="col-md-6">
                    {this.renderInput("last_name", "Last Name*")}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    {this.renderSelect(
                      [
                        { id: "M", name: "Male" },
                        { id: "F", name: "Female" },
                      ],
                      "gender",
                      "Gender*"
                    )}
                  </div>
                  <div className="col-md-5">
                    {this.renderInput("dob", "Date of Birth*", "date")}
                  </div>
                  <div className="col-md-4">
                    {this.renderInput("phone_number", "Phone*")}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    {" "}
                    {this.renderSelect(
                      this.props.countries.data,
                      "country",
                      "Country of residence*"
                    )}{" "}
                  </div>
                  <div className="col-md-4">
                    {" "}
                    {this.renderSelect(
                      this.props.regions.data,
                      "region",
                      "Region*"
                    )}
                  </div>
                  <div className="col-md-4">
                    {" "}
                    {this.renderInput("city", "City*")}
                  </div>
                </div>
                {this.renderInput("address", "Address*")}
                {this.renderMultiSelect(
                  this.props.fieldOfStudies.data,
                  "field_of_study",
                  "Field of studies*"
                )}
                {/* {this.renderSelect(
                  this.props.fieldOfStudies.data,
                  "field_of_study",
                  "Field of study*"
                )} */}
              </div>
              <div className="col-md-6">
                {/* <p>Tell us about your carrier information</p> */}
                {this.renderSelect(
                  this.props.educationLevels.data,
                  "education_level",
                  "Education Level*"
                )}

                {this.renderMultiSelect(
                  this.props.sectors.data,
                  "sector",
                  "Sector*"
                )}
                {this.renderSelect(
                  this.props.experienceLevels.data,
                  "experience_level",
                  "Experience Level*"
                )}
                {this.renderInput("current_company", "Current Company")}
                {this.renderInput("current_position", "Current Position")}
                {this.renderInput("current_salary", "Current Salary")}
                <div className="row">
                  <div className="col-md-6">
                    {this.renderInput(
                      "experience_in_years",
                      "Experience in Years*",
                      "number"
                    )}
                  </div>
                  <div className="col-md-6">
                    {this.renderInput(
                      "experience_in_months",
                      "Experience in months*",
                      "number"
                    )}
                  </div>
                </div>
              </div>
              <hr />
              <div className="col-md-12">
                <div className="row">{this.renderLanguage()}</div>

                {/* <div className="col-md-4"> {this.renderSelect(this.props.languages.data, "language1", "Language*")} </div>
                                        <div className="col-md-4"> {this.renderSelect(this.props.languages.data, "language2", "Language 2")} </div>
                                        <div className="col-md-4"> {this.renderSelect(this.props.languages.data, "language3", "Language 3")} </div> */}
              </div>
            </div>

            <div className="row">
              <div className="form-group text-left">
                <button
                  type="button"
                  className="site-button dz-xs-flex m-r5"
                  onClick={() => {
                    this.pageChange(0);
                  }}
                >
                  back
                </button>
              </div>
              <div className="form-group text-right">
                {/* {this.renderButton("Sign Up")} */}
                <button className="site-button "
                  disabled={this.state.loading}
                >
                {this.state.loading && <span> Loading...</span>}
                    {this.state.loading && ( <i className="fa fa-refresh fa-spin"></i> )}
                  {!this.state.loading && <span>Sign Up</span>}

                  {/* Sign Up */}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>,
    ];
    return pages;
  }
  renderStyle() {
    switch (this.state.renderIndex) {
      case 0:
        return "col-xl-4 col-lg-5 col-md-6 col-sm-12 bg-white z-index2 relative p-a0 content-scroll skew-section left-bottom";
      case 1:
        return "col-xl-8 col-lg-10 col-md-10 col-sm-12 bg-white z-index2 relative p-a0 content-scroll  left-bottom";
      default:
        return "col-xl-4 col-lg-5 col-md-6 col-sm-12 bg-white z-index2 relative p-a0 content-scroll skew-section left-bottom";
    }
  }

  render() {
    return (
      <div className="page-wraper">
        <div className="browse-job login-style3">
          <div
            className="bg-img-fix"
            style={{ backgroundImage: `url(${bnr})`, height: "100vh" }}
          >
            <div className="row">
              <div
                className={this.renderStyle()}
                style={{ height: "100vh", display: "block" }}
              >
                <div className="login-form style-2">
                  <div className="logo-header text-center p-tb">
                    <Link to={"./"}>
                      <img src={require("./../../images/logo.png")} alt="" />
                    </Link>
                  </div>
                  <div className="clearfix"></div>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    {this.renderPage()[this.state.renderIndex]}
                  </div>
                  <div className="bottom-footer clearfix m-t10 m-b20 row text-center">
                    <div className="col-lg-12 text-center">
                      <span>
                        © Copyright by{" "}
                        <i className="fa fa-bolt m-lr5 text-green circle"></i>
                        <Link to={""}>ABH Partners</Link> All rights reserved.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  sectors: state.entities.sector,
  countries: state.entities.country,
  experienceLevels: state.entities.experienceLevel,
  educationLevels: state.entities.educationLevel,
  fieldOfStudies: state.entities.fieldOfStudy,
  regions: state.entities.region,
  languages: state.entities.language,
});

const mapDispatchToProps = (dispatch) => ({
  loadSectors: () => dispatch(loadSectors()),
  loadCountries: () => dispatch(loadCountries()),
  loadCurrency: () => dispatch(loadCurrency()),
  loadEmploymentTypes: () => dispatch(loadEmploymentTypes()),
  loadExperienceLevels: () => dispatch(loadExperienceLevels()),
  loadFieldOfStudies: () => dispatch(loadFieldOfStudies()),
  loadEducationLevels: () => dispatch(loadEducationLevels()),
  loadRegions: () => dispatch(loadRegions()),
  loadSalaryType: () => dispatch(loadSalaryType()),
  loadLanguages: () => dispatch(loadlanguages()),
  getLanguagebyName: (id) => dispatch(getLanguagebyName(id)),
  getLanguages: (id) => dispatch(loadLanguageById(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);

// export default RegisterPage;
