import { createSlice } from '@reduxjs/toolkit';
import { ApiCallBegan } from '../actions/api';
import checkCache from '../../services/cache';
import { coreCacheLimit } from '../../config.json';
import authServices from '../../services/authServices';

const slice = createSlice({
    name: "authData",
    initialState: {
        data: {},
        loading: false,
        lastFetch: null,

    },
    reducers: {
        authDataFetched: (auth, action) => {
            //console.log("authDataFetched>>>>>>>>>>>>>>>>>>...", action.payload)
            
            auth.data = action.payload.data;
            auth.loading = false;
            auth.lastFetch = Date.now();
            return auth;
        },
        authDataRequested: (auth, action) => {
            auth.loading = true;
            return auth;
        },
        authDataRequestFailed: (auth, action) => {

            
            authServices.logout();
            window.location = "/login";

            
            auth.loading = false;
            return auth;
        },
        authDataRemoved: (auth, action) => {
            
            

            auth.data = {};
            auth.loading = false;
            auth.lastFetch = null;
            return auth;
        }

    }

})

export const { authDataFetched, authDataRequestFailed, authDataRequested, authDataRemoved } = slice.actions

export default slice.reducer;

const getUrl = (type) => {
    switch (type) {
        case "company":
            return "api/company/employer/me/";
        case "talent":
            return "api/talent/jobseeker/me/";
        case "admin":
            return "auth/users/me/";
        default:
            return "api/talent/jobseeker/me/"
}
}

export const getauthDataInformation = (type) => (dispatch, getState) => {
    const { lastFetch } = getState().auth.auth;
    if (checkCache(lastFetch, coreCacheLimit)) return;
    const url = getUrl(type);
    
    dispatch(ApiCallBegan({
        url: url,
        onSuccess: authDataFetched.type,
        onError: authDataRequestFailed.type,
        onStart: authDataRequested.type,
    }))    
}
