import React, { useEffect, useState, Component } from "react";
import { Link, useHistory } from "react-router-dom";
import { Modal, Form as BootStrapForm } from "react-bootstrap";

import Header from "./../Layout/Header";
import Footer from "./../Layout/Footer";
import PageTitle from "./../Layout/PageTitle";
import auth from "../../services/authServices";
import { getJobDetail } from "../../store/reducers/openJobs";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";
import authServices from "../../services/authServices";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import httpService from "../../services/httpService";
import { apiUrl } from "../../config.json";
import { getSalary, checkWordLen } from "../../utils/utilFunctions";
import { toast } from "react-toastify";
import "../../css/dangerousHtmls.css";
import axios from "axios";

import {
  FacebookShareButton,
  LinkedinShareButton,
  EmailShareButton,
  RedditShareButton,
  // TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
  RedditIcon,
} from "react-share";
import { loadTalentCvInformation } from "../../store/reducers/cv";
import { Controls, Reveal, Tween } from "react-gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import gsap from "gsap";

gsap.registerPlugin(ScrollTrigger);

const FacebookIcon = require("../../images/social_icons/facebook.png");
const TwitterIcon = require("../../images/social_icons/twitter.png");
const LinkedinIcon = require("../../images/social_icons/linkdin.png");
const EmailIcon = require("../../images/social_icons/website.png");
// const WhatsappIcon = require("../../images/social_icons/whatsapp.png");
// const ViberIcon = require("../../images/social_icons/viber.png");
const TelegramIcon = require("../../images/social_icons/telegram.png");
const WebsiteIcon = require("../../images/social_icons/website.png");
const swal = withReactContent(Swal);
var bnr = require("./../../images/main-slider/slide2T.png");

class Jobdetail extends Component {
  state = {
    apply: false,
    edit: false,
    towns: [],
    loading: false,
    cover_letter: "",
  };

  setApply = (value) => {
    this.setState({ apply: value });
  };
  setEdit = (value) => {
    this.setState({ edit: value });
  };
  //   const history = useHistory();
  //   const [apply, setApply] = useState(false);

  //   const user = auth.getCurrentUser();
  //   //console.log(user)
  //   const dispatch = useDispatch();
  //   const jobs = useSelector((state) => state.entities.openJobs);
  //   const authData = useSelector((state) => state.auth.auth);

  componentDidMount() {
    //console.log(this.props.match.params.id, "<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<");

    if (!this.props.match.params.id) {
      this.props.history.push({ path: "/browse-job-filter-list" });
    }

    window.scrollTo(0, 0);
    this.props.getJobDetail(this.props.match.params.id);
    this.props.loadCv();
  }
  //   useEffect(() => {
  //     if (props.match.params.id == undefined) {
  //       //console.log(props.history.push("/browse-job-list"));
  //     }

  //     window.scrollTo(0, 0);
  //     dispatch(getJobDetail(props.match.params.id));
  //   }, []);
  onRegionChange = (e) => {
    // //console.log(e.target.value)
    let towns = [];
    //console.log(this.props.jobs.jobDetail.towns);
    this.props.jobs.jobDetail.towns.map((town) => {
      if (town.region.id == e.target.value) {
        towns.push(town);
      }
    });
    this.setState({ towns });
  };
  renderModal = () => {
    const user = auth.getCurrentUser();
    //console.log(user);
    const jobs = this.props.jobs;
    const { apply, edit } = this.state;
    if (user == null) {
      return null;
    } else {
      return (
        <>
          <Modal
            show={apply}
            onHide={this.setApply}
            className="modal fade modal-bx-info"
          >
            <div className="modal-dialog my-0" role="document">
              <div className="modal-content">
                <div className="modal-header" style={{ width: "500px" }}>
                  <div className="logo-img">
                    <img
                      alt=""
                      src={require("./../../images/logo/icon2.png")}
                    />
                  </div>
                  <h5 className="modal-title">Apply Job</h5>

                  <button
                    type="button"
                    className="close"
                    onClick={() => {
                      this.setApply(false);
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <ul style={{ maxWidth: "500px" }}>
                    <li>
                      <strong>Job Title :</strong>
                      <p> {jobs.jobDetail.title}</p>
                    </li>
                    <li>
                      <strong>Experience :</strong>
                      <p>{jobs.jobDetail.experience_level}</p>
                    </li>
                    <li>
                      <strong>Purpose Of Job :</strong>
                    </li>
                    <br />
                    <p>{jobs.jobDetail.description}</p>
                  </ul>

                  <div className="form-group">
                    <label>Cover Letter</label>
                    <textarea
                      className="form-control"
                      id="coverLetter"
                      onChange={() => {
                        checkWordLen(
                          document.getElementById("coverLetter"),
                          200,
                          document.getElementById("count")
                        );
                      }}
                      placeholder="Cover Letter"
                      rows="4"
                    ></textarea>
                    <span>
                      <p id="count"></p>
                    </span>
                    <label>Select a region you want to Apply To:*</label>
                    <div className="form-control" style={{ padding: "0px" }}>
                      <BootStrapForm.Control
                        as="select"
                        custom
                        className="btn dropdown-toggle text-left btn-default"
                        id="region"
                        onChange={this.onRegionChange}
                      >
                        <option value={""}>Select Region</option>
                        {jobs.jobDetail.regions.map((item, index) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </BootStrapForm.Control>
                    </div>
                    <label>Town:</label>
                    <div className="form-control" style={{ padding: "0px" }}>
                      <BootStrapForm.Control
                        as="select"
                        custom
                        className="btn dropdown-toggle text-left btn-default"
                        id="town"
                        //   onChange={this.onRegionChange}
                      >
                        <option value={""}>Select Town</option>
                        {this.state.towns.map((item, index) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </BootStrapForm.Control>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={async () => {
                      const coverLetter =
                        document.getElementById("coverLetter").value;

                      // if(coverLetter == ""){
                      // 	toast.error("Please enter a cover letter");
                      // 	return;
                      // }

                      if (document.getElementById("region").value == "") {
                        toast.error("Please select a region");
                        return;
                      }
                      if (
                        document.getElementById("town").value == "" &&
                        this.state.towns.length > 0
                      ) {
                        toast.error("Please select a Town");

                        return;
                      }

                      const formData = new FormData();
                      formData.append("job", this.props.match.params.id);
                      formData.append("cover_letter", coverLetter);
                      formData.append(
                        "region",
                        document.getElementById("region").value
                      );
                      formData.append(
                        "town",
                        document.getElementById("town").value
                      );
                      // //console.log(coverLetter);
                      try {
                        this.setState({ loading: true });
                        const resp = await httpService.post(
                          apiUrl + "api/appliedjobs/jobs/",
                          formData
                        );
                        this.setState({ loading: false });
                        //console.log(resp);
                        if (resp.status == 201) {
                          swal
                            .fire({
                              title: "Success",
                              text: "You have successfully applied for this job",
                              icon: "success",
                              confirmButtonText: "Ok",
                            })
                            .then((result) => {
                              if (result.value) {
                                this.setApply(false);
                              }
                            });
                        } else {
                          //console.log(resp);
                          swal
                            .fire({
                              title: "Error",
                              text: resp.data.error,
                              icon: "error",
                              confirmButtonText: "Ok",
                            })
                            .then((result) => {
                              if (result.value) {
                                this.setApply(false);
                              }
                            });
                        }
                      } catch (err) {
                        this.setState({ loading: false });
                        //console.log(err.error);
                        if (
                          err == "Error: Request failed with status code 403"
                        ) {
                          swal
                            .fire({
                              title: "Error",
                              text: "You have already applied for this job",
                              icon: "error",
                              confirmButtonText: "Ok",
                            })
                            .then((result) => {
                              if (result.value) {
                                this.setApply(false);
                              }
                            });
                        } else {
                          swal
                            .fire({
                              title: "Error",
                              text: "Something went wrong, Try Again later",
                              icon: "error",
                              confirmButtonText: "Ok",
                            })
                            .then((result) => {
                              if (result.value) {
                                this.setApply(false);
                              }
                            });
                        }
                      }
                      this.setApply(false);
                    }}
                    disabled={this.state.loading}
                  >
                    {this.state.loading && <span> Loading...</span>}
                    {this.state.loading && (
                      <i className="fa fa-refresh fa-spin"></i>
                    )}
                    {!this.state.loading && <span>Apply</span>}
                  </button>

                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      this.setApply(false);
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {jobs.jobDetail.appliedId && (
            <Modal
              show={edit}
              onHide={this.setEdit}
              className="modal fade modal-bx-info"
            >
              <div className="modal-dialog my-0" role="document">
                <div className="modal-content">
                  <div className="modal-header" style={{ width: "500px" }}>
                    <div className="logo-img">
                      <img
                        alt=""
                        src={require("./../../images/logo/icon2.png")}
                      />
                    </div>
                    <h5 className="modal-title">Edit Job</h5>

                    <button
                      type="button"
                      className="close"
                      onClick={() => {
                        this.setEdit(false);
                      }}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <ul style={{ maxWidth: "500px" }}>
                      <li>
                        <strong>Job Title :</strong>
                        <p> {jobs.jobDetail.title}</p>
                      </li>
                      <li>
                        <strong>Experience :</strong>
                        <p>{jobs.jobDetail.experience_level}</p>
                      </li>
                      <li>
                        <strong>Purpose Of Job :</strong>
                      </li>
                      <br />
                      <p>{jobs.jobDetail.description}</p>
                    </ul>

                    <div className="form-group">
                      <label>Cover Letter</label>
                      <textarea
                        className="form-control"
                        id="coverLetter"
                        onChange={(text) => {
                          this.setState({ cover_letter: text.target.value });

                          checkWordLen(
                            document.getElementById("coverLetter"),
                            200,
                            document.getElementById("count")
                          );
                        }}
                        value={this.state.cover_letter}
                        placeholder="Cover Letter"
                        rows="4"
                      ></textarea>
                      <span>
                        <p id="count"></p>
                      </span>
                      <label>Select a region you want to Apply To:*</label>
                      <div className="form-control" style={{ padding: "0px" }}>
                        <BootStrapForm.Control
                          as="select"
                          custom
                          className="btn dropdown-toggle text-left btn-default"
                          id="region"
                          onChange={this.onRegionChange}
                        >
                          <option value={""}>Select Region</option>
                          {jobs.jobDetail.regions.map((item, index) => {
                            if (item.name === jobs.jobDetail.appliedId.region) {
                              return (
                                <option key={item.id} value={item.id} selected>
                                  {item.name}
                                </option>
                              );
                            } else {
                              return (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            }
                          })}
                        </BootStrapForm.Control>
                      </div>
                      <label>Town:</label>
                      <div className="form-control" style={{ padding: "0px" }}>
                        <BootStrapForm.Control
                          as="select"
                          custom
                          className="btn dropdown-toggle text-left btn-default"
                          id="town"
                          //   onChange={this.onRegionChange}
                        >
                          <option value={""}>Select Town</option>
                          {this.state.towns.map((item, index) => {
                            if (item.name == jobs.jobDetail.appliedId.town) {
                              return (
                                <option key={item.id} value={item.id} selected>
                                  {item.name}
                                </option>
                              );
                            } else {
                              return (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            }
                          })}
                        </BootStrapForm.Control>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={async () => {
                        const coverLetter =
                          document.getElementById("coverLetter").value;

                        // if(coverLetter == ""){
                        // 	toast.error("Please enter a cover letter");
                        // 	return;
                        // }

                        if (document.getElementById("region").value == "") {
                          toast.error("Please select a region");
                          return;
                        }
                        if (
                          document.getElementById("town").value == "" &&
                          this.state.towns.length > 0
                        ) {
                          toast.error("Please select a Town");

                          return;
                        }

                        const formData = new FormData();

                        formData.append("id", jobs.jobDetail.appliedId.id);
                        formData.append("cover_letter", coverLetter);
                        formData.append(
                          "region",
                          document.getElementById("region").value
                        );
                        formData.append(
                          "town",
                          document.getElementById("town").value
                        );
                        // //console.log(coverLetter);
                        try {
                          const user = auth.getCurrentUser();
                          const token = user.token;

                          const headers = {
                            "Content-Type": "application/json",
                            Authorization: `JWT ${token}`,
                          };
                          // const resp = await httpService.patch(
                          //   apiUrl +
                          //     `/api/appliedjobs/jobs/13/`,
                          //   formData
                          // );
                          const resp = await axios.patch(
                            apiUrl +
                              `/api/appliedjobs/jobs/${jobs.jobDetail.appliedId.id}/`,
                            formData,
                            { headers }
                          );

                          //console.log(resp);
                          if (resp.status == 200) {
                            swal
                              .fire({
                                title: "Success",
                                text: "Your application has been edited!",
                                icon: "success",
                                confirmButtonText: "Ok",
                              })
                              .then((result) => {
                                if (result.value) {
                                  this.setEdit(false);
                                  window.location.reload();
                                }
                              });
                          } else {
                            //console.log(resp);
                            swal
                              .fire({
                                title: "Error",
                                text: resp.data.error,
                                icon: "error",
                                confirmButtonText: "Ok",
                              })
                              .then((result) => {
                                if (result.value) {
                                  this.setEdit(false);
                                }
                              });
                          }
                        } catch (err) {
                          //console.log(err.error);
                          if (
                            err == "Error: Request failed with status code 403"
                          ) {
                            swal
                              .fire({
                                title: "Error",
                                text: "You can't Edit this Job",
                                icon: "error",
                                confirmButtonText: "Ok",
                              })
                              .then((result) => {
                                if (result.value) {
                                  this.setEdit(false);
                                }
                              });
                          } else {
                            swal
                              .fire({
                                title: "Error",
                                text:
                                  "Something went wrong, Try Again later" + err,
                                icon: "error",
                                confirmButtonText: "Ok",
                              })
                              .then((result) => {
                                if (result.value) {
                                  this.setEdit(false);
                                }
                              });
                          }
                        }
                        this.setEdit(false);
                      }}
                    >
                      Edit
                    </button>

                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        this.setEdit(false);
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
          )}
        </>
      );
    }
  };

  jobDescription = () => {
    const jobs = this.props.jobs;
    if (jobs.jobDetail.description == "") {
      return "";
    } else {
      return (
        <React.Fragment>
          <h5 className="font-weight-600">Purpose Of Job</h5>
          <div
            dangerouslySetInnerHTML={{ __html: jobs.jobDetail.description }}
          ></div>
          <div className="dez-divider divider-2px bg-gray-dark mb-4 mt-0"></div>
        </React.Fragment>
      );
    }
  };

  renderLoadingScreen = () => {
    return (
      <div className="loading">
        <div className="loading__icon">Loading</div>
      </div>
    );
  };
  handleApplyJob = () => {
    const jobs = this.props.jobs;
    const cv = this.props.cv;
    const authData = this.props.authData;
    const user = auth.getCurrentUser();
    if (user == null) {
      // alert("You must Log in to apply for this job")
      // swal("dsadasd")
      swal
        .fire({
          title: "Login",
          text: "You must Login in order apply for this job",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Login",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.props.history.push("/login");
          }
        });
    } else if (user.userProfile == "jobSeeker") {
      let uploadCv = false;
      let JobExperience = false;
      let uploadedCv = authData.data.uploaded_cv;
      let cvs = cv.data;

      if (cvs !== {}) {
        try {
          cvs = cvs.cv_experience;
        } catch (e) {
          cvs = null;
        }
      } else {
        cvs = null;
      }
      if (cvs.length == 0) {
        cvs = null;
      }

      let error = [];

      if (uploadedCv == null) {
        error.push("Cv is required. Please upload your CV first. ");
        uploadCv = true;
      }
      if (cvs == null) {
        if (
          parseInt(authData.data.experience_in_years) > 0 ||
          parseInt(authData.data.experience_in_months) > 0
        ) {
          error.push(
            "Employment History is required. Please Complete your Employment history that matches your experience."
          );
          JobExperience = true;
        }
      }

      if (error.length > 0) {
        swal
          .fire({
            title: "Please Complete your Profile First",
            text: error.join(", "),
            icon: "warning",
            showDenyButton: uploadCv,
            showConfirmButton: JobExperience,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            denyButtonColor: "rgb(85 221 51)",
            confirmButtonText: "Add Employment History",
            denyButtonText: "Upload CV",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.props.history.push("/jobs-my-resume");
            }
            // else
            else if (result.isDenied) {
              this.props.history.push("/jobs-profile");
            }
          });
      } else {
        this.setApply(true);
      }
    } else if (user.userProfile == "company") {
      // alert("You are not allowed to apply for this job")
    }
  };
  handleEditJob = () => {
    const jobs = this.props.jobs;
    this.setState({ cover_letter: jobs.jobDetail.appliedId.cover_letter });
    const cv = this.props.cv;
    const authData = this.props.authData;
    const user = auth.getCurrentUser();
    if (user == null) {
      // alert("You must Log in to apply for this job")
      // swal("dsadasd")
      swal
        .fire({
          title: "Login",
          text: "You must Login in order apply for this job",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Login",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.props.history.push("/login");
          }
        });
    } else if (user.userProfile == "jobSeeker") {
      let uploadCv = false;
      let JobExperience = false;
      let uploadedCv = authData.data.uploaded_cv;
      let cvs = cv.data;

      if (cvs !== {}) {
        try {
          cvs = cvs.cv_experience;
        } catch (e) {
          cvs = null;
        }
      } else {
        cvs = null;
      }
      if (cvs.length == 0) {
        cvs = null;
      }

      let error = [];

      if (uploadedCv == null) {
        error.push("Cv is required. Please upload your CV first. ");
        uploadCv = true;
      }
      if (cvs == null) {
        if (
          parseInt(authData.data.experience_in_years) > 0 ||
          parseInt(authData.data.experience_in_months) > 0
        ) {
          error.push(
            "Employment History is required. Please Complete your Employment history that matches your experience."
          );
          JobExperience = true;
        }
      }

      if (error.length > 0) {
        swal
          .fire({
            title: "Please Complete your Profile First",
            text: error.join(", "),
            icon: "warning",
            showDenyButton: uploadCv,
            showConfirmButton: JobExperience,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            denyButtonColor: "rgb(85 221 51)",
            confirmButtonText: "Add Employment History",
            denyButtonText: "Upload CV",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.props.history.push("/jobs-my-resume");
            }
            // else
            else if (result.isDenied) {
              this.props.history.push("/jobs-profile");
            }
          });
      } else {
        this.setEdit(true);
      }
    } else if (user.userProfile == "company") {
      // alert("You are not allowed to apply for this job")
    }
  };
  renderActionButton = (jobs) => {
    if (jobs.jobDetail.is_published == 1 && jobs.jobDetail.applied == false) {
      return (
        <Link
          to={"#"}
          // to={"/jobs-applied-job"}
          className="site-button m-b20"
          onClick={() => this.handleApplyJob()}
        >
          Apply To This Job
        </Link>
      );
    } else if (
      jobs.jobDetail.is_published == 1 &&
      jobs.jobDetail.applied == true
    ) {
      return (
        <Link
          to={"#"}
          // to={"/jobs-applied-job"}
          className="site-button m-b20"
          onClick={() => this.handleEditJob()}
        >
          Edit Application
        </Link>
      );
    }
  };

  handleDeleteJob = async (job_id) => {
    const user = auth.getCurrentUser();
    const token = user.token;

    const headers = {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    };
    const response = await axios.delete(
      apiUrl + `api/appliedjobs/jobs/${job_id.id}/`,
      headers
    );
  };
  promptDeleteJob = (job_id) => {
    swal
      .fire({
        title: "Are you sure you want to continue? ",
        text: "If you withdraw your application, you will no longer be considered for this position.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Withdraw!",
        cancelButtonText: "No, Cancel!",
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.handleDeleteJob(job_id);
          swal
            .fire(
              "Withdrawn!",
              "Your application has been withdrawn.",
              "success"
            )
            .then((result) => {
              if (result.isConfirmed) {
                this.props.history.push("/");
              }
            });
        }
      });
  };

  renderPage = () => {
    const jobs = this.props.jobs;
    const cv = this.props.cv;

    const authData = this.props.authData;
    const user = auth.getCurrentUser();
    //console.log(user);

    if (
      jobs.loading == true ||
      cv.loading ||
      jobs.jobDetail.company == undefined
    ) {
      return this.renderLoadingScreen();
    } else {
      // //console.log(jobs.jobDetail, "Job Data")
      return (
        <div className="page-content bg-white">
          <Tween
            from={{ y: "-200px", backgroundColor: "#ffffff" }}
            to={{ y: "0px", backgroundColor: "#009299" }}
            duration={1.7}
            ease="expo.out(1.7)"
          >
            <div
              className="dez-bnr-inr"
              style={{
                backgroundImage: "url(" + bnr + ")",
                maxHeight: "400px",
                backgroundColor: "#009299",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "left",
              }}
            >
              {/* <div className="progress-box m-t10">
              <div className="progress-info">
                Profile Strength (Average)<span>70%</span>
              </div>
              <div className="progress">
                <div
                  className="progress-bar bg-primary"
                  style={{ width: "80%" }}
                  role="progressbar"
                ></div>
              </div>
            </div> */}
              <PageTitle activeName="Job Detail" motherName="Home" />
            </div>
          </Tween>
          <div className="content-block jobView">
            <div className="section-full content-inner-1">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="sticky-top">
                      <Tween
                        from={{ x: "-200px" }}
                        to={{ x: "0px" }}
                        duration={1.7}
                        ease="expo.out(1.7)"
                      >
                        <div className="row">
                          <div className="col-lg-12 col-md-6">
                            <div className="col-lg-12 col-md-6 m-b30 border rounded">
                              <img
                                style={{
                                  height: "200px",
                                  width: "400px",
                                  objectFit: "contain",
                                }}
                                src={
                                  jobs.jobDetail.company.logo == null
                                    ? "https://shop.raceyaya.com/wp-content/uploads/2020/11/logo-placeholder.jpg"
                                    : jobs.jobDetail.company.logo
                                }
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-6">
                            <ul className="list-inline m-b30">
                              <li className="scale-on-hover">
                                <a
                                  href={`${jobs.jobDetail.company.website}`}
                                  target="_blank"
                                  className=" "
                                >
                                  <div
                                    style={{ width: "32px", height: "32px" }}
                                  >
                                    <img
                                      src={WebsiteIcon}
                                      style={{ objectFit: "fill" }}
                                      alt="FacebookIcon"
                                    />
                                  </div>
                                </a>
                              </li>
                              <li className="scale-on-hover">
                                <a
                                  href={`${jobs.jobDetail.company.facebook}`}
                                  target="_blank"
                                  className="circle"
                                >
                                  <div
                                    style={{ width: "32px", height: "32px" }}
                                  >
                                    <img
                                      src={FacebookIcon}
                                      style={{ objectFit: "fill" }}
                                      alt="FacebookIcon"
                                    />
                                  </div>
                                </a>
                              </li>
                              <li className="scale-on-hover">
                                <a
                                  href={`${jobs.jobDetail.company.linkedin}`}
                                  target="_blank"
                                  className=" "
                                >
                                  <div
                                    style={{ width: "32px", height: "32px" }}
                                  >
                                    <img
                                      src={LinkedinIcon}
                                      style={{ objectFit: "fill" }}
                                      alt="LinkedinIcon"
                                    />
                                  </div>
                                </a>
                              </li>
                              {/* <li className="scale-on-hover">
                              <a
                                href={`${jobs.jobDetail.company.google}`}
                                target="_blank"
                                className="site-button google circle "
                              >
                                <i className="fa fa-google"></i>
                              </a>
                            </li> */}
                              <li className="scale-on-hover">
                                <a
                                  href={`${jobs.jobDetail.company.twitter}`}
                                  target="_blank"
                                  className=" "
                                >
                                  <div
                                    style={{ width: "32px", height: "32px" }}
                                  >
                                    <img
                                      src={TwitterIcon}
                                      style={{ objectFit: "fill" }}
                                      alt="Twitter Icon"
                                    />
                                  </div>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="col-lg-12 col-md-6">
                            <div className="widget bg-white p-lr20 p-t20  widget_getintuch radius-sm">
                              <h4 className="text-black font-weight-700 p-t10 m-b15">
                                Job Details{" "}
                              </h4>

                              <ul>
                                <li>
                                  <i className="ti-location-pin"></i>
                                  <strong className="font-weight-700 text-black">
                                    Regions:
                                  </strong>
                                  <ul className="list">
                                    {jobs.jobDetail.regions.map(
                                      (region_, index) => {
                                        //console.log(region_, "regions_");
                                        return (
                                          <li
                                            key={index}
                                            style={{
                                              padding: "0px",
                                              margin: "0px",
                                            }}
                                          >
                                            <span className="text-black-light">
                                              {region_.name}
                                            </span>
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                  {/* <span className="text-black-light">
                                  {" "}
                                  {jobs.jobDetail.region} {jobs.jobDetail.city}{", "}
                                  {jobs.jobDetail.address}{", "}
                                </span> */}
                                </li>
                                {/* <li>
                                <i className="ti-location-pin"></i>
                                <strong className="font-weight-700 text-black">
                                  Address
                                </strong>
                                <span className="text-black-light">
                                  {" "}
                                  {jobs.jobDetail.region} {jobs.jobDetail.city}{" "}
                                  {jobs.jobDetail.address}{" "}
                                </span>
                              </li> */}
                                <li>
                                  <i className="ti-money"></i>
                                  <strong className="font-weight-700 text-black">
                                    Salary
                                  </strong>{" "}
                                  {getSalary(
                                    Intl.NumberFormat().format(
                                      jobs.jobDetail.salary
                                    ),
                                    jobs.jobDetail.salary_currency,
                                    jobs.jobDetail.salary_type,
                                    jobs.jobDetail.salary_mode
                                  )}
                                </li>
                                <li>
                                  <i className="ti-shield"></i>
                                  <strong className="font-weight-700 text-black">
                                    Experience
                                  </strong>
                                  {jobs.jobDetail.experience_level}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </Tween>
                    </div>
                  </div>
                  <Tween
                    from={{ x: "200px" }}
                    to={{ x: "0px" }}
                    duration={1.7}
                    ease="expo.out(1.7)"
                  >
                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <div className="row">
                            <div className="col-lg-3 col-md-3">
                              {this.renderActionButton(jobs)}
                              {/* <strong>Deadline:</strong>{" "}
                            {new Date(
                              jobs.jobDetail.application_end_date
                            ).toDateString()} */}
                            </div>
                            <div className="col-lg-5 col-md-5">
                              <TelegramShareButton
                                className="scale-on-hover"
                                style={{ marginRight: "5px" }}
                                url={window.location.href}
                                title={`Job From ${jobs.jobDetail.company.company_name} at Ethiotalent.com: ${jobs.jobDetail.title}`}
                              >
                                <div style={{ width: "32px", height: "32px" }}>
                                  <p style={{ marginRight: "200px" }}>Share:</p>
                                </div>
                              </TelegramShareButton>
                              <TelegramShareButton
                                className="scale-on-hover"
                                style={{ marginRight: "5px" }}
                                url={window.location.href}
                                title={`Job From ${jobs.jobDetail.company.company_name} at Ethiotalent.com: ${jobs.jobDetail.title}`}
                              >
                                <div style={{ width: "32px", height: "32px" }}>
                                  {/* <p style={{marginRight:"200px"}}>Share:</p> */}
                                </div>
                              </TelegramShareButton>

                              <TelegramShareButton
                                className="scale-on-hover"
                                style={{ marginRight: "5px" }}
                                url={window.location.href}
                                title={`Job From ${jobs.jobDetail.company.company_name} at Ethiotalent.com: ${jobs.jobDetail.title}`}
                              >
                                <div style={{ width: "32px", height: "32px" }}>
                                  <img
                                    src={TelegramIcon}
                                    style={{ objectFit: "fill" }}
                                    alt="TelegramLogo"
                                  />
                                </div>
                              </TelegramShareButton>
                              {/* <WhatsappShareButton
                              style={{ marginRight: "5px" }}
                              url={window.location.href}
                              title={`Job From ${jobs.jobDetail.company.company_name} at Ethiotalent.com: ${jobs.jobDetail.title}`}
                            >
                              <WhatsappIcon size={32} round />
                            </WhatsappShareButton> */}
                              {/* <ViberShareButton
                              style={{ marginRight: "5px" }}
                              url={window.location.href}
                              title={`Job From ${jobs.jobDetail.company.company_name} at Ethiotalent.com: ${jobs.jobDetail.title}`}
                            >
                              <ViberIcon size={32} round />
                            </ViberShareButton> */}
                              <FacebookShareButton
                                className="scale-on-hover"
                                style={{ marginRight: "5px" }}
                                url={window.location.href}
                                quote={`Job From ${jobs.jobDetail.company.company_name} at Ethiotalent.com: ${jobs.jobDetail.title}`}
                                hashtag="Ethiotalent"
                              >
                                {/* <FacebookIcon size={32} round />
                                 */}
                                <div style={{ width: "32px", height: "32px" }}>
                                  <img
                                    src={FacebookIcon}
                                    alt="facebook Logo"
                                    style={{ objectFit: "fill" }}
                                  />
                                </div>
                              </FacebookShareButton>

                              <TwitterShareButton
                                className="scale-on-hover"
                                style={{ marginRight: "5px" }}
                                url={window.location.href}
                                title={`Job From ${jobs.jobDetail.company.company_name} at Ethiotalent.com: ${jobs.jobDetail.title}`}
                              >
                                <div style={{ width: "32px", height: "32px" }}>
                                  <img
                                    src={TwitterIcon}
                                    alt="twitter Logo"
                                    style={{ objectFit: "fill" }}
                                  />
                                </div>
                              </TwitterShareButton>
                              {/* <RedditShareButton url={window.location.href} title={`Job From ${jobs.jobDetail.company.company_name} at Ethiotalent.com: ${jobs.jobDetail.title}`}>
                              <RedditIcon size={32} round />
                            </RedditShareButton> */}

                              <LinkedinShareButton
                                className="scale-on-hover"
                                style={{ marginRight: "5px" }}
                                url={window.location.href}
                                title={`Job From ${jobs.jobDetail.company.company_name} at Ethiotalent.com: ${jobs.jobDetail.title}`}
                              >
                                <div style={{ width: "32px", height: "32px" }}>
                                  <img
                                    src={LinkedinIcon}
                                    alt="Linkedin Logo"
                                    style={{ objectFit: "fill" }}
                                  />
                                </div>
                              </LinkedinShareButton>

                              {/* <EmailSharx   reButton> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="job-info-box">
                        <h3 className="m-t0 m-b10 font-weight-700 title-head">
                        <Reveal >
                          <Tween from={{ opacity: 0,transform: 'translate3d(-100px, 0, 0)' }} ease="back.out(1.4)" duration={2}>
                          <Link to={"#"} className="text-secondry m-r30">
                            {jobs.jobDetail.title}
                          </Link>
                          </Tween>
                        </Reveal>
                        </h3>
                        <ul className="job-info">
                          {/* toDateString */}
                          {jobs.jobDetail.is_published != 1 && (
                            <div className="d-flex">
                              <label className="">
                                {1 == 1 ? (
                                  <p style={{ color: "red" }}>Closed</p>
                                ) : (
                                  <></>
                                )}
                                {/* <input type="checkbox" />
															<span className="checkmark"></span> */}
                              </label>
                            </div>
                          )}
                          {jobs.jobDetail.applied != undefined &&
                          jobs.jobDetail.applied ? (
                            <>
                              <p style={{ color: "green" }}>
                                <i
                                  class="fa fa-check-circle"
                                  aria-hidden="true"
                                ></i>{" "}
                                Applied
                              </p>
                              <button
                                className="btn btn-danger m-r15"
                                onClick={() => {
                                  this.promptDeleteJob(
                                    jobs.jobDetail.appliedId
                                  );
                                }}
                              >
                                Withdraw Application
                              </button>
                              <br />
                            </>
                          ) : (
                            <></>
                          )}
                          <li>
                            <strong>Company</strong>{" "}
                            {jobs.jobDetail.company.company_name}
                          </li>
                          <li>
                            <strong>Deadline:</strong>{" "}
                            {new Date(
                              jobs.jobDetail.application_end_date
                            ).toDateString()}
                          </li>
                          <li>
                            <strong>Posted On:</strong>{" "}
                            {new Date(
                              jobs.jobDetail.date_created.split("T")[0]
                            ).toDateString()}
                          </li>
                          {/* <li>
                          <i className="ti-location-pin text-black m-r5"></i>{" "}
                          {jobs.jobDetail.region}{" "}
                        </li> */}
                        </ul>
                        <Reveal >
                          <Tween from={{ opacity: 0,transform: 'translate3d(-100px, 0, 0)' }} ease="back.out(1.4)" duration={2}>
                        <p style={{ textAlign: "justify" }}>
                          {this.jobDescription()}
                        </p>
                          </Tween>
                        </Reveal>
                        <Reveal >
                          <Tween from={{ opacity: 0,transform: 'translate3d(-100px, 0, 0)' }} ease="back.out(1.4)" duration={2}>
                        <h5 className="font-weight-600">
                          Duties and Responsiblities
                        </h5>
                        <div
                          className="dangerous-inner-html"
                          // style={{ textAlign: "justify" }}
                          dangerouslySetInnerHTML={{
                            __html: jobs.jobDetail.duties_and_responsibilities,
                          }}
                        ></div>
                          </Tween>
                        </Reveal>
                        <div className="dez-divider divider-2px bg-gray-dark mb-4 mt-0"></div>
                        {/*  */}{" "}
                        <Reveal >
                          <Tween from={{ opacity: 0,transform: 'translate3d(-100px, 0, 0)' }} ease="back.out(1.4)" duration={2}>
                        <h5 className="font-weight-600">Job Requirements</h5>
                        <div
                          className="dangerous-inner-html"
                          dangerouslySetInnerHTML={{
                            __html: jobs.jobDetail.job_requirements,
                          }}
                        ></div>
                          </Tween>
                        </Reveal>
                        <div className="dez-divider divider-2px bg-gray-dark mb-4 mt-0"></div>
                        <Reveal >
                          <Tween from={{ opacity: 0,transform: 'translate3d(-100px, 0, 0)' }} ease="back.out(1.4)" duration={2}>
                            <h5 className="font-weight-600">Other Skills</h5>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: jobs.jobDetail.other_skills,
                          }}
                        ></div>
                          </Tween>
                        </Reveal>
                        <div className="dez-divider divider-2px bg-gray-dark mb-4 mt-0"></div>
                        <Reveal >
                          <Tween from={{ opacity: 0,transform: 'translate3d(-100px, 0, 0)' }} ease="expo.out(1.4)" duration={2}>
                        <h5 className="font-weight-600">How to Apply</h5>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: jobs.jobDetail.how_to_apply,
                          }}
                        ></div>
                          </Tween>
                        </Reveal>
                        {/* {jobs.jobDetail.job_reqiurements} */}
                        <div className="dez-divider divider-2px bg-gray-dark mb-4 mt-0"></div>

                        {jobs.jobDetail.is_published == 1 && (
                          <Link
                            to={"#"}
                            // to={"/jobs-applied-job"}
                            className="site-button m-b20"
                            onClick={() => this.handleApplyJob()}
                          >
                            Apply To This Job
                          </Link>
                        )}
                      </div>
                      {this.renderModal()}
                    </div>
                  </Tween>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
  render() {
    return (
      <>
        <Header />
        {this.renderPage()}
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  cv: state.entities.cv,
  jobs: state.entities.openJobs,
  authData: state.auth.auth,
});
const mapDispatchToProps = (dispatch) => ({
  getJobDetail: (id) => dispatch(getJobDetail(id)),
  loadCv: () => dispatch(loadTalentCvInformation()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Jobdetail);
// export default Jobdetail;
