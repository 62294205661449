import axios from 'axios';
import { apiUrl } from "../../config.json";
import * as actions from "../actions/api";

const api = ({ dispatch, getState }) => next => async action => {
    // //console.log(action, "action")
    if (action.type !== actions.ApiCallBegan.type) return next(action)
    const { url, method, data, onSuccess, onError, onStart,headers } = action.payload
    if (onStart) dispatch({ type: onStart })
    next(action)
    try {
        // //console.log("wabalubdubdub")
        const response = await axios.request({
            baseURL: apiUrl,
            url,
            method,
            data,
            headers

        });
        // //console.log(response)
        
        dispatch({ type: onSuccess, payload: response })


    }
    catch (error) {
        //console.log(error)
        dispatch({ type: onError, payload: error })

    }


}
export default api;