import { createSlice } from '@reduxjs/toolkit';
import { ApiCallBegan } from "../actions/api"
import checkCache from "../../services/cache"
import { coreCacheLimit } from "../../config.json"
//console.log("Sector reducer")

const slice = createSlice({
    name: "sector",
    initialState: {
        data: [],
        lastFetch: null,
        loading: false
    },
    reducers: {
        sectorAdded: (sector, action) => {
            //console.log(sector, action)
            sector.data = action.payload.data.results
            sector.lastFetch = Date.now()
            sector.loading = false;
            return sector;
        },
        sectorRequested: (sector, action) => {
            sector.loading = true;
            return sector
        },
        sectorRequestFailed: (sector, action) => {
            sector.loading = false;
            return sector
        }

    }

})
const actions = slice.actions
// //console.log(actions.sectorAdded(["dsds"]))
export const { sectorAdded, sectorRequested, sectorRequestFailed } = slice.actions
export default slice.reducer;


export const getSectorByName = (id) => (dispatch, getState) => {
    const sector = getState().entities.sector.data
    // //console.log(id,sector[1].id,"<<<<<<<><><><<<<<<<<<<<<<<<<<<<")
    const sectorName = sector.find(sector => sector.id === parseInt(id))
    return sectorName ? sectorName.name : "Sector"
    // return "sdsd"
}


// Action Creators
export const loadSectors = () => (dispatch, getState) => {
    const { lastFetch } = getState().entities.sector
    if (checkCache(lastFetch, coreCacheLimit)) return;
    dispatch(ApiCallBegan({
        url: "api/core/sector/",
        onSuccess: sectorAdded.type,
        onStart: sectorRequested.type,
        onError: sectorRequestFailed.type
    }))


}
