import auth from "../services/authServices";
import _ from "lodash";

export const getCurrentPage = (previous) => {
  let current = 1;
  if (previous == null) {
    //console.log("previous is null");
    current = 0;
  } else {
    let url = previous.split("?");
    if (url.length > 1) {
      url = url[1];
      //console.log(previous, "url is right here");
      url.split("&").forEach((element) => {
        if (element.split("=")[0] == "page") {
          current = parseInt(element.split("=")[1]);
        }
      });
    }
  }
  return current;
};

export const getSalary = (salary, salaryCurrency, salaryType, salaryMode) => {
  if (salaryMode == 1) {
    return "Negotiable";
  } else if (salaryMode == 2) {
    return "Attractive";
  } else {
    return salary + " " + salaryCurrency + " " + salaryType;
  }
};

export const checkPermission = (action, target) => {
  const template = { view: [], add: [], change: [], delete: [] };

  let permission = {};
  if (auth.getCurrentUser()) {
    permission = auth.getCurrentUserPermissions();
  } else {
    permission = template;
  }
  // console.log(permission, "permission")
  const permissionList = permission[action];

  const func = _.find(permissionList, function (o) {
    return o == target;
  });
  //console.log(func, "func");
  if (func) {
    return true;
  }
  return false;
};
export const checkPermissionAny = () => { 
  console.log( "?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>permission")
    const template = { view: [], add: [], change: [], delete: [] };
  
    let permission = {};
    if (auth.getCurrentUser()) {
      permission = auth.getCurrentUserPermissions();
    } else {
      permission = template;
    }

    console.log(permission.view.length == 0 , permission.add.length == 0 , permission.change.length == 0 , permission.delete.length == 0 , "permission")
    
   return permission.view.length == 0 && permission.add.length == 0 && permission.change.length == 0 && permission.delete.length == 0;
  };


export const checkWordLen = (obj, wordLen, display) => {
    // //console.log(obj, wordLen,display,"obj.value")
  var len = obj.value.split(/[\s]+/);
  display.innerHTML = `${len.length}/${wordLen}`;
  if (len.length > wordLen) {
    
    //  alert("You cannot put more than "+wordLen+" words in this text area.");
    obj.oldValue = obj.value != obj.oldValue ? obj.value : obj.oldValue;
    obj.value = obj.oldValue ? obj.oldValue : "";
    return false;
  }
  return true;
};
