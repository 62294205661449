import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  Font,
  StyleSheet,
  Link,
} from "@react-pdf/renderer";
import { useDispatch, useSelector } from "react-redux";
import {
  loadEducationLevels,
  getEducationName,
} from "../../store/reducers/educationLevel";
import {
  loadFieldOfStudies,
  getFieldOfStudyName,
} from "../../store/reducers/fieldOfStudy";
import {
  loadlanguages,
  getLanguagebyName,
} from "../../store/reducers/language";
import { getRegionbyName } from "../../store/reducers/region";
import { getCountrybyName } from "../../store/reducers/country";

const CVView = ({props}) => {
  console.log(props,"<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<Props");
    const name = props.talent.data.user.first_name + " " + props.talent.data.user.last_name;
    const email = props.talent.data.user.email;
  const styles = StyleSheet.create({
    header: {
      height: "90px",
      padding: "40px 25px",
      backgroundColor: "#4A4A4A",
    },
    title: {
      //   fontFamily: "serif",
      color: "white",
      fontSize: "45px",
    },
    left: {
      minHeight: "966px",
      width: "280px",
      padding: "25px",
      float: "left",
      backgroundColor: "#576D7B",
    },
    right: {
      height: "100%",
      width: "560px",
      padding: "25px",
      float: "right",
      backgroundColor: "#ffffff",
    },
    page: {
      padding: 0,
    },
    container: {
      flex: 1,
      flexDirection: "row",
      "@media max-width: 400": {
        flexDirection: "column",
      },
    },
    image: {
      marginBottom: 10,
    },
    leftColumn: {
      flexDirection: "column",
      backgroundColor: "#576D7B",
      width: 170,
      paddingTop: 30,
      paddingRight: 15,
      "@media max-width: 400": {
        width: "100%",
        paddingRight: 0,
      },
      "@media orientation: landscape": {
        width: 200,
      },
    },
    footer: {
      fontSize: 12,
      //   fontFamily: "Lato Bold",
      textAlign: "center",
      marginTop: 15,
      paddingTop: 5,
      borderWidth: 3,
      borderColor: "gray",
      borderStyle: "dashed",
      "@media orientation: landscape": {
        marginTop: 10,
      },
    },
    headerContainer: {
      backgroundColor: "#4A4A4A",
      height: 60,
      padding: 10,
      flexDirection: "row",
      //   borderBottomWidth: 2,
      //   borderBottomColor: "#112131",
      //   borderBottomStyle: "solid",
      alignItems: "stretch",
    },
    detailColumn: {
      flexDirection: "column",
      flexGrow: 9,
      textTransform: "uppercase",
    },
    linkColumn: {
      flexDirection: "column",
      flexGrow: 2,
      alignSelf: "flex-end",
      justifySelf: "flex-end",
    },
    name: {
      fontSize: 24,
      color: "white",
      //   fontFamily: "Lato Bold",
    },
    subtitle: {
      fontSize: 10,
      color: "white",

      justifySelf: "flex-end",
      //   fontFamily: "Lato",
    },
    link: {
      //   fontFamily: "Lato",
      fontSize: 10,
      color: "white",
    //   textDecoration: "none",
      alignSelf: "flex-end",
      justifySelf: "flex-end",
    },
  });
  return (
    <React.Fragment>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.headerContainer}>
            <View style={styles.detailColumn}>
              <Text style={styles.name}>{name}</Text>
              <Text style={styles.subtitle}>{email} </Text>
            </View>
            {/* <View style={styles.linkColumn}>
               <Link href="mailto:luke@theforce.com" style={styles.link}> 
                luke@theforce.com
              </Link>
            </View> */}
          </View>

          <View style={styles.container}>
            <View style={styles.leftColumn}>
              <Text>Left</Text>
              <hr />
            </View>
            <View style={styles.rightColumn}>
              <Text>Right</Text>
            </View>
          </View>
        </Page>
      </Document>
    </React.Fragment>
  );
};

export default CVView;
