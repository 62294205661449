import { createSlice } from '@reduxjs/toolkit';
import { ApiCallBegan } from "../actions/api"
import checkCache from "../../services/cache";
import { coreCacheLimit } from "../../config.json"


const reportsSlice = createSlice({
    name: "reports",
    initialState: {
        data: [],
        lastFetch: null,
        loading: true

    },
    reducers: {
        reportAdded: (report, action) => {
            console.log(">>><<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<",action.payload.data)
            report.data = action.payload.data
            report.lastFetch = Date.now()
            report.loading = false;
            return report
        },
        reportRequested: (report, action) => {
            report.loading = true;
            return report
        },
        reportRequestFailed: (report, action) => {
            report.loading = false;
            return report
        }


    }

})

export const { reportAdded, reportRequestFailed, reportRequested } = reportsSlice.actions
export default reportsSlice.reducer;

export const loadreport = () => (dispatch, getState) => {
    const { lastFetch } = getState().entities.reports;
    // alert(1)
    if (checkCache(lastFetch, coreCacheLimit)) return;
    dispatch(ApiCallBegan({
        url: "/api/core/reports/",
        onStart: reportRequested.type,
        onSuccess: reportAdded.type,
        onError: reportRequestFailed.type
    }))
}
