import jwtDecode from "jwt-decode";
import http from "./httpService";
import { apiUrl } from "../config.json";


const apiEndpoint = apiUrl + "api/core/auth/";
const tokenKey = "token";
const refreshTokenKey = "refreshToken";

http.setJwt(getJwt());

export async function login(email, password) {
  const { data: jwt } = await http.post(apiEndpoint, { email, password });
  alert(jwt['access']);
  // localStorage.setItem(tokenKey, jwt["access"]);
  // localStorage.setItem(refreshTokenKey, jwt["refresh"]);
}

export function loginWithJwt(jwt) {
  // localStorage.setItem(tokenKey, jwt);
  // window.location = "/";
  localStorage.setItem(tokenKey, jwt["access"]);
  localStorage.setItem(refreshTokenKey, jwt["refresh"]);
}

export function logout() {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem(refreshTokenKey);
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    // //console.log(jwtDecode(jwt))
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function getCurrentUserPermissions() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    const { auth } = jwtDecode(jwt);
    return JSON.parse(decodeURIComponent(window.atob(auth)));
  } catch (ex) {
    return null;
  }

}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export default {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt,
  getCurrentUserPermissions
};
