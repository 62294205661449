import React, { Suspense, useEffect } from "react";
import Index from "./markup/Markup";
import { connect, useDispatch, useSelector } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";

import "./css/plugins.css";
import "./css/style.css";
import "./css/templete.css";
import "./css/skin/skin-1.css";
import "./plugins/slick/slick.min.css";
import "./plugins/slick/slick-theme.min.css";
import ReactGA from "react-ga";

import LoginPage from "./markup/Pages/loginPage";
import ResetPasswordPage from "./markup/Pages/resetPassword";
import RegisterPage from "./markup/Pages/registerPage";
import auth from "./services/authServices";
import Homepage2 from "./markup/Pages/Homepage2";
import Aboutus from "./markup/Pages/aboutUs";
import Browsejobfilterlist from "./markup/Pages/Browsejobfilterlist";
import Jobdetail from "./markup/Pages/Jobdetail";
import Contact from "./markup/Pages/Contact";
import { getauthDataInformation } from "./store/reducers/auth";

//const SignUp = lazy(() => import('./markup/Pages/Register2'));
//const ForgotPassword = lazy(() => import('./markup/pages/ForgotPassword'));
/*  const Login = lazy(() => {
    return new Promise(resolve => {
    setTimeout(() => resolve(import('./markup/Pages/Loginpage2')), 500);
  });
});  */

function App(props) {
  const TRACKING_ID = "UA-205453516-1"; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth.auth);

  const renderApp = () => {
    if (authData.loading || !authData.lastFetch) {
      return (
        <div
          className="spinner-border"
          role="status"
          style={{
            alignItems: "center",
            display: "flex",
            height: "100vh",
            justifyContent: "center",
            width: "100vw",
          }}
        >
          <i
            className="fa fa-spinner fa-spin m-r5"
            style={{ fontSize: "75px" }}
          ></i>
        </div>
      );
    } else {
      return <Index />;
    }
  };

  useEffect(() => {
    const user = auth.getCurrentUser();
    if (user) {
      dispatch(getauthDataInformation(user.userProfile));
    }

    //     checkAutoLogin(dispatch, props.history);
  }, [dispatch, props.history]);
  // //console.log(auth.getCurrentUser())
  let routes = (
    <Switch>
      <Route path="/about-us" exact component={Aboutus} />
      <Route path="/contact" exact component={Contact} />

      <Route path="/login" component={LoginPage} />
      <Route path="/register-2" component={RegisterPage} />
      <Route path="/reset-password" exact component={ResetPasswordPage} />
      <Route
        path="/reset-password/:uid/:token"
        exact
        component={ResetPasswordPage}
      />
      {/* <Route path='/register' component={RegisterCompany} /> */}
      <Route path="/" exact component={Homepage2} />
      <Route path="/browse-job-list" exact component={Browsejobfilterlist} />

      <Route
        path="/browse-job-filter-list"
        exact
        component={Browsejobfilterlist}
      />
      <Route path="/job-detail" exact component={Jobdetail} />
      <Route path="/job-detail/:id" exact component={Jobdetail} />

      {/* <Route path='/browse-job-list' exact component={Browsejoblist} /> */}

      {/* <Route path='*' component={Homepage2regis} /> */}

      <Route path="*" exact component={LoginPage} />
    </Switch>
  );
  if (auth.getCurrentUser()) {
    return (
      <>
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          {renderApp()}
        </Suspense>
      </>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          {routes}
        </Suspense>
      </div>
    );
  }
}

// const mapStateToProps = (state) => {
//     return {
//         isAuthenticated: isAuthenticated(state),
//     };
// };

export default App;
// export default withRouter(connect(mapStateToProps)(App));

//export default App;
