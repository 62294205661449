import React, { Component, useEffect } from "react";
import { Link } from "react-router-dom";
import auth from "../../services/authServices";
import { getOpenJobs } from "../../store/reducers/openJobs";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import "../../css/text.css";
import { getSalary } from "../../utils/utilFunctions";
import JobTile from "./common/jobTile";
import SideAD from "./common/sideAd";
import { loadads } from "../../store/reducers/ads";
import { Reveal, Tween } from "react-gsap";

function Jobsection() {
  // componentDidMount() {
  // 	const user = auth.getCurrentUser();
  // 	this.props.getOpenJobs();

  // }

  const user = auth.getCurrentUser();
  const history = useHistory();
  const primaryColor = "#009299";
  const secondaryColor = "#009299";
  //console.log(user, "Auth");
  const dispatch = useDispatch();
  const jobs = useSelector((state) => state.entities.openJobs);
  const ads = useSelector((state) => state.entities.ads);

  useEffect(() => {
    dispatch(getOpenJobs("0|0", "0|0"));
    dispatch(loadads());
  }, []);

  return (
    <div className="section-full bg-white content-inner-2">
      <div className="container">
        <div className="d-flex job-title-bx section-head">
          <div className="mr-auto">
            <h2 className="m-b5">Recent Jobs</h2>
            <h6 className="fw4 m-b0">Recently Added Jobs</h6>
          </div>
          <div className="align-self-end">
            <Link
              to={"/browse-job-filter-list"}
              className="site-button button-md"
            >
              Browse All Jobs <i className="fa fa-long-arrow-right"></i>
            </Link>
          </div>
        </div>
        <div
          className="row"
          style={{
            marginBottom: "20px",
          }}
        >
          <div
            className="col-lg-9"
            style={{
              padding: "1em",
              maxHeight: "600px",
              minHeight: "300px",
              overflowY: "scroll",
              overflowX: "hidden",
              boxShadow: "0px 0px 10px 0px #ccc",
            }}
          >
            {jobs.loading == true ? (
              <div className="post-job-bx browse-job">
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <i
                      className="fa fa-spinner fa-spin m-r5"
                      style={{ fontSize: "75px" }}
                    ></i>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <ul className="post-job-bx browse-job">
                  {jobs.data.map((section) => {
                    return section.map((job, index) => {
                      let regions = job.regions.join(", ");
                      if (regions.length > 30) {
                        regions = regions.slice(0, 30) + "...";
                      }
                      // alert(job.is_published)
                      // //console.log(regions)
                      return (
                            <JobTile key={job.id} job={job} regions={regions} />
                      );
                    });
                  })}
                </ul>
              </>
            )}
          </div>
          <div className="col-lg-3">
            <div className="sticky-top">
              {user == null && (
                <div className="quote-bx">
                  <div className="quote-info">
                    <Reveal>
                      <Tween
                        from={{
                          opacity: 0,
                          transform: "translate3d(-100px, 0, 0)",
                        }}
                        ease="back.out(1.4)"
                        duration={2}
                      >
                        <h4>Make a Difference with Your Online Resume!</h4>
                      </Tween>
                    </Reveal>
                    <Reveal>
                      <Tween
                        from={{
                          opacity: 0,
                          transform: "translate3d(100px, 0, 0)",
                        }}
                        ease="back.out(1.4)"
                        duration={2}
                      >
                        <p>
                          Your resume in minutes with Ethiotalent's resume
                          assistant!
                        </p>
                      </Tween>
                    </Reveal>
                        <Link to={"/register"} className="site-button">
                          Create an Account
                        </Link>
                      
                  </div>
                </div>
              )}
              {ads.data.length > 0 &&
                ads.data.map((ad) => {
                  return (
                    <SideAD
                      key={ad.id}
                      title={ad.title}
                      body={ad.description}
                      image={ad.image}
                      link={ad.actionUrl}
                    />
                  );
                })}

              {/* } */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  openjobs: state.entities.openJobs,
});

const mapDispatchToProps = (dispatch) => ({
  getOpenJobs: () => dispatch(getOpenJobs()),
});
export default Jobsection;
