import React, { Component } from 'react';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
class CompanyDashBoard extends Component {
    state = {}
    render() {
        return (<div>
            <Header/>
            <h1>Company Dashboard</h1>
            <Footer/>
        </div>);
    }
}

export default CompanyDashBoard;