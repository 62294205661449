import { createSlice } from "@reduxjs/toolkit";
import { ApiCallBegan } from "../actions/api";
import checkCache from "../../services/cache";
import { coreCacheLimit } from "../../config.json"
import { toast } from "react-toastify";

const jobPostSlice = createSlice({
    name: "jobPost",
    initialState: {
        data: {},
        lastFetch: null,
        loading: false,
        loaded: false,
        action:0
    },
    reducers: {
        jobPostFetched: (state, action) => {
            state.data = action.payload.data
            state.lastFetch = Date.now()
            state.loading = false;
            return state;
        },
        jobPostRequested: (state, action) => {

            state.loading = true;
            return state
        },
        jobPostRequestFailed: (state, action) => {
            console.log(action
                , "action");
            try{
                toast.error(action.payload.response.data.error)
            }
            catch (e){
                toast.error("An Error Occured Please Try Again")   
            }

            state.loading = false;
            return state
        },
        jobPostUpdated: (state, action) => {
            state.data = action.payload.data
            state.lastFetch = Date.now()
            toast.success("Job Post Updated")   
            state.loaded = true;
            state.action = 1;
            state.loading = false;
            return state;
        },
        jobPostCreated: (state, action) => {
            state.data = action.payload.data
            state.lastFetch = Date.now()
            toast.success("Job Post Created")  
            state.action = 2;
            state.loaded = true;
            state.loading = false;
            return state;
        },
        jobPostCleared: (state, action) => {
            state.loaded = false;
            state.action = 0;
            return state;
        }

    }
})

export const getJobPost = state => state.entities.jobPost
export const { jobPostFetched, jobPostRequested,jobPostRequestFailed,jobPostUpdated,jobPostCreated,jobPostCleared } = jobPostSlice.actions
export default jobPostSlice.reducer;


// export const addJobPost = (jobPost) => (dispatch, getState) => {
//     dispatch()
// }

export const createJobPost = (data) => (dispatch, getState) => {
    
    return dispatch(ApiCallBegan({
        url: "api/jobs/myjobs/",
        onSuccess: jobPostCreated.type,
        onError: jobPostRequestFailed.type,
        onStart: jobPostRequested.type,
        data: data,
        method: "POST",
    }))
}

export const updateJobPost = (data) => {
    return ApiCallBegan({
        url: `api/jobs/myjobs/${ data.id}/`,
        onSuccess: jobPostUpdated.type,
        onError: jobPostRequestFailed.type,
        onStart: jobPostRequested.type,
        data: data,
        method: "PUT",
    })
}
export const clearJobPost = () => (dispatch, getState) => {
    dispatch({ type: jobPostCleared.type })

}

export const loadJobPost = (id) => (dispatch, getState) => {
    
    const { lastFetch } = getState().entities.jobPost
    const currentId = getState().entities.jobPost.data.id

    const diffInMinutes = (Date.now() - lastFetch) / 1000 / 60
    if (diffInMinutes < coreCacheLimit && currentId ==id) return

    dispatch(
        ApiCallBegan({
            url: `api/jobs/myjobs/${id}/`,
            onSuccess: jobPostFetched.type,
            onStart: jobPostRequested.type,
            onError: jobPostRequestFailed.type,
        })
    )
}
