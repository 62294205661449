import React, { Component } from "react";
import { Link } from "react-router-dom";
import Joi, { errors } from "joi-browser";
import ReactQuill from "react-quill";
import { Form as bootstrapForm } from "react-bootstrap";

import Form from "../Element/common/form";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import CompanyProfileSideBar from "../Element/companyProfileSideBar";
import { EditorState, ContentState, convertFromHTML } from "draft-js";

import {
  loadEducationLevels,
  getEducationName,
} from "../../store/reducers/educationLevel";
import {
  loadFieldOfStudies,
  getFieldOfStudyName,
} from "../../store/reducers/fieldOfStudy";
import { getCountrybyName, loadCountries } from "../../store/reducers/country";

import "../../css/textEditor.css";
import { loadSectors, getSectorByName } from "../../store/reducers/sector";
import { loadEmploymentTypes } from "../../store/reducers/employmentType";
import { loadExperienceLevels } from "../../store/reducers/experienceLevel";
import { loadRegions, getRegionbyName } from "../../store/reducers/region";
import { loadSalaryType } from "../../store/reducers/salaryType";
import { loadCurrency } from "../../store/reducers/currency";
import { loadTowns, getTownByName } from "../../store/reducers/town";
import { connect } from "react-redux";
import { stateToHTML } from "draft-js-export-html";
import {
  clearJobPost,
  createJobPost,
  loadJobPost,
  updateJobPost,
} from "../../store/reducers/jobPost";
import { addJob, updateJob } from "../../store/reducers/myjobs";
import { checkPermission } from "../../utils/utilFunctions";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import { Select } from "@mui/material";

class Companypostjobs extends Form {
  status = [
    { id: 0, name: "Draft" },
    { id: 1, name: "Post" },
    { id: 2, name: "Pending" },
    { id: 3, name: "Expired" },
    { id: 4, name: "Withdraw" },
    { id: 5, name: "Selecting" },
    { id: 6, name: "Close" },
  ];
  salaryTypes = [
    { id: 0, name: "Salary In Figure" },
    { id: 1, name: "Negoitable" },
    { id: 2, name: "Attractive" },
  ];

  state = {
    loaded: 0,
    selectedSectors: [],
    selectedRegions: [],
    selectedTowns: [],
    duties: 1,
    requirements: 1,
    skills: 1,
    how_toapply: 1,
    data: {
      title: "",
      application_start_date: "",
      application_end_date: "",
      description: "",
      regions: [],
      towns: [],
      sector: [],
      salary_mode: 0,
      duties_and_responsibilities: "",
      other_skills: "",
      address: "",
      is_published: "0",
      employment_type: "",
      salary: "",
      salary_type: "",
      experience_level: "",
      job_requirements: "",
      how_to_apply: "",
    },
    errors: {},
    // description: EditorState.createEmpty(),
    job_requirements: EditorState.createEmpty(),
    how_to_apply: EditorState.createEmpty(),
    duties_and_responsibilities: EditorState.createEmpty(),
    other_skills: EditorState.createEmpty(),
  };
  getCurrentDate() {
    var date = new Date().getDate();
    var month = new Date().getMonth();
    var year = new Date().getFullYear();
    let today = new Date(year, month, date);
    return today;
  }

  schema = {
    title: Joi.string().required().label("Job Title"),
    application_start_date: Joi.date()
      .required()
      .label("Application Start Date"),
    application_end_date: Joi.date().required().label("Application End Date"),
    description: Joi.string().required().label("Purpose of Job"),
    regions: Joi.array().required().min(1).label("Regions"),
    towns: Joi.any().optional().allow([]).label("Towns"),
    sector: Joi.array().required().min(1).label("Sector"),
    address: Joi.string().required().label("Address"),
    employment_type: Joi.string().required().label("Employment Type"),
    salary: Joi.number().required().label("Salary"),
    salary_type: Joi.string().required().label("Salary Type"),
    salary_mode: Joi.number().required().label("Salary Mode"),
    experience_level: Joi.string().required().label("Experience Level"),
    is_published: Joi.string().required().label("Is Published"),
    job_requirements: Joi.string().required().label("Job Requirements"),
    duties_and_responsibilities: Joi.string()
      .required()
      .label("Duties and Responsibilities"),
    other_skills: Joi.string().required().label("Other Skills"),
    how_to_apply: Joi.string().required().label("How to Apply"),
  };
  constructor(props) {
    super(props);
    this.editorRef = React.createRef();
  }
  componentDidMount() {
    const permission = checkPermission("add", "job");
    if (!permission) {
      this.props.history.push("/");
    }

    if (this.props.match.params.id) {
      this.props.loadJobPost(this.props.match.params.id);
      this.setState({ loaded: 1 });
    }
    // ////console.log(this.props.match.params.id, "params")
    this.props.loadExperienceLevels();
    this.props.loadRegions();
    this.props.loadSectors();
    this.props.loadRegions();
    this.props.loadEmploymentTypes();
    this.props.loadSalaryType();
    this.props.loadCurrency();
    this.props.loadTowns();
  }

  doSubmit() {
    ////console.log("submitted");
    if (this.props.match.params.id) {
      let data = { ...this.state.data };
      data.id = this.props.match.params.id;
      this.props.updateJobPost(data);
    } else {
      this.props.createJobPost(this.state.data);
    }
  }

  renderLoadingScreen = (title) => {
    return (
      <div id={title} className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <i
            className="fa fa-spinner fa-spin m-r5"
            style={{ fontSize: "75px" }}
          ></i>
        </div>
      </div>
    );
  };

  renderJobPostStatusBadge = () => {
    switch (this.state.data.is_published) {
      case "0":
        return [
          <h6>
            <span className="badge badge-warning text-white badge-sm">
              Draft
            </span>
          </h6>,
          "warning",
        ];
      case "1":
        return [
          <h6>
            <span className="badge badge-success text-white badge-sm">
              Posted
            </span>
          </h6>,
          "success",
        ];
      case "2":
        return [
          <h6>
            <span className="badge badge-info text-white badge-sm">
              Pending
            </span>
          </h6>,
          "info",
        ];
      case "3":
        return [
          <h6>
            <span className="badge badge-danger text-white badge-sm">
              Expired
            </span>
          </h6>,
          "danger",
        ];
      case "4":
        return [
          <h6>
            <span className="badge badge-danger text-white badge-sm">
              Withdraw
            </span>
          </h6>,
          "danger",
        ];
      case "5":
        return [
          <h6>
            <span className="badge badge-primary text-white badge-sm">
              Selecting
            </span>
          </h6>,
          "primary",
        ];
      case "6":
        return [
          <h6>
            <span className="badge badge-danger text-white badge-sm">
              Closed
            </span>
          </h6>,
          "danger",
        ];
      default:
        this.setState({ data: { ...this.state.data, is_published: "0" } });
    }
  };

  renderPage = () => {
    if (
      this.props.jobPost.loading === true ||
      this.props.sectors.loading == true ||
      this.props.regions.loading == true ||
      this.props.towns.loading == true
    ) {
      return this.renderLoadingScreen("page");
    } else {
      if (this.props.jobPost.loaded) {
        if (this.props.jobPost.action == 2) {
          this.props.clearJobPost();
          this.props.addJob(this.props.jobPost.data);
        } else if (this.props.jobPost.action == 1) {
          this.props.clearJobPost();

          this.props.updateJob(this.props.jobPost.data);
        }

        this.props.history.push("/company-manage-job");
      }
      if (this.state.loaded === 1) {
        let data = { ...this.state.data };
        Object.entries(data).forEach(([key, value]) => {
          if (key === "sector" || key === "towns" || key === "regions") {
            ////console.log(this.props.jobPost.data[key], "sector");
            data[key] = this.props.jobPost.data[key];
          } else {
            data[key] = this.props.jobPost.data[key].toString();
          }
        });
        //////console.log(data, "data");
        let selectedSectors = [];
        let selectedRegions = [];
        let selectedTowns = [];

        this.props.jobPost.data["sector"].forEach((element) => {
          selectedSectors.push({
            id: element,
            name: this.props.getSectorByName(element),
          });
        });

        this.props.jobPost.data["regions"].forEach((element) => {
          selectedRegions.push({
            id: element,
            name: this.props.getRegionbyName(element),
          });
        });

        this.props.jobPost.data["towns"].forEach((element) => {
          selectedTowns.push({
            id: element,
            name: this.props.getTownByName(element),
          });
        });

        // const description = EditorState.createWithContent(
        // 	ContentState.createFromBlockArray(
        // 		convertFromHTML(this.props.jobPost.data.description + " ")
        // 	)
        // )

        let job_requirements = EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(this.props.jobPost.data.job_requirements + "")
          )
        );
        let how_to_apply = EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(this.props.jobPost.data.how_to_apply + "")
          )
        );
        let duties_and_responsibilities = EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(
              this.props.jobPost.data.duties_and_responsibilities + ""
            )
          )
        );

        let other_skills = EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(this.props.jobPost.data.other_skills + "")
          )
        );

        this.setState({
          data,
          selectedSectors,
          selectedRegions,
          selectedTowns,
          job_requirements,
          how_to_apply,
          other_skills,
          duties_and_responsibilities,
          loaded: 0,
        });
      } else {
        const statusNotification = this.renderJobPostStatusBadge();
        const selectedRegions = this.state.data.regions;

        let townData = [];

        this.props.towns.data.forEach((element) => {
          if (selectedRegions.includes(element.region.id)) {
            townData.push({
              id: element.id,
              name: `${element.region.name} : ${element.name}`,
            });
          }
        });
        // this.props.towns.data;

        //////console.log(townData, "townsData");

        return (
          <div className="page-content bg-white ">
            <div className="content-block">
              <div className="section-full bg-white p-t50 p-b20">
                <div className="container">
                  <div className="row">
                    <CompanyProfileSideBar currentPage={"Post A Job"} />
                    <div className="col-xl-9 col-lg-8 m-b30 ">
                      <div
                        className={`job-bx submit-resume border border-${statusNotification[1]}`}
                      >
                        <div className="float-right">
                          {this.renderSelect(
                            this.status,
                            "is_published",
                            "Status"
                          )}
                          {/* </div> */}
                        </div>
                        <div className="job-bx-title clearfix">
                          <h5 className="font-weight-700 pull-left text-uppercase m-r15">
                            Post A Job
                          </h5>
                          {/* <span className="new-page">Draft</span> */}
                          {statusNotification[0]}

                          {/* <div className="job-bx-title  clearfix"> */}
                          {/* <Link to={"/company-profile"} className="site-button right-arrow button-sm float-right">Back</Link> */}
                        </div>

                        <form onSubmit={this.handleSubmit}>
                          <div className="row">
                            <div className="col-lg-12 col-md-12">
                              {this.renderInput("title", "Job Title", "text")}
                            </div>
                            <div className="col-lg-6 col-md-6">
                              {this.renderMultiSelect(
                                this.props.regions.data,
                                "regions",
                                "Regions",
                                this.props.regions.data.length,
                                this.state.selectedRegions
                              )}
                            </div>
                            <div className="col-lg-6 col-md-6">
                              {this.renderMultiSelect(
                                townData,
                                "towns",
                                "Town",
                                this.props.towns.data.length,
                                this.state.selectedTowns
                              )}
                            </div>

                            <div className="col-lg-12 col-md-12">
                              {this.renderInput("address", "Address")}
                            </div>
                            <div className="col-lg-12 col-md-12">
                              {this.renderMultiSelect(
                                this.props.sectors.data,
                                "sector",
                                "Sector",
                                4,
                                this.state.selectedSectors
                              )}
                            </div>

                            <div className="col-lg-6 col-md-6">
                              {this.renderInput(
                                "application_start_date",
                                "Application Start Date",
                                "date"
                              )}
                            </div>
                            <div className="col-lg-6 col-md-6">
                              {this.renderInput(
                                "application_end_date",
                                "Application End Date",
                                "date"
                              )}
                            </div>

                            {/* <div className="col-lg-6 col-md-6">
                              {this.renderInput("salary", "Salary")}
                            </div> */}
                            <div className="col-lg-3 col-md-3">
                              {this.renderInput("salary", "Salary")}
                            </div>
                            <div className="col-lg-3 col-md-3">
                              {this.renderSelect(
                                this.salaryTypes,
                                "salary_mode",
                                "Display Mode"
                              )}
                            </div>

                            <div className="col-lg-6 col-md-6">
                              {this.renderSelect(
                                this.props.salaryType.data,
                                "salary_type",
                                "Salary Type"
                              )}
                            </div>
                            <div className="col-lg-6 col-md-6">
                              {this.renderSelect(
                                this.props.employmentType.data,
                                "employment_type",
                                "Employment Type"
                              )}
                            </div>
                            <div className="col-lg-6 col-md-6">
                              {this.renderSelect(
                                this.props.experienceLevels.data,
                                "experience_level",
                                "Experience Level"
                              )}
                            </div>
                            <div className="col-lg-12 col-md-12">
                              {this.renderTextArea(
                                "description",
                                "Purpose of Job"
                              )}
                            </div>
                            <div className="col-lg-12 col-md-12  mb-5">
                              <label>Duties and Responsibilities</label>
                              <div className="form-group col-md-4">
                                <label htmlFor="duties">Select Editor </label>
                                <bootstrapForm.Control
                                  name="duties"
                                  as="select"
                                  custom
                                  className="custom-select"
                                  onChange={(e) => {
                                    this.setState({
                                      duties: e.target.value,
                                    });
                                  }}
                                >
                                  <option value="1">Editor 1</option>
                                  <option value="2"> Editor 2</option>
                                </bootstrapForm.Control>
                              </div>

                              {this.state.duties == 1 ? (
                                <SunEditor
                                  setOptions={{
                                    height: 200,
                                    buttonList: buttonList.complex, // Or Array of button list, eg. [['font', 'align'], ['image']]
                                    // plugins: [font] set plugins, all plugins are set by default
                                    // Other option
                                    // defaultStyle:'pre'
                                  }}
                                  onChange={(content) => {
                                    let data = { ...this.state.data };
                                    const modifiedContent = content.replace(
                                      "<li></li>",
                                      ""
                                    );
                                    console.log(content);
                                    data.duties_and_responsibilities =
                                      modifiedContent;
                                    this.setState({ data });
                                  }}
                                  // onPaste={this.handlePaste}
                                  setContents={
                                    this.state.data
                                      .duties_and_responsibilities == ""
                                      ? this.props.jobPost.data
                                          .duties_and_responsibilities
                                      : this.state.data
                                          .duties_and_responsibilities
                                  }
                                />
                              ) : (
                                <ReactQuill
                                  className="editorClassName"
                                  theme="snow"
                                  modules={{
                                    toolbar: [
                                      [
                                        {
                                          size: [
                                            "normal",
                                            "small",
                                            "large",
                                            "huge",
                                          ],
                                        },
                                      ],
                                      [{ color: [] }, { background: [] }],
                                      ["bold", "italic", "underline", "strike"],
                                      [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                      // ['link', 'image', 'video'],
                                      [{ list: "ordered" }, { list: "bullet" }],
                                      ["blockquote", "code-block"],
                                      [{ align: [] }],
                                      [{ indent: "-1" }, { indent: "+1" }],
                                      ["clean"],
                                    ],
                                  }}
                                  bounds={".app"}
                                  onChange={(content) => {
                                    let data = { ...this.state.data };
                                    data.duties_and_responsibilities = content;
                                    this.setState({ data });
                                  }}
                                  value={
                                    this.state.data.duties_and_responsibilities
                                  }
                                />
                              )}

                              {this.state.errors
                                .duties_and_responsibilities && (
                                <div className="alert alert-danger">
                                  {
                                    this.state.errors
                                      .duties_and_responsibilities
                                  }
                                </div>
                              )}
                            </div>

                            <div className="col-lg-12 col-md-12  mb-5">
                              <label>Job Requirements</label>
                              <div className="form-group col-md-4">
                                <label htmlFor="requirements">
                                  Select Editor{" "}
                                </label>
                                <bootstrapForm.Control
                                  name="requirements"
                                  as="select"
                                  custom
                                  className="custom-select"
                                  onChange={(e) => {
                                    this.setState({
                                      requirements: e.target.value,
                                    });
                                  }}
                                >
                                  <option value="1">Editor 1</option>
                                  <option value="2"> Editor 2</option>
                                </bootstrapForm.Control>
                              </div>
                              {this.state.requirements == 1 ? (
                                <SunEditor
                                  setOptions={{
                                    height: 200,
                                    buttonList: buttonList.complex, // Or Array of button list, eg. [['font', 'align'], ['image']]
                                    // plugins: [font] set plugins, all plugins are set by default
                                    // Other option
                                  }}
                                  onChange={(content) => {
                                    let data = { ...this.state.data };
                                    data.job_requirements = content;
                                    this.setState({ data });
                                  }}
                                  setContents={
                                    this.state.data.job_requirements == ""
                                      ? this.props.jobPost.data.job_requirements
                                      : this.state.data.job_requirements
                                  }
                                />
                              ) : (
                                <ReactQuill
                                  className="editorClassName"
                                  theme="snow"
                                  modules={{
                                    toolbar: [
                                      [
                                        {
                                          size: [
                                            "normal",
                                            "small",
                                            "large",
                                            "huge",
                                          ],
                                        },
                                      ],
                                      [{ color: [] }, { background: [] }],
                                      ["bold", "italic", "underline", "strike"],
                                      [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                      // ['link', 'image', 'video'],
                                      [{ list: "ordered" }, { list: "bullet" }],
                                      ["blockquote", "code-block"],
                                      [{ align: [] }],
                                      [{ indent: "-1" }, { indent: "+1" }],
                                      ["clean"],
                                    ],
                                  }}
                                  bounds={".app"}
                                  onChange={(content) => {
                                    let data = { ...this.state.data };
                                    data.job_requirements = content;
                                    this.setState({ data });
                                  }}
                                  value={this.state.data.job_requirements}
                                />
                              )}
                              {this.state.errors.job_requirements && (
                                <div className="alert alert-danger">
                                  {this.state.errors.job_requirements}
                                </div>
                              )}
                            </div>
                            <div className="col-lg-12 col-md-12  mb-5">
                              <label>Other Skills</label>
                              <div className="form-group col-md-4">
                                <label htmlFor="skills">Select Editor </label>
                                <bootstrapForm.Control
                                  name="skills"
                                  as="select"
                                  custom
                                  className="custom-select"
                                  onChange={(e) => {
                                    this.setState({
                                      slills: e.target.value,
                                    });
                                  }}
                                >
                                  <option value="1">Editor 1</option>
                                  <option value="2"> Editor 2</option>
                                </bootstrapForm.Control>
                              </div>

                              {this.state.skills == 1 ? (
                                <SunEditor
                                  setOptions={{
                                    height: 200,
                                    buttonList: buttonList.complex, // Or Array of button list, eg. [['font', 'align'], ['image']]
                                    // plugins: [font] set plugins, all plugins are set by default
                                    // Other option
                                  }}
                                  onChange={(content) => {
                                    let data = { ...this.state.data };
                                    data.other_skills = content;
                                    this.setState({ data });
                                  }}
                                  setContents={
                                    this.state.data.other_skills == ""
                                      ? this.props.jobPost.data.other_skills
                                      : this.state.data.other_skills
                                  }
                                />
                              ) : (
                                <ReactQuill
                                  className="editorClassName"
                                  theme="snow"
                                  modules={{
                                    toolbar: [
                                      [
                                        {
                                          size: [
                                            "normal",
                                            "small",
                                            "large",
                                            "huge",
                                          ],
                                        },
                                      ],
                                      [{ color: [] }, { background: [] }],
                                      ["bold", "italic", "underline", "strike"],
                                      [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                      // ['link', 'image', 'video'],
                                      [{ list: "ordered" }, { list: "bullet" }],
                                      ["blockquote", "code-block"],
                                      [{ align: [] }],
                                      [{ indent: "-1" }, { indent: "+1" }],
                                      ["clean"],
                                    ],
                                  }}
                                  bounds={".app"}
                                  onChange={(content) => {
                                    let data = { ...this.state.data };
                                    data.other_skills = content;
                                    this.setState({ data });
                                  }}
                                  value={this.state.data.other_skills}
                                />
                              )}
                              {this.state.errors.other_skills && (
                                <div className="alert alert-danger">
                                  {this.state.errors.other_skills}
                                </div>
                              )}
                            </div>
                            <div className="col-lg-12 col-md-12 mb-5">
                              <label>How To Apply</label>
                              <div className="form-group col-md-4">
                                <label htmlFor="how_toapply">
                                  Select Editor{" "}
                                </label>
                                <bootstrapForm.Control
                                  name="how_toapply"
                                  as="select"
                                  custom
                                  className="custom-select"
                                  onChange={(e) => {
                                    this.setState({
                                      how_toapply: e.target.value,
                                    });
                                  }}
                                >
                                  <option value="1">Editor 1</option>
                                  <option value="2"> Editor 2</option>
                                </bootstrapForm.Control>
                              </div>
                              {this.state.how_toapply == 1 ? (
                                <SunEditor
                                  setOptions={{
                                    height: 200,
                                    buttonList: buttonList.complex, // Or Array of button list, eg. [['font', 'align'], ['image']]
                                    // plugins: [font] set plugins, all plugins are set by default
                                    // Other option
                                  }}
                                  onChange={(content) => {
                                    let data = { ...this.state.data };
                                    data.how_to_apply = content;
                                    this.setState({ data });
                                  }}
                                  setContents={
                                    this.state.data.how_to_apply == ""
                                      ? this.props.jobPost.data.how_to_apply
                                      : this.state.data.how_to_apply
                                  }
                                />
                              ) : (
                                <ReactQuill
                                  className="editorClassName"
                                  theme="snow"
                                  modules={{
                                    toolbar: [
                                      [
                                        {
                                          size: [
                                            "normal",
                                            "small",
                                            "large",
                                            "huge",
                                          ],
                                        },
                                      ],
                                      [{ color: [] }, { background: [] }],
                                      ["bold", "italic", "underline", "strike"],
                                      [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                      // ['link', 'image', 'video'],
                                      [{ list: "ordered" }, { list: "bullet" }],
                                      ["blockquote", "code-block"],
                                      [{ align: [] }],
                                      [{ indent: "-1" }, { indent: "+1" }],
                                      ["clean"],
                                    ],
                                  }}
                                  bounds={".app"}
                                  onChange={(content) => {
                                    let data = { ...this.state.data };
                                    data.how_to_apply = content;
                                    this.setState({ data });
                                  }}
                                  value={this.state.data.how_to_apply}
                                />
                              )}
                              {this.state.errors.how_to_apply && (
                                <div className="alert alert-danger">
                                  {this.state.errors.how_to_apply}
                                </div>
                              )}
                            </div>
                          </div>
                          <button type="sumbit" className="site-button m-b30">
                            Save
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  };

  render() {
    return (
      <>
        <Header />
        {this.renderPage()}
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  jobPost: state.entities.jobPost,
  sectors: state.entities.sector,
  experienceLevels: state.entities.experienceLevel,
  regions: state.entities.region,
  currency: state.entities.currency,
  educationLevels: state.entities.educationLevel,
  salaryType: state.entities.salaryType,
  employmentType: state.entities.employmentType,
  myJobs: state.entities.myJobs,
  towns: state.entities.town,
});

const mapDispatchToProps = (dispatch) => ({
  loadSectors: () => dispatch(loadSectors()),
  createJobPost: (data) => dispatch(createJobPost(data)),
  loadJobPost: (id) => dispatch(loadJobPost(id)),
  loadCurrency: () => dispatch(loadCurrency()),
  loadEmploymentTypes: () => dispatch(loadEmploymentTypes()),
  loadExperienceLevels: () => dispatch(loadExperienceLevels()),
  loadEducationLevels: () => dispatch(loadEducationLevels()),
  loadRegions: () => dispatch(loadRegions()),
  getRegionbyName: (id) => dispatch(getRegionbyName(id)),
  loadSalaryType: () => dispatch(loadSalaryType()),
  updateJobPost: (data) => dispatch(updateJobPost(data)),
  addJob: (data) => dispatch(addJob(data)),
  updateJob: (data) => dispatch(updateJob(data)),
  clearJobPost: () => dispatch(clearJobPost()),
  loadTowns: () => dispatch(loadTowns()),
  getSectorByName: (id) => dispatch(getSectorByName(id)),
  getTownByName: (id) => dispatch(getTownByName(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Companypostjobs);
