import { createSlice } from "@reduxjs/toolkit";
import { ApiCallBegan } from "../actions/api";
import checkCache from "../../services/cache";
import { coreCacheLimit } from "../../config.json";
import { getCurrentPage } from "../../utils/utilFunctions";
import { toast } from "react-toastify";
import _ from "lodash";

const slice = createSlice({
  name: "groups",
  initialState: {
    data: [],
    lastFetch: null,
    loading: true,
  },
  reducers: {
    groupsFetched: (state, action) => {
      state.data = action.payload.data.results;
      state.lastFetch = Date.now();
      state.loading = false;
    },
    groupsRequested: (state, action) => {
      state.loading = true;
    },
    groupsRequestFailed: (state, action) => {
      state.loading = false;
    },
  },
});


export const {groupsFetched, groupsRequested, groupsRequestFailed} = slice.actions;
export default slice.reducer;

export const loadGroups = () => (dispatch, getState) => {
    const {lastFetch} = getState().entities.groups;
    const diffInMinutes = (Date.now() - lastFetch) / 60000;
    if (diffInMinutes < coreCacheLimit) return;
    dispatch(
        ApiCallBegan({
        url: "api/core/groups/",
        onStart: groupsRequested.type,
        onSuccess: groupsFetched.type,
        onError: groupsRequestFailed.type,
        })
    );
    }