import { createSlice } from '@reduxjs/toolkit';
import { ApiCallBegan } from "../actions/api"
import { talentFetched } from './talent';

let referenceLastId = -1;
let languageLastId = -1;
let projectLastId = -1;
let certificationLastId = -1;
let experienceLastId = -1;
let educationLastId = -1;
const cvSlice = createSlice({
    name: "cv",
    initialState: {
        data: {
            title: "",
            skills: "",
            summary: "",
            cv_education: [],
            cv_experience: [],
            cv_project: [],
            cv_referance: [],
            cv_language: [],
            cv_certification: [],
        },
        loading: false,
        lastFetch: null,
    },
    reducers: {
        titleChanged: (state, action) => {
            state.data.title = action.payload
            return state;
        },
        skillsChanged: (state, action) => {
            state.data.skills = action.payload
            return state;
        },
        summaryChanged: (state, action) => {
            //console.log(action)
            state.data.summary = action.payload
            return state;
        },
        referenceAdded: (state, action) => {
            state.data.cv_referance.push({ ...action.payload, id: --referenceLastId })
            return state;
        },
        languageAdded: (state, action) => {
            state.data.cv_language.push({ ...action.payload, id: --languageLastId })
            return state;

        },
        languageRemoved: (state, action) => {
            state.data.cv_language = state.data.cv_language.filter(language => language.id !== action.payload)
            return state;

        },
        languageEdited: (state, action) => {
            state.data.cv_language = state.data.cv_language.map(language => language.id === action.payload.id ? action.payload : language)
            return state;

        },
        educationAdded: (state, action) => {
            state.data.cv_education.push({ ...action.payload, id: --educationLastId })
            return state;

        },
        educationRemoved: (state, action) => {
            state.data.cv_education = state.data.cv_education.filter(education => education.id !== action.payload)
            return state;

        },
        educationEdited: (state, action) => {
            state.data.cv_education = state.data.cv_education.map(education => education.id === action.payload.id ? action.payload : education)
            return state;
        },

        experienceAdded: (state, action) => {
            state.data.cv_experience.push({ ...action.payload, id: --experienceLastId })
            return state;

        },
        experienceRemoved: (state, action) => {
            state.data.cv_experience = state.data.cv_experience.filter(experience => experience.id !== action.payload)
            return state;
        },
        experienceEdited: (state, action) => {
            state.data.cv_experience = state.data.cv_experience.map(experience => experience.id === action.payload.id ? action.payload : experience)
            return state;
        },
        projectAdded: (state, action) => {
            state.data.cv_project.push({ ...action.payload, id: --projectLastId })
            return state;

        },
        projectRemoved: (state, action) => {
            state.data.cv_project = state.data.cv_project.filter(project => project.id !== action.payload)
            return state;
        },
        projectEdited: (state, action) => {
            state.data.cv_project = state.data.cv_project.map(project => project.id === action.payload.id ? action.payload : project)
            return state;
        },

        certificationAdded: (state, action) => {
            state.data.cv_certification.push({ ...action.payload, id: --certificationLastId })
            return state;

        },
        certificationRemoved: (state, action) => {
            state.data.cv_certification = state.data.cv_certification.filter(certification => certification.id !== action.payload)
            return state;
        },
        certificationEdited: (state, action) => {
            state.data.cv_certification = state.data.cv_certification.map(certification => certification.id === action.payload.id ? action.payload : certification)
            return state;
        },
        referenceAdded: (state, action) => {
            state.data.cv_referance.push({ ...action.payload, id: --referenceLastId })
            return state;
        },
        referenceRemoved: (state, action) => {
            state.data.cv_referance = state.data.cv_referance.filter(reference => reference.id !== action.payload)
            return state;
        },
        referenceEdited: (state, action) => {
            state.data.cv_referance = state.data.cv_referance.map(reference => reference.id === action.payload.id ? action.payload : reference)
            return state;
        },
        cvFetched: (state, action) => {
            //console.log(action.payload, "<< me data sudata")
            state.data = action.payload.data
            state.loading = false;
            state.lastFetch = Date.now();
            return state;


        },
        cvRequested: (state, action) => {
            state.loading = true;
            return state;

        },
        cvRequestFailed: (state, action) => {
            //console.log(action.payload, "error")
            if (action.payload.status === 404) {
            }
            state.data.title = "l"
            //console.log("sadas<<<<<<<<<<<<<<<<<<<<<<<<<<")
            state.loading = false;
            // state.error = "Error From Server"
            state.lastFetch = null;
            return state;

        },
        cvSent: (state, action) => {
            state.loading = false;
            state.lastFetch = Date.now();
            return state;

        }


    }

})

export const {
    titleChanged,
    referenceAdded,
    languageAdded,
    languageRemoved,
    languageEdited,

    educationAdded,
    educationEdited,
    educationRemoved,
    experienceAdded,
    experienceEdited,
    experienceRemoved,
    projectAdded,
    summaryChanged,
    skillsChanged,
    cvRequestFailed,
    cvRequested,
    cvFetched,
    projectEdited,
    projectRemoved,
    certificationAdded,
    certificationEdited,
    certificationRemoved,
    referenceEdited,
    referenceRemoved,
    cvSent



} = cvSlice.actions;

export default cvSlice.reducer;

export const loadLanguageById = (id) => (dispatch, getState) => {
    return getState().entities.cv.data.cv_language.find(language => language.id === id)
}
export const loadEducationById = (id) => (dispatch, getState) => {
    return getState().entities.cv.data.cv_education.find(education => education.id === id)
}
export const loadExperienceById = (id) => (dispatch, getState) => {
    return getState().entities.cv.data.cv_experience.find(experience => experience.id === id)
}
export const loadProjectById = (id) => (dispatch, getState) => {
    return getState().entities.cv.data.cv_project.find(project => project.id === id)

}
export const loadCertificationById = (id) => (dispatch, getState) => {
    return getState().entities.cv.data.cv_certification.find(certification => certification.id === id)
}
export const loadReferenceById = (id) => (dispatch, getState) => {
    return getState().entities.cv.data.cv_referance.find(reference => reference.id === id)
}




export const loadTalentCvInformation = (id="me") =>
    // remember to cache
    ApiCallBegan({
        url: `api/talent/cv/${id}/`,
        onSuccess: cvFetched.type,
        onError: cvRequestFailed.type,
        onStart: cvRequested.type,
    })

export const updateCv = () => {

}

export const sendTalentCvInformation = (data) => {
    // //console.log(data, "data")
    if (!data.lastFetch) {

        return ApiCallBegan({
            url: "api/talent/cv/",
            onSuccess: cvSent.type,
            method: "POST",
            data: data.data,
            onError: cvRequestFailed.type,
            onStart: cvRequested.type,
        })
    }
    else {
        // //console.log(data.data)
        return ApiCallBegan({
            url: "api/talent/cv/me/",
            onSuccess: cvSent.type,
            method: "PUT",
            data: data.data,
            onError: cvRequestFailed.type,
            onStart: cvRequested.type,
        })

    }


}
