import { createSlice } from '@reduxjs/toolkit';
import { ApiCallBegan } from "../actions/api"
import checkCache from "../../services/cache"
import { coreCacheLimit } from "../../config.json"
//console.log("sectorAggrigate reducer")

const slice = createSlice({
    name: "sectorAggrigate",
    initialState: {
        data: [],
        lastFetch: null,
        loading: true
    },
    reducers: {
        sectorAggrigateAdded: (sectorAggrigate, action) => {
            //console.log(sectorAggrigate, action)
            sectorAggrigate.data = action.payload.data
            sectorAggrigate.lastFetch = Date.now()
            sectorAggrigate.loading = false;
            return sectorAggrigate;
        },
        sectorAggrigateRequested: (sectorAggrigate, action) => {
            sectorAggrigate.loading = true;
            return sectorAggrigate
        },
        sectorAggrigateRequestFailed: (sectorAggrigate, action) => {
            sectorAggrigate.loading = false;
            return sectorAggrigate
        }

    }

})
const actions = slice.actions
// //console.log(actions.sectorAggrigateAdded(["dsds"]))
export const { sectorAggrigateAdded, sectorAggrigateRequested, sectorAggrigateRequestFailed } = slice.actions
export default slice.reducer;


export const getsectorAggrigateByName = (id) => (dispatch, getState) => {
    const sectorAggrigate = getState().entities.sectorAggrigate.data
    // //console.log(id,sectorAggrigate[1].id,"<<<<<<<><><><<<<<<<<<<<<<<<<<<<")
    const sectorAggrigateName = sectorAggrigate.find(sectorAggrigate => sectorAggrigate.id === parseInt(id))
    return sectorAggrigateName ? sectorAggrigateName.name : "sectorAggrigate"
    // return "sdsd"
}


// Action Creators
export const loadsectorAggrigates = () => (dispatch, getState) => {
    const { lastFetch } = getState().entities.sectorAggrigate
    if (checkCache(lastFetch, coreCacheLimit)) return;
    dispatch(ApiCallBegan({
        url: "api/jobs/jobsbysector/",
        onSuccess: sectorAggrigateAdded.type,
        onStart: sectorAggrigateRequested.type,
        onError: sectorAggrigateRequestFailed.type
    }))


}
