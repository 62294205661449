import { createSlice } from "@reduxjs/toolkit";
import { ApiCallBegan } from "../actions/api";
import checkCache from "../../services/cache";
import { coreCacheLimit } from "../../config.json"
import { toast } from "react-toastify";

const companyProfileSlice = createSlice({
    name: "companyProfile",
    initialState: {
        data: {},
        lastFetch: null,
        loading: true
    },
    reducers: {
        companyProfileFetched: (state, action) => {
            state.data = action.payload.data
            state.lastFetch = Date.now()
            state.loading = false;
            return state;
        },
        companyProfileRequested: (state, action) => {
            state.loading = true;
            return state
        },
        companyProfileRequestFailed: (state, action) => {
            state.loading = false;
            return state
        },

        companyProfileSent: (state, action) => {
            toast.success("Profile Updated")

            state.data = action.payload.data
            state.lastFetch = Date.now()
            state.loading = false;
            return state;
        }

    }
})

export const getCompanyProfile = state => state.entities.companyProfile
export const { companyProfileFetched, companyProfileRequested, companyProfileRequestFailed,companyProfileSent } = companyProfileSlice.actions
export default companyProfileSlice.reducer;


export const sendCompanyProfile = (data) => {
   return  ApiCallBegan({
        url: "api/company/employer/me/",
        onSuccess: companyProfileSent.type,
        onError: companyProfileRequestFailed.type,
        onStart: companyProfileRequested.type,
        data: data,
        method: "PUT",
    })

}

export const loadCompanyProfile = () => (dispatch, getState) => {
    const { lastFetch } = getState().entities.companyProfile
    if (checkCache(lastFetch, coreCacheLimit)) return;
    // //console.log(getState().entities,"getState<<<<<<<<<<<<<<<<<<<")
    // // remember to cache
    dispatch(ApiCallBegan({
        url: "api/company/employer/me/",
        onSuccess: companyProfileFetched.type,
        onError: "apiRequestFailed",
        onStart: companyProfileRequested.type,
    }))
}



