import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { http } from "../../services/httpService";
import { Accordion, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import { loadRegions } from "../../store/reducers/region";
import { loadExperienceLevels } from "../../store/reducers/experienceLevel";
import { loadSalaryType } from "../../store/reducers/salaryType";
import {
  loadlanguages,
  getLanguagebyName,
} from "../../store/reducers/language";
import { Modal } from "react-bootstrap";
import { getEducationName, loadEducationLevels } from "../../store/reducers/educationLevel";
import {
  loadAppliedApplicants,
  loadFilterdData,
} from "../../store/reducers/appliedApplicants";
import { apiUrl, languagesConstant } from "../../config.json";
import { loadTowns } from "../../store/reducers/town";
import { Multiselect } from "multiselect-react-dropdown";
import { authServices } from "../../services/authServices";
import { checkPermission } from "../../utils/utilFunctions";
import { getFieldOfStudyName, loadFieldOfStudies } from "../../store/reducers/fieldOfStudy";
import moment from "moment";

function CandidateFilterBar({ jobId }) {
  const dispatch = useDispatch();
  const region = useSelector((state) => state.entities.region);
  const towns = useSelector((state) => state.entities.town);

  const appliedApplicants = useSelector(
    (state) => state.entities.appliedApplicants
  );

  const languages = useSelector((state) => state.entities.language);

  const experience_level = useSelector(
    (state) => state.entities.experienceLevel
  );
  const education_level = useSelector((state) => state.entities.educationLevel);
  const educationLevels = useSelector((state) => state.entities.educationLevel);
  const fieldOfStudies = useSelector((state) => state.entities.fieldOfStudy);

  const salary_type = useSelector((state) => state.entities.salaryType);

  const handleSelectTown = (data) => {
    let selectedTowns = data.map((item) => item.id);
    setSelectedTowns(selectedTowns);
  };

  let csvData = [
    [
      "Full Name",
      "Gender",
      "DOB",
      "Email",
      "Phone",
      "Applied Region",
      "Applied Town",
      "Experience",
      "Experience History",
      "Experience Level",
      "Education Level",
      "Field Of Study",
      "Education History",
      "Region",
      "City",
      "Current Address",
      "Languages",
      "CV Link",
    ],
  ];
  let fileName = "";
  const [modal, setModal] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(loadExperienceLevels());
    dispatch(loadEducationLevels());
    dispatch(loadFieldOfStudies());
    dispatch(loadRegions());
    dispatch(loadSalaryType());
    dispatch(loadlanguages());
    dispatch(loadTowns());
  
  }, []);
  const [selectedTowns, setSelectedTowns] = useState();

  const loadModalPage = () => {
    if (appliedApplicants.filterLoading === true || fieldOfStudies.loading === true || educationLevels.loading === true) {
      return (
        <React.Fragment>
          <div className="d-flex justify-content-center">
            <div className="row">
              <h6>
                Exporting Applicants, This may Take a while, Please be patient.
              </h6>
            </div>
            <hr />
          </div>
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <i
                className="fa fa-spinner fa-spin m-r5"
                style={{ fontSize: "75px" }}
              ></i>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      appliedApplicants.exportData.forEach((element) => {
        let languages = [];
        let experience = "";
        let education = "";
        //console.log(dispatch(getLanguagebyName(element.employee.talent_language[0])),"talent Language")
        element.employee.talent_language.map((language) => {
          languages.push(dispatch(getLanguagebyName(language.language)));
        });
        try {
          // const exp = ;
          /**
           , * company:"YEs", cv:1, dateCreated:"2022-11-10T21:32:33.526824Z", duties_and_responsibilities:"Yes", end_date:"2022-11-10", id:1, position:"really", projects_completed:"Yes", remark:"", salary:1, start_date:"2022-11-10", type_of_organization:"NGO"
           * 
           * 
           */
          element.cv.cv_experience.forEach((exp) => {
            experience += `${exp.company} - (${exp.position}) - from  ${moment(exp.start_date).format("Do-MMM-YYYY")} to ${moment(exp.end_date).format("Do-MMM-YYYY")} , \n`;
          });
        } catch (Exception) {}
        
        try {
          // console.log(element.cv.cv_education, "cv experience");
          element.cv.cv_education.forEach((edu) => {
            // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", "cv Education<<<<<<<<<<<<<<<<<<");
            // console.log(dispatch(getFieldOfStudyName( edu.field_of_study)), "cv Education<<<<<<<<<<<<<<<<<<");
            education = `${dispatch(getEducationName(edu.degree))} from ${edu.school} in (${dispatch(getFieldOfStudyName( edu.field_of_study))})  , \n`;
          });
          // console.log(education, "cv Education");
        } catch (Exception) {}
        // console.log();
        const temp = [
          element.employee.user.first_name +
            " " +
            element.employee.user.last_name,
          element.employee.gender,
          moment(element.employee.dob).format("Do-MMM-YYYY")  ,
          element.employee.user.email,
          element.employee.phone_number,
          element.region,
          element.town,
          `${element.employee.experience_in_years} Years and ${element.employee.experience_in_months} months`,
          experience,
          element.employee.experience_level,
          element.employee.education_level,
          element.employee.field_of_study,
          education,
          element.employee.region,
          element.employee.city,
          element.employee.address,
          languages.join(", "),
          apiUrl + element.employee.uploaded_cv,
        ];
        csvData.push(temp);
      });
      if (appliedApplicants.data.length > 0) {
        fileName =
          "Applicants for (" + appliedApplicants.data[0][0].job + ").csv";
      }
      return (
        <React.Fragment>
          <div className="row">
            <div className="d-flex justify-content-center">
              <h6>
                Your File Is ready, Please Click The Download Button to Download
              </h6>
            </div>
            <hr />
          </div>
          <div className="d-flex justify-content-center">
            <div className="col-md-6">
              <CSVLink
                className="btn btn-block btn-success"
                data={csvData}
                filename={fileName}
              >
                Download Now
                {"            "}
                <i className="fa fa-download"></i>
              </CSVLink>
            </div>
          </div>
        </React.Fragment>
      );
    }
  };

  const renderPage = () => {
    if (
      region.loading ||
      experience_level.loading ||
      education_level.loading ||
      salary_type.loading ||
      languages.loading
    ) {
      return (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <i
              className="fa fa-spinner fa-spin m-r5"
              style={{ fontSize: "75px" }}
            ></i>
          </div>
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <aside id="accordion1" className="sticky-top sidebar-filter bg-white">
            <button
              className="btn btn-block btn-primary"
              onClick={() => {
                let regionFilter = "employee__region__in=";
                const regionCount = region.data.length;
                for (let i = 0; i < regionCount; i++) {
                  if (document.getElementById("region" + i).checked) {
                    regionFilter += region.data[i].id + ",";
                  }
                }
                regionFilter = regionFilter.slice(0, -1);

                let appliedRegionFilter = "region__in=";
                const appliedRegionCount = region.data.length;
                for (let i = 0; i < appliedRegionCount; i++) {
                  if (document.getElementById("appliedRegions" + i).checked) {
                    appliedRegionFilter += region.data[i].id + ",";
                  }
                }
                appliedRegionFilter = appliedRegionFilter.slice(0, -1);
                let townFilter = "";
                if (selectedTowns) {
                  townFilter = "town__in=" + selectedTowns.join(",");
                }

                let experience_levelFilter = "employee__experience_level__in=";
                const experience_levelCount = experience_level.data.length;
                for (let i = 0; i < experience_levelCount; i++) {
                  if (document.getElementById("experience" + i).checked) {
                    experience_levelFilter += experience_level.data[i].id + ",";
                  }
                }
                experience_levelFilter = experience_levelFilter.slice(0, -1);

                let languageFilter =
                  "employee__talent_language__language__in=" +
                  document.getElementById("language").value;
                let speakingFilter =
                  "employee__talent_language__speaking__in=" +
                  document.getElementById("speaking").value;
                let writingFilter =
                  "employee__talent_language__writing__in=" +
                  document.getElementById("writing").value;
                let readingFilter =
                  "employee__talent_language__reading__in=" +
                  document.getElementById("reading").value;
                let listeningFilter =
                  "employee__talent_language__listening__in=" +
                  document.getElementById("listening").value;

                //   let language2Filter =
                //     "employee__language2__in=" +
                //     document.getElementById("language").value;
                //   let language3Filter =
                //     "employee__language3__in=" +
                //     document.getElementById("language").value;

                let education_levelFilter =
                  "employee__education_level__in=" +
                  document.getElementById("educationLevel").value;

                let genderFilter = "employee__gender__in=";
                const genders = ["M", "F"];
                for (let i = 0; i < 2; i++) {
                  if (document.getElementById("gender" + i).checked) {
                    genderFilter += genders[i] + ",";
                  }
                }
                genderFilter = genderFilter.slice(0, -1);

                let maxYear =
                  "employee__experience_in_years__lte=" +
                  document.getElementById("workExperienceYearLessThan").value;
                let minYear =
                  "employee__experience_in_years__gte=" +
                  document.getElementById("workExperienceYearGreaterThan")
                    .value;
                let maxMonth =
                  "employee__experience_in_months__lte=" +
                  document.getElementById("workExperienceMonthLessThan").value;
                let minMonth =
                  "employee__experience_in_months__gte=" +
                  document.getElementById("workExperienceMonthGreaterThan")
                    .value;

                let filter =
                  townFilter +
                  "&" +
                  appliedRegionFilter +
                  "&" +
                  regionFilter +
                  "&" +
                  experience_levelFilter +
                  "&" +
                  education_levelFilter +
                  "&" +
                  languageFilter +
                  "&" +
                  speakingFilter +
                  "&" +
                  writingFilter +
                  "&" +
                  readingFilter +
                  "&" +
                  listeningFilter +
                  "&" +
                  education_level +
                  "&" +
                  genderFilter +
                  "&" +
                  maxYear +
                  "&" +
                  minYear +
                  "&" +
                  maxMonth +
                  "&" +
                  minMonth;
                // //console.log(gende    rFilter);

                // //console.log("match Params", jobId);
                dispatch(
                  loadAppliedApplicants(
                    `api/appliedjobs/jobs/getapplicants/${jobId}/?`,
                    filter
                  )
                );
              }}
            >
              Filter
            </button>
            {checkPermission("view", "applied__jobs") && (
              <button
                className="btn btn-block btn-primary"
                onClick={() => {
                  dispatch(loadFilterdData(jobId));
                  setModal(true);
                }}
              >
                Export Selection
              </button>
            )}
            <Accordion defaultActiveKey="0">
              <h6 className="title">
                <i className="fa fa-sliders m-r5"></i> Refined By{" "}
                <Link
                  to={"#"}
                  className="font-12 float-right"
                  onClick={() => {
                    const regionCount = region.data.length;
                    for (let i = 0; i < regionCount; i++) {
                      document.getElementById(`region${i}`).checked = false;
                    }
                    const experienceLevelCount = experience_level.data.length;
                    for (let i = 0; i < experienceLevelCount; i++) {
                      document.getElementById(`experience${i}`).checked = false;
                    }
                    document.getElementById("language").value = "";

                    document.getElementById("educationLevel").value = "";
                    for (let i = 0; i < 2; i++) {
                      document.getElementById("gender" + i).checked = false;
                    }
                    document.getElementById(
                      "workExperienceYearLessThan"
                    ).value = "";
                    document.getElementById(
                      "workExperienceYearGreaterThan"
                    ).value = "";
                    document.getElementById(
                      "workExperienceMonthLessThan"
                    ).value = "";
                    document.getElementById(
                      "workExperienceMonthGreaterThan"
                    ).value = "";
                    // dispatch(loadAppliedApplicants(jobId));
                  }}
                >
                  Reset All
                </Link>
              </h6>

              <Accordion.Toggle as={Card} eventKey="0">
                <div className="acod-head">
                  <h6 className="acod-title">
                    <Link data-toggle="collapse" to="#regions">
                      Region
                    </Link>
                  </h6>
                </div>
              </Accordion.Toggle>

              <Accordion.Collapse eventKey="0">
                <div id="regions" className="acod-body collapse show">
                  <div className="acod-content">
                    {region.data.map((item, index) => {
                      return (
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={"region" + index}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={"region" + index}
                          >
                            {item.name}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Accordion.Collapse>

              <Accordion.Toggle as={Card} eventKey="6">
                <div className="acod-head">
                  <h6 className="acod-title">
                    <a
                      data-toggle="collapse"
                      href="#appliedRegions"
                      className="collapsed"
                    >
                      Applied Regions{" "}
                    </a>
                  </h6>
                </div>
              </Accordion.Toggle>

              <Accordion.Collapse eventKey="6">
                <div id="appliedRegions" className="acod-body collapse show">
                  <div className="acod-content">
                    {region.data.map((item, index) => {
                      return (
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={"appliedRegions" + index}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={"appliedRegions" + index}
                          >
                            {item.name}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Accordion.Collapse>

              <Accordion.Toggle as={Card} eventKey="7">
                <div className="acod-head">
                  <h6 className="acod-title">
                    <a
                      data-toggle="collapse"
                      href="#appliedTowns"
                      className="collapsed"
                    >
                      Applied Towns{" "}
                    </a>
                  </h6>
                </div>
              </Accordion.Toggle>

              <Accordion.Collapse eventKey="7">
                <div id="appliedTowns" className="acod-body collapse show">
                  <div className="acod-content">
                    <Multiselect
                      options={towns.data}
                      // onChange={handleSelectTown}
                      onSelect={handleSelectTown}
                      onRemove={handleSelectTown}
                      displayValue="name"
                      id="appliedTowns"
                      value={selectedTowns}
                      placeholder="Select Towns"
                      isSerchable={true}
                    />
                  </div>
                </div>
              </Accordion.Collapse>

              <Accordion.Toggle as={Card} eventKey="1">
                <div className="acod-head">
                  <h6 className="acod-title">
                    <a
                      data-toggle="collapse"
                      href="#experience"
                      className="collapsed"
                    >
                      Experience Level
                    </a>
                  </h6>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <div id="experience" className="acod-body collapse show">
                  <div className="acod-content">
                    {experience_level.data.map((item, index) => {
                      return (
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={"experience" + index}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={"experience" + index}
                          >
                            {item.name}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Accordion.Collapse>

              <Accordion.Toggle as={Card} eventKey="2">
                <div className="acod-head">
                  <h6 className="acod-title">
                    <a
                      data-toggle="collapse"
                      href="#Language"
                      className="collapsed"
                    >
                      Language
                    </a>
                  </h6>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <div id="Language" className="acod-body collapse show">
                  <div className="acod-content">
                    <div className="col-md-12">
                      <div className="row">
                        {/* <label htmlFor=""> Language</label> */}

                        <div
                          className="form-control"
                          style={{ padding: "0px" }}
                        >
                          <Form.Control
                            as="select"
                            custom
                            className="btn dropdown-toggle text-left btn-default"
                            id="language"
                          >
                            <option value={""}>Select Language</option>
                            {languages.data.map((item, index) => {
                              return (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </div>
                      </div>
                      <div className="row">
                        {/* <label htmlFor=""> Language</label> */}
                        <div className="col-md-6">
                          <label htmlFor="speaking">Speaking</label>
                          <div
                            className="form-control"
                            style={{ padding: "0px" }}
                          >
                            <Form.Control
                              as="select"
                              className="btn dropdown-toggle text-left btn-default p0"
                              id="speaking"
                            >
                              <option value={""}>Select</option>
                              {[0, 1, 2, 3, 4].map((item, index) => {
                                return (
                                  <option key={index} value={item}>
                                    {languagesConstant[item]}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="speaking">Listening</label>
                          <div
                            className="form-control"
                            style={{ padding: "0px" }}
                          >
                            <Form.Control
                              as="select"
                              className="btn dropdown-toggle text-left btn-default p0"
                              id="listening"
                            >
                              <option value={""}>Select</option>
                              {[0, 1, 2, 3, 4].map((item, index) => {
                                return (
                                  <option key={index} value={item}>
                                    {languagesConstant[item]}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* <label htmlFor=""> Language</label> */}
                        <div className="col-md-6">
                          <label htmlFor="speaking">Writing</label>
                          <div
                            className="form-control"
                            style={{ padding: "0px" }}
                          >
                            <Form.Control
                              as="select"
                              className="btn dropdown-toggle text-left btn-default p0"
                              id="writing"
                            >
                              <option value={""}>Select</option>
                              {[0, 1, 2, 3, 4].map((item, index) => {
                                return (
                                  <option key={index} value={item}>
                                    {languagesConstant[item]}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="speaking">Reading</label>
                          <div
                            className="form-control"
                            style={{ padding: "0px" }}
                          >
                            <Form.Control
                              as="select"
                              className="btn dropdown-toggle text-left btn-default p0"
                              id="reading"
                            >
                              <option value={""}>Select</option>
                              {[0, 1, 2, 3, 4].map((item, index) => {
                                return (
                                  <option key={index} value={item}>
                                    {languagesConstant[item]}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Collapse>

              <Accordion.Toggle as={Card} eventKey="3">
                <div className="acod-head">
                  <h6 className="acod-title">
                    <a
                      data-toggle="collapse"
                      href="#Education"
                      className="collapsed"
                    >
                      Education Level
                    </a>
                  </h6>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <div id="Education" className="acod-body collapse show">
                  <div className="acod-content">
                    <div className="row">
                      {/* <label htmlFor=""> Education Level</label> */}
                      <div className="form-control" style={{ padding: "0px" }}>
                        <Form.Control
                          as="select"
                          custom
                          className="btn dropdown-toggle text-left btn-default"
                          id="educationLevel"
                        >
                          <option value={""}>Select Education Level</option>
                          {education_level.data.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Collapse>
              <Accordion.Toggle as={Card} eventKey="4">
                <div className="acod-head">
                  <h6 className="acod-title">
                    <a
                      data-toggle="collapse"
                      href="#salary"
                      className="collapsed"
                    >
                      Gender
                    </a>
                  </h6>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="4">
                <div id="gender" className="acod-body collapse show">
                  <div className="acod-content">
                    {[
                      { name: "Male", id: "M" },
                      { name: "Female", id: "F" },
                    ].map((item, index) => {
                      return (
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={"gender" + index}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={"gender" + index}
                          >
                            {item.name}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Accordion.Collapse>
              <Accordion.Toggle as={Card} eventKey="5">
                <div className="acod-head">
                  <h6 className="acod-title">
                    <a
                      data-toggle="collapse"
                      href="#workExperience"
                      className="collapsed"
                    >
                      Work Experience
                    </a>
                  </h6>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="5">
                <div id="workExperience" className="acod-body collapse show">
                  <div className="">
                    <div className="row">
                      <div className="col-md-6">
                        {" "}
                        <div className="custom-control p-3">
                          <label className="" htmlFor="function-services-1">
                            Max Year
                          </label>
                          <input
                            type="number"
                            min="0"
                            className="form-control"
                            placeholder="Max Year"
                            id="workExperienceYearLessThan"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="custom-control p-3">
                          <label className="" htmlFor="function-services-1">
                            Max Month
                          </label>
                          <input
                            type="number"
                            min="0"
                            className="form-control"
                            placeholder="Max Month"
                            id="workExperienceMonthLessThan"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="custom-control p-3">
                          <label className="" htmlFor="function-services-1">
                            Min Year{" "}
                          </label>
                          <input
                            type="number"
                            min="0"
                            className="form-control"
                            placeholder="min Year"
                            id="workExperienceYearGreaterThan"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="custom-control p-3">
                          <label className="" htmlFor="function-services-1">
                            Min Month{" "}
                          </label>
                          <input
                            type="number"
                            min="0"
                            className="form-control"
                            placeholder="Min Month"
                            id="workExperienceMonthGreaterThan"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Collapse>
            </Accordion>
          </aside>
          <Modal
            show={modal}
            onHide={setModal}
            className="modal fade modal-bx-info"
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                {/* Custom Modal Styling */}
                <h5 className="modal-title">Export Applicants Data</h5>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{loadModalPage()}</Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setModal(false)}
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>
        </React.Fragment>
      );
    }
  };

  return <div className="col-xl-3 col-lg-4 col-md-5 m-b30">{renderPage()}</div>;
}
export default CandidateFilterBar;
