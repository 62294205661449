import React from "react";
import { Link } from "react-router-dom";
import { Reveal, Tween } from "react-gsap";

var bnr2 = require("./../../../images/slide1.jpg");

const SideAD = ({ title, body, image, link }) => {
  return (
    <div
      className="ad-bx"
      style={{
        marginTop: "10px",
        backgroundImage: `url("${image}")`,
        backgroundColor: "#0009306b",
        backgroundBlendMode: "multiply",
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="quote-info">
        <Reveal>
          <Tween
            from={{ opacity: 0, transform: "translate3d(-100px, 0, 0)" }}
            ease="back.out(1.4)"
            duration={2}
          >
            <h4>{title}</h4>
          </Tween>
        </Reveal>
        <Reveal>
          <Tween
            from={{ opacity: 0, transform: "translate3d(100px, 0, 0)" }}
            ease="back.out(1.4)"
            duration={2}
          >
            <p>{body}</p>
          </Tween>
        </Reveal>
        {link.length > 5 && (
          <a href={link} target="_blank" className="site-button">
            Click Here
          </a>
        )}
      </div>
    </div>
  );
};

export default SideAD;
