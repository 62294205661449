import React from 'react';
import { Form } from 'react-bootstrap';

const DatePicker = ({ name, label, range = [1980, 2060], year = 0, month = 0 }) => {
    const interpolateRange = (range) => {
        let list = []
        for (var i = range[0]; i <= range[1]; i++) list.push(i)
        return list;
    }

    const months = (selected = 0) => {
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        return months.map((month, index) => {
            return <option key={index} value={index + 1} selected={selected.toString() === (index + 1).toString()}>{month}</option>
        }
        )
    }

    return (
        <div className="form-group">
            <label>{label}</label>
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <Form.Control name={name + "-year"} id={name + "-year"} as="select">
                        {interpolateRange(range).map((item) => {
                            return (<option key={item} value={item} selected={item.toString() === year.toString()}> {item}</option>)
                        })}
                    </Form.Control>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <Form.Control name={name + "-month"} id={name + "-month"} as="select">
                        {months(month)}
                    </Form.Control>
                </div>
            </div>
        </div>
    );
}

export default DatePicker;