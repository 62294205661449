import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Header from "./../Layout/Header";
import Footer from "./../Layout/Footer";
import PageTitle from "./../Layout/PageTitle";
import Jobfindbox from "./../Element/Jobfindbox";
import Accordsidebar from "./../Element/Accordsidebar";
import auth from "../../services/authServices";
import { getOpenJobs } from "../../store/reducers/openJobs";
import { getOpenJobsUrl } from "../../config.json";
import "../../css/text.css";
import { getSalary } from "../../utils/utilFunctions";
import JobTile from "../Element/common/jobTile";

import { Nav, Tab } from "react-bootstrap";
import { Controls, PlayState, Tween } from "react-gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import gsap from "gsap";

gsap.registerPlugin(ScrollTrigger);
//Images
// import logo from "./../../images/logo/icon1.png";
var bnr = require("./../../images/main-slider/slide2T.png");

function Browsejobfilterlist() {
  const primaryColor = "#009299";
  const secondaryColor = "#009299";
  const user = auth.getCurrentUser();
  const dispatch = useDispatch();
  const jobs = useSelector((state) => state.entities.openJobs);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getOpenJobs());
  }, []);
  return (
    <>
      <Header />
      <div className="page-content bg-white">
      <Tween
              from={{ x: "200px",backgroundColor:"#ffffff" }}
              to={{ x: "0px",backgroundColor:"#009299" }}
              duration={1.7}
              ease="expo.out(1.7)"
            >
        <div
          className="dez-bnr-inr "
          style={{ backgroundImage: "url(" + bnr + ")", maxHeight: "400px",backgroundColor:"#009299",backgroundSize:"contain",backgroundRepeat:"no-repeat",backgroundPositionX:"left" }}
        >
          
          <PageTitle motherName="Home" activeName="Browse Jobs" />
        </div>
        </Tween>
        <Jobfindbox />
        <div className="content-block">
          <div className="section-full browse-job p-b50">
            <div className="container">
              <div className="row">
                <Accordsidebar />
                <div className="col-xl-9 col-lg-8 col-md-7">
                  <div className="job-bx-title clearfix">
                    <h5 className="font-weight-700 pull-left text-uppercase">
                      {jobs.count} Jobs Found
                    </h5>
                    
                    {/* <div className="float-right">
											<span className="select-title">Sort by</span>
											<select className="custom-btn" style={{width:"auto"}} id = {"sort"} onChange={() => { //console.log(document.getElementById("sort").value) }}>
												<option value={"1"}>Date Posted (Asc)</option>
												<option value={"2"}>Date Posted (Desc)</option>
												{/* <option value={"1"}>Date Posted (Asc)</option>
												<option value={"1"}>Date Posted (Asc)</option> 
												{/* <option>Last Months</option>
												<option>Last Weeks</option>
												<option>Last 3 Days</option> 
											</select>
											<div className="float-right p-tb5 p-r10">
												<Link to={"/browse-job-filter-list"} className="p-lr5"><i className="fa fa-th-list"></i></Link>
												<Link to={"/browse-job-filter-grid"} className="p-lr5"><i className="fa fa-th"></i></Link>
											</div> 
										</div> */}
                  </div>
                  <ul className="post-job-bx">
                    {jobs.data.map((section) => {
                      return section.map((job, index) => {
                        let regions = job.regions.join(", ");
                        if (regions.length > 30) {
                          regions = regions.slice(0, 30) + "...";
                        }
                        return (
                          <JobTile
                          key={job.id}
                          job={job}
                          regions={regions}
                        />
                          
                        );
                      });
                    })}
                  </ul>
                  <div className="m-t30">
                    <div className="d-flex">
                      {jobs.next && (
                        <Link
                          className="site-button button-sm mr-auto"
                          to={"#"}
                          onClick={() => {
                            //console.log(jobs.next);
                            let url = jobs.next.split("?")[1];
                            dispatch(getOpenJobs(`${getOpenJobsUrl}?${url}`));
                          }}
                        >
                          <i className="ti-dot"></i> Load More
                        </Link>
                      )}

                      {/* <Link className="site-button button-sm" to={""}>Next <i className="ti-arrow-right"></i></Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Browsejobfilterlist;
