import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import CompanyProfileSideBar from "../Element/companyProfileSideBar";
import {
  loadUsers,
  updateUser,
  userFetched,
  createUser,
  loadUser,
} from "../../store/reducers/users";
import { connect } from "react-redux";
import Joi from "joi-browser";
import _ from "lodash";
import Loading from "../Element/common/loading";
import { Modal } from "react-bootstrap";
import Form from "../Element/common/form";
import { loadGroups } from "../../store/reducers/groups";
import { checkPermission } from "../../utils/utilFunctions";

const pageUrl = "api/company/employerUser/";

class CompanyUsersForm extends Form {
  role = [
    { id: 0, name: "Admin" },
    { id: 1, name: "User" },
  ];
  status = [
    { id: 0, name: "Inactive" },
    { id: 1, name: "Active" },
  ];
  state = {
    loaded: false,
    id: "",
    data: {
      first_name: "",
      last_name: "",
      password: "",
      email: "",
      role: "",
      status: "",
    },
    errors: {},
  };

  schema = {};
  componentDidMount() {
    const permission = checkPermission("change", "user") || checkPermission("add", "user");
    if (!permission) {
      this.props.history.push("/");
    }
    this.props.loadGroups();
    if (this.props.match.params.id !== "new") {
      this.setState({ id: this.props.match.params.id });
      this.props.loadUser(this.props.match.params.id);
      this.schema = {
        first_name: Joi.string().required().label("First Name"),
        last_name: Joi.string().required().label("Last Name"),
        password: Joi.string().allow("").optional().label("Password"),
        email: Joi.string().required().label("Email"),
        role: Joi.number().required().label("Role"),
        status: Joi.number().required().label("Status"),
      };
    } else {
      this.schema = {
        first_name: Joi.string().required().label("First Name"),
        last_name: Joi.string().required().label("Last Name"),
        // password: Joi.string().allow("").optional().label("Password"),
        // email: Joi.string().required().label("Email"),
        role: Joi.number().required().label("Role"),
        status: Joi.number().required().label("Status"),
      };
    }
    this.schema = {
      first_name: Joi.string().required().label("First Name"),
      last_name: Joi.string().required().label("Last Name"),
      password: Joi.string().allow("").optional().label("Password"),
      email: Joi.string().required().label("Email"),
      role: Joi.number().required().label("Role"),
      status: Joi.number().required().label("Status"),
    };
  }

  doSubmit = () => {
    // Call the server
    //console.log("Submitted");
    if (this.props.match.params.id === "new") {
      this.props.createUser(this.state.data);
    } else {
      //console.log("update");
      let data = this.state.data;
      data.id = this.props.users.user.id;
      data.status = data.status == 1 ? true : false;
      let payload = this.props.users.user;

      let newData = {};
      newData.user = data;
      newData.role = data.role;
      newData.id = this.props.match.params.id;
      newData.company = payload.company;
      this.props.updateUsers(newData);
    }
  };

  renderPage = () => {
    //console.log(
    //   this.props.users.loading && this.props.users.user.user === undefined,
    //   "Condition "
    // );
    if (
      (this.props.users.loading || this.props.users.user.user === undefined) &&
      this.props.match.params.id !== "new"
    ) {
      return (
        <div className="col-xl-9 col-lg-8 m-b30">
          <Loading />
        </div>
      );
    } else {
      //console.log(this.props.users);
      //console.log(
      //   this.props.match.params.id !== "new" && this.state.loaded === false,
      //   "inner"
      // );
      if (this.props.match.params.id !== "new" && this.state.loaded === false) {
        let data = this.state.data;
        data.first_name = this.props.users.user.user.first_name;
        data.last_name = this.props.users.user.user.last_name;
        data.email = this.props.users.user.user.email;
        data.role = this.props.users.user.role.id;
        data.status = this.props.users.user.user.is_active ? 1 : 0;
        //console.log(
        //   this.props.users.user,
        //   "<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<Data"
        // );

        this.setState({ data, loaded: true });
      } else {
        return (
          <div className="col-xl-9 col-lg-8 m-b30">
            <div className="job-bx table-job-bx clearfix">
              <div className="job-bx-title clearfix">
                <h5 className="font-weight-700 pull-left text-uppercase">
                  User Manager
                </h5>
                <Link
                  to={"/company-users"}
                  className="site-button right-arrow button-sm float-right"
                >
                  Back
                </Link>
              </div>

              <form onSubmit={this.handleSubmit}>
                <div className="row m-b30">
                  <div className="col-lg-6 col-md-6">
                    {this.renderInput("first_name", "First Name")}
                  </div>
                  <div className="col-lg-6 col-md-6">
                    {this.renderInput("last_name", "Last Name")}
                  </div>
                  {this.props.match.params.id === "new" ? (
                    <>
                      <div className="col-lg-12 col-md-12">
                        {this.renderInput("password", "Password", "password")}
                      </div>
                      <div className="col-lg-12 col-md-12">
                        {this.renderInput("email", "Email")}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <div className="col-lg-6 col-md-6">
                    {this.renderSelect(this.props.groups.data, "role", "Role")}
                  </div>
                  <div className="col-lg-6 col-md-6">
                    {this.renderSelect(this.status, "status", "Status")}
                  </div>
                </div>

                {this.renderButton("Save")}
              </form>
            </div>
          </div>
        );
      }
    }
  };

  render() {
    return (
      <>
        <Header />
        <div className="page-content bg-white">
          <div className="content-block">
            <div className="section-full bg-white p-t50 p-b20">
              <div className="container">
                <div className="row">
                  <CompanyProfileSideBar currentPage="Manage Users" />
                  {this.renderPage()}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.entities.users,
  groups: state.entities.groups,
});

const mapDispatchToProps = (dispatch) => ({
  loadUsers: (url) => dispatch(loadUsers(url)),
  updateUsers: (data) => dispatch(updateUser(data)),
  createUser: (data) => dispatch(createUser(data)),
  userFetched: (data) => dispatch(userFetched(data)),
  loadUser: (id) => dispatch(loadUser(id)),
  loadGroups: () => dispatch(loadGroups()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyUsersForm);

// export default CompanyUsers;
