import { createSlice } from '@reduxjs/toolkit';
import { ApiCallBegan } from "../actions/api"
import checkCache from "../../services/cache"
import { coreCacheLimit } from "../../config.json"



const FieldOfStudySlice = createSlice({
    name: "fieldOfStudy",
    initialState: {
        data: [],
        lastFetch: null,
        loading: false
    },
    reducers: {
        FieldOfStudyAdded: (FieldOfStudy, action) => {
            FieldOfStudy.data = action.payload.data.results
            FieldOfStudy.lastFetch = Date.now()
            FieldOfStudy.loading = false;

            return FieldOfStudy
        },
        FieldOfStudyRequested: (FieldOfStudy, action) => {
            FieldOfStudy.loading = true;
            return FieldOfStudy
        },
        FieldOfStudyRequestFailed: (FieldOfStudy, action) => {
            FieldOfStudy.loading = false;
            return FieldOfStudy
        }


    }

})

export const { FieldOfStudyAdded, FieldOfStudyRequestFailed, FieldOfStudyRequested } = FieldOfStudySlice.actions
export default FieldOfStudySlice.reducer;

export const getFieldOfStudyName = (id) => (dispatch, getState) => {
    const fos = getState().entities.fieldOfStudy.data
    const fieldOfStudy = fos.find(fieldOfStudy => fieldOfStudy.id === id)
    // //console.log(fieldOfStudy?fieldOfStudy.name: "asd")
    return fieldOfStudy ? fieldOfStudy.name : ""
    // return "sdsd"
}


export const loadFieldOfStudies = () => (dispatch, getState) => {
    const { lastFetch } = getState().entities.fieldOfStudy
    if (checkCache(lastFetch, coreCacheLimit)) return;
   dispatch( ApiCallBegan({
        url: "api/core/fieldofstudy/",
        onStart: FieldOfStudyRequested.type,
        onSuccess: FieldOfStudyAdded.type,
        onError: FieldOfStudyRequestFailed.type
    }))
}