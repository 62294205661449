import {createSlice} from "@reduxjs/toolkit";
import {ApiCallBegan} from "../actions/api";
import {toast} from "react-toastify";

const slice = createSlice({
    name :"town",
    initialState: {
        data: [],
        lastFetch: null,
        loading: true
    },
    reducers: {
        townFetched: (state, action) => {
            state.data = action.payload.data.results
            state.lastFetch = Date.now()
            state.loading = false;
            return state;
        },
        townRequested: (state, action) => {
            state.loading = true;
            return state
        },
        townRequestFailed: (state, action) => {
            state.loading = false;
            return state
        }
    }
})

export const {townFetched, townRequested, townRequestFailed} = slice.actions
export default slice.reducer;

export const getTownByName = (id) => (dispatch, getState) => {
    const town = getState().entities.town.data
    const townName = town.find(town => town.id === id)
    return townName ? townName.name : ""
}



export const loadTowns = ()=>{
    return ApiCallBegan({
        url: "api/core/town/",
        onSuccess: townFetched.type,
        onError: townRequestFailed.type,
        onStart: townRequested.type,
    })
}
