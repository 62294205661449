import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMailBulk, faPhone, faLocation } from '@fortawesome/free-solid-svg-icons'

const Template1LeftPanel = ({ list, title, is_list, content = "" }) => {
    // const list = [{icon:<FontAwesomeIcon icon={faLocation} />,text:"Address"},{icon:<FontAwesomeIcon icon={faPhone} />,text:"Phone"},{icon:<FontAwesomeIcon icon={faMailBulk} />,text:"Email"}]
    const buildContent = () => {
        try{

            if (is_list) {
                return (list.map((item, index) => {
                    return <li style={{ color: "white", fontSize: "12px" }} key={index}>{item.text}</li>
                }));
    
            }
            else if (content !== "") {
                return (
                    <div style={{color:"white"}}>
    
    
                        <div dangerouslySetInnerHTML={{ __html: content }}></div>
                    </div>)
    
    
            }
            else {
                return list.map((item, index) => {
                    return (
                        <p style={{ color: "white", fontSize: "12px" }} key={index}>
                            {item.icon} &nbsp;&nbsp;
                            {item.text}
                        </p>
                    );
                })
            }
        }
        catch(e){
            console.log(e)
        }
    }
    return (
        <React.Fragment>
            <h4 style={{ color: "white", fontFamily: "serif", fontSize: "16px" }}>
                {title.toUpperCase()}
            </h4>
            {buildContent()}


            <hr />
        </React.Fragment>
    );
}

export default Template1LeftPanel;